import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormGroup,
  Switch,
  FormHelperText,
  InputLabel,
  Menu,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import Modal from "@material-ui/core/Modal";
import { Link, withRouter } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import AccountCreationControllerWeb, {
  Props
} from "./AccountCreationController.web";
import { webemptyImage, sortIcon, filterIcon } from "../assets";
import AppHeader from "../../../../components/src/AppHeader.web";
import NoTeamsFound from "../../../../components/src/NoTeamsFound.web";;
import TeamMember from "../../../../components/src/TeamMemberListTable.web";
import MembersListTable from "../../../../components/src/MembersListTable.web";
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import { customStyles } from "./TeamsStyles.web";
import ConfirmActionModal from "./ConfirmActionModal.web";
import TeamMembersSort from "./TeamMembersSort.web";
import EmailEdit from "./EmailEdit.web";
import EditMember from "./EditMember.web";
import EditPassword from "./EditPassword.web";
import TeamSort from "./TeamSort.web";
import ReactivateMember from "./ReactivateMember.web";
import WebLoader from "../../../../components/src/WebLoader.web";
import { showMessageBasedOnRole } from "../../../../components/src/ReusableFunctions";
import SubscriptionScreenWeb from "./SubscriptionScreen.web";
export const configJSON = require("../config");

export const getRenderedComponent = (
  teamSearchValue: string,
  teamFilterTab: string[],
  teamSearchData: any[],
  configJSON: any
) => {
  if (teamSearchValue !== "" || teamFilterTab.length > 0) {
    if (teamSearchData.length > 0) {
      return "TeamMember Component";
    } else {
      return configJSON.notTemsFondTxt;
    }
  }
  return null;
};
class AccountCreationWeb extends AccountCreationControllerWeb {
  constructor(props: Props) {
    super(props);
  }

  dropMenuClick = (e: any) => {this.setState({ anchorEl: e.currentTarget })}

  

  listTeamsData = () => {
    const {
      unAssignedTeamList,
      teamsList,
      teamSearchValue,
      teamFilterTab
    } = this.state;
    const { classes } = this.props;

    if (teamsList.length === 0) {
      return (
        <NoTeamsFound
          classes={classes}
          emptyImage={webemptyImage}
          createTeamHeading={this.t(`${this.labelwebCreateTeam}`)}
          createTeamSubText={this.t(`${this.labelwebCreateTeamSubtxt}`)}
          creatTeamHandler={this.createTeamHandler}
          createTeamBtn={this.t(`${this.labelwebCreateTeamtxt}`)}
        />
      );
    }

    if (teamSearchValue !== "" || teamFilterTab.length > 0) {
      if (this.state.teamSearchData.length > 0) {
        return (
          <TeamMember
            renderTeams={this.state.teamSearchData}
            renameTeam={this.renameTeamHandler}
            deleteTeam={this.deleteTeamHandler}
            multipleDelete={this.multipleDeleteHandler}
            userRole={this.userRole}
          />
        );
      } else {
        return <Box className="no-member">{this.t(`${configJSON.notTemsFondTxt}`)}</Box>;
      }
    }

    return (
      <TeamMember
        unAssignedTeams={unAssignedTeamList}
        renderTeams={teamsList}
        renameTeam={this.renameTeamHandler}
        deleteTeam={this.deleteTeamHandler}
        multipleDelete={this.multipleDeleteHandler}
        getSelectedRecords={this.getSelectedRecords}
        userRole={this.userRole}
      />
    );
  };

  memberTeamsData = (t:any) => {
    if (this.state.isFiltering) {
      if (this.state.filteredData.length > 0) {
        return (
          <MembersListTable
            renderMembers={this.state.filteredData}
            editMember={this.editMemberHandler}
            deactivateMember={this.deactivateMemHandler}
            reactivateMember={this.reactivateMemHAndler}
            addMembertoTeam={this.addMembertoTeamHandler}
            showEditOption={true}
            showDeactivateOption={true}
            userRole={this.userRole}
            resendInviteOption={this.resendInviteHandler}
            t={this.t}
          />
        );
      } else {
        return <Box className="no-member">{this.t(`${configJSON.noMemberFound}`)}</Box>;
      }
    } else if (this.state.membersList.length > 0) {
      const sortedMembers = this.state.membersList.sort((a:any, b:any) => {
        if (a.attributes.deactivated === b.attributes.deactivated) {
          return 0; 
        }
        return a.attributes.deactivated ? 1 : -1; 
      });
      
      return (
        <MembersListTable
          renderMembers={sortedMembers}
          editMember={this.editMemberHandler}
          deactivateMember={this.deactivateMemHandler}
          reactivateMember={this.reactivateMemHAndler}
          addMembertoTeam={this.addMembertoTeamHandler}
          showEditOption={true}
          showDeactivateOption={true}
          userRole={this.userRole}
          getSelectedRecords={this.getSelectedRecords}
          clearSelection={this.state.clearSelectedValues}
          resendInviteOption={this.resendInviteHandler}
          t={this.t}
        />
      );
    } else {
      return <Box className="no-member">{this.t(`${configJSON.noMemberFound}`)}</Box>;
    }
  };

  showTeamsHeader = (isTeamsHeader: boolean,userRole:string) => {
    const { classes } = this.props;
    if(isTeamsHeader){
      return (
        <>
          {userRole === 'owner' &&
            <Box className="heading-right">
              <Box component="span" className="members-count">
                {this.state.membersList.length === 1 ? <>{this.state.membersList.length} {this.t(`${configJSON.membersTxtSingle}`)}</> : <>{this.state.membersList.length} {this.t(`${configJSON.membersTxt}`)}</>}
              </Box>

              <Box className={classes.dropMenu} >
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  variant="outlined"
                  onClick={this.dropMenuClick}
                  className="team-dropmenu-btn"
                >
                  + {this.t(`${configJSON.inviteMemberTxt}`)}
                </Button>

                <Menu
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  getContentAnchorEl={null}
                  onClose={() => { this.setState({ anchorEl: null }) }}
                  className={classes.dropDropdown}
                >
                  <MenuItem data-test-id="InviteDropdown" onClick={this.dropMenuClose}>{this.t(`${configJSON.addManuallyTxt}`)} </MenuItem>
                  <MenuItem data-test-id="BulkDropdown" onClick={this.dropMenuClose2}>{this.t(`${configJSON.bulkUploadTxt}`)} </MenuItem>
                </Menu>
              </Box>

            </Box>
          }
        </>
      )
    }
  }

  opensubscriptionmodal = () => {
    return (
      this.state.isCartOpen && (
        <SubscriptionScreenWeb
          navigation={undefined}
          id="subscription-screen-id"
          handleCloseCartEvent={this.handleCloseCart}
          isCartOpen={this.state.isCartOpen}
          type="skip"
          data-test-id="subscription-screen"
        />
      )
    );
  };
  

  render() {
    const { classes } = this.props;
    const pageURL = window.location.pathname;
    const isTeamsHeader: boolean = this.state.isTeamsTab;
    let headerBtnTxt = "";
    if (isTeamsHeader) {
      headerBtnTxt = this.t(`${this.labelwebCreateTeamtxt}`);
    } else {
      headerBtnTxt = this.t(`${configJSON.labelAddMembersBtntxt}`);
    }

    return (
      <Box>
        {this.state.isLoading && <WebLoader />}
        <Box className={`wrapper ${classes.wrapper} ${classes.dashboardWrapper}`}>
          <AppHeader
            openModal={this.createTeamHandler}
            buttonTxt={headerBtnTxt}
            isTeamsHeader={isTeamsHeader}
            pageLink={pageURL}
            data-test-id="app-header-btn"
            t={this.t}
            userRole={this.userRole}
            showSkipButton={this.state.showSkipButton}
            skipButtonClickHandler={this.handleOpenCart}
          />
          <Box className={classes.innerWrapper}>
            <Box className={classes.teamModuleWrapper}>
              <Box className="heading-wrapper">
                <Box className="heading-left">
                  <Typography className="heading" variant="h1">
                    {this.t(`${this.labelwebTeamsTitle}`)}
                  </Typography>
                  {showMessageBasedOnRole(this.userRole)}
                </Box>
                {this.showTeamsHeader(isTeamsHeader,this.userRole)}
                {!isTeamsHeader &&
                  <Button
                    title="Skip"
                        style={{display: "none"}}
                    onClick={this.handleSkipButtonClick}
                    data-test-id="skip-btn"
                  >
                    {this.t(`${configJSON.skipTxt}`)}
                  </Button>
                }
              </Box>
              <Box className="search-filter-sm">
                <Box className="search-wrapper">
                  <CustomInputWeb
                    type="search"
                    value={this.state.teamSearchValue}
                    label=""
                    placeholder={this.t(`${configJSON.searchTxt}`)}
                    onChange={newSearchTerm =>
                      this.handleTeamsSearch(newSearchTerm)
                    }
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </Box>
                <Box className="sort-group">
                  <img src={sortIcon} alt="sort-group" />
                </Box>
                <Box className="filter-group" onClick={this.filterTeamHandler}>
                  <img src={filterIcon} alt="filter-group" />
                </Box>
              </Box>

              {this.state.isFilter && (
                <Modal
                  open={this.state.modalOpen}
                  onClose={this.handle_modalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  className={classes.modalDialog}
                >
                  <Box className={`${classes.modalDialogBox} filter-dialogbox`}>
                    <Box className="modal-heading">
                      <Box className="filter-heading">
                        <Box
                          className="auth-back"
                          onClick={this.handle_modalClose}
                        >
                          <i>
                            <svg
                              className="MuiSvgIcon-root"
                              focusable="false"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                            >
                              <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                            </svg>
                          </i>
                        </Box>
                        <Typography className="modal-title" component="h2">
                          {this.t(`${configJSON.txtFilters}`)}
                        </Typography>
                      </Box>
                      <Link to="" className="filter-link">
                        {this.t(`${configJSON.txtReset}`)}
                      </Link>
                    </Box>
                    <Box className="modal-description">
                      <Box className="modal-form" component="form">
                        <Box className="form-info-wrapper">
                          <Typography className="form-heading" variant="h2">
                            {this.t(`${configJSON.teamTxt}`)}
                          </Typography>
                          <Box className="search-wrapper">
                            <CustomInputWeb
                              type="search"
                              value={this.state.searchValue}
                              label=""
                              placeholder={this.t(`${configJSON.searchTitleTxt}`)}
                              endAdornment={
                                <InputAdornment position="end">
                                  <SearchIcon />
                                </InputAdornment>
                              }
                            />
                          </Box>
                        </Box>
                        <Box className="form-info-wrapper">
                          <Typography className="form-heading" variant="h2">
                            {this.t(`${configJSON.txtChooseRole}`)}
                          </Typography>
                          <FormControl
                            component="fieldset"
                            className={`${classes.radioChoose} `}
                          >
                            <RadioGroup className="radio-group" name="role">
                              <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label={this.t(`${configJSON.txtOwner}`)}
                              />
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label={this.t(`${configJSON.txtManager}`)}
                              />
                              <FormControlLabel
                                value="2"
                                control={<Radio />}
                                label={this.t(`${configJSON.txtEmployee}`)}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Box className="form-info-wrapper">
                          <Typography className="form-heading" variant="h2">
                            {this.t(`${configJSON.ownerJobTitleText}`)}
                          </Typography>
                          <Box className="search-wrapper">
                            <CustomInputWeb
                              type="search"
                              value={this.state.searchValue}
                              label=""
                              placeholder={this.t(`${configJSON.txtPlaceholderSearchJobTitle}`)}
                              endAdornment={
                                <InputAdornment position="end">
                                  <SearchIcon />
                                </InputAdornment>
                              }
                            />
                          </Box>
                        </Box>
                        <Box className={classes.switchWrapper}>
                          <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                              <FormControlLabel
                                value="start"
                                // checked={deActivated}
                                control={
                                  <Switch
                                  // onClick={ondeActivatedChange}
                                  />
                                }
                                label={this.t(`${configJSON.deactivatedOnlyTxt}`)}
                                labelPlacement="start"
                              />
                            </FormGroup>
                          </FormControl>
                        </Box>
                      </Box>
                      <Box className="modal-footer">
                        <Button
                          className={`primary-btn ${classes.primaryButton}`}
                          title="Apply Filters"
                        >
                          {this.t(`${configJSON.txtApplyFilters}`)}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Modal>
              )}

              <Box className={classes.tabWrapper}>
                <Box sx={{ width: "100%" }}>
                  <TabContext value={this.state.tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={(e, v) => {
                          this.tabsChangeHandler(v);
                        }}
                        data-testid="tabChange"
                        aria-label="lab API tabs example"
                      >
                        <Tab label={this.t(`${configJSON.teamsTxt}`)} value="1" />
                        <Tab label={this.t(`${configJSON.membersTxt}`)} value="2" />
                      </TabList>
                    </Box>

                    {/* team tab */}
                    <TabPanel value="1">
                      {this.state.teamsList.length > 0 &&
                      <Box className="table-top">
                        <Box className="top-left">
                          <Box className="search-wrapper">
                            <Input
                              placeholder={this.t(`${configJSON.searchTxtType}`)}
                              type="search"
                              onChange={newSearchTerm =>
                                this.handleTeamsSearch(newSearchTerm)
                              }
                              value={this.state.teamSearchValue}
                              startAdornment={
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              }

                            />
                          </Box>
                          {/* {this.state.teamsList.length > 0 && (
                            <TeamSort
                              teamStateName={"teamFilterTab"}
                              selectTeamOptions={this.state.teamsList}
                              classes={classes}
                              onSelectedOptions={this.saveSelectedTeamOption}
                              clearAction={this.handleTeamClearSelection}
                              selectedTeam={this.state.teamFilterTab}
                              t={this.t}
                            />
                          )} */}
                        </Box>
                        <Box className="top-right">
                          <FormControl className="select-control teams-sort">
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left"
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left"
                                },
                                getContentAnchorEl: null,
                                classes: { paper: classes.dropdownStyle }
                              }}
                              displayEmpty
                              defaultValue=""
                              onChange={this.changeSortByTeams}
                              data-test-id="sortByTeams"
                            >
                              <MenuItem value="">{this.t(`${configJSON.sortByTxt}`)}</MenuItem>
                              <MenuItem value={1}>A to Z</MenuItem>
                              <MenuItem value={2}>Z to A</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                      }
                      {this.listTeamsData()}
                    </TabPanel>
                    <ConfirmActionModal
                      isOpen={
                        this.state.isDeleting || this.state.isMultipleDelete
                      }
                      modalHeading={this.t(`${configJSON.deleteTeamTxt}`)}
                      modalMessage={this.t(`${this.deleteModalTxt}`)}
                      handleClose={this.handle_modalClose}
                      modalConfirmAction={
                        this.state.isDeleting
                          ? this.deleteTeamFromList
                          : this.multipleDeleteTeams
                      }
                      confirmBtnTxt={this.t(`${this.labelDeleteModalBtnTxt}`)}
                      cancelBtnTxt={this.t(`${configJSON.txtCancel}`)}
                      data-testid="confirmDeleteTeam"
                    />
                    <Modal
                      open={this.state.isCreatingTeam || this.state.isRenaming}
                      onClose={this.handle_modalClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      className={classes.modalDialog}
                    >
                      <Box className={classes.modalDialogBox}>
                        <Box className="modal-heading">
                          <Typography className="modal-title" component="h2">
                            {this.state.isRenaming
                              ? this.t(`${this.labelwebRenameTeamtxt}`)
                              : this.t(`${this.labelwebCreateTeamtxt}`)}
                          </Typography>
                          <Link
                            to="#"
                            className="close-icon"
                            onClick={this.handle_modalClose}
                            data-test-id="create-team-modal-close"
                          >
                            <CloseIcon />
                          </Link>
                        </Box>
                        <Box className="modal-description">
                          <Box component="p" className="sub-txt">
                            {this.state.isRenaming
                              ? this.t(`${this.labelRenameTeamModaltxt}`)
                              : this.t(`${this.labelCreateTeamModaltxt}`) }
                          </Box>
                          <Box className="modal-form" component="form">
                            {this.state.isRenaming ? (
                              <CustomInputWeb
                                type="text"
                                isRequired={true}
                                errors={
                                  this.state.error.errornewTeamNameNotValid
                                    .message
                                }
                                label={this.t(`${configJSON.ownerTeamNameText}`)}
                                value={this.state.renameTeamData.name}
                                onChange={event =>
                                  this.setState({
                                    renameTeamData: {
                                      id: this.state.renameTeamData.id,
                                      name: event.target.value
                                    }
                                  })
                                }
                                onKeyDown={this.preventReloadonEnter}
                              />
                            ) : (
                              <>
                                {this.state.additionalFields.length > 0 && (
                                  <>
                                    {this.state.additionalFields.map(
                                      (data: any, index: any) => {
                                        return (
                                          <React.Fragment key={data.id}>
                                            <CustomInputWeb
                                              type="text"
                                              errors={
                                                data.value == "" ? this.state.error
                                                  .errornewTeamNameNotValid
                                                  .message : ""
                                              }
                                              isRequired={true}
                                              label={this.t(`${configJSON.ownerTeamNameText}`)}
                                              value={data.value}
                                              onChange={event =>
                                                this.teamValueChangeHandler(
                                                  event,
                                                  data.id,
                                                  index
                                                )
                                              }
                                              data-test-id={`team-input`}
                                              onKeyDown={this.preventReloadonEnter}
                                              endAdornment={
                                                <InputAdornment position="end">
                                                  {index !== 0 && (
                                                    <CloseIcon
                                                      className="close-icon"
                                                      onClick={() =>
                                                        this.deleteRow(data.id)
                                                      }
                                                    />
                                                  )}
                                                </InputAdornment>
                                              }
                                            />
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </>
                                )}
                              </>
                            )}

                            <Box className="modal-footer">
                              {this.state.isRenaming ? (
                                <Button
                                    className={`primary-btn ${
                                      classes.primaryButton
                                    }`}
                                  title={this.labelwebRenameTeamBtntxt}
                                  onClick={this.updateTeam}
                                  data-testid="renameTeam"
                                >
                                  {this.t(`${this.labelwebRenameTeamBtntxt}`)}
                                </Button>
                              ) : (
                                <>
                                  <Button
                                    className={`secondary-btn ${
                                      classes.secondaryButton
                                      }`}
                                    title={this.labelwebAnotherTeamtxt}
                                    onClick={this.addAnotherTeam}
                                  >
                                    <AddIcon /> {this.t(`${this.labelwebAnotherTeamtxt}`)}
                                  </Button>
                                  <Button
                                    className={`primary-btn ${
                                      classes.primaryButton
                                      }`}
                                    title={this.labelwebCreateTeamBtntxt}
                                    onClick={this.createTeams}
                                    data-testid="create-teams-modal-btn"
                                  >
                                    {this.t(`${this.labelwebCreateTeamBtntxt}`)}
                                  </Button>
                                </>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Modal>

                    {/* member tab */}
                    <TabPanel value="2">
                      <Box className="table-top">
                        <Box className="top-left">
                          <Box className="search-wrapper">
                            <Input
                              placeholder={this.t(`${configJSON.searchTxtType}`)}
                              type="search"
                              onChange={newSearchTerm =>
                                this.handleMembersSearch(newSearchTerm)
                              }
                              onKeyDown={(event:any) => this.preventReloadonEnter(event)}
                              value={this.state.searchValue}
                              startAdornment={
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              }
                            />
                          </Box>
                          <TeamMembersSort
                            selectTeamOptions={this.state.teamsList}
                            selectJobOptions={this.state.membersList}
                            classes={classes}
                            onSelectedOptions={this.saveSelectedOption}
                            selectedTeam={this.state.selectedTeamVal}
                            selectedRole={this.state.selectedRoleVal}
                            selectedTitle={this.state.selectedTitleVal}
                            clearAction={this.handleClearSelection}
                            deActivated={this.state.isDeactivatedActive}
                            ondeActivatedChange={this.handledeActivateSwitch}
                            t={this.t}
                          />
                        </Box>
                        <Box className="top-right">
                          <FormControl className="select-control">
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left"
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left"
                                },
                                getContentAnchorEl: null,
                                classes: { paper: classes.dropdownStyle }
                              }}
                              displayEmpty
                              defaultValue=""
                              onChange={this.changeSortByMembers}
                              data-test-id="sortByMembers"
                            >
                              <MenuItem value="">{this.t(`${configJSON.sortByTxt}`)}</MenuItem>
                              <MenuItem value={1}>A to Z</MenuItem>
                              <MenuItem value={2}>Z to A</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                      {this.memberTeamsData(this.t)}
                      {this.state.isEditing && (
                        <EditMember
                          classes={classes}
                          modalOpen={this.state.modalOpen}
                          modalClose={this.handle_modalClose}
                          memberDetails={this.state.editMembers}
                          errorState={this.state.editMemerror}
                          roleChange={this.handleroleChange}
                          memberDetailsFun={this.updateMemberDetails}
                          updateMemHandler={this.updateMemProfile}
                          changeEmailHandler={this.changeEmailHandler}
                          changePasswordHandler={this.changePasswordHandler}
                          t={this.t}
                          isRemoveTeam = {this.state.isRemoveTeam}
                          handleRemoveTeam={this.handleRemoveTeam}
                          handleCloseTeam ={this.handleCloseTeam}
                          removeMemberApiCall ={this.removeMemberApiCall}
                          removeTeamTitle ={this.state.removeTeamTitle}
                          data-test-id="EditMember"
                        />
                      )}
                      {this.state.isEditingEmail && (
                        <EmailEdit
                          classes={classes}
                          modalClose={this.handle_modalClose}
                          backToEdit={this.handleEditBack}
                          saveNewEmail={this.saveEmailAddressValue}
                          updateMemberEmail={this.updateMemberEmail}
                          allStates={this.state}
                          t={this.t}
                          pageType="account_page"
                        />
                      )}
                      {this.state.isEditingPassword && (
                        <EditPassword
                          classes={classes}
                          modalOpen={this.state.modalOpen}
                          modalClose={this.handle_modalClose}
                          backToEdit={this.handleEditBack}
                          newPasswordVal={this.state.newPassword}
                          passwordValError={this.state.newPasswordError}
                          saveNewPassword={this.savePasswordValue}
                          updateMemberEmail={this.updateMemberPassword}
                          pageType="account_page"
                          t={this.t}
                        />
                      )}
                      {this.state.isReactivating && (
                        <ReactivateMember
                          classes={classes}
                          modalOpen={this.state.modalOpen}
                          modalClose={this.handle_modalClose}
                          memberDetails={this.state.reActivateMembers}
                          teamsList={this.state.teamsList}
                          selectJobOptions={this.state.membersList}
                          errorState={this.state.reActivateMemerror}
                          reActivateMemFunc={this.updateMemberDetails}
                          roleChange={this.handleroleChange}
                          reActivateChooseTeam={this.reActivateChooseTeam}
                          reActivateMemHandler={this.reactivateMem}
                          t={this.t}
                        />
                      )}
                      {this.state.isDeactivating && (
                        <ConfirmActionModal
                          isOpen={this.state.isDeactivating}
                          modalHeading={this.t(`${configJSON.deactivateMemberTxt}`)}
                          modalMessage={this.t(configJSON.revokeAccessTxt, { userName: this.state.deActivateMember.deActivateMemberName })}
                          handleClose={this.handle_modalClose}
                          deleteTeamId={this.state.deleteTeamId}
                          modalConfirmAction={this.deactivateMem}
                          confirmBtnTxt={this.labelDeactivateMemberBtnTxt}
                          cancelBtnTxt={this.t(`${configJSON.txtCancel}`)}
                          data-testid="confirmDeActivateTeam"
                        />
                      )}
                      {this.state.isAddingMember && (
                        <Modal
                          open={this.state.modalOpen}
                          onClose={this.handle_modalClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          className={classes.modalDialog}
                        >
                          <Box className={classes.modalDialogBox}>
                            <Box className="modal-heading">
                              <Typography
                                className="modal-title"
                                component="h2"
                              >
                                {this.t(`${configJSON.addToTeam}`)}
                              </Typography>
                              <Link
                                to="#"
                                className="close-icon"
                                onClick={this.handle_modalClose}
                              >
                                <CloseIcon />
                              </Link>
                            </Box>
                            <Box className="modal-description">
                              <Box component="p" className="sub-txt">
                                {this.state.selected.length > 1 ? (
                                  <>{this.t(`${configJSON.addMemberMultipleText}`)}</>
                                ) : (
                                  <>{this.t(`${configJSON.addMemberSingleText}`)}</>
                                )}
                              </Box>
                              <Box className="modal-form" component="form">
                                <FormControl
                                  variant="outlined"
                                  className={classes.selectOutline}
                                >
                                  <InputLabel id="select-label">
                                    {this.t(`${configJSON.chooseTeam}`)}
                                  </InputLabel>
                                  <Select
                                    labelId="choose-team"
                                    id="choose-team"
                                    label={this.t(`${configJSON.chooseTeam}`)}
                                    displayEmpty
                                    defaultValue=""
                                    MenuProps={{
                                      anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                      },
                                      transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                      },
                                      getContentAnchorEl: null,
                                      classes: { paper: classes.dropdownStyle }
                                    }}
                                    onChange={e =>
                                      this.addMemberToTeamChange(e)
                                    }
                                  >
                                    {this.state.excludeTeamsList.map(
                                      (team: any) => {
                                        return (
                                          <MenuItem
                                            key={team.id}
                                            value={team.id}
                                          >
                                            {team.attributes.title}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                  {this.state.updateMemTeamEmpty && (
                                    <FormHelperText className="error-select">
                                      {this.state.updateMemTeamEmpty}
                                    </FormHelperText>
                                  )}
                                  {this.state.updateMemError && (
                                    <FormHelperText className="error-select">
                                      {this.state.updateMemError.message}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                                <Box className="modal-footer">
                                  <Button
                                    className={`secondary-btn ${
                                      classes.secondaryButton
                                      }`}
                                    title={this.labelwebAnotherTeamtxt}
                                    onClick={this.createTeamHandler}
                                  >
                                    {this.t(`${configJSON.labelwebCreateTeamtxt}`)}
                                  </Button>
                                  <Button
                                    className={`primary-btn ${
                                      classes.primaryButton
                                      }`}
                                    title={this.labelwebRenameTeamBtntxt}
                                    onClick={this.addMemberToTeamRequest}
                                    data-testid="addToTeambtn"
                                  >
                                    {this.t(`${configJSON.addToTeam}`)}
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Modal>
                      )}
                    </TabPanel>
                  </TabContext>
                </Box>
              </Box>
            </Box>
          </Box>
            {this.opensubscriptionmodal()}
        </Box>
      </Box>
    );
  }
}

export default withRouter(withStyles(customStyles, { withTheme: true })(AccountCreationWeb)) as React.ComponentType<any>
// Customizable Area End
