import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import i18n from "../../../web/src/utilities/i18n";
import { toast } from "react-toastify";
import { CardMedia } from "@material-ui/core";
import { warningIcon } from "./assets";

interface SelectedSubscription {
  value: number;
  name: string;
  price: string;
  mainprice: string;
}
interface Service {
  id: string;
  type: string;
  attributes: ServiceAttributes;
}

interface ServiceAttributes {
  id: number;
  SKU: string;
  title: string;
  etoh_shop_category_id: number;
  etoh_shop_sub_category_id: number;
  visibililty_in_catalog: string | null;
  short_description: string;
  description: string;
  service_type: string;
  converted_price: string | null;
  converted_sale_price: string | null;
  converted_price_weekly: string | null;
  converted_sale_price_weekly: string | null;
  converted_price_monthly: string | null;
  converted_sale_price_monthly: string | null;
  converted_price_yearly: string | null;
  converted_sale_price_yearly: string | null;
  currency: string | null;
  is_featured: boolean;
  buyers_additional_details: boolean;
  created_at: string;
  updated_at: string;
  thumbnails: File[];
}

interface File {
  id: number;
  filename: string;
  url: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any;
  isMobile:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading:boolean;
  isMenuDrawerOpened: boolean;
  productsData:any;
  isOneTime:boolean;
  isSubscription:boolean;
  searchValue:string;
  sortValue:string;
  filterValue:string;
  isFilter:boolean;
  isQuantityOpen: boolean;
  filterResults:any;
  subCategoryName:any;
  subscriptionData:any;
  anchorElDropdown: any;
  selectedSubscriptions: { [id: string]: SelectedSubscription }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfEtohApi6Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userSessionData: any;
  userToken: any;
  getAllEtohProductsRequestId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {    
      // Customizable Area Start
      isLoading:false,
      isMenuDrawerOpened: false,
      productsData: [],
      isOneTime:false,
      isSubscription:false,
      searchValue:"",
      sortValue:"",
      filterValue:"",
      isFilter:false,
      isQuantityOpen: false,
      filterResults:[],
      subCategoryName:"",
      anchorElDropdown: null,
      subscriptionData:[],
      selectedSubscriptions: {},
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    // Customizable Area End
  }
  
 // Customizable Area Start
 async componentDidMount() {
  const lang = localStorage.getItem("lang") || "en";
  await (i18n as any).changeLanguage(lang);
  this.getAllEtohProducts("");
}

t(key:any, variables?: Record<string, any>) {
  return (i18n as any).t(key, { ns: "translation" , ...variables } )
}
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson.error) {       
        this.setState({ isLoading: false });
        return;
      }else{
        switch (apiRequestCallId) {        
          case this.getAllEtohProductsRequestId:
            this.setState({ isLoading: false });  
            this.setState({productsData:responseJson.data})  
            break;     
          default:
            this.parseApiCatchErrorResponse(this.t(errorResponse));
            break;
        }
      }
    }  
    // Customizable Area End
  }
 
  // Customizable Area Start
  toggleMenuDrawer = () => {      
    this.setState({
        isMenuDrawerOpened: true,
    })        
  }
  closeMenuDrawer = () => {
      this.setState({
          isMenuDrawerOpened: false,
      })
  }
  handleBackBtn = () => {
    window.history.back();
  };

handleRadioChange = (event: any) => {
    this.setState(
      {
        filterValue: event.target.value,
        isFilter: true,
      },
      () => {
        this.getAllEtohProducts();
      }
    );
  };

  handleSearchInputChange = (event:any) => {
    this.setState({ searchValue: event.target.value , isFilter:true },()=>{
      this.getAllEtohProducts()
    });
  };

  handleSortByChange = (event:any) => {
    this.setState({ sortValue: event.target.value , isFilter:true },()=>{
      this.getAllEtohProducts()
    });
  };

  handleSubcriptionChange = (id: string, event: SelectedSubscription) => {
    this.setState((prevState: any) => ({
      selectedSubscriptions: {
        ...prevState.selectedSubscriptions,
        [id]: {
          name: event.name,
          price: event.price,
          mainprice : event.mainprice
        },
      },
      anchorElDropdown: null,
    }));
  };

  handleOpenMenu = (event: any, id: any) => {
    if (event) {
      this.setState({ anchorElDropdown: event.target });
    }
    const product = this.state.productsData.find(
      (item: any) => item.attributes.id === id
    );
  
    if (product && product.attributes.service_type === "Subscription") {
      const { converted_price_weekly, converted_price_monthly, converted_price_yearly, converted_sale_price_weekly, converted_sale_price_monthly, converted_sale_price_yearly } = product.attributes;
      const subscriptionData = [
        {
          value: id,
          name: "Weekly",
          price: converted_sale_price_weekly,
          mainprice: converted_price_weekly
        },
        {
          value: id,
          name: "Monthly",
          price: converted_sale_price_monthly,
          mainprice: converted_price_monthly
        },
        {
          value: id,
          name: "Yearly",
          price: converted_sale_price_yearly,
          mainprice: converted_price_yearly
        },
      ].filter((entry) => entry.price !== null && entry.mainprice !== null);
      this.setState({ subscriptionData });
    }
  };
  
  handleCloseMenu = () => {
    this.setState({ anchorElDropdown: null });
  };

  createToastNotificationError = (toastMesssage: string) => {
    toast.error(this.t(`${toastMesssage}`), {
      position: toast.POSITION.TOP_CENTER,
      icon: () => (
        <CardMedia component="img" src={warningIcon} alt="emptydata" />
      ),
    });
  };
  getAllEtohProducts = (id?: any, name?: string) => {
    const getCategoryId = localStorage.getItem("sub_category_id");
    const getCategoryName = localStorage.getItem("sub_category_name");
    this.setState({ subCategoryName: name || getCategoryName || "Services" });
    let subCategoryId = id || getCategoryId || "";
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const endpoint = `${configJSON.getAllProductsApiPath}?etoh_shop_sub_category_id=${subCategoryId}&product_name=${this.state.searchValue}&sort_by=${this.state.sortValue}&service_type=${this.state.filterValue}`;

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllEtohProductsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  viewNavigate=(event:any,itemId:any)=>{ 
   this.props.navigation.navigate("OrderDetails",{":itemId":itemId});  
  }
  clearFilter = ()=>{
    this.setState({filterValue:""},()=>{
    this.getAllEtohProducts()
    })
  }
  getPrice = (product: Service) => {
    const selected = this.state.selectedSubscriptions[product.attributes.id] 
    if (selected) {
      return selected?.price;
    }

    if (product.attributes.converted_sale_price_weekly) {
      return product.attributes.converted_sale_price_weekly;
    } else if (product.attributes.converted_sale_price_monthly) {
      return product.attributes.converted_sale_price_monthly;
    } else if (product.attributes.converted_sale_price_yearly) {
      return product.attributes.converted_sale_price_yearly;
    }

    return product.attributes.converted_sale_price_weekly; 
  };  

  getMainPrice = (product: Service) => {
    const selected = this.state.selectedSubscriptions[product.attributes.id];
    if (selected) {
      return selected?.mainprice;
    }
  
    if (product.attributes.converted_price_weekly) {
      return product.attributes.converted_price_weekly;
    } else if (product.attributes.converted_price_monthly) {
      return product.attributes.converted_price_monthly;
    } else if (product.attributes.converted_price_yearly) {
      return product.attributes.converted_price_yearly;
    }
  
    return product.attributes.converted_price_weekly; 
  };

  getDurationName = (product: Service) => {
    const selected = this.state.selectedSubscriptions[product.attributes.id];
    if (selected) {
      return selected?.name;
    }

    if (product.attributes.converted_sale_price_weekly || product.attributes.converted_price_weekly) {
      return "Weekly";
    } else if (product.attributes.converted_sale_price_monthly || product.attributes.converted_price_monthly) {
      return "Monthly";
    } else if (product.attributes.converted_sale_price_yearly || product.attributes.converted_price_yearly) {
      return "Yearly";
    }

    return "Weekly";
  };
  // Customizable Area End
}
