// Customizable Area Start
import React, { useState, useEffect,useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { Box, Button } from "@material-ui/core/";
import { Link } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { calendar } from "../../blocks/TaskList/src/assets";
import { t } from "i18next";
import i18n from "../../web/src/utilities/i18n";

interface CustomDatePickerProps {
  range?: boolean;
  isDeal?:boolean;
  placeholder?: string;
  value?: any;
  render?: any;
  currentDate: string;
  required?: boolean;
  error?:string;
  t:(key: string) => string;
  maxDate?:any;
  onChange: ((
    selectedDates: DateObject | DateObject[] | null
  ) => false | void) &
    ((
      selectedDates: DateObject | DateObject[] | null,
      validatedValue: string | string[],
      input: HTMLElement,
      isTyping: boolean
    ) => false | void);
  onOpenPickNewDate: boolean;
  onOpen?:any;
  showPastWarning?:boolean;
  minDate?:Date | string;
  inPortal?:boolean;
  customPortal?:HTMLElement;
  onClose?:any;
  calendarPosition?:string;
  getFilterdData?:any;
  calendarIcon?:any;
}

type CustomDatePickerRef = HTMLDivElement & {
  closeCalendar : ()=>void
}
const DATE_FORMAT = "YYYY-MM-DD";
const useStyles = makeStyles((theme) => ({
  datepickerWrapper: {
    "&.required": {
      "& .rmdp-container": {
        position: "relative",       
        "&:before": {
          content: "'*'",
          display: "inline-block",
          position: "absolute",
          color: "#e86577",
          fontSize: "18px",
          lineHeight: "18px",
          width: "10px",
          height: "10px",
          right: "15px",          
          //top: "50%",
          transform: "translateY(-50%)",    
          zIndex: 1      
        }
      }
    },
    "&.rmdp-error-border": {
      "& .rmdp-container": {
        "&:before": {
          top: "-7%"
        },
        "& .rmdp-input": {
          border: "1px solid #C7263E",
        }
      }
    },
    "& .rmdp-error-msg": {
      fontSize: "12px",
      fontFamily: "Roboto",
      fontWeight: 400,
      letterSpacing: "0.0075em",
      margin: "3px 14px 0",
      color: "#C7263E",
    },
    "& .rmdp-container": {
      "& .floating-date-label":{
        position:"relative",
        "& label":{
          position: "absolute",
          fontFamily: "Roboto",
          top: "20px",
          left: "19px",
          fontSize: "14px",
          color: "#94A3B8",
          transition:"all 0.1s",
          fontWeight: 400,
        },
        "& input":{
          "&:focus + label":{
            top: "-5px",
            left: "12px",
            backgroundColor: "#FFF",
            padding: "0 5px",
            display: "inline-block",
            fontSize: "12px",
          },
         "&:not(:placeholder-shown) + label":{
          top: "-5px",
          left: "12px",
          backgroundColor: "#FFF",
          padding: "0 5px",
          display: "inline-block",
          fontSize: "12px",
         },
        }
      },
      width: "100%",
      position:"relative",
      "& .rmdp-wrapper" :{
       marginTop:"20px",
       borderRadius: "24px",
        "& .rmdp-calendar": {
          padding: "20px 17px 17px"
        },
       "& .rmdp-button-wrapper": {
        "& .button-bottom-actions" : {
            padding: "20px 17px 0 17px"
        },
       },
       },
      "& .rmdp-input": {
        //width: "auto",
        //border: "none",
        // backgroundImage: `url(${calendar}) !important`,
        // backgroundRepeat: "no-repeat",
        // backgroundPosition: "left center",
        // padding: "0 0 0 24px",
        color: "#2B2B2B",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "normal",
        padding: "19px 55px 19px 16px",
        borderRadius: "6px",
        height: "auto",
        border: "1px solid #E8ECF2",
        width: "100%",
        backgroundPosition: "calc(100% - 24px) center",
        minHeight: "56px",
        cursor: "pointer",
        "&:focus": {
          boxShadow: "none",
        },
        "&::-webkit-input-placeholder": {
          color: "#94A3B8",
        },
        "&:-ms-input-placeholder": {
          color: "#94A3B8",
        },
        "&::placeholder": {
          color: "#94A3B8",
          fontSize:"14px",
        },
      },
      "& > input + div":{
        transform:"none !important",
        top:"100% !important",
      },
      
    },
  },
  secondaryButton: {
    background: "transparent",
    border: "1px solid #e8ecf2",
    borderRadius: "7px",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#2b2b2b",
    fontWeight: 500,
    fontFamily: "Expletus Sans",
    textAlign: "center",
    textTransform: "unset",
    maxWidth: "100%",
    minWidth: "198px",
    padding: "12px 10px",
    minHeight: "48px",
    marginBottom: "0",
    transition: "all 0.5s ease-in-out",
    textDecoration: "none",
    "&:hover": {
      background: "transparent",
      color: "#2b2b2b",
    },
  },
  primaryButton: {
    background: "#4ba3b7",
    border: "1px solid #4ba3b7",
    borderRadius: "6px",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#f2f8fc",
    fontWeight: 500,
    fontFamily: "Expletus Sans",
    textAlign: "center",
    maxWidth: "100%",
    minWidth: "170px",
    padding: "12px 10px",
    minHeight: "48px",
    marginBottom: "0",
    transition: "all 0.5s ease-in-out",
    textTransform: "capitalize",
    "&:hover": {
      background: "#4ba3b7",
      color: "#ffffff",
    },
  },
}));

const CustomDatePicker = (props: CustomDatePickerProps) => {
  const [todayDate, setTodayDate] = useState("");
  const [tomorrowDate, setTomorrowDate] = useState("");
  const [nextWeekDate, setNextWeekDate] = useState("");
  const [nextMonthDate, setNextMonthDate] = useState("");
  const [LastWeekDate, setLastWeekDate] = useState<DateObject[] | null>(null);
  const [LastMonthDate, setLastMonthDate] = useState<DateObject[] | null>(null);
  const [LastYearDate, setLastYearDate] = useState<DateObject[] | null>(null);

  const [tempDate, setTempDate] = useState<Date | undefined>(undefined);
  const datePickerRef=useRef<CustomDatePickerRef>(null);
  const [lastValue,setLastValue] = useState<DateObject | DateObject[] | null>(null);
let isSavingDate:boolean = false;
  const {
    range,
    isDeal,
    placeholder,
    value,
    currentDate,
    render,
    maxDate,
    onChange,
    onOpenPickNewDate,
    onOpen,
    required,
    error,
    showPastWarning,
    minDate,
    inPortal,
    customPortal,
    onClose,
    calendarPosition,
    getFilterdData,
    calendarIcon,
  } = props;
  const classes = useStyles();
  const weekDays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
  useEffect(() => {
    const today = moment();
    setTodayDate(today.format(DATE_FORMAT));

    const tomorrow = moment().add(1, "days");
    setTomorrowDate(tomorrow.format(DATE_FORMAT));

    const nextWeek = moment().add(7, "days");
    setNextWeekDate(nextWeek.format(DATE_FORMAT));

     const nextMonth = moment().add(1, "months");
    setNextMonthDate(nextMonth.format(DATE_FORMAT));

    const lastMonday = moment(today).subtract(1, 'weeks').startOf('isoWeek');
    const lastSunday = moment(today).subtract(1, 'weeks').endOf('isoWeek');
    

    setLastWeekDate([new DateObject(lastMonday.toDate()),new DateObject(lastSunday.toDate())]);

    // Get the first date of the last month
  const firstOfLastMonth = moment(today).subtract(1, 'months').startOf('month');
  const lastOfLastMonth = moment(today).subtract(1, 'months').endOf('month');


    setLastMonthDate([new DateObject(firstOfLastMonth.toDate()),new DateObject(lastOfLastMonth.toDate())]);


  const firstOfLastYear = moment(today).subtract(1, 'years').startOf('year');
  const lastOfLastYear = moment(today).subtract(1, 'years').endOf('year');
    setLastYearDate([new DateObject(firstOfLastYear.toDate()),new DateObject(lastOfLastYear.toDate())]);

   

    // setCurrent(myTaskDate)
  }, []);

 const createToastNotification = (toastMesssage: string) => {
    toast.warn(toastMesssage, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
    });
  };

  
 const saveDate=()=>{
   isSavingDate = true;
   if(range){
datePickerRef?.current?.closeCalendar();
getFilterdData && getFilterdData();
   }
   else{
 onChange(new DateObject(
        moment(tempDate).format(DATE_FORMAT)
      ))
      }
      datePickerRef?.current?.closeCalendar();
  }
  const translateDateContent = (key:any, variables?: Record<string, any>) => {
    return (i18n as any).t(key, { ns: "translation" , ...variables } ) 
  }
  return (
    <Box className={`datepicker-wrapper ${classes.datepickerWrapper} ${required ? "required " : ""}  ${error!=="" && required?"rmdp-error":""} ${error ? "rmdp-error-border " : ""}`}>
      <DatePicker
        style={{ maxHeight: '300px', overflowY: 'auto' }}
        range={range}
        dateSeparator=" - "
        multipleRangeSeparator="&"
        weekDays={weekDays}  
        arrow={false}
        weekStartDayIndex={1} 
        format="MMM DD, YYYY"
        placeholder={placeholder}
        className={isDeal ? "top-date-picker" : ""}
        monthYearSeparator=" "
        value={range ? value : tempDate ?? new Date(currentDate)}
        render={render? render :  (value, openCalendar) => (
          <div className={`floating-date-label`} onClick={openCalendar}>
          <input
                      value={value}
                      placeholder=""
                      readOnly
                      onClick={openCalendar}
                      className="rmdp-input"
                      style={{
                        backgroundImage:calendarIcon 
                            ? `url(${calendarIcon})` 
                            : `url(${calendar})`,
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                    <label>{placeholder}</label>
                    </div>
                  )}
        currentDate={range ? value :new DateObject(currentDate)}
        maxDate={maxDate}
        ref={datePickerRef}
        calendarPosition={calendarPosition ?? "bottom-left"}
        onChange={(date: any) => {
          isSavingDate = true;
          if(!range){
          const dt= date?.isValid?new Date(date.format("YYYY-MM-DD")):undefined;
          setTempDate(dt);
          if(date?.isValid && showPastWarning){
            let curDate = new Date();
            curDate.setHours(0,0,0,0);

            let dateToCheck = new Date(date.format("YYYY-MM-DD"))
            dateToCheck.setHours(0,0,0,0);
            if(dateToCheck < curDate){
              createToastNotification("This date is in the past")
            }
          }
          }
          onChange(date);
        }}
        onOpenPickNewDate={onOpenPickNewDate}
        onOpen={()=>{isSavingDate=false;if(range){setLastValue(value)}
       if(onOpen){
          onOpen()
        }
      }      
      }
        onClose={() => {          
          if(range){
                if(value.length == 1){
                  onChange(lastValue)
                }
          }
          else if (!isSavingDate && tempDate !== undefined) {
            setTempDate(new Date(currentDate));
          }
          if(onClose){
            onClose()
          }
          return true;
        }}
        minDate={minDate}
        portal={inPortal}
        portalTarget={inPortal ? customPortal ?? document.getElementById("root")??undefined : undefined}
        zIndex={inPortal?9999:100}
      >
        <Box className="rmdp-button-wrapper">
          <Box className="button-actions">
            <Button
              className={`secondary-btn ${classes.secondaryButton}`}
              onClick={() => {datePickerRef?.current?.closeCalendar();if(range){onChange(lastValue)}}}
            >
              {t("Cancel")}
            </Button>
            <Button
              className={`primary-btn ${classes.primaryButton}`}
              onClick={saveDate}
            >
              {t("Done")}
            </Button>
          </Box>
          <Box className="button-bottom-actions">
  {range ? (
    <>
      <Link
        to="#"
        className="tasklist-link"
        onClick={() => {
          onChange(LastWeekDate);
          // datePickerRef?.current?.closeCalendar();
        }}
      >
       {t("Last Week")}
      </Link>
      <Link
        to="#"
        className="tasklist-link"
        onClick={() => {
          onChange(LastMonthDate);
          // datePickerRef?.current?.closeCalendar();
        }}
      >
        {t("Last Month")}
      </Link>
      <Link
        to="#"
        className="tasklist-link"
        onClick={() => {
          onChange(LastYearDate);
          // datePickerRef?.current?.closeCalendar();
        }}
      >
        {t("Last Year")}
      </Link>
    </>
  ) : isDeal ? (
    <>
      <Link
        to="#"
        className="tasklist-link"
        onClick={() => setTempDate(new Date(tomorrowDate))}
      >
        {t("This Week")}
      </Link>
      <Link
        to="#"
        className="tasklist-link"
        onClick={() => setTempDate(new Date(nextWeekDate))}
      >
        {t("Next Week")}
      </Link>
      <Link
        to="#"
        className="tasklist-link"
        onClick={() => setTempDate(new Date(nextMonthDate))}
      >
        {t("Next Month")}
      </Link>
    </>
  ) : (
    <>
      <Link
        to="#"
        className="tasklist-link"
        onClick={() => setTempDate(new Date(todayDate))}
      >
        {t("Today")}
      </Link>
      <Link
        to="#"
        className="tasklist-link"
        onClick={() => setTempDate(new Date(tomorrowDate))}
      >
        {t("Tomorrow")}
      </Link>
      <Link
        to="#"
        className="tasklist-link"
        onClick={() => setTempDate(new Date(nextWeekDate))}
      >
        {t("Next Week")}
      </Link>
      <Link
        to="#"
        className="tasklist-link"
        onClick={() => setTempDate(new Date(nextMonthDate))}
      >
        {t("Next Month")}
      </Link>
    </>
  )}
</Box>&nbsp;

        </Box>
      </DatePicker>
      <Box className="rmdp-error-msg">{error ?? ""}</Box>
    </Box>
  );
};

export default CustomDatePicker;
// Customizable Area End
