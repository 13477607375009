import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme
} from "@material-ui/core/styles";
import { etohlogo, wineAdded, spiritAddedImg, 
otherProductAddImg, beerAddedImg, hardwareAddImg,
machineryAddImg,landImg,chemicalImg,fertilizerImg,phytoImg,vesselImg
} from "./assets";
import WebLoader from "../../../components/src/WebLoader.web";
// Customizable Area End

import ItemSuccessPageController, {
  Props,
  configJSON,
} from "./ItemSuccessPageController.web";

export class ItemSuccessPage extends ItemSuccessPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  itemSuccessImages =(itemName:string)=>{
    switch (itemName) {
      case configJSON.wineText:
        return <img src={wineAdded} className="logo"/>;
      case configJSON.beerText:
        return <img src={beerAddedImg} className="logo"/>;
      case configJSON.spiritText:
        return <img src={spiritAddedImg} className="logo"/>;
      case configJSON.otherText:
        return <img src={otherProductAddImg} className="logo"/>;
      case configJSON.hardwareText:
        return <img src={hardwareAddImg} className="logo"/>;
      case configJSON.machineText:
        return <img src={machineryAddImg} className="logo"/>;
      case configJSON.landText:
        return <img src={landImg} className="logo"/>;
      case configJSON.ChemicalsText:
        return <img src={chemicalImg} className="logo"/>;
      case configJSON.FertilizersText:
        return <img src={fertilizerImg} className="logo"/>;
      case configJSON.PhytoProductsText:
        return <img src={phytoImg} className="logo"/>;
      case configJSON.vesselsText:
        return <img src={vesselImg} className="logo"/>;
      default:
        return <img src={wineAdded} className="logo"/>;
    }
  }

  itemSubText = (itemName:string)=>{
    switch (itemName) {
      case configJSON.landText:    
      case configJSON.ChemicalsText:
      case configJSON.FertilizersText:
      case configJSON.PhytoProductsText:
      case configJSON.vesselsText:
      case configJSON.hardwareText:
      case configJSON.machineText:
        return configJSON.yourText+ this.removeLastChar(itemName.toLowerCase()) + (this.state.getItemName.isUpdate?configJSON.itemupdateMsg2:configJSON.itemsuccessMsg2);
      case configJSON.otherText:
        return configJSON.yourText+ itemName.toLowerCase() + (this.state.getItemName.isUpdate?configJSON.itemupdateMsg2:configJSON.itemsuccessMsg2);
      default:
        return configJSON.yourText+ itemName.toLowerCase() + (this.state.getItemName.isUpdate?configJSON.itemupdateMsg:configJSON.itemsuccessMsg);
    }
  }

  itemDescriptionEvent = (selectedItemName:string)=>{
    switch (selectedItemName) {
      case configJSON.landText:     
      case configJSON.ChemicalsText: 
      case configJSON.FertilizersText: 
      case configJSON.PhytoProductsText: 
      case configJSON.vesselsText:  
      case configJSON.hardwareText: 
      case configJSON.machineText: 
        return this.t(configJSON.itemdescription2);
      default:
        return this.t(configJSON.itemdescription);
    }
  };
  removeLastChar=(itemName:string)=>{
    if(itemName.charAt(itemName.length-1)==='s'){
      return itemName.slice(0,-1)
    }
    return itemName;
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {classes} = this.props;   
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      {this.state.isLoading && <WebLoader/>}
        <Box>
            <Box className={classes.logoBox}>
                <img src={etohlogo} alt="logo" />
            </Box>
            <Box className={classes.mainContainer}>
                <Box data-testId={"image-box"} className='content-img'>
                 {this.itemSuccessImages(this.state.getItemName.name)}
                </Box>
                <Box className='content-box'>
                  <Typography className={classes.heading} variant="h1">
                  {this.t(this.removeLastChar(this.state.getItemName.name.trim()))}&nbsp;{this.state.getItemName.isUpdate? this.t(`${configJSON.updateTitle}`):this.t(`${configJSON.successTitle}`)}
                  </Typography>
                  <Typography className={classes.subtitleText}>
                   {this.t(this.itemSubText(this.state.getItemName.name.trim()))}
                  </Typography>
                  <Typography className={classes.subtitleText}>
                    {this.t(this.itemDescriptionEvent(this.state.getItemName.name))}
                  </Typography>
                  <Box className={classes.buttonBox}>
                    <Button className="back-btn" onClick={this.backToInventory}
                    data-testId={"back-btn"}
                    >
                    {this.t(`${configJSON.backInvetoryButton}`)}
                    </Button>
                    <Button className="add-next-item" onClick={this.addNewItem}
                     data-testId={"add-another-btn"}
                    >
                    {this.t(`${configJSON.addAnotherButton}`)}
                    </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
        </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const customStyles = (theme: AugmentedTheme) =>
createStyles({
    logoBox:{
        padding:'4.6vw 0 0 4.6vw',
    },
    mainContainer:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-evenly',
        margin:'8vw auto',
        maxWidth: '1020px',
        padding: '0 16px',
        width: '100%',        
        [theme.breakpoints.down("md")]: {
          maxWidth: '900px',
        },
        [theme.breakpoints.down("sm")]: {
          maxWidth: '768px',
        },
        [theme.breakpoints.down("xs")]: {
          maxWidth: '100%',
          flexDirection: 'column',
          textAlign: "center",
        },
        "& .content-img":{
          maxWidth:'456px',
          width: '100%',
          [theme.breakpoints.down("md")]: {
            maxWidth: '400px',
          },
          [theme.breakpoints.down("sm")]: {
            maxWidth: '355px',
          },
          [theme.breakpoints.down("xs")]: {
            maxWidth: '327px',
            marginBottom: '24px',
          },
        },
        "& .content-box": {
          paddingLeft: '116px',
          [theme.breakpoints.down("md")]: {
            paddingLeft: '95px',
          },
          [theme.breakpoints.down("sm")]: {
            paddingLeft: '65px',
          },
          [theme.breakpoints.down("xs")]: {
            paddingLeft: '0',
          },
        }
    },
    heading: {
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "32px",
    color: "rgba(43, 43, 43, 0.87)",
    fontFamily: "Expletus Sans",
    marginBottom:'8px',
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      lineHeight: "24px",
      color: "rgba(43, 43, 43, 1)",
      marginBottom:'13px',
    },
  },
  subtitleText: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#505050",
    fontFamily: "Roboto",
    letterSpacing: "0.25px",
  },
  buttonBox:{
    marginTop:'48px',
    display: 'flex',
    gap: '16px',
    "& .back-btn":{
        borderRadius: "6px",
        border: "1px solid #E8ECF2",
        fontSize: "14px",
        lineHeight: "18px",
        padding: "10px",
        backgroundColor: "#fff",
        color: "rbga(43, 43, 43, 1)",
        fontFamily: "Expletus Sans",
        textTransform: "none",
        flex: "1",
        minHeight: "56px",
    },
    "& .add-next-item":{
        borderRadius: "6px",
        border: "1px solid #ECF1F4",
        fontSize: "14px",
        fontWeight: 500,
        fontFamily: "Expletus Sans",
        textTransform: "capitalize",
        lineHeight: "18px",
        padding: "10px",
        backgroundColor: "#4BA3B7",
        color: "#F2F8FC",
        flex: "1",
        minHeight: "56px",
    },
  },
});

export default withStyles(customStyles, { withTheme: true })(ItemSuccessPage);
// Customizable Area End
