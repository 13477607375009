import React from "react";
// Customizable Area Start
import { Box, Typography, Button, FormHelperText,  List,
  ListItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import Modal from "@material-ui/core/Modal";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import "react-toastify/dist/ReactToastify.css";
import AccountCreationControllerWeb, {
  Props,
  configJSON,
} from "./AccountCreationController.web";
import MembersListTable from "../../../../components/src/MembersListTable.web";
import UnAssignedMembersTable from "./UnAssignedMembersTable.web";
import { customStyles } from "./TeamsStyles.web";
import AppHeader from "../../../../components/src/AppHeader.web";
import TeamMembersSort from "./TeamMembersSort.web";
import EmailEdit from "./EmailEdit.web";
import EditMember from "./EditMember.web";
import EditPassword from "./EditPassword.web";
import MoveMemberTeam from "./MoveMemberTeam.web";
import RemoveMemberTeam from "./RemoveMemberTeam.web";
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import AddIcon from "@material-ui/icons/Add";
import WebLoader from "../../../../components/src/WebLoader.web";

class AccountViewTeam extends AccountCreationControllerWeb {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const pageURL = window.location.pathname;
    let headerBtnTxt = "+ Add members";
    const renderUnassignedList = () => {
      if (
        pageURL === "/view-team-members/unassigned-members" &&
        this.state.unAssignedTeamList.length > 0
      ) {
        return (
          <UnAssignedMembersTable
            renderUnAssignedMembers={this.state.unAssignedTeamList}
            editAction={this.editMemberHandler}
            moveMemberAction={this.moveToTeamHandler}
            userRole={this.userRole}
          />
        );
      }
    };
    const renderMembersList = () => {
      if (this.state.isFiltering) {
        if (this.state.filteredData.length > 0) {
          return (
            <MembersListTable
              individualTeam={true}
              rederIndividualMembers={this.state.filteredData}
              editMember={this.editMemberHandler}
              addMembertoTeam={this.addMembertoTeamHandler}
              moveMembertoTeam={this.moveToTeamHandler}
              removeMemberfromTeam={this.removeMemberFromTeam}
              classes={classes}
              multipleSelected={this.multipleDeleteHandler}
              showEditOption={true}
              showDeactivateOption={true}
              userRole={this.userRole}
              getSelectedRecords={this.getSelectedRecords}
            />
          );
        }else{
          return <Box className="no-member">{this.t(`${configJSON.noMemberFound}`)}</Box>
        }
      }else{
        if (this.state.memberListbyTeam.length >= 0) {
          return (
            <MembersListTable
              individualTeam={true}
              rederIndividualMembers={this.state.memberListbyTeam}
              editMember={this.editMemberHandler}
              addMembertoTeam={this.addMembertoTeamHandler}
              moveMembertoTeam={this.moveToTeamHandler}
              removeMemberfromTeam={this.removeMemberFromTeam}
              classes={classes}
              multipleSelected={this.multipleDeleteHandler}
              showEditOption={true}
              showDeactivateOption={true}
              userRole={this.userRole}
              getSelectedRecords={this.getSelectedRecords}
            />
          );
        }
      }
      
    };
    return (
      <Box>
        {this.state.isLoading && <WebLoader/>}
        <Box
          className={`wrapper ${classes.wrapper} ${classes.dashboardWrapper}`}
        >
          <AppHeader
            openModal={this.handle_modalOpen}
            buttonTxt={headerBtnTxt}
            pageLink={pageURL}
            teamIDParam={this.teamIDParam}
            userRole={this.userRole}
          />
          <Box className={classes.innerWrapper}>
            <Box className={classes.teamModuleWrapper}>
              <Box className="heading-wrapper">
                <Box className="heading-left">
                  <Typography className="heading" variant="h1">
                    {this.state.currentTeamName}
                  </Typography>
                  <Box component="p" className="sub-txt">
                    {this.t(`${configJSON.allMenberTxt}`)}
                  </Box>
                </Box>
              </Box>
              <Box className={classes.tabWrapper}>
                <Box sx={{ width: "100%" }}>
                  <Box className="table-top">
                    <Box className="top-left">
                      <Box className="search-wrapper">
                        <Input
                          placeholder={this.t(`${configJSON.searchTxtType}`)}
                          type="search"
                          onChange={newSearchTerm =>
                            this.handleMembersSearch(newSearchTerm)
                          }
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                        />
                      </Box>
                          <TeamMembersSort
                            selectTeamOptions={this.state.teamsList}
                            selectJobOptions={this.state.membersList}
                            classes={classes}
                            onSelectedOptions={this.saveSelectedOption}
                            selectedTeam={this.state.selectedTeamVal}
                            selectedRole={this.state.selectedRoleVal}
                            selectedTitle={this.state.selectedTitleVal}
                            clearAction={this.handleClearSelection}
                            deActivated={this.state.isDeactivatedActive}
                            ondeActivatedChange={this.handledeActivateSwitch}
                            hideDeactivateColumn={true}
                          />
                    </Box>
                    <Box className="top-right">
                    <FormControl className="select-control">
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left"
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left"
                                },
                                getContentAnchorEl: null,
                                classes: { paper: classes.dropdownStyle }
                              }}
                              displayEmpty
                              defaultValue=""
                              onChange={this.changeSortByTeams}
                            >
                              <MenuItem value="">{this.t(`${configJSON.sortByTxt}`)}</MenuItem>
                              <MenuItem value={1}>A to Z</MenuItem>
                              <MenuItem value={2}>Z to A</MenuItem>
                            </Select>
                          </FormControl>
                    </Box>
                  </Box>
                  {renderMembersList()}
                  {renderUnassignedList()}

                  {this.state.isEditing && (
                    <EditMember
                      classes={classes}
                      modalOpen={this.state.modalOpen}
                      modalClose={this.handle_modalClose}
                      memberDetails={this.state.editMembers}
                      errorState={this.state.editMemerror}
                      roleChange={this.handleroleChange}
                      memberDetailsFun={this.updateMemberDetails}
                      updateMemHandler={this.updateMemProfile}
                      changeEmailHandler={this.changeEmailHandler}
                      changePasswordHandler={this.changePasswordHandler}
                      data-testid="editMember"
                      t={this.t}
                      handleRemoveTeam={this.handleRemoveTeam}
                      isRemoveTeam = {this.state.isRemoveTeam}
                      handleCloseTeam ={this.handleCloseTeam}
                      removeMemberApiCall ={this.removeMemberApiCall}
                      removeTeamTitle={this.state.removeTeamTitle}
                      data-test-id="EditMember"
                    />
                  )}
                  {this.state.isEditingEmail && (
                    <EmailEdit
                      classes={classes}
                      modalClose={this.handle_modalClose}
                      backToEdit={this.handleEditBack}
                      saveNewEmail={this.saveEmailAddressValue}
                      updateMemberEmail={this.updateMemberEmail}
                      allStates={this.state}
                      pageType="account_page"
                      t={this.t}
                    />
                  )}
                  {this.state.isEditingPassword && (
                    <EditPassword
                      classes={classes}
                      modalOpen={this.state.modalOpen}
                      modalClose={this.handle_modalClose}
                      backToEdit={this.handleEditBack}
                      newPasswordVal={this.state.newPassword}
                      passwordValError={this.state.newPasswordError}
                      saveNewPassword={this.savePasswordValue}
                      updateMemberEmail={this.updateMemberPassword}
                      pageType="account_page"
                      t={this.t}
                    />
                  )}
                  {this.state.isMovingMember && (
                    <MoveMemberTeam
                      classes={classes}
                      modalOpen={this.state.modalOpen}
                      modalClose={this.handle_modalClose}
                      moveMembertoTeam={this.moveMembertoTeam}
                      addAnotherTeam={this.createTeamHandler}
                      saveNewTeam={this.saveNewTeam}
                      chooseTeamError={
                        this.state.moveTeamError.choose_team_error
                      }
                      teamExists={this.state.memberTeamExists}
                      allStates={this.state}
                      createTeamTitleBtn={this.labelwebAnotherTeamtxt}
                      data-testid = "moveto-link"
                      t={this.t}
                    />
                  )}
                  {this.state.isRemovingmember && (
                    <RemoveMemberTeam
                      modalOpen={this.state.modalOpen}
                      modalClose={this.handle_modalClose}
                      removeMemDetails={this.state.reMoveMem}
                      removeMemAction={this.removeMemberTeams}
                      teamName={this.state.currentTeamName}
                      selectedMem={this.state.selected}
                      data-testid="removeMemberTeam"
                      t={this.t}
                    />
                  )}
                  {this.state.isAddingMember && (
                    <Modal
                      open={this.state.modalOpen}
                      onClose={this.handle_modalClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      className={classes.modalDialog}
                      data-testid = "addtoTeam-link" 
                    >
                      <Box className={classes.modalDialogBox}>
                        <Box className="modal-heading">
                          <Typography className="modal-title" component="h2">
                            {this.t(`${configJSON.addToTeam}`)}
                          </Typography>
                          <Link
                            to="#"
                            className="close-icon"
                            onClick={this.handle_modalClose}
                          >
                            <CloseIcon />
                          </Link>
                        </Box>
                        <Box className="modal-description">
                          <Box component="p" className="sub-txt">
                          {this.t(`${configJSON.addMemberMultipleText}`)}
                          </Box>
                          <Box className="modal-form" component="form">
                            <FormControl
                              variant="outlined"
                              className={classes.selectOutline}
                            >
                              <InputLabel id="select-label">
                              {this.t(`${configJSON.chooseTeam}`)}
                              </InputLabel>
                              <Select
                                labelId="choose-team"
                                id="choose-team"
                                label={this.t(`${configJSON.chooseTeam}`)}
                                displayEmpty
                                defaultValue=""
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                  classes: { paper: classes.dropdownStyle },
                                }}
                                onChange={(e) => this.addMemberToTeamChange(e)}
                              >
                                {this.state.excludeTeamsList.map((team: any) => {
                                  return (
                                    <MenuItem key={team.id} value={team.id}>
                                      {team.attributes.title}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              {this.state.updateMemTeamEmpty && (
                                <FormHelperText>
                                  {this.state.updateMemTeamEmpty}
                                </FormHelperText>
                              )}
                              {this.state.updateMemError && (
                                <FormHelperText>
                                  {this.state.updateMemError.message}
                                </FormHelperText>
                              )}
                            </FormControl>
                            <Box className="modal-footer">
                              <Button
                                className={`secondary-btn ${
                                  classes.secondaryButton
                                }`}
                                title={this.labelwebAnotherTeamtxt}
                                onClick={this.createTeamHandler}
                              >
                                {this.t(`${configJSON.labelwebCreateTeamtxt}`)}
                              </Button>
                              <Button
                                className={`primary-btn ${
                                  classes.primaryButton
                                }`}
                                title={this.labelwebRenameTeamBtntxt}
                                onClick={this.addMemberToTeamRequest}
                              >
                              {this.t(`${configJSON.addToTeam}`)}
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Modal>
                  )}
                  <Modal
                      open={this.state.isCreatingTeam}
                      onClose={this.handle_modalClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      className={classes.modalDialog}
                    >
                      <Box className={classes.modalDialogBox}>
                        <Box className="modal-heading">
                          <Typography className="modal-title" component="h2">
                            {this.t(`${this.labelwebCreateTeamtxt}`)}
                          </Typography>
                          <Link
                            to="#"
                            className="close-icon"
                            onClick={this.handle_modalClose}
                          >
                            <CloseIcon />
                          </Link>
                        </Box>
                        <Box className="modal-description">
                          <Box component="p" className="sub-txt">
                            {this.t(`${this.labelCreateTeamModaltxt}`)}
                          </Box>
                          <Box className="modal-form" component="form">
                              <>
                                {this.state.additionalFields.length > 0 && (
                                  <>
                                    {this.state.additionalFields.map(
                                      (data: any, index: any) => {
                                        return (
                                          <React.Fragment key={data.id}>
                                            <CustomInputWeb
                                              type="text"
                                              errors={
                                                this.state.error
                                                  .errornewTeamNameNotValid
                                                  .message
                                              }
                                              isRequired={true}
                                              label={this.t(`${configJSON.ownerTeamNameText}`)}
                                              value={data.value}
                                              onChange={(event) =>
                                                this.teamValueChangeHandler(
                                                  event,
                                                  data.id,
                                                  index
                                                )
                                              }
                                              endAdornment={
                                                <InputAdornment position="end">
                                                  {index !== 0 && (
                                                    <CloseIcon
                                                      className="close-icon"
                                                      onClick={() =>
                                                        this.deleteRow(data.id)
                                                      }
                                                    />
                                                  )}
                                                </InputAdornment>
                                              }
                                            />
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </>
                                )}
                              </>
                         

                            <Box className="modal-footer">
                            
                                <>
                                  <Button
                                    className={`secondary-btn ${classes.secondaryButton}`}
                                    title={this.labelwebAnotherTeamtxt}
                                    onClick={this.addAnotherTeam}
                                  >
                                    <AddIcon /> {this.t(`${this.labelwebAnotherTeamtxt}`)}
                                  </Button>
                                  <Button
                                    className={`primary-btn ${classes.primaryButton}`}
                                    title={this.labelwebCreateTeamBtntxt}
                                    onClick={this.createTeams}
                                    data-testid="create-teams-modal-btn"
                                  >
                                    {this.t(`${this.labelwebCreateTeamBtntxt}`)}
                                  </Button>
                                  <Box className={classes.createdTeamData}>
                                    <Typography
                                      className="heading"
                                      variant="h2"
                                    >
                                      {this.t(`${configJSON.allTems}`)}
                                    </Typography>
                                    <Box component="p" className="sub-txt">
                                      {this.t(`${configJSON.allTeamsSubTxt}`)}
                                    </Box>
                                    <List
                                      className={classes.createdTeamDataList}
                                    >
                                      {this.state.teamsList.map(
                                        (team: any, i: any) => {
                                          return (
                                            <ListItem key={team.id}>
                                              <Box className="list-row">
                                                <Box className="list-left">
                                                  <Box
                                                    component="p"
                                                    className="team-name"
                                                  >
                                                    {team.attributes.title}
                                                  </Box>
                                                  <Box
                                                    component="span"
                                                    className="team-user"
                                                  >
                                                    {team.attributes.users}{" "}
                                                    {this.t(`${configJSON.usersTxt}`)}
                                                  </Box>
                                                </Box>
                                              </Box>
                                            </ListItem>
                                          );
                                        }
                                      )}
                                    </List>
                                  </Box>
                                </>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Modal>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}
export default withStyles(customStyles, { withTheme: true })(AccountViewTeam);
// Customizable Area End

