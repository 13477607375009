import React from "react";
// Customizable Area Start
import {
  Menu,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  FormControl,
  Select,
  MenuItem,
  Popover,
  CardMedia,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Drawer,
  Modal,
  Switch,
} from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import { FileDrop } from "react-file-drop";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import GlobalHeaderWeb from "../../customisableuserprofiles/src/GlobalHeader.web";
import CustomMarketingSidebar from "../../../components/src/CustomMarketingSidebar.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  sortVariant,
  minimizeGray,
  closeGray,
  menu,
  deleteIcon,
  copyIcon,
  taskIcon,
  attachGray,
  checkGreen,
  emptyDeals,
  attachment,
  searchDeals,
  minimizeDeal,
  cheveronDown,
} from "./assets";
import { customStyles } from "../../../components/src/LeadManagmentHelper.web";
import ViewDealsWeb from "./ViewDeals.web";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WebLoader from "../../../components/src/WebLoader.web";
import DeleteConfirmationModal from "../../../components/src/DeleteConfirmationModal.web";
import moment from "moment";
import { AssociatedProductsWeb } from "./AssociatedProducts.web";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ProductViewModal from "../../../components/src/ProductViewModal.web";
import ContactModal from "../../../components/src/ChooseContactDeals.web";
import Slider from '@material-ui/core/Slider';
import CreateContactWeb from "../../ContactsList/src/CreateContact.web";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
export function assigneeA11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// Customizable Area End

import LeadManagementControllerWeb, {
  Props,
  configJSON,
  // Customizable Area Start
  // Customizable Area End
} from "./LeadManagementController.web";

export class LeadManagement extends LeadManagementControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderStatusItems = () => {
    const statusItems = [
      { value: 0, name: "Initiated", className: "sky-blue" },
      { value: 1, name: "Sent Proposal", className: "orange" },
      { value: 2, name: "Follow Up", className: "yellow" },
      { value: 3, name: "Won", className: "green" },
      { value: 4, name: "Lost", className: "black" },
    ];

    return statusItems.map((item) => (
      <MenuItem key={item.value} value={item.value} className="status-item">
        <Box className="status-name">{this.t(item.name)}</Box>
        <Box className={`status ${item.className}`}></Box>
      </MenuItem>
    ));
  };
  stageClassMaping = (dealStage: string) => {
    if (dealStage === "Initiated") {
      return <Box className="status sky-blue"></Box>;
    } else if (dealStage === "sent proposal") {
      return <Box className="status orange"></Box>;
    } else if (dealStage === "follow up") {
      return <Box className="status yellow"></Box>;
    } else if (dealStage === "won") {
      return <Box className="status green"></Box>;
    } else if (dealStage === "lost") {
      return <Box className="status black"></Box>;
    }
  };
  chooseContactModal = () => {
    const { classes } = this.props;
    return (
      <ContactModal
        classes={classes}
        isOpen={this.state.isActiveContact}
        onClose={this.closeContactModal}
        title={this.t(configJSON.chooseContactTxt)}
        subText={this.t(configJSON.chooseContactSubTxt)}
        placeholder={this.t(configJSON.searchContactsTxt)}
        contactList={this.state.contactList}
        selectedContacts={this.state.selectedContacts}
        displayContactOptions={this.displayContactOptions}
        handleContactAction={this.handleContactsList}
        removeSelectedContactAction={this.removeSelectedContact}
        removeAllContacts={this.removeAllContacts}
        searchContact={this.handleContactsSearch}
        openCreateContact={this.createContact}
        isEditModal={false}
        isCreateContact={this.state.showCreateContact}
        data-test-id="contact-modal-choose"
      />
    );
  };
  chooseContactInput = () => {
    return (
      <Box className="form-row">
        <Box className="form-col">
          <CustomInputWeb
            type="text"
            label="Contact"
            name="Contact"
            disabled={false}
            customClass="has-modal"
            onClick={this.openContactModal}
            isRequired={true}
            isDealForm={true}
            data-test-id="choose-contact-input"
            errors={
              this.state.selectedContacts.length === 0
                ? this.state.createDealError.dealContactError
                : undefined
            }
            value=""
            startAdornment={
              this.state.selectedContacts.length > 0 ? (
                <InputAdornment position="start">
                  <Box className="assignee-list">
                    {this.displayContactOptions()
                      .filter((x: any) =>
                        this.state.selectedContacts.includes(x.id)
                      )
                      .slice(0, 2)
                      .map((option: any) => {
                        return (
                          <Box className="assignee-bubble" key={option.id}>
                            {option.initials}
                          </Box>
                        );
                      })}
                    {this.state.selectedContacts.length > 2 && (
                      <Box className="assignee-bubble">
                        +{this.state.selectedContacts.length - 2}
                      </Box>
                    )}
                  </Box>
                </InputAdornment>
              ) : null
            }
            endAdornment={
              <InputAdornment
                position="end"
                onClick={this.openContactModal}
                data-test-id="openContactModal"
              >
                <ChevronRightIcon />
              </InputAdornment>
            }
          />
        </Box>
      </Box>
      
    );
  };
  editChooseContactModal = () => {
    const { classes } = this.props;
    const dealContactIds = this.state.editDealDetails.dealContacts
      .filter((contact: any) => contact.id !== undefined)
      .map((contact: any) => contact.id.toString());
    
    return (
      <ContactModal
        classes={classes}
        isOpen={this.state.isEditContact}
        onClose={this.closeEditContactModal}
        title={this.t(configJSON.chooseContactTxt)}
        subText={this.t(configJSON.chooseContactSubTxt)}
        placeholder={this.t(configJSON.searchContactsTxt)}
        contactList={this.state.contactList}
        selectedContacts={this.state.selectedContacts}
        displayContactOptions={this.displayContactOptions}
        handleContactAction={this.handleEditContactsList}
        removeSelectedContactAction={this.removeSelectedEditContact}
        removeAllContacts={this.removeAllContacts}
        //searchContact={this.handleSearchContact}
        dealContactIds={dealContactIds}
        isEditModal={true}
        data-test-id="edit-contact-modal-choose"
      />
    );
  };
  editChooseContactInput = () => {
    return (
      <Box className="form-row">
        <Box className="form-col">
          <CustomInputWeb
            type="text"
            label="Contact"
            name="Contact"
            disabled={true}
            isRequired={true}
            data-test-id="edit-contact-input"
            errors={
              this.state.selectedContacts.length === 0
                ? this.state.createDealError.dealContactError
                : undefined
            }
            value=""
            startAdornment={
              this.state.editDealDetails.dealContacts.length > 0 ? (
                <InputAdornment position="start">
                  <Box className="assignee-list">
                    {this.state.editDealDetails.dealContacts.map(
                      (option: any) => {
                       
                        const initials = option.name ? option.name.split(" ") : [];
                        const firstNameInitial = initials.length > 0 ? initials[0].charAt(0) : "";
                        const secondNameInitial = initials.length > 1 ? initials[1].charAt(0) : "";
                        return (
                          <Box className="assignee-bubble" key={option.id}>
                            {firstNameInitial}
                            {secondNameInitial}
                          </Box>
                        );
                      }
                    )}
                    {this.state.editDealDetails.dealContacts > 2 && (
                      <Box className="assignee-bubble">
                        +{this.state.editDealDetails.dealContacts - 2}
                      </Box>
                    )}
                  </Box>
                </InputAdornment>
              ) : null
            }
            endAdornment={
              <InputAdornment
                position="end"
                onClick={this.openEditContactModal}
                data-test-id="openEditContactModal"
              >
                <ChevronRightIcon />
              </InputAdornment>
            }
          />
        </Box>
      </Box>
    );
  };
  fileUpoladInput = () => {
    const { classes } = this.props;
    return (
      <Box className="form-info-wrapper attachment-deals">
        <Typography className="form-heading" variant="h2">
          {this.t(`${configJSON.attachmentHeadingTxt}`)}
        </Typography>
        <Box className={classes.fileUploadWrapper}>
          <FileDrop>
            <Box className="file-upload-block">
              <Typography className="file-heading" variant="h2">
                <Box className="attach-icon-deals">
                  <img src={attachGray} alt="attachGray" />
                </Box>
                {this.t(`${configJSON.clickHereToAttachTxt}`)}
                <Box
                  component="span"
                  className="browse-link-file"
                  onClick={this.onBrowse}
                  data-test-id="attachment-link"
                >
                  {this.t(`${configJSON.browseTxt}`)}
                </Box>{" "}
                {this.t(`${configJSON.yourFilesTxt}`)}
                <form ref={this.formRef}>
                  <input
                    name="upload-photo"
                    accept="*"
                    type="file"
                    multiple
                    onChange={this.handleOnDrop}
                    hidden
                    ref={this.fileRef}
                    data-test-id="deal-attachment-file"
                  />
                </form>
              </Typography>
            </Box>
          </FileDrop>
        </Box>
        {this.state.filesUploaded.map((name: any, index: number) => (
          <List className="attachlist-ul" key={index}>
            <ListItem
              disableRipple
              className="attachlist-li"
              role={undefined}
              dense
              button
              
            >
              <ListItemIcon className="attachlist-icon">
                <img src={checkGreen} alt="checkGreen" />
              </ListItemIcon>
              <ListItemText
                className="attachlist-label"
                id="checkbox-list-label"
                primary={name.name}
                ref={this.attachListLabelRef}
              />
              <ListItemSecondaryAction>
                <Link
                  to="#"
                  className="tasklist-link link-gray"
                  onClick={() => this.removeFileUPload(index)}
                  data-test-id="remove-file-attachment"
                >
                  <img src={closeGray} alt="closeGray" />
                </Link>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        ))}
      </Box>
    );
  };
  editFileUploadInput = () => {
    const { classes } = this.props;
    console.log(this.state.editAttachments)
    console.log(this.state.editAttachmentsNew)
    return (
      <Box className="form-info-wrapper attachment-deals">
        <Typography className="form-heading" variant="h2">
          {this.t(`${configJSON.attachmentHeadingTxt}`)}
        </Typography>
        <Box className={classes.fileUploadWrapper}>
          <FileDrop>
            <Box className="file-upload-block">
              <Typography className="file-heading" variant="h2">
                <Box className="attach-icon-deals">
                  <img src={attachGray} alt="attachGray" />
                </Box>
                {this.t(`${configJSON.clickHereToAttachTxt}`)}
                <Box
                  component="span"
                  className="browse-link-file"
                  onClick={this.onBrowse}
                  data-test-id="attachment-link"
                >
                  {this.t(`${configJSON.browseTxt}`)}
                </Box>{" "}
                {this.t(`${configJSON.yourFilesTxt}`)}
                <form ref={this.formRef}>
                  <input
                    name="upload-photo"
                    accept="*"
                    type="file"
                    multiple
                    onChange={this.handleOnDropEdit}
                    hidden
                    ref={this.fileRef}
                    data-test-id="deal-attachment-file-edit"
                  />
                </form>
              </Typography>
            </Box>
          </FileDrop>
        </Box>

        {this.state.editAttachments !==null  &&
          <>
          {this.state.editAttachments.map((name: any, index: number) => (
          <List className="attachlist-ul" key={index}>
            <ListItem
              disableRipple
              className="attachlist-li"
              role={undefined}
              dense
              button
            >
              <ListItemIcon className="attachlist-icon">
                <img src={checkGreen} alt="checkGreen" />
              </ListItemIcon>
              <ListItemText
                className="attachlist-label"
                id="checkbox-list-label"
                primary={name.filename}
              />
              <ListItemSecondaryAction>
             
                  <img src={closeGray} alt="closeGray" onClick={() => this.removeAddedFile(name.id)} data-test-id={`remove-attach-${name.id}`} />
                
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        ))}
          </>
        }
        {this.state.editAttachmentsNew && this.state.editAttachmentsNew.length > 0  &&
          <>
          {this.state.editAttachmentsNew.map((name: any, index: number) => (
          <List className="attachlist-ul" key={index}>
            <ListItem
              disableRipple
              className="attachlist-li"
              role={undefined}
              dense
              button
            >
              <ListItemIcon className="attachlist-icon">
                <img src={checkGreen} alt="checkGreen" />
              </ListItemIcon>
              <ListItemText
                className="attachlist-label"
                id="checkbox-list-label"
                primary={name.name}
              />
              <ListItemSecondaryAction>
                <Link
                  to="#"
                  className="tasklist-link link-gray"
                  onClick={() => this.removeAddedFileNew(index)}
                  data-test-id="remove-file-attachment"
                >
                  <img src={closeGray} alt="closeGray" />
                </Link>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        ))}
          </>
        }
        
      </Box>
    );
  };
  
  isLoading = () => {
    if (this.state.isLoading) {
      return <WebLoader />;
    }
  };
  floatingCreateDeal = () => {
    const { classes } = this.props;
    const dealNameText = this.state.dealName.trim() === "" 
  ? this.t(configJSON.dealNameHEreTxt) 
  : this.state.dealName;
    if (this.state.floatCreateDeal) {
      return (
        <Box className={classes.btnMinimizeDeals}>
          <CardMedia
            component="img"
            src={minimizeDeal}
            className="expand-icon"
            onClick={this.openCreateDealPopoverShown}
            data-test-id="maximizeDealPopover"
          />
          <Typography className="create-txt">
            {dealNameText}
          </Typography>
          <Button className="minimize-close-btn">
            <CardMedia
              component="img"
              src={closeGray}
              className="close-icon"
              onClick={this.closeFloatCreateDeal}
              data-test-id="close-floating-deal"
            />
          </Button>
        </Box>
      );
    }
  };
  floatingCreateContact = () => {
    const { classes } = this.props;
    if (this.state.floatCreateContact) {
      return (
        <Box className={classes.btnMinimizeDeals}>
          <CardMedia
            component="img"
            src={minimizeDeal}
            className="expand-icon"
            onClick={this.closeFloatCreateContact}
            data-test-id="maximize-contact"
          />
          <Typography className="create-txt">
            Create Contact
          </Typography>
          <Button className="minimize-close-btn">
            <CardMedia
              component="img"
              src={closeGray}
              className="close-icon"
              onClick={this.handleCloseCreateContact}
              data-test-id="close-create-contact"
            />
          </Button>
        </Box>
      );
    }
  }
  dealsProductModal = () => {
    const { classes } = this.props;
    if (this.state.openProductsModal) {
      return (
        <AssociatedProductsWeb
          openProductsModal={this.state.openProductsModal}
          closeProductsModal={this.closeProductsModal}
          classes={classes}
          selectedDealProducts={this.selectedDealProducts}
          chosenDealProductIds={this.state.editDealPopoverShown ? this.state.editDealDetails.productIds : this.state.chosenDealProductIds}
          topButtonTxt={this.t((`${configJSON.addToDealstxt}`))}
          headingTxt={this.t(`${configJSON.associatedHeading}`)}
          headingSubTxt={this.t(`${configJSON.associatedSubHeading}`)}
          data-test-id="associated-products-web"
        />
      );
    }
  };
  showChosenItems = (productItems: any) => {
    
    return (
      productItems &&
      productItems.length > 0 && (
        <Box className="favorite-product">
          <Box className="group-form favorite-box" component="form">
            <Box className="category-left-box">
              <Typography className="left-title">
                {this.t(`${configJSON.associatedProductsTxt}`)}
              </Typography>
              <Typography className="left-sub-txt">
                {productItems.length}{" "}
                {productItems.length > 1 ? "Items" : "Item"}
              </Typography>
            </Box>
            <Box className="category-right-box">
              <Typography className="total-amount-txt">
                {this.t(`${configJSON.totalAmmount}`)}
              </Typography>
              <Typography className="amount-txt">
              {this.state.editDealPopoverShown ? `$${this.state.editDealDetails.edittotalAmount}` : `$${this.state.productAmount}.00`}
                
              </Typography>
              <Button
                className="arrow-btn"
                data-test-id="view-selected-product"
                onClick={this.openViewSelectedProducts}
              >
                <ArrowForwardIosIcon className="btn-icon" />
              </Button>
            </Box>
          </Box>
        </Box>
      )
    );
  };
  associatedProductFields = () => {
    return (
      this.state.chosenDealProductIds.length === 0 && (
        <Box className="form-row">
          <Box className="form-col" style={{ position: 'relative' }}>
            <CustomInputWeb
              type="text"
              label="Associated Product(s)"
              name="Associated Product(s)"
              disabled={false}
              customClass="has-modal"
              onClick={this.openProductsModal}
              isDealForm={true}
              data-test-id="associated-products"
              value=""
              endAdornment={
                <InputAdornment
                  position="end"
                  onClick={this.openProductsModal}
                  data-test-id="openProductsModal"
                >
                  <ChevronRightIcon />
                </InputAdornment>
              }
            />
          </Box>
         
        </Box>
      )
    );
  };
  deleteDealModal = () => {
    return (
      this.state.openDeleteModal && (
        <DeleteConfirmationModal
          modalOpen={this.state.openDeleteModal}
          modalClose={this.closeDeleteModal}
          deleteText={this.t(`${configJSON.dealTxt}`)}
          deleteItemLabel={this.state.menuDealName}
          confirmHandler={this.deleteDealHandler}
          data-test-id="deleteDealModal"
          t={this.t}
        />
      )
    );
  };
  showDealCount = (count: number) => {
    return count === 1
      ? this.t(`${configJSON.dealsTxtSingle}`)
      : this.t(`${configJSON.dealsTxt}`);
  };
  renderCloseDate = (date: any) => {
    if (date === null) {
      return "-";
    }
    return moment(date).format("MMM DD, YYYY");
  };

  renderTotalAmount = (totalAmount: string) => {
    if (totalAmount === null) {
      return "-";
    }
    return `$${totalAmount}`;
  };
  showDealsAttachments = (dealAttachments:any) => {
    return (
      <>
      {dealAttachments?.has_attachments &&
        dealAttachments.attachments &&
      dealAttachments.attachments.length > 0 &&
    (
      <Box className="attachment-link">
        <Box
          component="span"
          className="attach-icon-deals"
        >
          <img
            src={attachment}
            alt="attachment"
          />
        </Box>
        {dealAttachments.attachments.length}{" "}
        {this.t(
          `${configJSON.fileAttachmentTxt}`
        )}
      </Box>
     
    )}
     </>
    )
  }
  
  dealOwnersListing = () => {
    const { classes } = this.props;
    const { dealOwners, selectedOwner, editDealDetails, editDealPopoverShown, isActiveChooseDeal } = this.state;
  
    const ownerDetails = editDealPopoverShown && editDealDetails.dealOwnerSelected?.attributes
      ? editDealDetails.dealOwnerSelected.attributes
      : selectedOwner?.attributes;
  
    const ownerFirstName = ownerDetails?.first_name || "";
    const ownerLastName = ownerDetails?.last_name || "";
    const ownerEmail = ownerDetails?.email || "";
    const ownerTeam = ownerDetails?.team || null;
  
    const dealOwnersLength = dealOwners.length;
    const membersText = this.t(configJSON.membersTxt);
    const membersSingleText = this.t(configJSON.membersSingleTxt);
    const selectedOwnerId = editDealPopoverShown ? editDealDetails.dealOwnerSelected : selectedOwner;
  
    const handleOwnerClick = (dealOwner:any) => {
      editDealPopoverShown
        ? this.handleEditDealOwner(dealOwner.id, dealOwner.attributes)
        : this.handleDealOwnersClick(dealOwner.id);
    };
  
    return (
      <Modal
        open={isActiveChooseDeal}
        onClose={this.closeChooseDealOwner}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.chooseModalBox}
      >
        <Box className={classes.dealOwnersModal}>
          <Box className="modal-heading">
            <Box className="heading-left">
              <Typography className="modal-title" component="h2">
                {this.t(configJSON.dealOwnerTxt)}
              </Typography>
              <Box component="p" className="sub-txt">
                {this.t(configJSON.chooseDealSubTxt)}
              </Box>
            </Box>
            <Box className="heading-right">
              <Link
                to="#"
                className="close-icon"
                onClick={this.closeChooseDealOwner}
                data-test-id="closeDealOwnerModal"
              >
                <CloseIcon />
              </Link>
            </Box>
          </Box>
          <Box className="search-box">
            <CustomInputWeb
              type="select"
              data-test-id="select"
              label=""
              placeholder="Search Owner"
              errors=""
              rows={4}
              startAdornment={
                <InputAdornment position="start">
                  <img src={searchDeals} alt="searchDeals" />
                </InputAdornment>
              }
            />
          </Box>
          <Box className="assign-owner">
            <Box className="selected-owner-header">
              <Typography className="selected-owner-txt" component="h2">
                {this.t(configJSON.selectedOwnerTxt)}
              </Typography>
            </Box>
            <Box className="selected-owner">
              <Box className="select-owner-item">
                <Box className="avatar">
                  <Typography className="short-name">
                    {ownerFirstName.charAt(0)}
                    {ownerLastName.charAt(0)}
                  </Typography>
                </Box>
                <Box className="owner-info">
                  <Typography className="name">
                    {ownerFirstName} {ownerLastName}
                  </Typography>
                  <Typography className="email">{ownerEmail}</Typography>
                  <Box className="departments">
                    {ownerTeam && ownerTeam.map((team:any) => (
                      <Box className="dep-item" key={team.id}>
                        <Typography className="dep-name">
                          {team.title}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <CheckCircleIcon className="checkedCirlce" />
              </Box>
            </Box>
            <Box className="un-assign-owner">
              <Typography className="top-title">
                {this.t(configJSON.unassignedTxt)}
              </Typography>
              <Box className="top-list">
                <Typography className="count-owner">
                  {dealOwnersLength > 1 ? `${dealOwnersLength} ${membersText}` : `${dealOwnersLength} ${membersSingleText}`}
                </Typography>
                {this.sortByModal()}
              </Box>
              {dealOwnersLength > 0 && (
                <Box className="un-assign-owner-list">
                  {dealOwners
                    .filter((x:any) => selectedOwnerId.id !== x.id)
                    .map((dealOwner:any) => (
                      <Box
                        className="owner-item"
                        key={dealOwner.id}
                        onClick={() => handleOwnerClick(dealOwner)}
                        data-test-id={`owner-item-${dealOwner.id}`}
                      >
                        <Box className="avatar">
                          <Typography className="short-name">
                            {dealOwner.attributes.first_name.charAt(0)}
                            {dealOwner.attributes.last_name.charAt(0)}
                          </Typography>
                        </Box>
                        <Box className="owner-info">
                          <Typography className="name">
                            {dealOwner.attributes.first_name}{" "}
                            {dealOwner.attributes.last_name}
                          </Typography>
                          <Typography className="email">
                            {dealOwner.attributes.email}
                          </Typography>
                          {dealOwner.attributes.team && (
                            <Box className="departments">
                              {dealOwner.attributes.team.map((team:any) => (
                                <Box className="dep-item" key={team.id}>
                                  <Typography className="dep-name">
                                    {team.title}
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                          )}
                        </Box>
                        <RadioButtonUnchecked className="circleCheck" />
                      </Box>
                    ))}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };
  sortByModal = () => {
    const {classes} = this.props;
    return (
      <FormControl className="select-control">
        <Select
          inputProps={{ IconComponent: () => null }}
          displayEmpty
          defaultValue={this.t(`${configJSON.sortByTxt}`)}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
            classes: { paper: classes.dropdownStyle },
          }}
          renderValue={(value) => {
            return (
              <Box>
                {this.t(`${value}`)}
                <img
                  className="select-icon"
                  src={cheveronDown}
                  alt="sortVariantDeal"
                />
              </Box>
            );
          }}
        >
          <MenuItem value="A-Z">A-Z</MenuItem>
          <MenuItem value="Z-A">Z-A</MenuItem>
        </Select>
      </FormControl>
    );
  };
  showMoreOptionsTable = (dealId: string) => {
    const {classes} = this.props;
    return (
      <Menu
          id="tableMenuEl"
          anchorEl={this.state.tableMenuEl}
          keepMounted
          open={Boolean(this.state.tableMenuEl)}
          onClose={() => this.tableMenuClose()}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          className={`${classes.dropdownDeals} tablemenu-dropdown`}
          data-test-id={`changeTableMenu-${dealId}`}
          >
          <MenuItem
            className="table-menu-item"
            onClick={() =>
              this.duplicateDealHandler(
                this.state.menuDealId
              )
            }
            data-test-id={`duplicate-deal-${dealId}`}
          >
            <Box className="name">
              {this.t(
                `${configJSON.duplicateDealTxt}`
              )}
            </Box>
            <Box className="icon">
              <img
                src={copyIcon}
                alt="copyIcon"
              />
            </Box>
          </MenuItem>
          <MenuItem className="table-menu-item">
            <Box className="name">
              {this.t(
                `${configJSON.createTaskTxt}`
              )}
            </Box>
            <Box className="icon">
              <img
                src={taskIcon}
                alt="taskIcon"
              />
            </Box>
          </MenuItem>
          {this.userRole !=="employee" && 
          <MenuItem
            className="table-menu-item"
            onClick={() => this.openDeleteModal()}
            data-test-id={`delete-deal-${dealId}`}
          >
            <Box className="name">
              {this.t(
                `${configJSON.deleteDealTxt}`
              )}
            </Box>
            <Box className="icon">
              <img
                src={deleteIcon}
                alt="deleteIcon"
              />
            </Box>
          </MenuItem>
          }
      </Menu>
    )
  }
  showCreateContactForm = () => {
    const {classes} = this.props;
    if(this.state.showCreateContact){
      return (
      <CreateContactWeb 
      handleCloseDialog={this.handleCloseCreateContact}
      handleMinimizeCreateContact={this.openFloatCreateContact}
      id={""}
      navigation="/contacts"
      classes={classes}
      data-test-id="create-contact-form"
      anchorElState={this.state.contactEl}
      handleEscCreateContact={this.handleEscCreateContact}
      isCreatingFromDeal={true}
      /> 
     
      )
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    let ownerfirstName, ownerId, ownerLastName, ownerEmail, ownerTeam;
    let dealsListData;
    const {selectedOwner, dealsListing, searchResults,currentPage, dealsPerPage } =
      this.state;
    if (this.state.isSearching) {
      dealsListData = searchResults;
    } else {
      dealsListData = dealsListing;
    }

      if (selectedOwner?.attributes) {
        ownerfirstName = selectedOwner.attributes.first_name;
        ownerLastName = selectedOwner.attributes.last_name;
        ownerEmail = selectedOwner.attributes.email;
        ownerTeam = selectedOwner.attributes.team ?? null;
      }
    const ownerFilters = dealsListing.map((dealOwner: any) => ({
      value: dealOwner.attributes.deal_owner.id,
      label: dealOwner.attributes.deal_owner.name,
    }));
    // Use Set to get unique objects based on the "value" property
    const uniqueOwnerFiltersSet = new Set(
      ownerFilters.map((item: any) => item.value)
    );

    // Create an array of unique objects
    const uniqueOwnerFilters = Array.from(uniqueOwnerFiltersSet).map((value) =>
      ownerFilters.find((item: any) => item.value === value)
    );
    // Use Set to store unique contact names
    const uniqueContactNamesSet = new Set();

    // Use an array to store unique contacts
    const uniqueContacts: any = [];

    // Iterate through each deal and extract unique contact names
    dealsListing.forEach((deal: any) => {
      const contacts = deal.attributes.contacts || [];
      contacts.forEach((contact: { id: string; name: string }) => {
        const contactId = contact.id;
        const contactName = contact.name;

        // Check if the contact name is not in the set, add it, and push to the array
        if (!uniqueContactNamesSet.has(contactName)) {
          uniqueContactNamesSet.add(contactName);

          // Add the unique contact to the array
          uniqueContacts.push({ id: contactId, name: contactName });
        }
      });
    });
    const { isMobile, classes } = this.props;
    const popOverOpen = Boolean(this.state.popoveAnchorEl);
    const popoverId = popOverOpen ? "simple-popover" : undefined;
    return (
      <>
        {this.isLoading()}
        <div style={{position: "fixed", width: "100%", zIndex: 2}}>
        <GlobalHeaderWeb  t={this.t}/>
        </div>
        <Box className={classes.dealsMarketingRoot}>
          <Box className="mobile-heading-deals">
            <Box className="back-btn-wrap-deals">
              <i>
                <svg
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  className="MuiSvgIcon-root"
                >
                  <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                </svg>
              </i>
            </Box>
            <Typography className="heading" variant="h1">
              {this.t(`${configJSON.dealsTxt}`)}
            </Typography>
            <Box
              className="marketing-menu-deals"
              onClick={this.toggleMenuDrawer}
              data-test-id="mobile-marketing-menu"
            >
              <img src={menu} alt="menu" />
            </Box>
          </Box>
          <Box className={classes.dealsdrawerWrapper} sx={{
           paddingTop: dealsListData.length === 0 ? { xs: "10%", sm: "8%", md: "6%" } : "",
          }}>
            <Box className={classes.drawer}>
              <Drawer
                classes={{
                  root: classes.drawerRootWrapper,
                  paper: classes.drawerPaper,
                }}
                variant={isMobile ? "temporary" : "permanent"}
                open={isMobile ? this.state.isMenuDrawerOpened : true}
              >
                <CustomMarketingSidebar
                  toggleMenu={this.closeMenuDrawer}
                  data-test-id="marketing-sidebar"
                  t={this.t}
                  allTabsCount={this.state.dealsTabCount}
                />
              </Drawer>
            </Box>
            <Box className={classes.dealsdrawerContent}>
              
              <Box className="marketing-content-deals">
                {this.state.dealsListing.length > 0 ? (
                  <>
                    <Box className="marketing-top-deals">
                      <Box className="top-left">
                        <Box className="email-count">
                          {dealsListData.length}{" "}
                          {this.showDealCount(dealsListData.length)}
                        </Box>
                        <Box className="search-wrapper">
                          <Input
                            placeholder={this.t(`${configJSON.searchTxt}`)}
                            type="search"
                            onChange={(event) =>
                              this.handleDealsSearchquery(event.target.value)
                            }
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            }
                            data-test-id="deals-search-input"
                          />
                        </Box>
                      </Box>
                      <Box className="top-right">
                        <Box className="filter-list">
                          <FormControl className="select-control-deals">
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                                classes: { paper: classes.dropdownStyle },
                              }}
                              displayEmpty
                              defaultValue=""
                              onChange={this.filterStageHandler}
                              data-test-id={"filter-stage-dropdown"}
                            >
                              <MenuItem value="">
                                {this.t(`${configJSON.stageTxt}`)}
                              </MenuItem>
                              {this.renderStatusItems()}
                            </Select>
                          </FormControl>
                          
                           <Box className="filter-more">
                            <Box
                              className="menu-dropdown more"
                              onClick={this.handleOpenDealAmount}
                              data-test-id="deal-amount-filter"
                            >
                              <Typography className="more-txt">
                                {this.t(`${configJSON.dealAmountTxt}`)}
                              </Typography>
                              <ArrowDropDownIcon
                                style={{ color: "#737070" }}
                              />
                            </Box>
                            <Popover
                              open={Boolean(this.state.anchorDealAmount)}
                              anchorEl={this.state.anchorDealAmount}
                              onClose={this.handleCloseDealAmount}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              classes={{ paper: `${classes.popoverStyle}` }}
                              data-test-id="deal-amount-popover"
                            >
                              <Box className="view-more-dropdown">
                                <Box className="dropdown-menu-item close-menu-item">
                                  <Box className="deal-amount-header">
                                    <Typography className="title">Deal Range</Typography>
                                    <Typography className="reset" onClick={this.resetDealAmountsFilter}>Reset</Typography>
                                  </Box>
                                <Box className="slider-input">
                                <CustomInputWeb
                                  type="number"
                                  label="Min"
                                  name="Min"
                                  value={this.state.dealAmountRange[0].toString()}
                                  onChange={(event: any) =>
                                    this.handleMinInputChange(event)
                                  }
                                  
                                  data-test-id="minAmountValue"
                                />
                                
                                <CustomInputWeb
                                  type="number"
                                  label="Max"
                                  name="Max"
                                  value={this.state.dealAmountRange[1].toString()}
                                  onChange={(event: any) =>
                                    this.handleMaxInputChange(event)
                                  }
                                  data-test-id="maxAmountValue"
                                />
                              </Box>
                                <Box className="deal-amount-slider">
                                  <Slider
                                    value={this.state.dealAmountRange}
                                    onChange={(event: any, newValue: any) =>
                                      this.handleSliderChange(newValue as number[])
                                    }
                                    valueLabelDisplay="auto"
                                    aria-labelledby="range-slider"
                                    min={this.state.minValueAmount}
                                    max={this.state.maxValueAmount}
                                    data-test-id="amount-slider"
                                  />
                                </Box>
                                <Button
                                className={`primary-btn ${classes.primaryButton}`}
                                aria-describedby={popoverId}
                                onClick={this.handleAmountsFilter}
                                data-test-id="apply-deal-amount-btn"
                              >
                                Apply
                              </Button>
                               
                                </Box>
                              </Box>
                            </Popover>
                          </Box>
                            <Box className="filter-more">
                            <Box
                              className="menu-dropdown more"
                              onClick={this.handleOpenCloseDateFilters}
                              data-test-id="close-by-date-filter"
                            >
                              <Typography className="more-txt">
                                {this.t(`${configJSON.closeByDateTxt}`)}
                              </Typography>
                              <ArrowDropDownIcon
                                style={{ color: "#737070" }}
                              />
                            </Box>
                            <Popover
                              open={Boolean(this.state.anchorElCloseDate)}
                              anchorEl={this.state.anchorElCloseDate}
                              onClose={this.handleCloseDateFilters}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              classes={{ paper: `${classes.popoverStyle}` }}
                              data-test-id="close-by-date-popover"
                            >
                              <Box className="view-more-dropdown">
                                <Box className="dropdown-menu-item close-menu-item">
                                  <FormControl className="select-control-deals">
                                    <Select
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "left",
                                        },
                                        transformOrigin: {
                                          vertical: "top",
                                          horizontal: "left",
                                        },
                                        getContentAnchorEl: null,
                                        classes: { paper: classes.dropdownStyleDeals },
                                      }}
                                      displayEmpty
                                      defaultValue="on_or_before"
                                      onChange={this.handleCloseDateTypeFilter}
                                      data-test-id="close-by-date-type"
                                    >
                                      <MenuItem value="on_or_before">
                                        {this.t(`${configJSON.onBeforeCloseDate}`)}
                                      </MenuItem>
                                      <MenuItem value="on_or_after">
                                      {this.t(`${configJSON.onAfterCloseDate}`)}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                  <CustomDatePicker
                                      placeholder={this.t(`${configJSON.selectCloseDate}`)}
                                      range={false}
                                      value={this.state.dealFilterDate}
                                      currentDate={this.state.dealFilterDate}
                                      onChange={this.handleDealFilterChange}
                                      t={this.t}
                                      onOpenPickNewDate={false}
                                      data-test-id="dealFilterDate"
                                      calendarPosition="top-right"
                                      isDeal={true}
                                      inPortal={true}
                                    />
                                </Box>
                              </Box>
                            </Popover>
                          </Box>
                          <FormControl className="select-control-deals">
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                                classes: { paper: classes.dropdownStyle },
                              }}
                              displayEmpty
                              defaultValue=""
                              onChange={this.filterContactsHandler}
                              data-test-id={"dropdown-"}
                            >
                              <MenuItem value="">
                                {this.t(`${configJSON.contactTxt}`)}
                              </MenuItem>
                              {uniqueContacts.map((contact: any) => {
                                return (
                                  <MenuItem key={contact.id} value={contact.id}>
                                    {contact.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          <FormControl className="select-control-deals">
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                                classes: { paper: classes.dropdownStyle },
                              }}
                              displayEmpty
                              defaultValue=""
                              onChange={this.filterDealOwnerHandler}
                              data-test-id={"dropdown-"}
                            >
                              <MenuItem value="">
                                {this.t(`${configJSON.dealOwnerTxt}`)}
                              </MenuItem>
                              {uniqueOwnerFilters.map((dealOwner: any) => {
                                return (
                                  <MenuItem
                                    key={dealOwner.value}
                                    value={dealOwner.value}
                                  >
                                    {dealOwner.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          
                          <Box className="filter-more">
                            <Box
                              className="menu-dropdown more"
                              onClick={this.handleOpenMoreMenu}
                              data-test-id="moreDropdownOptions"
                            >
                              <Typography className="more-txt">
                                {this.t(`${configJSON.moreTxt}`)}
                              </Typography>
                              <ArrowDropDownIcon
                                style={{ color: "#737070"}}
                              />
                            </Box>
                            <Popover
                              open={Boolean(this.state.anchorElMore)}
                              anchorEl={this.state.anchorElMore}
                              onClose={this.handleCloseMoreMenu}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              classes={{ paper: `${classes.popoverStyle}` }}
                              data-test-id="morePopover"
                            >
                              <Box className="view-more-dropdown">
                                <Box className="dropdown-menu-item">
                                  <Typography className="dropdown-menu-item-text">
                                    {this.t(`${configJSON.hasTasks}`)}
                                  </Typography>
                                  <Switch
                                    className="dropdown-menu-item-switch"
                                    checked={this.state.hasTasksDeals}
                                    onChange={this.handleChecked}
                                    name="hasTasksDeals"
                                    data-test-id="hasTaskDeals"
                                  />
                                </Box>
                                <Box className="dropdown-menu-item">
                                  <Typography className="dropdown-menu-item-text">
                                    {this.t(`${configJSON.hasAttachment}`)}
                                  </Typography>
                                  <Switch
                                    className="dropdown-menu-item-switch"
                                    checked={this.state.hasAttachment}
                                    onChange={this.handleChecked}
                                    name="hasAttachment"
                                    data-test-id="hasAttachment"
                                  />
                                </Box>
                                <Box className="dropdown-menu-item">
                                  <Typography className="dropdown-menu-item-text">
                                    {this.t(
                                      `${configJSON.hasAssociatedProduct}`
                                    )}
                                  </Typography>
                                  <Switch
                                    className="dropdown-menu-item-switch"
                                    checked={this.state.hasAssociatedProduct}
                                    onChange={this.handleChecked}
                                    name="hasAssociatedProduct"
                                    data-test-id="hasAssociatedProduct"
                                  />
                                </Box>
                                
                              </Box>
                            </Popover>
                          </Box>

                          <FormControl className="select-control-deals sortby-control">
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                                classes: { paper: classes.dropdownStyleDeals },
                              }}
                              displayEmpty
                              defaultValue={this.t(`${configJSON.sortByTxt}`)}
                              data-test-id="sortDeals"
                              inputProps={{ IconComponent: () => null }}
                              onChange={this.handleSortByDeals}
                              renderValue={(value) => {
                                return (
                                  <Box>
                                    <img
                                      className="select-icon"
                                      src={sortVariant}
                                      alt="sortVariant"
                                    />
                                    {this.t(`${value}`)}
                                  </Box>
                                );
                              }}
                            >
                              <MenuItem value="Sort by">
                                {this.t(`${configJSON.sortByTxt}`)}
                              </MenuItem>
                              <MenuItem value="A-Z">A-Z</MenuItem>
                              <MenuItem value="Z-A">Z-A</MenuItem>
                              <MenuItem value="Highest to lowest value">Highest to lowest value</MenuItem>
                              <MenuItem value="Lowest to highest value">Lowest to highest value</MenuItem>
                              <MenuItem value="Soonest Close Date">Soonest Close Date</MenuItem>
                              <MenuItem value="Date Created">Date Created</MenuItem>
                              
                            </Select>
                          </FormControl>
                          <TablePagination
                          rowsPerPageOptions={[]}
                          component="div"
                          count={dealsListData.length}
                          rowsPerPage={dealsPerPage}
                          page={currentPage}
                          onPageChange={this.handleDealsPageChange}
                          onRowsPerPageChange={this.handleChangeRowsPerPage}
                          data-test-id="table-pagination"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box className="marketing-inner">
                      <TableContainer>
                        <Table
                          className={`table-wrap-deals ${classes.table}`}
                          //className=""
                          // className="direct-emails-sent"
                          aria-labelledby="tableTitle"
                          aria-label="enhanced table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell className="name-cell">
                                {this.t(`${configJSON.nameTxt}`)}
                              </TableCell>
                              <TableCell className="stage-cell">
                                {this.t(`${configJSON.stageTxt}`)}
                              </TableCell>
                              <TableCell className="deal-amount-cell">
                                {this.t(`${configJSON.closeByDateTxt}`)}
                              </TableCell>
                              <TableCell className="task-cell">
                                {this.t(`${configJSON.contactTxt}`)}
                              </TableCell>
                              <TableCell className="subtask-cell">
                                {this.t(`${configJSON.dealOwnerTxt}`)}
                              </TableCell>
                              <TableCell className="attachment-cell">
                                {this.t(`${configJSON.dealAmountTxt}`)}
                              </TableCell>
                              <TableCell className="aso-products-cell">
                                {this.t(`${configJSON.taskTxt}`)}
                              </TableCell>
                              <TableCell className="aso-products-cell">
                                {this.t(`${configJSON.attachmentTxt}`)}
                              </TableCell>
                              <TableCell className="action-cell">
                                {this.t(`${configJSON.actionsTxt}`)}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            
                            {dealsListData.slice(currentPage * dealsPerPage, currentPage * dealsPerPage + dealsPerPage).map((deal: any, index: number) => {
                              return (
                                <TableRow key={deal.id}>
                                  <TableCell className="name-cell" scope="row">
                                    <Box
                                      className="name-col"
                                      onClick={() =>
                                        this.viewDealDetails(deal.id)
                                      }
                                      data-test-id={`view-deal-item-${deal.id}`}
                                    >
                                      {deal.attributes.name}
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box className="form-col select-modal-field">
                                      <CustomInputWeb
                                        label=""
                                        disabled={true}
                                        startAdornment={
                                          <InputAdornment position="start">
                                            <Box className="status-item">
                                              <Box className="status-name">
                                                {deal.attributes.stage}
                                              </Box>
                                              {this.stageClassMaping(
                                                deal.attributes.stage
                                              )}
                                            </Box>
                                          </InputAdornment>
                                        }
                                        endAdornment={
                                          <InputAdornment
                                            position="end"
                                            onClick={() =>
                                              this.chooseStageHandler(
                                                deal.id,
                                                deal.attributes.stage,
                                                deal.attributes.note
                                              )
                                            }
                                            data-test-id={`chooseStageModal-${deal.id}`}
                                          >
                                            <ExpandMoreIcon />
                                          </InputAdornment>
                                        }
                                        data-test-id={`stageInput-${deal.id}`}
                                        isRequired={true}
                                      />
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    {this.renderCloseDate(
                                      deal.attributes.close_date
                                    )}
                                  </TableCell>

                                  <TableCell>
                                    {deal.attributes.contacts.length > 0 && (
                                      <>
                                        {deal.attributes.contacts
                                          .map(
                                            (contact: {
                                              id: string;
                                              name: string;
                                            }) => contact.name
                                          )
                                          .join(", ")}
                                      </>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {deal.attributes.deal_owner.name}
                                  </TableCell>
                                  <TableCell>
                                    {this.renderTotalAmount(
                                      deal.attributes.total_amount
                                    )}
                                  </TableCell>
                                  <TableCell align="center">{deal.attributes.tasks?.length}</TableCell>
                                  <TableCell>
                                    {this.showDealsAttachments(deal.attributes)}
                                  </TableCell>

                                  <TableCell className="action-link">
                                    <Link
                                      to="#"
                                      title="Edit"
                                      className="table-link"
                                      onClick={(event: any) =>
                                        this.popoverEditDeal(event, deal.id)
                                      }
                                      data-test-id={`editDeal-${deal.id}`}
                                    >
                                      {this.t(`${configJSON.editTxt}`)}
                                    </Link>
                                    <Box className="table-menu">
                                      <Button
                                        className="table-link"
                                        aria-controls="tableMenuEl"
                                        aria-haspopup="true"
                                        onClick={(event: any) =>
                                          this.setTableMenuEl(
                                            event,
                                            deal.id,
                                            deal.attributes.name
                                          )
                                        }
                                        data-test-id={`moreDropdownOption-${deal.id}`}
                                        endIcon={<ArrowDropDownIcon />}
                                      >
                                        {this.t(`${configJSON.moreTxt}`)}
                                      </Button>
                                      {this.showMoreOptionsTable(deal.id)}
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </>
                ) : (
                  <Box className="empty-box">
                    <CardMedia
                      component="img"
                      className="img-contact-book"
                      image={emptyDeals}
                    />
                    <Box className="box-empty-right">
                      <Typography className="txt-no-contact-title">
                        {this.t(`${configJSON.noDealCreatedTxt}`)}
                      </Typography>
                      <Typography className="txt-no-contact-sub-title">
                        {this.t(`${configJSON.createDealMsgTxt}`)}
                      </Typography>
                      <Button
                        onClick={(event: any) => this.popoverHandleClick(event)}
                        data-test-id="empty-deal-btn"
                      >
                        + {this.t(`${configJSON.createDealTxt}`)}
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.createTaskWrapper}>
          {this.state.dealsListing.length > 0 && (
            <Box className="task-actions">
              <Button
                className={`primary-btn ${classes.primaryButton}`}
                aria-describedby={popoverId}
                onClick={(event: any) => this.popoverHandleClick(event)}
                data-test-id="new-deal-btn"
              >
                + {this.t(`${configJSON.newDealTxt}`)}
              </Button>
            </Box>
          )}
          {this.state.createDealPopoverShown && (
            <Popover
              id={popoverId}
              open={popOverOpen}
              anchorEl={this.state.popoveAnchorEl}
              onClose={this.popoverHandleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              classes={{ paper: `${classes.createDealsBox}` }}
              data-test-id="deals-popover"
              onKeyDown={(event) => {this.handleEscDealsForm(event)}}
            >
              <Box className="deals-heading">
                <Box className="heading-left-deals">
                  <Typography className="modal-title" component="h2">
                    {this.t(`${configJSON.createDealTxt}`)}
                  </Typography>
                  <Box component="p" className="sub-txt">
                    {this.t(`${configJSON.createDealSubTxt}`)}
                  </Box>
                </Box>
                <Box className="heading-right">
                  <Link
                    to="#"
                    className="heading-icon"
                    onClick={this.openFloatCreateDeal}
                    data-test-id="minimMizeCreateDeal"
                  >
                    <img src={minimizeGray} alt="minimizeGray" />
                  </Link>
                  <Link
                    to="#"
                    className="heading-icon"
                    onClick={this.popoverHandleClose}
                    data-test-id="new-deal-btn-close"
                  >
                    <img src={closeGray} alt="closeGray" />
                  </Link>
                </Box>
              </Box>
              <Box className="task-description">
                <Box className="task-form" component="form">
                  <Box className="form-info-wrapper">
                    <Box className="form-row">
                      <Box className="form-col">
                        <CustomInputWeb
                          type="text"
                          label={this.t(`${configJSON.dealNameTxt}`)}
                          value={this.state.dealName}
                          isRequired={true}
                          isDealForm={true}
                          errors={this.state.createDealError.dealNameError}
                          name="Deal Name"
                          onChange={(e: any) =>
                            this.dealNameHandler(e.target.value)
                          }
                          data-test-id="deal-name-input"
                        />
                      </Box>
                    </Box>
                    <Box className="form-row">
                      <Box className="form-col">
                        <Box className="form-control">
                          <CustomDatePicker
                            placeholder={this.t(
                              `${configJSON.expectedDateCloseTxt}`
                            )}
                            range={true}
                            isDeal={true}
                            value={this.state.expectedDateRange}
                            currentDate=""
                            onChange={this.handleCloseDateChange}
                            onOpenPickNewDate={false}
                            data-test-id="dealCloseDate"
                            minDate={new Date()}
                            calendarPosition="bottom-right"
                            inPortal={true}
                            customPortal={document.body}
                            t={this.t}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box className="form-row">
                      <Box className="form-col">
                        <FormControl
                          className="select-outer"
                          variant="outlined"
                        >
                          <InputLabel id="stage">
                            {this.t(`${configJSON.stageTxt}`)}
                          </InputLabel>
                          <Select
                            labelId="stage"
                            id="stage"
                            name="newStatus"
                            value={this.state.dealStage}
                            label={this.t(`${configJSON.stageTxt}`)}
                            onChange={this.dealStageHAndler}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                              classes: {
                                paper: `${classes.dropdownStyleDeals}`,
                              },
                            }}
                            data-test-id="dealStage-select"
                          >
                            {this.renderStatusItems()}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <Box className="form-row">
                      <Box className="form-col">
                        <CustomInputWeb
                          type="text"
                          label={this.t(`${configJSON.descriptionTxt}`)}
                          isMultiline
                          rows={4}
                          isDealForm={true}
                          value={this.state.dealDescription}
                          name="compose-email"
                          onChange={(e: any) =>
                            this.dealDescriptionHandler(e.target.value)
                          }
                          data-test-id="dealDescription"
                        />
                      </Box>
                    </Box>
                    <Box className="form-row">
                      <Box className="form-col">
                        <CustomInputWeb
                          type="text"
                          label={this.t(`${configJSON.dealOwnerTxt}`)}
                          name="Deal Owner"
                          disabled={false}
                          data-test-id="chooseDealOwnersModal"
                          isRequired={true}
                          isDealForm={true}
                          customClass="has-modal"
                          errors={
                            ownerfirstName || ownerLastName
                              ? ""
                              : this.state.createDealError.dealOwnerError
                          }
                          value=""
                          onClick={this.openChooseDealModal}
                          startAdornment={
                            <InputAdornment position="start">
                              {ownerfirstName} {ownerLastName}
                            </InputAdornment>
                          }
                          endAdornment={
                            <InputAdornment
                              position="end"
                             onClick={this.openChooseDealModal}
                              data-test-id="openDealOwnersModal"
                            >
                              <ArrowDropDownIcon />
                            </InputAdornment>
                         }
                        />
                       
                      </Box>
                    </Box>
                    {this.chooseContactInput()}
                    {this.associatedProductFields()}

                    {this.showChosenItems(this.state.chosenProductsData)}
                  </Box>
                  {this.fileUpoladInput()}
                </Box>
              </Box>
              <Box className="createtask-bottom">
                <Box className="full-btn">
                  <Button
                    className={`primary-btn ${classes.primaryButton}`}
                    onClick={this.createDealHandler}
                    data-test-id="create-deal-btn"
                  >
                    {this.t(`${configJSON.createDealTxt}`)}
                  </Button>
                </Box>
              </Box>
            </Popover>
          )}
        </Box>
        
        {this.floatingCreateDeal()}
        {this.floatingCreateContact()}

        {this.state.isActiveChooseStage && (
          <Modal
            open={this.state.isActiveChooseStage}
            onClose={this.chooseStageModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`${classes.modalDialog}`}
            BackdropProps={{ className: "backdrop-root" }}
            data-test-id="activeStageModal"
          >
            <Box
              className={`${classes.modalCenterDialogBox} modalCenterDialogBox`}
            >
              <Box className="modal-heading">
                <Typography className="modal-title" component="h2">
                  {this.t(`${configJSON.changeStageTxt}`)}
                </Typography>
                <Link
                  to="#"
                  className="close-icon"
                  data-test-id="stage-modal-close"
                  onClick={this.chooseStageModalClose}
                >
                  <CloseOutlinedIcon />
                </Link>
              </Box>
              <Box className="modal-description-stage">
                <Box className="modal-form-stage" component="form">
                  <Box className="form-info-wrapper">
                    <Box className="form-row">
                      <Box className="form-col">
                        <FormControl
                          className="select-outer"
                          variant="outlined"
                        >
                          <Select
                            labelId="current-stage"
                            id="current-stage"
                            //editDealDetails
                            value={this.state.newStatus ?? 0}
                            defaultValue={
                              this.state.currentDealStage.currentdealStage
                            }
                            onChange={this.handleStageChange}
                            name="newStage"
                            label="Current Stage"
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                              classes: {
                                paper: `${classes.dropdownStyleDeals} modal-dropdown`,
                              },
                            }}
                            data-test-id="modalChangeStatus"
                            IconComponent={ExpandMoreIcon}
                          >
                            {this.renderStatusItems()}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <Box className="form-row">
                      <Box className="form-col">
                        <CustomInputWeb
                          type="text"
                          value={this.state.addDealNote}
                          name="addNote"
                          onChange={(event) =>
                            this.dealNoteHandler(event.target.value)
                          }
                          label={this.t(`${configJSON.addANoteTxt}`)}
                          data-test-id="dealNote-input"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="modal-footer">
                  <Button
                    className={`secondary-btn ${classes.secondaryButton}`}
                    title="Cancel"
                    onClick={this.chooseStageModalClose}
                  >
                    {this.t(`${configJSON.cancelTxt}`)}
                  </Button>
                  <Button
                    className={`primary-btn ${classes.primaryButton}`}
                    title="Update"
                    onClick={this.updateDealStage}
                    data-test-id="updateDealStage"
                  >
                    {this.t(`${configJSON.updateTxt}`)}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        )}
        {this.state.isActiveChooseDeal && this.dealOwnersListing()}
        {this.chooseContactModal()}
        {this.state.openViewDetailModal && (
          <ViewDealsWeb
            openViewModal={this.state.openViewDetailModal}
            dealId={this.state.viewSingleDealId}
            data-test-id="viewDealcomponent"
            closeViewModal={this.closeViewDetails}
            duplicateHandler={() =>
              this.duplicateDealHandler(this.state.viewSingleDealId)
            }
            deleteDealHandler={() => this.openDeleteModal()}
            editDealHAndler={(event) =>
              this.popoverEditDeal(event, this.state.viewSingleDealId)
            }
            allDealsLoad={this.getAllDeals}
          />
        )}
        {this.deleteDealModal()}
        {this.state.editDealPopoverShown && (
          <Popover
            id={popoverId}
            open={Boolean(this.state.popoverEditDealEl)}
            anchorEl={this.state.popoverEditDealEl}
            onClose={this.editPopoverHandleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            classes={{ paper: `${classes.createDealsBox}` }}
            data-test-id="deals-edit-popover"
          >
            <Box className="deals-heading">
              <Box className="heading-left-deals">
                <Typography className="modal-title" component="h2">
                  {this.t(`${configJSON.editDealTxt}`)}
                </Typography>
                <Box component="p" className="sub-txt">
                  {this.t(`${configJSON.editDealSubTxt}`)}
                </Box>
              </Box>
              <Box className="heading-right">
                <Link
                  to="#"
                  className="heading-icon"
                  onClick={this.openFloatCreateDeal}
                  data-test-id="minimMizeCreateDeal"
                >
                  <img src={minimizeGray} alt="minimizeGray" />
                </Link>
                <Link
                  to="#"
                  className="heading-icon"
                  onClick={this.editPopoverHandleClose}
                  data-test-id="edit-deal-btn-close"
                >
                  <img src={closeGray} alt="closeGray" />
                </Link>
              </Box>
            </Box>
            <Box className="task-description">
              <Box className="task-form" component="form">
                <Box className="form-info-wrapper">
                  <Box className="form-row">
                    <Box className="form-col">
                      <CustomInputWeb
                        type="text"
                        label={this.t(`${configJSON.dealNameTxt}`)}
                        value={this.state.editDealDetails.dealName}
                        isRequired={true}
                        errors={this.state.createDealError.dealNameError}
                        name="Deal Name"
                        onChange={(e: any) =>
                          this.editDealNameHandler(e.target.value)
                        }
                        data-test-id="edit-dealname-input"
                      />
                    </Box>
                  </Box>
                  <Box className="form-row">
                    <Box className="form-col">
                      <Box className="form-control">
                        <CustomDatePicker
                          placeholder={this.t(
                            `${configJSON.expectedDateCloseTxt}`
                          )}
                          range={true}
                          isDeal={true}
                          value={this.state.editDealDetails.dealCloseDate}
                          currentDate=""
                          onChange={this.editCloseDateHandler}
                          onOpenPickNewDate={false}
                          data-test-id="editDeal-dealCloseDate"
                          inPortal={true}
                          customPortal={document.body}
                          minDate={new Date()}
                          calendarPosition="bottom-right"
                          t={this.t}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box className="form-row">
                    <Box className="form-col">
                      <FormControl className="select-outer" variant="outlined">
                        <InputLabel id="stage">
                          {this.t(`${configJSON.stageTxt}`)}
                        </InputLabel>
                        <Select
                          labelId="stage"
                          id="stage"
                          name="newStatus"
                          value={this.state.editDealDetails.dealStage}
                          label={this.t(`${configJSON.stageTxt}`)}
                          onChange={this.editDealStageHandler}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            classes: {
                              paper: `${classes.dropdownStyleDeals}`,
                            },
                          }}
                          data-test-id="editdealStage-select"
                        >
                          {this.renderStatusItems()}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                  {this.state.showDealNoteInput && 
                  <Box className="form-col">
                  <CustomInputWeb
                    type="text"
                    label={this.t(`${configJSON.noteTxt}`)}
                    value={this.state.editDealDetails.dealNote}
                    name="Note"
                    onChange={(e: any) => this.editDealNote(e.target.value)}
                    data-test-id="editDealnote"
                  />
                </Box>
                  }
                  
                  <Box className="form-row">
                    <Box className="form-col">
                      <CustomInputWeb
                        type="text"
                        label={this.t(`${configJSON.descriptionTxt}`)}
                        isMultiline
                        rows={4}
                        value={this.state.editDealDetails.dealDescription}
                        name="description"
                        onChange={(e: any) =>
                          this.editdealDescription(e.target.value)
                        }
                        data-test-id="editDeal-description"
                      />
                    </Box>
                  </Box>
                  
                  {this.editChooseContactInput()}
                  {this.state.isEditContact && 
                    <>
                   {this.editChooseContactModal()}
                   </>
                  }
                  <Box className="form-row">
                      <Box className="form-col">
                        <CustomInputWeb
                          type="text"
                          label={this.t(`${configJSON.dealOwnerTxt}`)}
                          name="Deal Owner"
                          disabled={true}
                          data-test-id="editDealOwnersModal"
                          errors={
                            ownerfirstName || ownerLastName
                              ? ""
                              : this.state.createDealError.dealOwnerError
                          }
                          value=""
                          startAdornment={
                            <InputAdornment position="start">
                              {this.state.editDealDetails?.dealOwner?.name}
                              
                            </InputAdornment>
                          }
                          endAdornment={
                            <InputAdornment
                              position="end"
                              onClick={this.openChooseDealModal}
                              data-test-id="openEditDealOwner"
                            >
                              <ArrowDropDownIcon />
                            </InputAdornment>
                          }
                        />
                      </Box>
                    </Box>
                    {this.state.editDealDetails.dealProducts.length > 0 ? <>{this.showChosenItems(
                    this.state.editDealDetails.dealProducts
                  )}</> : <>  {this.associatedProductFields()}</>
                    
                    }         
                </Box>
                {this.editFileUploadInput()}
              </Box>
            </Box>
            <Box className="createtask-bottom">
              <Box className="full-btn edit-btn">
                <Button
                  onClick={this.updateDealHandler}
                  className={`primary-btn ${classes.primaryButton}`}
                  data-test-id="update-deal-btn"
                >
                  {this.t(`${configJSON.updateDealTxt}`)}
                </Button>
              </Box>
            </Box>
          </Popover>
        )}
        {this.dealsProductModal()}
        {this.state.viewSelectedProducts && (
          <ProductViewModal
            modalOpen={this.state.viewSelectedProducts}
            modalClose={this.closeViewSelectedProducts}
            selectedProductsData={this.state.editDealPopoverShown ? this.state.editDealDetails.dealProducts : this.state.chosenProductsData}
            openEdit={this.openProductsModal}
            classes={classes}
            data-test-id="deal-view-products"
            t={this.t}
          />
        )}
        {this.showCreateContactForm()}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(LeadManagement);
// Customizable Area End
