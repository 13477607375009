// Customizable Area Start
import React from "react";
import {
  createStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
import { calendarBlack } from "./assets";


export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    marketingRoot: {
      display: "flex",
      flexWrap: "wrap",
      flex: "1",     
      minHeight: "calc(100vh - 88px)", 
      "& .mobile-heading": {
        display: "none",
       [theme.breakpoints.down("xs")]: {
          paddingBottom: "0",
          marginBottom: "48px",
          borderBottom: "0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "24px 24px 0",
        },
        "& .marketing-menu": {
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        },
        "& .back-btn-wrap": {
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        },
        "& .heading": {          
          [theme.breakpoints.down("xs")]: {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "18px",
            margin: "0 20px",
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            letterSpacing: "0",
          },
        },
      },
      "& .marketing-content": {   
        // "& .marketing-inner":{
        //   "& .MuiTableContainer-root":{
        //     overflowX: "unset !important",
        //   },
        // },    
        "& .marketing-top": {
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          alignItems: "center",
          padding: "8px 32px",
          borderBottom: "1px solid #E8ECF2",
          [theme.breakpoints.down("xs")]: {
            display: "none",
          },
          "& .top-left": {
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            "& .email-count": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: 500,
              letterSpacing: "0.1px",
              marginRight: "18px",
              paddingRight: "18px",
              borderRight: "1px solid #E8ECF2",
            },
            "& .search-wrapper": {
              [theme.breakpoints.down("xs")]: {
                width: "100%",
              },
              "& .MuiInput-root": {
                // width: "413px",
                maxWidth: "100%",
                // [theme.breakpoints.down("md")]: {
                //   width: "308px",
                // },
                // [theme.breakpoints.down("sm")]: {
                //   width: "234px",
                // },
                // [theme.breakpoints.down("xs")]: {
                //   width: "100%",
                // },
              },
              "& .MuiInputAdornment-positionStart": {
                marginRight: "12px",
              },
              "& .MuiInput-underline": {
                "&:before, &:after": {
                  display: "none",
                },
              },
              "& .MuiSvgIcon-root": {
                color: "#2b2b2b",
                fontSize: "20px",
                opacity: "0.67",
              },
              "& .MuiInputBase-input": {
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "0.1px",
                color: "#2B2B2B",
                fontWeight: "500",
                fontFamily: "Expletus Sans",
                "&::-webkit-input-placeholder": {
                  opacity: "0.37",
                  color: "#2B2B2B",
                },
                "&:-ms-input-placeholder": {
                  opacity: "0.37",
                  color: "#2B2B2B",
                },
                "&::placeholder": {
                  opacity: "0.37",
                  color: "#2B2B2B",
                },
              },
            },
          },
          "& .top-right": {
            //marginLeft: "15px",
            "& .filter-list": {
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              "& .filter-control": {
                marginRight: "24px",
                color: "#2B2B2B",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
                alignItems: "center",
                display: "flex",
                cursor : "pointer",
                //opacity: "0.67",
                "& .icon": {
                  marginRight: "6px",
                },
                "& .datepicker-wrapper": {
                  marginRight: "0",
                  "& .rmdp-container": {
                    width: "100px",
                    "& .rmdp-input": {
                      border: "0",
                      padding: "0 0 0 24px",
                      //minHeight: "unset",
                      backgroundPosition: "left center",
                      backgroundImage: `url(${calendarBlack})`,
                      opacity: "0.67",
                      "&::-webkit-input-placeholder": {
                        color: "#2B2B2B",
                      },
                      "&:-ms-input-placeholder": {
                        color: "#2B2B2B",
                      },
                      "&::placeholder": {
                        color: "#2B2B2B",
                      },
                    },
                  },
                },
              },
              "& .switch-wrapper": {
                // marginRight: "24px",
                // paddingRight: "24px",
                // borderRight: "1px solid #E8ECF2",
                "& .MuiFormControlLabel-root": {
                  marginRight: "0",
                }
              },
              "& .select-control": {
                marginRight: "24px",
                "&:last-child": {
                  marginRight: "0",
                },
              },
              "& .border-hl": {
                marginLeft: "24px",
                paddingLeft: "24px",
                borderLeft: "1px solid #E8ECF2",
                cursor: "pointer",
              },
              "& .MuiTablePagination-toolbar":{
                paddingLeft : "0",
                "& .MuiTablePagination-actions":{
                  marginLeft: "0",
                  "& button":{
                    paddingLeft: "0",
                  }
                },
              },
            },
          },
          "& .select-control": {
            "&.multi-select": {
              "& .multiselect-label": {
                display: "none",
              },
              "& .MuiInput-formControl": {
                marginTop: "0",
              },
              "& .MuiSelect-select": {
                paddingRight: "40px",
                display: "flex",
                alignItems: "center",
                "& .select-icon": {
                  marginRight: "6px",
                },
                "& .assignee-list": {
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "6px",
                  "& .assignee-bubble": {
                    color: "rgba(43, 43, 43, 0.87)",
                    width: "24px",
                    border: "2px solid #FFF",
                    height: "24px",
                    display: "flex",
                    position: "relative",
                    fontSize: "9.586px",
                    background: "#ECF1F4",
                    alignItems: "center",
                    fontFamily: "Roboto",
                    fontWeight: 600,
                    lineHeight: "13.42px",
                    marginLeft: "-4px",
                    borderRadius: "100%",
                    letterSpacing: "-0.244px",
                    justifyContent: "center",
                    "&:first-child": {
                      marginLeft: 0,
                    }
                  }
                }
              },
              "& .multiple-clear": {
                position: "absolute",
                fontSize: "15px",
                right: "18px",
                top: "4px",
                zIndex: 1,
                color: "#94A3B8",
              },
            },
            "&.sortby-control": {
              "& .MuiSelect-select": {
                paddingRight: "0",
              },
              "& .select-icon": {
                top: "3px",
              },
            },
            "& .select-icon": {
              position: "relative",
              marginRight: "6px",
            },
            "& .MuiSelect-select": {
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.1px",
              color: "rgba(43, 43, 43, 0.87)",
              opacity: "0.671",
              fontWeight: "500",
              fontFamily: "Expletus Sans",
              paddingBottom: "0",
              paddingTop: "0",
              "&:focus": {
                backgroundColor: "transparent",
              },
              "& .clear-icon": {
                width: "50px",
                height: "50px",
                background: "red",
              },
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
          },
        },
        "& .empty-box": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          [theme.breakpoints.down(767)]: {
            flexDirection: "column",
          },

          "& .img-contact-book": {
            width: "300px",
            height: "244px",
            objectFit: "contain",
          },
          "& .box-empty-right": {
            margin: "0 0 0 93px",
            maxWidth:"415px",
            [theme.breakpoints.down(767)]: {
              margin: "0",
              padding: "0 25px",
            },
            "& .txt-no-contact-title": {
              color: "var(--task-completed-color, #2B2B2B)",
              fontFamily: "Expletus Sans",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "400",
              linHeight: "normal",
              letterSpacing: "0.25px",
            },
            "& .txt-no-contact-sub-title": {
              color: "var(--task-completed-color, #94A3B8)",
              fontFamily: "Roboto",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              linHeight: "20px",
              letterSpacing: "0.25px",
              margin: "12px 0",
            },
            "& .MuiButton-root": {
              borderRadius: "6px",
              background: "var(--brand-color-primary-100, #4BA3B7)",
              color: "#ffffff",
              textTransform: "none",
              fontFamily: "Expletus Sans",
              width: "211px",
              height: "48px",
              margin: "48px 0px 0px",
            },
            "& .brevo-connect-btn":{
              width: "136px",
            }
          },
        },
        "& .no-search-results":{
          display: "flex",
          padding: "24px",
          "& .MuiSvgIcon-root":{
            color: "#2b2b2b",
            fontSize: "20px",
            marginRight: "10px",
          },
          "& .search-txt":{
            fontFamily: 'Expletus Sans',
            fontSize: '14px',
            fontWeight: '500',  
          }
        }
      },
    },
    table: {
      "&.direct-emails-sent": {  
        minWidth: "auto",      
        "& .MuiTableCell-head": {
          cursor:"pointer",
          "&.name-cell": {
            width: "18.5%",
          },
          "&.title-cell": {
            width: "13.8%",
          },
          "&.desc-cell": {
            width: "25.5%",
          },
          "&.date-cell": {
            width: "230px",
            minWidth:"230px",
            maxWidth: "230px",
          },
          "&.attachment-cell": {
            width: "150px",
            minWidth:"150px",
            maxWidth: "150px",
          },
          "&.action-cell": {
            width: "7.7%",
          },
        },
      },
      "& .MuiTableRow-root": {
        "&.Mui-selected": {
          background: "#fafcfe",
          "&:hover": {
            background: "#fafcfe",
          },
          "& .MuiTableCell-root": {
            "& .profile-name": {
              backgroundColor: "#eaeff2",
            },
          },
        },
        "&.disable-member-row": {
          "&:hover": {
            background: "#ffffff",
          },
        },
      },
      "& .MuiTableCell-head": {
        fontSize: "14px",
        letterSpacing: "0.1px",
        lineHeight: "20px",
        color: "rgba(43, 43, 43, 0.369)",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        padding: "19px 16px",
        borderBottom: "1px solid #E8ECF2",
        cursor: "pointer",
        [theme.breakpoints.down("md")]: {
          padding: "19px 10px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "17px 10px",
        },
        "&.MuiTableCell-paddingNone": {
          padding: "0",
        },
        "&.MuiTableCell-paddingCheckbox": {
          padding: "0 40px 0 18px",
        },
        "&.name-cell": {
          paddingLeft: "29px",
        },
      },
      "& .MuiTableCell-body": {
        fontSize: "14px",
        letterSpacing: "0.1px",
        lineHeight: "20px",
        color: "#2b2b2b",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        padding: "11px 16px",
        borderBottom: "1px solid #E8ECF2",
        [theme.breakpoints.down("md")]: {
          padding: "11px 10px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "10px",
        },
        "&.MuiTableCell-paddingNone": {
          padding: "0",
        },
        "&.MuiTableCell-paddingCheckbox": {
          padding: "0 40px 0 18px",
        },
        "&.draft-cell":{
          color: "rgba(199, 38, 62, 1)",
        },
        "&.name-cell": {
          paddingLeft: "29px",
        },
        "& .MuiLink-root": {
          color: "inherit",
        },
        "&.action-link": {
          whiteSpace: "noWrap",
          "& .table-link": {
            marginLeft: "0",
            whiteSpace: "noWrap",
          },
        },
        "& .show-me-link": {
          marginLeft: "5px",
          background: "#4ba3b7",
          borderRadius: "6px",
          color: "#FFFFFF",
          textAlign: "center",
          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0.0075em",
          padding: "2px 8px",
          textTransform: "capitalize",
        },
        "& .attachment-link": {
          font: "inherit",
          textDecoration: "none",
          color: "inherit",
          display: "flex",
          alignItems: "center",
          whiteSpace: "noWrap",
          "& .attach-icon": {
            marginRight: "12px",
          }
        },
        "& .table-menu": {
          display: "inline-block",
          "& .table-link": {
            padding: "0",
            textTransform: "none",
            font: "inherit",
            "& .MuiButton-endIcon": {
              marginLeft: "2px",
            },
          },
        },
        "& .table-link": {
          color: "#2b2b2b",
          textDecoration: "none",
          marginLeft: "10px",
          "&:hover": {
            textDecoration: "underline",
          },
        },
        "& .name-col": {
          display: "flex",
          alignItems: "center",
          "& .table-link": {
            marginLeft: "0",
          },
        },
        "& .desc": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          "-webkit-line-clamp": "1",
          "-webkit-box-orient": "vertical",
        },
        "& .profile-name": {
          width: "48px",
          height: "48px",
          borderRadius: "24px",
          backgroundColor: "#fafcfe",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textTransform: "uppercase",
          fontWeight: "700",
          marginRight: "12px",
        },
        "& .assignee-bubble":{
          color: "rgba(43, 43, 43, 0.87)",
          width: "20px",
          border: "2px solid #FFF",
          height: "20px",
          display: "flex",
          position: "relative",
          fontSize: "9.586px",
          background: "#ECF1F4",
          alignItems: "center",
          fontFamily: "Roboto",
          fontWeight: 600,
          lineHeight: "13.42px",
          marginLeft: "4px",
          borderRadius: "100%",
          letterSpacing: "-0.244px",
          justifyContent: "center",
          "&:hover":{
            "& .text-detail":{
              opacity: 1,
              visibility: "visible",
              zIndex: 99999,
            },
          },
        },
        "& .text-detail":{
          // top: "calc(100% - 20px)",
          // left: "calc(100% - 31px)",
          top:"32px",
          left:"-140px",
          position: "absolute",
          boxShadow: "3px 1px 29px -2px rgba(0, 0, 0, 0.3)",
          borderRadius: "15px",
          backgroundColor: "#fff",
          padding: "15px",
          minWidth:"360px",
          transition: "all 0.5s",
          opacity: 0,
          visibility: "hidden",
          [theme.breakpoints.down(575)]: {
            left:"auto",
          },
          [theme.breakpoints.down(360)]: {
            minWidth:"100%",
          },
          "& ul":{
            padding:"0",
            "& li":{
              padding:"0",
              margin:"0 0 10px",
              "&:last-child":{
                margin:"0",
              },
              "& .title":{
                flex: "0 0 100px", 
                maxWidth: "100px",
                color: "#2B2B2B",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "1.28",
              },
              "& .desc":{
                flex:"1",
                display: "flex",
                alignItems: "center",
                whiteSpace: "nowrap",
                color: "#94A3B8",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "1.28",
                "& span":{
                  color:"#94A3B8",
                  display:"inline-block",
                  marginLeft:"5px",
                }
              },
            }
          }
        },
        "& .select-control": {
          "& .MuiSelect-select": {
            fontSize: "14px",
            letterSpacing: "0.1px",
            color: "#2b2b2b",
            fontWeight: "500",
            fontFamily: "Expletus Sans",
            paddingBottom: "0",
            paddingTop: "0",
            "&:focus": {
              backgroundColor: "transparent",
            },
          },
          "& .MuiInput-underline:before": {
            display: "none",
          },
          "& .MuiInput-underline:after": {
            display: "none",
          },
        },
      },
      "& .action-link": {
        width: "14.6%",
        [theme.breakpoints.down("md")]: {
          width: "21.6%",
        },
        "&> a": {
          marginRight: "25px",
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
    },
    dropdownStyle: {
      marginTop: "21px",
      maxWidth: "190px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "26px",
      },
      "& .status-item": {
        display: "flex",
        "& .status-name": {
          marginRight: "10px",
        },
        "& .status": {
          width: "12px",
          height: "12px",
          background: "#94A3B8",
          borderRadius: "100%",
          marginLeft: "auto",
          "&.open": {
            background: "#4BA3B7",
          },
          "&.cancelled": {
            background: "#F7E223",
          },
        },
      },
      "&.multiSelect": {
        maxWidth: "265px",
        boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
        borderRadius: "12px",
        "&.assignee-multiSelect": {
          maxWidth: "400px",
        },
        [theme.breakpoints.down("sm")]: {
          marginTop: "26px",
        },
        "& .MuiList-padding": {
          paddingTop: "16px",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "24px",
            paddingRight: "24px",
            position: "static",
            "&.multiselect-subheader": {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#2B2B2B",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              lineHeight: "19px",
              borderBottom: "1px solid #ECF1F4",
              paddingTop: "15px",
              paddingBottom: "15px",
              marginBottom: "5px",
              "& .tasklist-link": {
                marginLeft: "10px",
                color: "#C7263E",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontWeight: "500",
                lineHeight: "19px",
                whiteSpace: "nowrap",
                textDecoration: "none",
              },
            },
            "& .form-control": {
              marginBottom: "20px",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "16px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
        },
        "& .MuiMenuItem-root": {
          color: "#2B2B2B",
          padding: "14px 24px",
          "& .priority-name": {
            margin: "0",
            "& .MuiTypography-root": {
              display: "flex",
            },
            "& .priority-status": {
              marginLeft: "auto",
            }
          },
          "&.Mui-selected": {
            backgroundColor: "#F2F8FC",
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
            "& .multiselect-check": {
              display: "block",
            },
            "& .multiselect-squareCheck": {
              "& .normal-img": {
                display: "none",
              },
              "& .selected-img": {
                display: "block",
              }
            },
            "& .assignee-detail": {
              "& .assignee-right": {
                "& .assinee-selected": {
                  "& .normal-img": {
                    display: "none",
                  },
                  "& .selected-img": {
                    display: "block",
                  },
                },
              },
            },
          },
          "& .multiselect-check": {
            display: "none",
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
          },
          "& .multiselect-squareCheck": {
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
            "& .selected-img": {
              display: "none",
            }
          },
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#000000",
        padding: "14px 17px",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        whiteSpace: "normal",
        "& .select-icon": {
          marginRight: "6px",
        },
        "& .MuiTypography-root": {
          font: "inherit",
          letterSpacing: "0",
        },
      },
      "& .MuiList-padding": {
        paddingTop: "4px",
        paddingBottom: "8px",
      },
      "& .assignee-detail": {
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        "& .assignee-left": {
          display: "flex",
          alignItems: "center",
          "& .assignee-info": {
            marginLeft: "10px",
          },
          "& .assignee-profile": {
            width: "39px",
            height: "39px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ECF1F4",
            borderRadius: "100%",
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Roboto",
            fontSize: "15.485px",
            fontWeight: 600,
            lineHeight: "21.679px",
            letterSpacing: "-0.395px",
          },
          "& .assignee-name": {
            fontSize: "14px",
            lineHeight: "19px",
            color: "#2b2b2b",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
          },
          "& .assignee-email": {
            fontSize: "12px",
            lineHeight: "16px",
            color: "#94A3B8",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
          },
        },
        "& .assignee-right": {
          marginLeft: "10px",
          "& .assinee-selected": {
            width: "20px",
            height: "20px",
            "& .normal-img": {
              display: "none",
            },
            "& .selected-img": {
              display: "block",
            },
          },
          "& .assinee-unselected": {
            width: "20px",
            height: "20px",
            "& .normal-img": {
              display: "block",
            },
            "& .selected-img": {
              display: "none",
            },
          },
        },
      },
    },
    switchWrapper: {
      [theme.breakpoints.down("xs")]: {
        marginBottom: "20px",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& .MuiFormControlLabel-root": {
        opacity: "0.671",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0px",
        color: "#000000",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        [theme.breakpoints.down("xs")]: {
          marginLeft: "0",
          width: "100%",
          justifyContent: "space-between",
          opacity: "1",
          color: "#2B2B2B",
        },
        "& .MuiFormControlLabel-label": {
          font: "inherit",
        },
      },
      "& .MuiSwitch-track": {
        backgroundColor: "#d3d9e1",
        opacity: 1,
        [theme.breakpoints.down("xs")]: {
          backgroundColor: "#94A3B8",
        },
      },
      "& .MuiSwitch-root": {
        [theme.breakpoints.down("xs")]: {
          width: "33px",
          height: "20px",
          padding: "5px",
        },
        "& .MuiIconButton-root": {
          [theme.breakpoints.down("xs")]: {
            padding: "6px",
          },
        },
      },
      "& .MuiSwitch-switchBase": {
        color: "#fafafa",
        [theme.breakpoints.down("xs")]: {
          color: "#ffffff",
        },
        "&.Mui-checked": {
          color: "#3691a6",
          [theme.breakpoints.down("xs")]: {
            transform: "translateX(12px)",
          },
          "&+ .MuiSwitch-track": {
            backgroundColor: "#3691a6",
          },
        },
      },
      "& .MuiSwitch-thumb": {
        boxShadow: "none",
        filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.2))",
        [theme.breakpoints.down("xs")]: {
          width: "8px",
          height: "8px",
        },
      },
    },
    createTaskWrapper: {
      "& .task-actions": {
        position: "fixed",
        right: "32px",
        bottom: "32px",
        background: "#ffffff",
        [theme.breakpoints.down("sm")]: {
            bottom: "60px",
        },
        "& .secondary-btn": {
          boxShadow: "0px 0px 18px 2px rgba(0, 0, 0, 0.10)",
          marginRight: "16px",
          minWidth: "auto",
          color: "rgba(43, 43, 43, 0.87)",
          "&:hover": {
            border: "1px solid rgba(43, 43, 43, 0.87)",
          },
          "& .draft-name": {
            display: "flex",
            alignItems: "center",
          },
          "& .draft-img": {
            marginRight: "6px",
          },
          "& .close": {
            margin: "0 0 0 5px",
            cursor: "pointer",
          },
        },
      },
      "& .primary-btn": {
        boxShadow: "0px 0px 24px 0px rgba(5, 111, 135, 0.47)",
        minWidth: "136px",
      },
    },
    createTaskBox: {
      borderRadius: "12px",
      background: "#FFF",
      boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.22)",
      padding: "0",
      overflow: "hidden",
      maxWidth: "591px",
      width: "100%",
      marginLeft: "auto",
      maxHeight: "unset",
      display: "inline-block",
      top: "auto !important",
      bottom: "95px",
      right: "32px",
      position: "fixed",
      [theme.breakpoints.down("sm")]: {
        bottom: "65px",
      },
      [theme.breakpoints.down("xs")]: {
        top: "0 !important",
        bottom: "0",
        left: "0 !important",
        right: "0",
        maxWidth: "100%",
        borderRadius: "0",
        boxShadow: "none",
      },
      "& .task-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "40px 40px 14px",
        marginBottom: "0",
        [theme.breakpoints.down("xs")]: {
            padding: "14px 24px 17px",
        },
        "& .modal-title": {
          color: "#2B2B2B",
          fontSize: "18px",
          fontWeight: 400,
          lineHeight: "24px",
          fontFamily: "Expletus Sans",
          letterSpacing: "0",
          marginBottom: "5px",
        },
        "& .sub-txt": {
          fontSize: "12px",
          lineHeight: "16px",
          color: "#94A3B8",
          fontFamily: "Roboto",
          fontWeight: 400,
          letterSpacing: "0.09px",
        },
        "& .heading-right": {
          display: "flex",
          marginLeft: "10px",
          "& .heading-icon": {
            marginRight: "24px",
            "&:last-child": {
              marginRight: "0",
            },
          },
        },
      },
      "& .task-description": {
        padding: "10px 40px",
        overflowY: "auto",
        height: "calc(100vh - 400px)",
        [theme.breakpoints.down("sm")]: {
            height: "calc(100vh - 355px)",
        },
        [theme.breakpoints.down("xs")]: {
            height: "calc(100vh - 193px)",
            padding: "10px 24px",
        },
      },
      "& .form-heading": {
        color: "#2B2B2B",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "19px",
        fontFamily: "Expletus Sans",
        letterSpacing: "0",
        marginBottom: "16px",
      },
      "& .form-info-wrapper": {
        marginBottom: "16px",
        "&:last-child": {
          marginBottom: "0",
        },
        "& .product-summary-wrapper":{
          display:"flex",
          alignItems:"center",
          justifyContent:"space-between",
          marginBottom:"25px",
          "& .edit-items-link":{
            color: "#4ba3b7",
            textDecoration: "none",
            cursor:"pointer",
          },

        },
      },
      "& .form-row": {
        display: "flex",
        flexWrap: "wrap",
        margin: "0 -8px",
        "&:last-child": {
          "& .form-control": {
            marginBottom: "0",
          },
        },
        "& .form-col": {
          padding: "0 8px",
          flex: "0 0 100%",
          maxWidth: "100%",
          "&.col6": {
            flex: "0 0 50%",
            maxWidth: "50%",
          },
          "& .form-control": {
            marginBottom: "16px",
          },
        },
      },
      "& .select-outer": {
        width: "100%",
        marginBottom: "24px",
       
        "& .MuiInputLabel-formControl": {
          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "19px",
          letterSpacing: "0.1px",
          color: "#94A3B8",
          display: "flex",
          width: "calc(100% - 46px)",
          "&.MuiFormLabel-filled": {
            width: "auto",
          },
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0",
          color: "#000000",
          opacity: "1",
          fontWeight: "400",
          fontFamily: "Roboto",
          display: "flex",
          alignItems: "center",
          "&:focus": {
            backgroundColor: "transparent",
          },
          "& .status-name": {
            color: "#2B2B2B",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "26px",
            letterSpacing: "0.105px",
          },
          "& .status": {
            width: "12px",
            height: "12px",
            background: "#94A3B8",
            borderRadius: "100%",
            marginLeft: "10px",
            "&.open": {
              background: "#4BA3B7",
            },
            "&.cancelled": {
              background: "#F7E223",
            },
            "&.yellow": {
              background: "#F7E223",
            },
            "&.green": {
              background: "#00BA88",
            },
            "&.orange": {
              background: "#F6A318",
            },
            "&.sky-blue": {
              background: "#12AEF1",
            },

            "&.black": {
              background: "#2B2B2B",
            },
          },
        },
        "& .MuiSvgIcon-root": {
          fontSize: "20px",
          color: "#94A3B8",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e8ecf2",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px",
            },
          },
        },
        "& .MuiFormLabel-root": {
          "&.Mui-focused": {
            width: "auto",
          },
          "& .MuiInputLabel-asterisk": {
            color: "#e86577",
            fontSize: "18px",
            lineHeight: "23px",
            marginLeft: "auto",
            top: "-27px",
            position: "absolute",
            right: "-18px",
          },
          
        },
        '& .MuiFormHelperText-root':{
          color : '#C7263E',
          fontSize: "12px",
          fontFamily: 'Roboto',
          fontWeight: 400,
          letterSpacing: "0.0075em",
        }
      },
      "& .multiselect-autocomplete": {
        marginBottom: "16px",
        "&.MuiAutocomplete-hasPopupIcon": {
          "&.MuiAutocomplete-hasClearIcon": {
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
              paddingRight: "85px",
            }
          }
        },
        "& .MuiOutlinedInput-root": {
          paddingLeft: "16px",
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e8ecf2",
              borderWidth: "1px",
            }
          }
        },
        "& .MuiInputAdornment-root": {
          color: "#94A3B8",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "29px",
          height: "auto",
        },
        "& .start-wrapper": {
          display: "flex",
          "& .MuiTypography-body1":{
            fontSize: "14px",
            fontFamily: "Roboto",
            fontWeight: 400,
            lineHeight: 1.5,
            letterSpacing: "0.00938em",
            color: "#94A3B8",

          },
          "& .label-requuired":{
            top: "-13px",
            color: "#e86577",
            right: "12px",
            position: "absolute",
            fontSize: "18px",
            lineHeight: "23px",
          },
        },
        "& .end-icon": {
          position: "absolute",
          top: "calc(50% - 12px)",
          right: "60px",
          marginLeft: "0",
        },
        "& .MuiChip-root": {
          backgroundColor: "#ECF1F4",
          borderRadius: "6px",
          padding: "6px 8px",
          height: "auto",
          color: "#4F4F4F",
          fontFamily: "Roboto",
          fontSize: "13px",
          fontWeight: 400,
          lineHeight: "17px",
          margin: "0 5px 5px 0",
          "& .MuiChip-label": {
            paddingLeft: "0",
          },
          "& .MuiChip-deleteIcon": {
            width: "17px",
            height: "17px",
            margin: "0",
          }
        },
        "& .MuiAutocomplete-endAdornment": {
          "& .MuiIconButton-root": {
            color: "#94A3B8",
          }
        },
      },
      "& .inventory-wrapper": {
        marginBottom: "12px",
        "& .links-wrapper": {
          display: "flex",
          flexWrap: "wrap",
        },
        "& .inventory-link": {
          padding: "5px 12px",
          borderRadius: "15px",
          border: "1px solid #E8ECF2",
          color: "rgba(43, 43, 43, 0.87)",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0.09px",
          textDecoration: "none",
          marginRight: "12px",
          marginBottom: "12px",
        },
        "& .items-list-wrapper": {
          "& .items-list": {
            padding: "24px",
            marginBottom: "16px",
            borderRadius: "6px",
            border: "1px solid #E8ECF2",
            "& .items-title": {
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "5px",
            },
            "& .title-icon": {
              "& .MuiSvgIcon-root": {
                color: "#94A3B8",
                "&.close-icon": {
                  fontSize: "20px",
                }
              }
            },
            "& .primary-txt": {
              "& .primary-txt-link":{
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "19px",
              textDecoration: "none",
              },
            },
            "& .sub-txt": {
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "0.09px",
              "& span": {
                color: "#2B2B2B",
              },
            },
          }
        }
      },
      "& .createtask-bottom": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "14px 40px 40px",
        [theme.breakpoints.down("xs")]: {
            padding: "14px 24px 55px",
        },
        "& .full-btn": {
          width: "100%",
          "& .MuiButton-root": {
            width: "100%",
          },
        },
        "& .primary-btn": {
          minWidth: "165px",
        },
      },
    },
    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "6px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#f2f8fc",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      maxWidth: "100%",
      minWidth: "170px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
    },
    secondaryButton: {
      "&.blue-border":{
        border: "1px solid #4BA3B7",
        color: "#4BA3B7",
        "&:hover": {
          background: "transparent",
          color: "#4BA3B7",
        },
      },
      background: "transparent",
      border: "1px solid #e8ecf2",
      borderRadius: "7px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2b2b2b",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      textTransform: "unset",
      maxWidth: "100%",
      minWidth: "198px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textDecoration: "none",
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b",
      },
    },
    fileUploadWrapper: {
      border: "2px dashed #e5e5e5",
      padding: "20px 24px",
      borderRadius: "12px",
      textAlign: "center",
      transition: "all 0.5s ease-in-out",
      cursor: "pointer",
      "&:hover": {
        // filter: "drop-shadow(0px 16px 10px rgba(0,0,0,0.05))",
        // backgroundColor: "#fafcfe",
        // borderColor: "transparent",
      },
      "& .upload-icon": {
        marginBottom: "16px",
      },
      "& .secondary-btn": {
        color: "#4BA3B7",
        borderRadius: "6px",
        borderColor: "#4BA3B7",
        minWidth: "130px",
        minHeight: "36px",
        padding: "8px 4px",
      },
      "& .file-heading": {
        fontSize: "16px",
        lineHeight: "21px",
        margin: "0 0 7px",
        color: "rgba(0,0,0,0.87)",
        letterSpacing: "0.15px",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
      },
      "& .file-subtxt": {
        fontSize: "12px",
        lineHeight: "16px",
        color: "#2B2B2B",
        letterSpacing: "0.09px",
        fontFamily: "Roboto",
        fontWeight: 400,
        marginBottom: "16px",
        "& .browse-link": {
          color: "#4ba3b7",
          cursor: "pointer",
          margin: "0 5px",
          fontWeight: 700,
        },
      },
    },
    fileDropDrag: {
      filter: "drop-shadow(0px 16px 10px rgba(0,0,0,0.05))",
      backgroundColor: "#fafcfe",
      borderColor: "transparent",
    },
    autocompleteDropbox: {
      padding: "0",
      "& .MuiAutocomplete-paper": {
        borderRadius: "12px",
        background: "#ffffff",
        filter: "none",
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
      },
      "& .MuiAutocomplete-listbox": {
        padding: "0",
        "& .MuiAutocomplete-option": {
          padding: "0",
          background: "transparent",
        }
      },
      "& .tasklist-li": {
        display: "flex",
        justifyContent: "space-between",
        background: "transparent",
        position: "relative",
        borderBottom: "1px solid #ECF1F4",
        padding: "18px 24px",
        width: "100%",
        "& .tasklist-left": {
          display: "flex",
          alignItems: "center",
          "& .assignee-info": {
            marginLeft: "12px",
          },
          "& .assignee-profile": {
            width: "38px",
            height: "38px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ECF1F4",
            borderRadius: "100%",
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Roboto",
            fontSize: "15.485px",
            fontWeight: 600,
            lineHeight: "21.679px",
            letterSpacing: "-0.395px",
          },
          "& .assignee-name": {
            fontSize: "14px",
            lineHeight: "19px",
            color: "#000000",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
            textTransform: "capitalize"
          },
          "& .assignee-email": {
            fontSize: "12px",
            lineHeight: "16px",
            color: "#94A3B8",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
          },
        },
        "& .tasklist-icon": {
          minWidth: "unset",
        },
      },
    },
    drawerWrapper: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
      },
      drawerRootWrapper: {
        "& .MuiBackdrop-root": {
          [theme.breakpoints.down("xs")]: {
            background: "rgba(34, 89, 128, 0.70)",
          },
        },
        "& .drawer-inner": {
          [theme.breakpoints.down("xs")]: {
            padding: "56px 24px",
          },
        },
      },
      drawer: {
        minWidth: "367px",
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",        
        position: "sticky",
        top: "88px",  
        maxHeight: "calc(100vh - 88px)",      
        [theme.breakpoints.down("md")]: {
            minWidth: "320px",
        },
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
      },
      drawerPaper: {
        width: "100%",
        position: "static",
        borderRight: "0",
        padding: "18px 40px 18px 28px",
        maxHeight: "100%",
        overflowY: "auto",
        [theme.breakpoints.down("sm")]: {
            padding: "18px 28px",
        },
        [theme.breakpoints.down("xs")]: {
          width: "80%",
          position: "fixed",
        },
      },
      drawerContent: {
        width: "calc(100% - 367px)",
        [theme.breakpoints.down("md")]: {
            width: "calc(100% - 320px)",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
      },
      viewEmailsWrapper: {
        display: "flex",
        flexDirection: "column",
        background:"#fff",
        overflow: 'auto',
        width:"100%",
        height: "100%",
  
        "& .product-top-header": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: "84px",
          padding: "12px 24px",
          borderBottom: "1px solid #E8ECF2",
          cursor: "pointer",
          "& .back-text":{
            padding: "16px",
          },
  
          "& .top-left": {},
          "& .top-right": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
  
            "& .calendar-icon": {
              height: "24x",
              width: "24px",
              marginRight: "12px",
              
            },
            "& .selected-txt": {
              color: "var(--task-completed-color, #2B2B2B)",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
              whiteSpace: "nowrap",
              cursor:"pointer",
            },
            "& .add-to-contact-btn": {
              textTransform: "none",
              borderRadius: "6px",
              background: "var(--brand-color-disable, #92C7D3)",
              width: "147px",
              height: "48px",
              padding: "8px 14px",
              color: "#ffffff",
              marginLeft: "32px",
            },
            "& .add-to-contact-btn-active": {
              textTransform: "none",
              borderRadius: "6px",
              background: "var(--brand-color-disable, #4BA3B7)",
              width: "147px",
              height: "48px",
              padding: "8px 14px",
              color: "#ffffff",
              marginLeft: "32px",
            },
          },
        },
        "& .main-wrapper": {
          padding:"0 90px",
          [theme.breakpoints.down("xs")]: {
            padding:"0 30px",
          },
          // chatto project
          "&.chatto-project-wrapper":{
            padding:"40px 25px",
            maxWidth: "1700px",
            minWidth:"1700px",
            margin: "0 auto",
            [theme.breakpoints.down(1700)]: {
              minWidth:"100%",
            },
            "& .title-wrapper": { 
              display: "flex", 
              justifyContent: "space-between",
              marginBottom:"30px",
              [theme.breakpoints.down(576)]: {
                flexDirection: "column",
              },
              "& .title-left":{
                [theme.breakpoints.down(576)]: {
                  order:"2",
                },
                "& .title-txt":{
                  color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                  fontFamily: "Expletus Sans",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "23px",
                  marginBottom:"24px",
                  [theme.breakpoints.down(576)]: {
                    fontSize: "20px",
                    marginBottom:"22px",
                  },
                },
                "& .avatar-wrapper":{
                  display:"flex",
                  alignItems: "center",
                  "& .profile-wrapper":{
                    flex:"0 0 39px",
                    maxWidth:"39px",
                    "& .profile-name":{
                      width: "39px",
                      height: "39px",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 700,
                      marginRight: "12px",
                      borderRadius: "24px",
                      textTransform: "uppercase",
                      justifyContent: "center",
                      backgroundColor: "#ECF1F4"
                    },
                  },
                  "& .profile-info":{
                    flex:1,
                    paddingLeft:"7px",
                    "& .info-text":{
                      position:"relative",
                      display:"inline-block",
                      paddingBottom:"20px",
                      marginBottom:"-20px",
                      "& .assignee-bubble":{
                        color: "rgba(43, 43, 43, 0.87)",
                        width: "20px",
                        border: "2px solid #FFF",
                        height: "20px",
                        display: "flex",
                        position: "relative",
                        fontSize: "9.586px",
                        background: "#ECF1F4",
                        alignItems: "center",
                        fontFamily: "Roboto",
                        fontWeight: 600,
                        lineHeight: "13.42px",
                        marginLeft: "4px",
                        borderRadius: "100%",
                        letterSpacing: "-0.244px",
                        justifyContent: "center", 
                      },
                      "& .expand-more":{
                        "& .icon-wrapper": {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          "& .expand-more-icon": {
                            display: "block", /* Show by default */
                          },
                          "& .expand-less-icon": {
                            display: "none", /* Hidden by default */
                          },
                          "&:hover":{
                            "& .expand-more-icon": {
                              display: "none", /* Show by default */
                            },
                            "& .expand-less-icon": {
                              display: "block", /* Hidden by default */
                            },
                          },
                        },
                        "&:hover":{
                          "& .text-sub-detail":{
                            opacity: 1,
                            visibility: "visible",
                          },
                        },
                      },
                      
                      "& * ":{
                        boxSizing:"border-box",
                      },
                      "&:hover":{
                        "& .text-detail":{
                          opacity: 1,
                          visibility: "visible",
                        },
                        
                      },
                      "& .text-detail":{
                        top: "calc(100% - 20px)",
                        left: "calc(100% - 31px)",
                        position: "absolute",
                        boxShadow: "3px 1px 29px -2px rgba(0, 0, 0, 0.3)",
                        borderRadius: "15px",
                        backgroundColor: "#fff",
                        padding: "15px",
                        minWidth:"415px",
                        transition: "all 0.5s",
                        opacity: 0,
                        visibility: "hidden",
                        [theme.breakpoints.down(575)]: {
                          left:"auto",
                        },
                        [theme.breakpoints.down(360)]: {
                          minWidth:"100%",
                        },
                        "& ul":{
                          padding:"0",
                          "& li":{
                            padding:"0",
                            margin:"0 0 10px",
                            "&:last-child":{
                              margin:"0",
                            },
                            "& .title":{
                              flex: "0 0 55px", 
                              maxWidth: "105px !important",
                              color: "#94A3B8",
                              fontFamily: "Roboto",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "1.28",
                              marginRight:"15px",
                            },
                            "& .desc":{
                              flex:"1",
                              display: "flex",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                              color: "#2B2B2B",
                              fontFamily: "Roboto",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "1.28",
                              "& span":{
                                color:"#94A3B8",
                                display:"inline-block",
                                marginLeft:"5px",
                              }
                            },
                          }
                        }
                      },
                      "& .text-sub-detail":{
                        top: "35px",
                        left: "35px",
                        position: "absolute",
                        boxShadow: "3px 1px 29px -2px rgba(0, 0, 0, 0.3)",
                        borderRadius: "15px",
                        backgroundColor: "#fff",
                        padding: "15px",
                        minWidth:"360px",
                        transition: "all 0.5s",
                        opacity: 0,
                        visibility: "hidden",
                        zIndex: "999",
                        [theme.breakpoints.down(575)]: {
                          left:"auto",
                        },
                        [theme.breakpoints.down(360)]: {
                          minWidth:"100%",
                        },
                        "& ul":{
                          padding:"0",
                          "& li":{
                            padding:"0",
                            margin:"0 0 10px",
                            "&:last-child":{
                              margin:"0",
                            },
                            "& .title":{
                              flex: "0 0 55px", 
                              maxWidth: "55px",
                              color: "#94A3B8",
                              fontFamily: "Roboto",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "1.28",
                            },
                            "& .desc":{
                              flex:"1",
                              display: "flex",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                              color: "#2B2B2B",
                              fontFamily: "Roboto",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "1.28",
                              "& span":{
                                color:"#94A3B8",
                                display:"inline-block",
                                marginLeft:"5px",
                              }
                            },
                          }
                        }
                      }
                      
                      
                    },
                    "& p":{
                      color:"#94A3B8",
                      fontFamily: "Expletus Sans",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "16px",
                      "& span":{
                        fontSize: "14px",
                        lineHeight: "18px",
                        color:"#000",
                        marginRight:"3px"
                      }
                    }
                  }
                }
              },
              "& .title-right":{
                [theme.breakpoints.down(576)]: {
                  order:1,
                  textAlign:"right",
                  marginBottom:"20px",
                },
              }
            },
            "& .main-content-wrapper":{
              "& p":{
                color: "#2B2B2B",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "19px",
                "& + p":{
                  marginTop:"15px",
                }
              },
             

              "& .attachments-grid-wrapper":{
                marginTop:"30px",
                "& .attachments-title-wrapper":{
                  display: "flex", 
                  justifyContent: "space-between",
                  marginBottom:"30px",
                  [theme.breakpoints.down(576)]: {
                    flexDirection: "column",
                  },
                },
                "& .attachments-list": {            
                  "& .list-secondary": {
                    right: "0",
                    "& .MuiIconButton-root": {
                      margin: "0",
                      padding: "0",
                      color: "#828282",
                      "& .MuiSvgIcon-root": {
                        fontSize: "20px",
                      },
                    },
                  },            
                  "& .list-item": {
                    paddingLeft: "0",
                    paddingBottom: "16px",
                    paddingTop: "16px",
                    
                    "& .list-item-inner":{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                    },
                    "& .list-img": {
                      marginRight: "8px",
                      width: "28px",
                    },
                    "& .list-name": {
                      fontFamily: "Expletus Sans",
                      lineHeight: "16px",
                      fontSize: "12px",
                      fontWeight: 500,
                      color: "#2B2B2B",
                    },
                  },
                 
                },
              },

              "& .product-summary-grid-wrapper":{
                marginTop:"30px",
                "& .product-summary-title-wrapper":{
                  display: "flex", 
                  justifyContent: "space-between",
                  marginBottom:"30px",
                  [theme.breakpoints.down(576)]: {
                    flexDirection: "column",
                  },
                  "& p":{
                    color: "#2B2B2B",
                    fontFamily: "Expletus Sans",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "24px",
                    [theme.breakpoints.down(576)]: {
                      order:2
                    },
                  },
                  "& .title-right":{
                    [theme.breakpoints.down(576)]: {
                      order:1,
                      textAlign:"right",
                      marginBottom:"15px",
                    },
                    "& p":{
                      color:"#94A3B8",
                      "& span":{
                        color:"#2B2B2B",
                      }
                    }
                  }
                }
              }
            },
            // "& .info-text":{
            //   position:"relative",
            //   display:"inline-block",
            //   "& .text-detail":{
            //     top: "100%",
            //     left: "calc(100% - 31px)",
            //     position: "absolute",
            //     boxShadow: "3px 1px 29px -2px rgba(0, 0, 0, 0.3)",
            //     borderRadius: "15px",
            //     backgroundColor: "#fff",
            //     padding: "15px",
            //     "& ul":{
            //       padding:"0",
            //       "& li":{
            //         padding:"0",
            //         margin:"0 0 10px",
            //         "&:last-child":{
            //           margin:"0",
            //         },
            //         "& .title":{
            //           flex: "0 0 55px", 
            //           maxWidth: "55px",
            //           color: "#94A3B8",
            //           fontFamily: "Roboto",
            //           fontSize: "12px",
            //           fontStyle: "normal",
            //           fontWeight: "400",
            //           lineHeight: "32px",
            //         },
            //         "& .desc":{
            //           display: "flex",
            //           alignItems: "center",
            //           whiteSpace: "nowrap",
            //           color: "#2B2B2B",
            //           fontFamily: "Roboto",
            //           fontSize: "12px",
            //           fontStyle: "normal",
            //           fontWeight: "400",
            //           lineHeight: "32px",
            //           "& span":{
            //             color:"#94A3B8",
            //           }
            //         },
            //       }
            //     }
            //   }
            // },
          },
          // "& .top-main-wrapper": {
          //   display: "flex",
          //   flexDirection: "column",
          //   "& .title-section": {
          //     display: "flex",
          //     flexDirection: "column",
          //     padding: "67px 0px 0",
              
          //     "& .choose-item-sub-txt": {
          //       color: "#505050",
          //       fontFamily: "Roboto",
          //       fontSize: "14px",
          //       fontStyle: "normal",
          //       fontWeight: "400",
          //       lineHeight: "24px",
          //       letterSpacing: "0.25px",
          //       marginTop: "16px",
          //     },
          //   },
          //   "& .filter-section": {
          //     display: "flex",
          //     flexDirection: "row",
          //     alignItems: "center",
          //     marginTop: "16px",
          //     borderBottom: "1px solid #E8ECF2",
          //     "& .left-box": {
          //       display: "flex",
          //       flexDirection: "row",
          //       alignItems: "center",
          //       justifyContent: "space-between",
          //       // paddingLeft: "184px",
          //       paddingRight: "20px",
          //       // width: "580px",
          //       // borderRight: "1px solid #E8ECF2",
  
          //       "& .filter-txt": {
          //         color: "#505050",
          //         fontFamily: "Expletus Sans",
          //         fontSize: "20px",
          //         fontStyle: "normal",
          //         fontWeight: "500",
          //         lineHeight: "normal",
          //         letterSpacing: "0.25px",
          //       },
          //       "& .clear-all-btn": {
          //         color: "var(--brand-color-primary-100, #4BA3B7)",
          //         textAlign: "right",
          //         fontFamily: "Expletus Sans",
          //         fontSize: "14px",
          //         fontStyle: "normal",
          //         fontWeight: "500",
          //         lineHeight: "20px",
          //         letterSpacing: "0.1px",
          //         textTransform: "none",
          //         whiteSpace: "nowrap",
          //       },
          //     },
          //     "& .right-box": {
          //       display: "flex",
          //       flexDirection: "row",
          //       alignItems: "center",
          //       justifyContent: "space-between",
          //       // width: "calc(100vw - 580px)",
          //       paddingRight: "12px",
  
          //       "& .check-box": {
          //         display: "flex",
          //         flexDirection: "row",
          //         alignItems: "center",
  
          //         "& .chk-select-all": {
          //           color: "#94A3B8",
          //           width: "24px",
          //           height: "24px",
          //           marginRight: "6px",
          //         },
          //         "& .selected-txt": {
          //           color: "rgba(0, 0, 0, 0.60)",
          //           fontFamily: "Expletus Sans",
          //           fontSize: "14px",
          //           fontStyle: "normal",
          //           fontWeight: "500",
          //           lineHeight: "24px",
          //           letterSpacing: "0.1px",
          //           whiteSpace: "nowrap",
          //         },
          //       },
          //       "& .search-sort-box": {
          //         display: "flex",
          //         flexDirection: "row",
          //         alignItems: "center",
          //         "& .form-control": {
          //           marginBottom: 0,
          //           "& .MuiTextField-root": {
          //             "& fieldset": {
          //               border: "none",
          //             },
          //             height: "48px",
          //             marginRight: "68px",
          //           },
          //         },
          //         "& .top-right": {
          //           "& .select-control": {                    
          //             "& .MuiInput-underline:before": {
          //               display: "none",
          //             },
          //             "& .MuiInput-underline:after": {
          //               display: "none",
          //             },
          //             "& .MuiInputBase-root": {
          //               color:"rgb(109 106 106 / 87%)",
          //               fontFamily: "Expletus Sans",
          //               fontSize: "14px",
          //               fontStyle: "normal",
          //               fontWeight: "500",
          //               lineHeight: "24px",
          //               "& fieldset": {
          //                 border: "none",
          //               },
          //             },
          //           },
          //         },
          //       },
          //       "& .select-control": {
          //         "&.multi-select": {
          //           "& .MuiInput-formControl": {
          //             marginTop: "0",
          //           },
          //           "& .multiselect-label": {
          //             display: "none",
          //           },
          //           "& .MuiSelect-select": {
          //             paddingRight: "40px",
          //           },
          //           "& .multiple-clear": {
          //             position: "absolute",
          //             right: "18px",
          //             fontSize: "15px",
          //             zIndex: 1,
          //             top: "3px",
          //             color: "#94A3B8",
          //           },
          //         },
          //         "& .MuiSelect-select": {
          //           letterSpacing: "0.1px",
          //           fontSize: "14px",
          //           color: "#2b2b2b",
          //           opacity: "0.671",
          //           fontFamily: "Expletus Sans",
          //           paddingBottom: "0",
          //           fontWeight: "500",
          //           paddingTop: "0",
          //           paddingRight: "2px",
          //           "&:focus": {
          //             backgroundColor: "transparent",
          //           },
          //           "& .clear-icon": {
          //             height: "50px",
          //             background: "red",
          //             width: "50px",
          //           },
          //           "& .MuiBox-root": {
          //             alignItems: "center",
          //             display: "flex",
          //             justifyContent: "space-between",
          //             flexDirection: "row",
          //             "& .select-icon": {
          //               marginRight: "6px",
          //             },
          //           },
          //         },
          //         "& .MuiInput-underline:after": {
          //           display: "none",
          //         },
          //         "& .MuiInput-underline:before": {
          //           display: "none",
          //         },
          //       },
          //     },
          //   },
          // },
          // "& .main-content-wrapper": {
          //   display: "flex",
          //   flexDirection: "row",
          //   "& .left-wrapper": {
          //     display: "flex",
          //     flexDirection: "column",
          //     // width: "580px",
          //     // height: "50vh",           
  
          //     "& .section-header": {
          //       display: "block",
          //       alignItems: "center",
          //       flexDirection: "row",
          //       justifyContent: "space-between",
          //       borderBottom: "1px solid #E8ECF2",
          //       marginBottom:"30px",
          //         "& .select-control": {
          //           "& .MuiSelect-select": {
          //             paddingRight: "0",
          //             "& .down-arrow": {
          //               color: "rgba(43, 43, 43, 0.67)",
          //             },
          //           },
          //         },
          //         "& .MuiAccordion-root": {
          //           borderRadius: "0",
          //           boxShadow: "none",
          //           // margin: "0 0 10px",
          //           "&:before": {
          //             display: "none",
          //           },
          //           "& .MuiAccordionSummary-root": {
          //             marginBottom: "28px",
          //             padding: "0",
          //             minHeight: "unset",
          //             [theme.breakpoints.down("sm")]: {
          //               // marginBottom: "16px",
          //             },
          //             "& .MuiAccordionSummary-content": {
          //               margin: 0,
          //             },
          //             "& .MuiIconButton-root": {
          //               marginRight: "0",
          //               padding: "0",
          //               "& .MuiSvgIcon-root": {
          //                 width: "20px",
          //                 height: "20px",
          //                 fontSize: "20px",
          //                 color: "#94A3B8",
          //               },
          //             },
          //           },
          //           "& .MuiAccordionDetails-root": {
          //             flexDirection: "column",
          //             padding: "0",
          //             display: "flex",
          //           },
          //           "& .form-control":{
          //             margin: "-16px 0px 8px 0",
          //           }
          //         },               
          //       "& .section-title": {
          //         color: "var(--gray, rgba(43, 43, 43, 0.87))",
          //         fontFamily: "Expletus Sans",
          //         fontSize: "14px",
          //         fontStyle: "normal",
          //         fontWeight: "500",
          //         lineHeight: "20px",
          //         letterSpacing: "0.1px",
          //       },
          //       "& .hide-content-section-btn": {
          //         margin: 0,
          //         minWidth: 24,
          //       },
          //     },
          //     "& .form-control": {
          //       "& .MuiTextField-root": {
          //         "& fieldset": { border: "none" },
          //         borderBottom: "1px solid #E8ECF2",
          //         height: "48px",
          //       },
          //     },
          //     "& .wrap-items": {
          //       "& .product-item": {
          //         display: "flex",
          //         flexDirection: "row",
          //         alignItems: "center",
          //         // marginBottom: "8px",
  
          //         "& .chk-item": {
          //           color: "#94A3B8",
          //           marginRight: "8px",
          //         },
          //         "& .item-txt": {
          //           color: " #2B2B2B",
          //           fontFamily: "Roboto",
          //           fontSize: "14px",
          //           fontStyle: "normal",
          //           fontWeight: "400",
          //           lineHeight: "24px" /* 171.429% */,
          //           letterSpacing: "0.25px",
          //         },
          //       },
          //     },
          //     "& .wrap-expand": {
          //       paddingLeft: "18px",
          //       "& .expand-list-btn": {
          //         textTransform: "none",
          //         color: "var(--brand-color-primary-100, #4BA3B7)",
          //         fontFamily: "Roboto",
          //         fontSize: "14px",
          //         fontStyle: "normal",
          //         fontWeight: "400",
          //         lineHeight: "normal",
          //         letterSpacing: "0.25px",
          //       },
          //     },
          //   },
          //   "& .right-wrapper": {
          //     display: "flex",
          //     flexDirection: "row",
          //     columns: 3,
          //     width: "calc(100vw - 580px)",
          //     height: "50vh",
          //     flex: 1,
          //     padding: "10px 0px 0px 0px",
          //     "& .grid-container":{            
          //     "& .left-side-info":{
          //       paddingTop:"35px",
          //       borderRight: "1px solid #E8ECF2",
          //     },
          //     "& .right-side-info":{
          //       paddingLeft:"35px",
          //       paddingTop:"40px",
          //     }
          //     },
  
          //     "& .product-list-item": {
          //       display: "flex",
          //       flexDirection: "column",
          //       flex: 1,
          //       border: "1px solid #E8ECF2",
          //       borderRadius: "12px",
          //       padding: "16px",
          //       minHeight: "203px",
  
          //       "& .item-top": {
          //         display: "flex",
          //         flexDirection: "row",
          //         justifyContent: "space-between",
          //         alignItems: "flex-start",
          //         "& .check-section":{
          //           flexDirection: "row",
          //           display: "flex",
          //           alignItems: "center",
          //           justifyContent: "center",
          //           "& .instock-quantity":{
          //             color: 'var(--Gray, rgba(43, 43, 43, 0.87))',
          //             fontFamily: 'Roboto',
          //             fontSize: '12px',
          //             fontStyle: 'normal',
          //             fontWeight: 400,
          //             lineHeight: '16px',
          //             letterSpacing: '0.09px',
          //             background: '#F2F8FC',
          //             borderRadius: '6px',
          //             padding: '2px 8px',
          //             whiteSpace: "nowrap",
          //           },
          //         },
          //         "& .left-top": {
          //           display: "flex",
          //           flexDirection: "column",
                    
          //           "& .product-img": {
          //             height: "68px",
          //             width: "68px",
          //             marginBottom: "25px",
          //           },
                
          //           "& .wrap-under-img": {
          //             display: "flex",
          //             flexDirection: "row",
          //             alignItems: "center",
          //             "& .dot-img": {
          //               width: "6px",
          //               height: "6px",
          //               margin: "0 6px",
          //             },
          //             "& .star-icon": {
          //               width: "12px",
          //               height: "11.4px",
          //               marginLeft: "6px",
          //             },
          //           },
          //         },
  
          //         "& .right-top-chk": {
          //           color: "#94A3B8",
          //         },
          //       },
          //       "& .item-bottom-info": {
          //         marginTop: "25px",
          //         display: "flex",
          //         flexDirection: "row",
          //         justifyContent: "space-between",
  
          //         "& .wrap-name": {
          //           flexDirection: "column",
          //           display: "flex",
          //           "& .dot-section":{
          //            display:"flex",
          //            "& .product-info":{
          //              fontFamily: "Roboto",
          //              color: "rgba(0, 0, 0, 0.38)",
          //             fontSize: "12px",
          //             fontWeight: "400",
          //             fontStyle: "normal",
          //             letterSpacing: "0.4px",
          //             lineHeight: "normal",
          //            },
          //            "& .product-dot-info":{
          //             color: "rgba(0, 0, 0, 0.38)",
          //             fontSize: "12px",
          //             fontFamily: "Roboto",
          //             fontStyle: "normal",
          //             fontWeight: "400",
          //             lineHeight: "normal",
          //             letterSpacing: "0.4px",
          //             marginLeft:"6px",
          //             "&:before": {
          //               content: '""',
          //               width: "8px",
          //               borderRadius: "100%",
          //               height: "8px",
          //               display: "inline-block",
          //               background: "#ECF1F4",
          //               marginRight: "8px",
          //             },
          //            }
          //           },
          //           "& .product-name-txt": {
          //             fontFamily: "Roboto",
          //             color: "var(--gray, rgba(43, 43, 43, 0.87))",
          //             fontSize: "16px",
          //             fontStyle: "normal",
          //             fontWeight: "400",
          //             lineHeight: "24px",
          //             letterSpacing: "0.15px",
          //             margin:"6px 0"
          //           },
          //           "& .product-additional-info": {
          //             color: "rgba(0, 0, 0, 0.38)",
          //             fontFamily: "Roboto",
          //             fontSize: "12px",
          //             fontStyle: "normal",
          //             fontWeight: "400",
          //             lineHeight: "normal",
          //             letterSpacing: "0.4px",
          //           },
          //         },
          //         "& .wrap-price": {
          //           display: "flex",
          //           flexDirection: "column",
          //           alignItems: "flex-end",
          //           "& .price-btn": {
          //             display: "flex",
          //             flexDirection: "row",
          //             alignItems: "center",
          //             justifyContent: "flex-end",
          //             textTransform: "none",
          //             marginTop:"20px",
          //             "& .retail-price-txt": {
          //               color: "rgba(0, 0, 0, 0.38)",
          //               fontFamily: "Roboto",
          //               fontSize: "12px",
          //               fontStyle: "normal",
          //               fontWeight: "400",
          //               lineHeight: "normal",
          //               letterSpacing: "0.4px",
          //               whiteSpace:"nowrap",
          //             },
          //             "& .icon-down": {
          //               width: "24px",
          //               height: "24px",
          //               color: "#94A3B8",
          //             },
          //           },
          //           "& .price-txt": {
          //             color: "var(--gray, rgba(43, 43, 43, 0.87))",
          //             fontFamily: "Roboto",
          //             fontSize: "16px",
          //             fontStyle: "normal",
          //             fontWeight: "400",
          //             lineHeight: "24px" /* 150% */,
          //             letterSpacing: "0.15px,",
          //             marginRight:"8px",
          //           },
          //         },
          //       },
          //     },
          //   },
          // },
        },
      },
      productsWrapper: {
        display: "flex",
        flexDirection: "column",
        background:"#fff",
        overflow: 'auto',
        width:"100%",
        height: "100%",
  
        "& .product-top-header": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          height: "84px",
          padding: "12px 24px",
          borderBottom: "1px solid #E8ECF2",
  
          "& .top-left": {},
          "& .top-right": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
  
            "& .calendar-icon": {
              height: "24x",
              width: "24px",
              marginRight: "12px",
              
            },
            "& .selected-txt": {
              color: "var(--task-completed-color, #2B2B2B)",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
              whiteSpace: "nowrap",
              cursor:"pointer",
            },
            "& .add-to-contact-btn": {
              textTransform: "none",
              borderRadius: "6px",
              background: "var(--brand-color-disable, #92C7D3)",
              width: "147px",
              height: "48px",
              padding: "8px 14px",
              color: "#ffffff",
              marginLeft: "32px",
            },
            "& .add-to-contact-btn-active": {
              textTransform: "none",
              borderRadius: "6px",
              background: "var(--brand-color-disable, #4BA3B7)",
              width: "147px",
              height: "48px",
              padding: "8px 14px",
              color: "#ffffff",
              marginLeft: "32px",
            },
          },
        },
        "& .main-wrapper": {
          padding:"0 90px",
          [theme.breakpoints.down("xs")]: {
            padding:"0 30px",
          },
          "& .top-main-wrapper": {
            display: "flex",
            flexDirection: "column",
            "& .title-section": {
              display: "flex",
              flexDirection: "column",
              padding: "67px 0px 0",
              "& .choose-item-txt": {
                color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontSize: "22px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "26px",
              },
              "& .choose-item-sub-txt": {
                color: "#505050",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.25px",
                marginTop: "16px",
              },
            },
            "& .filter-section": {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "16px",
              borderBottom: "1px solid #E8ECF2",
              "& .left-box": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                // paddingLeft: "184px",
                paddingRight: "20px",
                // width: "580px",
                // borderRight: "1px solid #E8ECF2",
  
                "& .filter-txt": {
                  color: "#505050",
                  fontFamily: "Expletus Sans",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                  letterSpacing: "0.25px",
                },
                "& .clear-all-btn": {
                  color: "var(--brand-color-primary-100, #4BA3B7)",
                  textAlign: "right",
                  fontFamily: "Expletus Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                  textTransform: "none",
                  whiteSpace: "nowrap",
                },
              },
              "& .right-box": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                // width: "calc(100vw - 580px)",
                paddingRight: "12px",
  
                "& .check-box": {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
  
                  "& .chk-select-all": {
                    color: "#94A3B8",
                    width: "24px",
                    height: "24px",
                    marginRight: "6px",
                  },
                  "& .selected-txt": {
                    color: "rgba(0, 0, 0, 0.60)",
                    fontFamily: "Expletus Sans",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "24px",
                    letterSpacing: "0.1px",
                    whiteSpace: "nowrap",
                  },
                },
                "& .search-sort-box": {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  "& .form-control": {
                    marginBottom: 0,
                    "& .MuiTextField-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      height: "48px",
                      marginRight: "68px",
                    },
                  },
                  "& .top-right": {
                    "& .select-control": {                    
                      "& .MuiInput-underline:before": {
                        display: "none",
                      },
                      "& .MuiInput-underline:after": {
                        display: "none",
                      },
                      "& .MuiInputBase-root": {
                        color:"rgb(109 106 106 / 87%)",
                        fontFamily: "Expletus Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "24px",
                        "& fieldset": {
                          border: "none",
                        },
                      },
                    },
                  },
                },
                "& .select-control": {
                  "&.multi-select": {
                    "& .MuiInput-formControl": {
                      marginTop: "0",
                    },
                    "& .multiselect-label": {
                      display: "none",
                    },
                    "& .MuiSelect-select": {
                      paddingRight: "40px",
                    },
                    "& .multiple-clear": {
                      position: "absolute",
                      right: "18px",
                      fontSize: "15px",
                      zIndex: 1,
                      top: "3px",
                      color: "#94A3B8",
                    },
                  },
                  "& .MuiSelect-select": {
                    letterSpacing: "0.1px",
                    fontSize: "14px",
                    color: "#2b2b2b",
                    opacity: "0.671",
                    fontFamily: "Expletus Sans",
                    paddingBottom: "0",
                    fontWeight: "500",
                    paddingTop: "0",
                    paddingRight: "2px",
                    "&:focus": {
                      backgroundColor: "transparent",
                    },
                    "& .clear-icon": {
                      height: "50px",
                      background: "red",
                      width: "50px",
                    },
                    "& .MuiBox-root": {
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      "& .select-icon": {
                        marginRight: "6px",
                      },
                    },
                  },
                  "& .MuiInput-underline:after": {
                    display: "none",
                  },
                  "& .MuiInput-underline:before": {
                    display: "none",
                  },
                },
              },
            },
          },
          "& .main-content-wrapper": {
            display: "flex",
            flexDirection: "row",
            "& .left-wrapper": {
              display: "flex",
              flexDirection: "column",
              // width: "580px",
              // height: "50vh",           
  
              "& .section-header": {
                display: "block",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                borderBottom: "1px solid #E8ECF2",
                marginBottom:"30px",
                  "& .select-control": {
                    "& .MuiSelect-select": {
                      paddingRight: "0",
                      "& .down-arrow": {
                        color: "rgba(43, 43, 43, 0.67)",
                      },
                    },
                  },
                  "& .MuiAccordion-root": {
                    borderRadius: "0",
                    boxShadow: "none",
                    // margin: "0 0 10px",
                    "&:before": {
                      display: "none",
                    },
                    "& .MuiAccordionSummary-root": {
                      marginBottom: "28px",
                      padding: "0",
                      minHeight: "unset",
                      [theme.breakpoints.down("sm")]: {
                        // marginBottom: "16px",
                      },
                      "& .MuiAccordionSummary-content": {
                        margin: 0,
                      },
                      "& .MuiIconButton-root": {
                        marginRight: "0",
                        padding: "0",
                        "& .MuiSvgIcon-root": {
                          width: "20px",
                          height: "20px",
                          fontSize: "20px",
                          color: "#94A3B8",
                        },
                      },
                    },
                    "& .MuiAccordionDetails-root": {
                      flexDirection: "column",
                      padding: "0",
                      display: "flex",
                    },
                    "& .form-control":{
                      margin: "-16px 0px 8px 0",
                    }
                  },               
                "& .section-title": {
                  color: "var(--gray, rgba(43, 43, 43, 0.87))",
                  fontFamily: "Expletus Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                },
                "& .hide-content-section-btn": {
                  margin: 0,
                  minWidth: 24,
                },
              },
              "& .form-control": {
                "& .MuiTextField-root": {
                  "& fieldset": { border: "none" },
                  borderBottom: "1px solid #E8ECF2",
                  height: "48px",
                },
              },
              "& .wrap-items": {
                "& .product-item": {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  // marginBottom: "8px",
  
                  "& .chk-item": {
                    color: "#94A3B8",
                    marginRight: "8px",
                  },
                  "& .item-txt": {
                    color: " #2B2B2B",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "24px" /* 171.429% */,
                    letterSpacing: "0.25px",
                  },
                },
              },
              "& .wrap-expand": {
                paddingLeft: "18px",
                "& .expand-list-btn": {
                  textTransform: "none",
                  color: "var(--brand-color-primary-100, #4BA3B7)",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                  letterSpacing: "0.25px",
                },
              },
            },
            "& .right-wrapper": {
              display: "flex",
              flexDirection: "row",
              columns: 3,
              width: "calc(100vw - 580px)",
              height: "50vh",
              flex: 1,
              padding: "10px 0px 0px 0px",
              "& .grid-container":{            
              "& .left-side-info":{
                paddingTop:"35px",
                borderRight: "1px solid #E8ECF2",
              },
              "& .right-side-info":{
                paddingLeft:"35px",
                paddingTop:"40px",
              }
              },
  
              "& .product-list-item": {
                display: "flex",
                flexDirection: "column",
                flex: 1,
                border: "1px solid #E8ECF2",
                borderRadius: "12px",
                padding: "16px",
                minHeight: "203px",
  
                "& .item-top": {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  "& .check-section":{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& .instock-quantity":{
                      color: 'var(--Gray, rgba(43, 43, 43, 0.87))',
                      fontFamily: 'Roboto',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '16px',
                      letterSpacing: '0.09px',
                      background: '#F2F8FC',
                      borderRadius: '6px',
                      padding: '2px 8px',
                      whiteSpace: "nowrap",
                    },
                  },
                  "& .left-top": {
                    display: "flex",
                    flexDirection: "column",
                    
                    "& .product-img": {
                      height: "68px",
                      width: "68px",
                      marginBottom: "25px",
                    },
                
                    "& .wrap-under-img": {
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      "& .dot-img": {
                        width: "6px",
                        height: "6px",
                        margin: "0 6px",
                      },
                      "& .star-icon": {
                        width: "12px",
                        height: "11.4px",
                        marginLeft: "6px",
                      },
                    },
                  },
  
                  "& .right-top-chk": {
                    color: "#94A3B8",
                  },
                },
                "& .item-bottom-info": {
                  marginTop: "25px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
  
                  "& .wrap-name": {
                    flexDirection: "column",
                    display: "flex",
                    "& .dot-section":{
                     display:"flex",
                     "& .product-info":{
                       fontFamily: "Roboto",
                       color: "rgba(0, 0, 0, 0.38)",
                      fontSize: "12px",
                      fontWeight: "400",
                      fontStyle: "normal",
                      letterSpacing: "0.4px",
                      lineHeight: "normal",
                     },
                     "& .product-dot-info":{
                      color: "rgba(0, 0, 0, 0.38)",
                      fontSize: "12px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                      letterSpacing: "0.4px",
                      marginLeft:"6px",
                      "&:before": {
                        content: '""',
                        width: "8px",
                        borderRadius: "100%",
                        height: "8px",
                        display: "inline-block",
                        background: "#ECF1F4",
                        marginRight: "8px",
                      },
                     }
                    },
                    "& .product-name-txt": {
                      fontFamily: "Roboto",
                      color: "var(--gray, rgba(43, 43, 43, 0.87))",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0.15px",
                      margin:"6px 0"
                    },
                    "& .product-additional-info": {
                      color: "rgba(0, 0, 0, 0.38)",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                      letterSpacing: "0.4px",
                    },
                  },
                  "& .wrap-price": {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    "& .price-btn": {
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      textTransform: "none",
                      marginTop:"20px",
                      "& .retail-price-txt": {
                        color: "rgba(0, 0, 0, 0.38)",
                        fontFamily: "Roboto",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        letterSpacing: "0.4px",
                        whiteSpace:"nowrap",
                      },
                      "& .icon-down": {
                        width: "24px",
                        height: "24px",
                        color: "#94A3B8",
                      },
                    },
                    "& .price-txt": {
                      color: "var(--gray, rgba(43, 43, 43, 0.87))",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "24px" /* 150% */,
                      letterSpacing: "0.15px,",
                      marginRight:"8px",
                    },
                  },
                },
              },
            },
          },
        },
      },
      productListViewModal: {
        height: "100vh",
        width: "680px",
        backgroundColor: "#ffffff",
        overflow: "auto",
        position: "relative",
        padding:" 45px 60px",
        marginLeft: "auto",
        "& .modal-heading": {
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "row",
          marginTop: "20px",
          justifyContent: "space-between",
          marginBottom: "48px",        
          "& .title-txt": {
            color: 'rgba(43, 43, 43, 0.87)',
            fontSize: '24px',
            fontFamily: 'Expletus Sans',
            fontStyle: 'normal',
            lineHeight: 'normal',         
            fontWeight: 400,
          },
          "& .heading-left": {
            "& .modal-title": {
              color: "var(--gray, rgba(43, 43, 43, 0.87))",
              fontFamily: "Expletus Sans",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "30px",
            },
            "& .sub-txt": {
              color: "var(--text-secondary, #94A3B8)",
              fontFamily: "Roboto",
              fontSize: "14px",
              lineHeight: "26px",
              fontStyle: "normal",
              letterSpacing: "0.105px",
              fontWeight: "400",
            },
          },
          "& .heading-right": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "& .heading-icon":{
              marginLeft: "12px",
              "& .pencil-icon": {
                width: "24px",
                height: "24px",
                color: "#94A3B8",
              },
              "& .close-icon": {
                width: "24px",
                height: "24px",
                color: "#94A3B8",
              },
            },
          },
        },
        "& .product-card":{
          display:"flex",
          justifyContent:"space-between",
          borderBottom: "1px solid #E8ECF2",
          marginBottom:"24px",
          "& .categoryImg":{
            height: '68px',
            objectFit:"contain",
            width: "100%",
            maxWidth: "68px",
            [theme.breakpoints.down("sm")]:{
              height: '56px',
              maxWidth: "56px",
              margin: "0 auto",
            },
          },
          "& .product-img":{
            width:"56px",
            height:"56px",
           },
           "& .card-content":{
             "& .headding":{
              color: 'var(--Brand-Color-Brand-Color-Dark, #2B2B2B)',
              fontFamily: 'Expletus Sans',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal'
             },
             "& .table-content":{
              "& .table-content-2":{             
                marginBottom:"16px"
               },
               "& .table-content-2 th":{
                color: 'var(--text-Secondary, #94A3B8)',
                fontFamily: 'Roboto',
                fontSize: '10px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                padding:"6px 24px 6px 0",
                textAlign:"start",
                marginBottom:"16px"
               },
               "& .table-content-2 td":{
                color: 'var(--Brand-Color-Brand-Color-Dark, #2B2B2B)',
                textAlign: 'start',
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '16px',
                letterSpacing: '0.09px',
                padding:"0px 24px 0px 0",
                marginBottom:"16px"
               },
             },
             "& .retail-content":{
              display:"flex",
              marginTop:"8px",
              marginBottom:"16px",
              "& .item-quantity":{
                color: 'var(--Gray, rgba(43, 43, 43, 0.87))',
                fontFamily: 'Roboto',
                fontSize: '10px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                marginRight:"4px",
              },
              "& .retail-price-txt":{
                color: 'var(--text-Secondary, #94A3B8)',
                fontFamily: 'Roboto',
                fontSize: '10px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal'
              },
             },
           },
           "& .price-txt":{
            "& .price":{
              color: 'var(--Brand-Color-Brand-Color-Dark, #2B2B2B)',
              fontFamily: 'Expletus Sans',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal'
            },
           },
        }
       
        
      },
      brevoInputWrapper: {
        display: "flex",
        flexDirection: "column",
        background: "#fff",
        overflow: "auto",
        width: "100%",
        height: "100%",
  
        "& .brevo-input-header": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          height: "84px",
          padding: "12px 24px",
          borderBottom: "1px solid #E8ECF2",
  
          "& .top-left": {},
          "& .top-right": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
  
            "& .calendar-icon": {
              height: "24x",
              width: "24px",
              marginRight: "12px",
            },
            "& .selected-txt": {
              color: "var(--task-completed-color, #2B2B2B)",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
              whiteSpace: "nowrap",
            },
            "& .add-to-contact-btn": {
              textTransform: "none",
              borderRadius: "6px",
              background: "var(--brand-color-disable, #92C7D3)",
              width: "147px",
              height: "48px",
              padding: "8px 14px",
              color: "#ffffff",
              marginLeft: "32px",
            },
            "& .add-to-contact-btn-active": {
              textTransform: "none",
              borderRadius: "6px",
              background: "var(--brand-color-disable, #4BA3B7)",
              width: "147px",
              height: "48px",
              padding: "8px 14px",
              color: "#ffffff",
              marginLeft: "32px",
            },
          },
        },
        "& .main-wrapper": {
          padding: "0 90px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          maxWidth: "600px",
          margin: "0 auto",
          [theme.breakpoints.down("xs")]: {
            padding: "0 30px",
          },
          "& .MuiButton-root": {
            borderRadius: "6px",
            background: "var(--brand-color-primary-100, #4BA3B7)",
            color: "#ffffff",
            textTransform: "none",
            fontFamily: "Expletus Sans",
            width: "100%",
            height: "48px",
            margin: "0",
          },
          "& .main-content-wrapper": {
            display: "flex",
            flexDirection: "column",
            "& .brevo-title": {
              color: "#2B2B2B",
              fontSize: "22px",
              fontStyle: "normal",
              linHeight: "normal",
              fontFamily: "Expletus Sans",
              fontWeight: 400,
              letterSpacing: "0.25px",
            },
            "& .brevo-desc": {
              color: "#94A3B8",
              margin: "0",
              fontSize: "14px",
              fontStyle: "normal",
              linHeight: "20px",
              fontFamily: "Roboto",
              fontWeight: 400,
              letterSpacing: "0.25px",
            },
            "& .custom-input-wrapper": {
              margin: "31px 0 16px",
            },
            "& .list-wrapper": {
              marginBottom: "40px",
              "& .brevo-desc": {
                fontSize: "12px",
                marginBottom: '8px',
              }
            },
            "& .MuiList-root": {
              padding: "0 0 8px",
              "& .MuiListItem-root": {
                padding: "0 0 0 6px",
                color: "#94A3B8",
                fontSize: "12px",
                linHeight: "normal",
                fontFamily: "Roboto",
                display: "inline-block",
                "& + .MuiListItem-root": {
                  marginTop: "5px",
                },
                "& a": {
                  color: "#2B2B2B",
                  marginLeft: "5px",
                  transition: "all 0.3s",
                  wordBreak: "break-all",
                  "&:hover": {
                    textDecoration: "none",
                  },
                },
              },
            },
          },
        },
      },
      btnMinimizeForm: {
        gap: "16px",
        right: "230px",
        bottom: "34px",
        height: "56px",
        display: "flex",
        padding: "14px 16px",
        position: "fixed",
        background: "#FFF",
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
        alignItems: "center",
        borderRadius: "8px",
        flexDirection: "row",
        "& .expand-icon": {
          width: " 16px",
          height: "16px",
          cursor: "pointer",
        },
        "& .create-txt": {
          color: "#2B2B2B",
          fontSize: "14px",
          fontStyle: "normal",
          fontFamily: " Expletus Sans",
          fontWeight: 500,
          lineHeight: "normal",
        },
        "& .minimize-close-btn": {
          minWidth: "24px",
        },
        "& .close-icon": {
          width: " 16px",
          height: "16px",
        },
      },
      modalImageDialog: {
        overflow: "auto",
        //backdropFilter: "blur(4px)",
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(57,57,57,0.502)",
          
        },
        "& .image-container": {
          "& .image-wrapper-2": {
            width: "600px",
            margin: "0 auto",
          },
          "& .close-icon": {
            color: "#fff",
            margin: "10px",
            textAlign: "end",
            cursor: "pointer",
          },
        },
      }, // kruti
      carouselWrapper: {
        maxWidth: "100%",
        width: "100%",
        "& .react-transform-component.transform-component-module_content__FBWxo":{
          width:"100%",
          justifyContent:"center",
        },
        "& .react-transform-wrapper.transform-component-module_wrapper__SPB86":{
          width:"100%",
        },
        // 
        [theme.breakpoints.down("sm")]: {
          margin: "0 auto",
        },
        "& .slider-item": {
          // height: "350px",
        },
        "& .slick-next:before": {
          content: "none",
        },
        "& .slick-prev:before": {
          content: "none",
        },
        "& .item-inner":{
          height:"700px",
          width:"599px",
          [theme.breakpoints.down(1440)]: {
            height:"600px",
            width:"457px",
          },
          [theme.breakpoints.down(1366)]: {
            height:"500px",
            width:"381px",
          },
          [theme.breakpoints.down(1199)]: {
            height:"400px",
            width:"305px",
          },
          "&.has-video":{
            "& .image-video-wrapper":{
              "&.is-video-playing":{
                "&::after":{
                  display: "none",
                },
              },
              "&::after":{
                position: "absolute",
                content: '""',
                inset: "0",
                width: "100%",
                height: "100%",
                background: "rgba(0,0,0,0.5)",
                zIndex:"2",
              },
            },
          },
          "& .image-video-wrapper": {
            width: "100%",
            height: "100%",
            overflow: "hidden",
            position: "relative",
            borderRadius: "6px",
            zIndex: 1,
            "& img": {
              marginTop: "0",
              height:"100%",
              width:"100%",
            },
            "& video": {
              marginTop: "0",
              height:"100%",
              width:"100%",
              objectFit:"cover",
              position:"relative"
            },
            "& .play-icon":{
              position: "absolute",
              zIndex: 999,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            },
          },
          "&.has-document":{
            "& .file-icon":{
              height: "100%",
              backgroundColor: "#ECF1F4",
              borderRadius: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position:"relative",
              "& p":{
                position:"absolute",
                left:"50%",
                transform:"translateX(-50%)",
                bottom:"15px",
                fontSize:"14px",
                color:"#212121",
              },
            },
          },
        },
        "& .slick-slide": {
          height: "inherit",
        },
        "& .slick-dots": {
          position: "absolute",
          bottom: "-33px",
        },
        "& .slick-dots li.slick-active button:before": {
          color: "rgba(0, 0, 0, 0.87)",
        },
        "& .slick-dots li button:before": {
          color: "#565454",
          visibility: "hidden",
        },
        "& .slick-dots li": {
          color: "#FFFF",
          margin: "0 2px",
        },
        "& .slick-dots li img": {
          width: "10px",
          height: "5px",
          borderRadius: "2px",
        },
        "& .slick-dots li.slick-active img": {
          width: "28px",
        },
      },
      modalDialog: {
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(57,57,57,0.502)",
        },
      },
      modalDialogBox: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 580,
        backgroundColor: "#ffffff",
        padding: "48px",
        borderRadius: "24px",
        [theme.breakpoints.down("xs")]: {
          padding: "24px",
          maxWidth: "327px",
          width: "90%",
          borderRadius: "12px",
          backgroundColor: "#F2F8FC",
        },
        "& .modal-heading": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "22px",
          [theme.breakpoints.down("xs")]: {
            marginBottom: "23px",
          },
        },
        "& .modal-title": {
          fontSize: "24px",
          lineHeight: "30px",
          color: "#000000",
          letterSpacing: "0",
          fontFamily: "Expletus Sans",
          fontWeight: 400,
          [theme.breakpoints.down("xs")]: {
            fontSize: "18px",
            lineHeight: "24px",
          },
        },
        "& .modal-desc": {
          fontSize: "14px",
          letterSpacing: "0.0075em",
          lineHeight: "26px",
          color: "#2b2b2b",
          fontWeight: 400,
          fontFamily: "Roboto",
          [theme.breakpoints.down("xs")]: {
            fontSize: "13px",
            lineHeight: "17px",
          },
          "& .title-dark": {
            fontWeight: "700",
          },
        },
        "& .close-icon": {
          color: "rgba(43, 43, 43, 0.87)",
          lineHeight: "0",
        },
        "& .modal-footer": {
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "32px",
          [theme.breakpoints.down("xs")]: {
            marginTop: "24px",
          },
          "& .primary-btn, & .secondary-btn ": {
            width: "auto",
            minWidth: "157px",
            [theme.breakpoints.down("xs")]: {
              minWidth: "70px",
              padding: "4px 6px",
            },
          },
          "& .MuiButton-root": {
            marginRight: "24px",
            "&:last-child": {
              marginRight: "0",
            },
          },
        },
        "& .primary-btn": {
          minHeight: "48px",
          fontSize: "14px",
          lineHeight: "19px",
          marginBottom: "0",
          [theme.breakpoints.down("xs")]: {
            minHeight: "30px",
          },
        },
        "& .secondary-btn": {
          minHeight: "48px",
          fontSize: "14px",
          lineHeight: "19px",
          marginBottom: "0",
          [theme.breakpoints.down("xs")]: {
            minHeight: "30px",
            border: "0",
            color: "#4BA3B7",
          },
        },
      },
      popoverStyle: {
        display: "inline-flex",
        height: "auto",
        width: "310px",
        top:"140px",
        flexDirection: "column",
        alignItems: "center",
        flexShrink: 0,
        padding: "10px",
        borderRadius: "12px",
        background: "var(--white, #FFF)",
        marginTop:"24px",
        "& .MuiFormControl-root": {
          width: "100%",
        },
        "& .MuiFormControlLabel-root": {
          opacity: "0.671",
          lineHeight: "20px",
          fontSize: "14px",
          color: "#000000",
          letterSpacing: "0px",
          fontFamily: "Expletus Sans",
          fontWeight: "500",
          [theme.breakpoints.down("xs")]: {
            marginLeft: "0",
            justifyContent: "space-between",
            width: "100%",
            color: "#2B2B2B",
            opacity: "1",
          },
          "& .MuiFormControlLabel-label": {
            font: "inherit",
          },
        },
        "& .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#d3d9e1",
          [theme.breakpoints.down("xs")]: {
            backgroundColor: "#94A3B8",
          },
        },
        "& .MuiSwitch-root": {
          [theme.breakpoints.down("xs")]: {
            width: "33px",
            padding: "5px",
            height: "20px",
          },
          "& .MuiIconButton-root": {
            [theme.breakpoints.down("xs")]: {
              padding: "6px",
            },
          },
        },
        "& .MuiSwitch-switchBase": {
          color: "#fafafa",
          [theme.breakpoints.down("xs")]: {
            color: "#ffffff",
          },
          "&.Mui-checked": {
            color: "#3691a6",
            [theme.breakpoints.down("xs")]: {
              transform: "translateX(12px)",
            },
            "&+ .MuiSwitch-track": {
              backgroundColor: "#3691a6",
            },
          },
        },
        "& .MuiSwitch-thumb": {
          filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.2))",
          boxShadow: "none",
          [theme.breakpoints.down("xs")]: {
            width: "8px",
            height: "8px",
          },
        },
        "& .MuiSwitch-colorSecondary.Mui-checked":{
          color:'#FFF' 
        },
        "& .view-more-dropdown":{
          width: "310px",
          "& .dropdown-menu-item":{
            display: 'flex',
            padding: '12px 24px',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'stretch',
          },
          "& .MuiFormControl-root":{
            width:"280px",
            marginLeft:"20px"
          },
        
          "& .dropdown-menu-item-column":{
            display: 'flex',
            padding: '8px 16px',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'stretch',
          },
          "& .MuiSvgIcon-root":{
            color:"#E8ECF2"
          },
          // "& .dropdown-menu-item-checked":{
          //   color:"#4BA3B7",
          // }, 
          "& .MuiTouchRipple-root": {
            display: "none",
          },      
          "& .dropdown-menu-item-text":{
            color: '#2B2B2B',
            fontFamily: 'Expletus Sans',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal',
          }
        },
      },
      productListPopoverStyle: {
        display: "inline-flex",
        height: "auto",
        width: "500px",
        marginLeft:"-160px",
        flexDirection: "column",
        flexShrink: 0,
        padding: "20px",
        borderRadius: "24px",
        background: "var(--white, #FFF)",
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
        marginTop:"68px",
        maxHeight: "calc(100% - 90px)",
        "& .view-selected-item-dropdown-list":{
          "& .headding":{
            display:"flex",
            justifyContent:"space-between",
            alignItems:"center",
            "& .headding-txt":{
              color: 'var(--Task-Completed-Color, #2B2B2B)',
              fontFamily: 'Expletus Sans',
              fontSize: '22px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '26px',
            }
          },
          "& .sub-headding-txt":{
            color: 'var(--Task-Completed-Color, #2B2B2B)',
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '17px',
            marginTop:"6px",
          },
          "& .product-container":{
            padding:"24px 16px",
            borderBottom: "1px solid #E8ECF2",
            display:"flex",
            justifyContent:"space-between",
            alignItems:"center",
           "& .categoryImg":{
            width:"56px",
            height:"56px",
           },
           "& .delete-content":{
            display:"flex",
            justifyContent:"space-between",
            alignItems:"center",
            "& .delete-img":{
              width:"24px",
              height:"24px",
              position:"absolute",
              right:"32px",
            },
           },
           "& .product-content":{
            "& .product-name": {
              color: "var(--gray, rgba(43, 43, 43, 0.87))",
              fontFamily: "Roboto",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "24px" /* 150% */,
              letterSpacing: "0.15px,",
            },
            "& .product-item": {
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              letterSpacing: "0.4px",
              margin:"6px 0",
            },
           },
           "& .product-add-remove":{
            display:"flex",
            justifyContent:"space-between",
            alignItems:"center",
            marginTop:"-32px",
            "& .secondary-btn": {
              width:"32px",
              height:"32px",
              minWidth: "35px",
              minHeight: "35px",
              color: "#4BA3B7",
            },
            "& .product-count":{
              color: "var(--gray, rgba(43, 43, 43, 0.87))",
              fontFamily: "Roboto",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "24px" /* 150% */,
              letterSpacing: "0.15px,",
              margin:"0 8px",
            }
           },
           "& .wrap-price": {
            display: "flex",
            // flexDirection: "column",
            alignItems: "flex-end",
            "& .price-btn": {
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              textTransform: "none",
              "& .retail-price-txt": {
                color: "#94A3B8",
                fontFamily: "Roboto",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                letterSpacing: "0.4px",
                whiteSpace:"nowrap",
              },
              "& .icon-down": {
                width: "24px",
                height: "24px",
                color: "#94A3B8",
              },
            },
            "& .price-txt": {
              color: "var(--gray, rgba(43, 43, 43, 0.87))",
              fontFamily: "Roboto",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "24px" /* 150% */,
              letterSpacing: "0.15px,",
            },
          },
          }
        }
      },
      syncBrevoModal: {
        display: "flex",
        flexDirection: "column",
        background: "#fff",
        overflow: "auto",
        width: "100%",
        height: "100%",
        "& .brevo-input-header": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          height: "84px",
          padding: "12px 24px",
          borderBottom: "1px solid #E8ECF2",
  
          "& .top-left": {},
          "& .top-right": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
  
            "& .calendar-icon": {
              height: "24x",
              width: "24px",
              marginRight: "12px",
            },
            "& .selected-txt": {
              color: "var(--task-completed-color, #2B2B2B)",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
              whiteSpace: "nowrap",
            },
            "& .add-to-contact-btn": {
              textTransform: "none",
              borderRadius: "6px",
              background: "var(--brand-color-disable, #92C7D3)",
              width: "147px",
              height: "48px",
              padding: "8px 14px",
              color: "#ffffff",
              marginLeft: "32px",
            },
            "& .add-to-contact-btn-active": {
              textTransform: "none",
              borderRadius: "6px",
              background: "var(--brand-color-disable, #4BA3B7)",
              width: "147px",
              height: "48px",
              padding: "8px 14px",
              color: "#ffffff",
              marginLeft: "32px",
            },
          },
        },
        "& .main-wrapper": {
          padding: "0 90px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          maxWidth: "600px",
          margin: "0 auto",
          [theme.breakpoints.down("xs")]: {
            padding: "0 30px",
          },
          "& .MuiButton-root": {
            borderRadius: "6px",
            background: "var(--brand-color-primary-100, #4BA3B7)",
            color: "#ffffff",
            textTransform: "none",
            fontFamily: "Expletus Sans",
            width: "100%",
            height: "48px",
            margin: "0",
          },
          "& .main-content-wrapper": {
            display: "flex",
            flexDirection: "column",
            "& .brevo-title": {
              color: "#2B2B2B",
              fontSize: "22px",
              fontStyle: "normal",
              linHeight: "normal",
              fontFamily: "Expletus Sans",
              fontWeight: 400,
              letterSpacing: "0.25px",
            },
            "& .brevo-desc": {
              color: "#94A3B8",
              margin: "0",
              fontSize: "14px",
              fontStyle: "normal",
              linHeight: "20px",
              fontFamily: "Roboto",
              fontWeight: 400,
              letterSpacing: "0.25px",
            },
            "& .custom-input-wrapper": {
              margin: "31px 0 16px",
            },
            "& .list-wrapper": {
              marginBottom: "40px",
              "& .brevo-desc": {
                fontSize: "12px",
                marginBottom: '8px',
              }
            },
            "& .MuiList-root": {
              padding: "0 0 8px",
              "& .MuiListItem-root": {
                padding: "0 0 0 6px",
                color: "#94A3B8",
                fontSize: "12px",
                linHeight: "normal",
                fontFamily: "Roboto",
                display: "inline-block",
                "& + .MuiListItem-root": {
                  marginTop: "5px",
                },
                "& a": {
                  color: "#2B2B2B",
                  marginLeft: "5px",
                  transition: "all 0.3s",
                  wordBreak: "break-all",
                  "&:hover": {
                    textDecoration: "none",
                  },
                },
              },
            },
          },
        },
        "& .sync-brevo-screen":{
           display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            [theme.breakpoints.down(767)]: {
              flexDirection: "column",
            },
  
            "& .img-contact-book": {
              width: "300px",
              height: "244px",
              objectFit: "contain",
            },
            "& .box-empty-right": {
              margin: "0 0 0 93px",
              maxWidth:"415px",
              [theme.breakpoints.down(767)]: {
                margin: "0",
                padding: "0 25px",
              },
              "& .txt-no-contact-title": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "400",
                linHeight: "normal",
                letterSpacing: "0.25px",
              },
              "& .txt-no-contact-sub-title": {
                color: "var(--task-completed-color, #94A3B8)",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                linHeight: "20px",
                letterSpacing: "0.25px",
                margin: "12px 0",
              },
              "& .MuiButton-root": {
                borderRadius: "6px",
                background: "var(--brand-color-primary-100, #4BA3B7)",
                color: "#ffffff",
                textTransform: "none",
                fontFamily: "Expletus Sans",
                width: "211px",
                height: "48px",
                margin: "48px 0px 0px",
              },
              "& .brevo-connect-btn":{
                width: "136px",
              }
            },
        },
      }
  });
// Customizable Area End
