// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  List,
  FormControl,
  Select,
  Chip,
  ListItem,
  Popover,
  ListSubheader,
  InputAdornment,
  Typography,
  Modal,
  Card,
  InputLabel,
  Switch,
  RadioGroup,
  ListItemIcon,
  ListItemSecondaryAction,
  TextField,
  ListItemText,
  Tabs,
  Tab,
  Radio,
} from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
import GlobalHeaderWebController, {
  Props,
  configJSON,
} from "./GlobalHeaderWebController";
import { bellBadge, menuIcon, sortby, checkGreen, timerSand, chatBubble, cartIcon, minimizeGray, checkBoxFilled, fileTree, checkBoxEmpty, closeGray, accountPlus, uploadImg, projectCreate } from "./assets";
import { Link, NavLink } from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import withMediaQuery from "../../../components/src/withMediaQuery.web";
import { imgLogo } from "../../../components/src/assets";
import ProfileMenu from "../../../components/src/ProfileMenu.web";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SettingsIcon from "@material-ui/icons/Settings";
import CheckOutlined from "@material-ui/icons/CheckOutlined";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchIcon from "@material-ui/icons/Search";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import moment from "moment";
import { withHistory } from "../../../components/src/withHistory.web";

import InfiniteScroll from "react-infinite-scroll-component";
import { ShoppingCartOrders } from "../../shoppingcart/src/ShoppingCartOrders.web";
import QuickChat from "../../LiveChat2/src/QuickChat.web";
import RoleSelectionDropdown from "../../../components/src/Role-selection-dropdown";
import { getNotificationIcon, getPlaceholderImage, generateSummary } from "../../../components/src/ReusableFunctions";
import CloseIcon from "@material-ui/icons/Close";
import { FileDrop } from "react-file-drop";
import FreeSoloCreateOption from "../../../components/src/FreeSoloCreateOption.web";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ProductInventoryViewModal from "../../../components/src/ProductInventoryViewModal.web";
import AddInventoryAndAssests from '../../TaskList/src/web/AddInventoryAndAssests.web';
import ConfirmActionModal from "../../email-account-registration/src/teams-web/ConfirmActionModal.web";


export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    HeaderWrapper: {
      padding: "60px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
      [theme.breakpoints.down("md")]: {
        padding: "40px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "60px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "60px 24px 24px",
      },
      "&.profile-header": {
        padding: "20px 32px",
        flexDirection: "unset",
        background: "#ffffff",
        boxShadow: "0px 1px 0px 0px #E8ECF2",
        // position: "fixed",
        // left: "0",
        // right: "0",
        // top: "0",
        [theme.breakpoints.down("sm")]: {
          padding: "20px 24px",
        },
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
        "& .header-left": {
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          "& .auth-logo": {
            [theme.breakpoints.down("sm")]: {
              marginRight: "50px",
            },
          },
        },
        "& .header-nav": {
          "& .nav-list": {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            padding: "0",
            "& .MuiListItem-root": {
              width: "auto",
              padding: "0",
              marginRight: "36px",
              [theme.breakpoints.down("sm")]: {
                marginRight: "32px",
              },
              "& .nav-link": {
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                color: "#2B2B2B",
                opacity: "0.37",
                textDecoration: "none",
                fontFamily: "Expletus Sans",
                letterSpacing: "0.1px",
                "&.active": {
                  opacity: "1",
                },
              },
            },
          },
        },
        "& .header-right": {
          display: "flex",
          alignItems: "center",
          marginLeft: "15px",
          "& .link-btn": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "6px",
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "19px",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          },
          "& .MuiButton-root": {
            minHeight: "40px",
            padding: "10px 9px",
          },
          "& .right-icon": {
            marginLeft: "10px",
            [theme.breakpoints.down("sm")]: {
              marginLeft: "20px",
            },
            "& .icon-btn": {
              padding: "0",
            },
          },
          "& .profile-menu": {
            display: "flex",
            padding: "7px 13px",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "30px",
            border: "1px solid #E8ECF2",
            "& .profile-icon": {
              marginLeft: "12px",
              width: "26px",
              height: "26px",
              fontSize: "12px",
              lineHeight: "15px",
              background: "#E8ECF2",
            },
          },
          "& .profile-icon": {
            width: "36px",
            height: "36px",
            background: "#ECF1F4",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "14px",
            lineHeight: "19px",
            color: "#2B2B2B",
            borderRadius: "100%",
            fontWeight: 500,
            fontFamily: "Expletus Sans",
          },
        },
      },
      "& .auth-logo": {
        maxWidth: "162px",
        width: "100%",
        "& img": {
          maxWidth: "100%",
        },
      },
      "& .auth-back": {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        "& i": {
          marginRight: "10px",
        },
      },
      "& .auth-right": {
        marginLeft: "20px",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        [theme.breakpoints.down("xs")]: {
          marginLeft: "10px",
        },
        "& .MuiButton-root": {
          marginRight: "15px",
          transition: "none",
          [theme.breakpoints.down("xs")]: {
            marginRight: "10px",
          },
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
    },
    createTaskWrapper: {
      "& .task-actions": {
        position: "fixed", 
        right: "0",
        bottom: "27px",
        background: "#ffffff",
        maxWidth: "calc(100% - 367px)",
        padding: "0 32px",
        [theme.breakpoints.down("md")]: {
          maxWidth: "calc(100% - 320px)",
        },
        [theme.breakpoints.down("xs")]: {
          maxWidth: "100%",
        },
        "& .MuiButton-root": {
          marginBottom: "5px",
        },
        "& .secondary-btn": {
          boxShadow: "0px 0px 18px 2px rgba(0, 0, 0, 0.10)",
          marginRight: "16px",
          minWidth: "auto",
          color: "rgba(43, 43, 43, 0.87)",
          "&:hover": {
            border: "1px solid rgba(43, 43, 43, 0.87)",
          },
          "& .draft-name": {
            display: "flex",
            alignItems: "center",
          },
          "& .draft-img": {
            marginRight: "6px",
          },
          "& .close": {
            margin: "0 0 0 5px",
            cursor: "pointer",
          },
        },
      },
      "& .primary-btn": {
        boxShadow: "0px 0px 24px 0px rgba(5, 111, 135, 0.47)",
        minWidth: "136px",
      },
    },
    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "7px",
      filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#ffffff",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      width: "auto",
      minWidth: "156px",
      [theme.breakpoints.down("xs")]: {
        padding: "8px 10px",
        minHeight: "46px",
        minWidth: "unset",
      },
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
    },
    modalDialogBox: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 680,
      backgroundColor: "#ffffff",
      padding: "0",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("md")]: {
        width: 520,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      "&.view-modal-dialog": {
        width: 640,
        [theme.breakpoints.down("md")]: {
          width: 520,
        },
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        "& .modal-heading": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #E8ECF2",
          padding: "22px 40px",
          marginBottom: "0",
          [theme.breakpoints.down("xs")]: {
            padding: "24px",
          },
          "& .heading-links": {
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("xs")]: {
              justifyContent: "space-between",
              width: "100%",
            },
            "& .heading-link": {
              marginRight: "24px",
              "&:last-child": {
                marginRight: "0",
              },
              "& .auth-back": {
                color: "#2b2b2b",
                display: "inline-block",
                "& i": {
                  display: "inline-block",
                },
              },
            },
          },
          "& .close-icon": {
            color: "#94A3B8",
            lineHeight: "0",
          },
        },
        "& .main-content-txt":{
          display:"flex",
          margin:"21px 20px -16px 32px",
         "& .content-sub-txt":{
           fontSize: "12px",
           lineHeight: "normal",
           color: "#797878",
           fontFamily: "Roboto",
           fontWeight: 400,
           letterSpacing: "0.4px",
           marginTop: "4px",
           marginBottom:"24px",
           textTransform: "capitalize",         
           "&.content-dots": {
             color: "#797878",
             marginLeft:"12px",
             "&:before": {
               content: '""',
               width: "8px",
               height: "8px",
               borderRadius: "100%",
               background: "#ECF1F4",
               display: "inline-block",
               marginRight: "8px",
             },
           },            
         },
       },
        "& .modal-title": {
          fontSize: "22px",
          lineHeight: "26px",
          color: "#2B2B2B",
          letterSpacing: "0",
          fontFamily: "Expletus Sans",
          fontWeight: 400,
          marginBottom: "15px",
          "& .priority-status": {
            color: "#C7263E",
            marginRight: "5px",
          },
        },
        "& .modal-description": {
          padding: "24px 40px",
          paddingTop:"0px",
          [theme.breakpoints.down("md")]: {
            padding: "24px 32px",
            paddingTop:"0px",
          },
          [theme.breakpoints.down("xs")]: {
            padding: "16px 24px",
            paddingTop:"0px",
          },
        },
      },
      "&.all-assignee-dialogbox": {
        "& .modal-dialoginner": {
          padding: "84px 60px",
          [theme.breakpoints.down("md")]: {
            padding: "80px 32px",
          },
          [theme.breakpoints.down("xs")]: {
            padding: "24px",
          },
        },
        "& .modal-heading": {
          marginBottom: "36px",
          "& .filter-heading": {
            "& .auth-back": {
              marginRight: "7px",
            },
            "& .modal-title": {
              fontSize: "14px",
              lineHeight: "19px",
            },
          },
        },
        "& .modal-description": {
          "& .heading-block": {
            marginBottom: "25px",
            "& .primary-txt": {
              fontSize: "22px",
              lineHeight: "26px",
              marginBottom: "12px",
            },
          },
          "& .tasklist-member": {
            "& .tasklist-li": {
              display: "flex",
              padding: "8px 24px 5px",
              [theme.breakpoints.down("xs")]: {
                margin: "0 -24px",
                width: "auto",
              },
              "& .tasklist-left": {
                display: "flex",
                alignItems: "flex-start",
                "& .chip-wrapper": {
                  marginTop: "3px",
                  "& .MuiChip-root": {
                    marginBottom: "2px",
                    height: "24px",
                  },
                },
              },
            },
          },
        },
      },
      "&.timesheet-assignee-dialogbox": {
        "& .modal-description": {
          "& .heading-block": {
            marginBottom: "24px",
          },
        },
      },
      "&.filter-dialogbox": {
        [theme.breakpoints.down("xs")]: {
          maxWidth: "100%",
          width: "100%",
        },
        "& .modal-heading": {
          marginBottom: "44px",
          [theme.breakpoints.down("xs")]: {
            marginBottom: "26px",
          },
          "& .filter-heading": {
            display: "flex",
            alignItems: "center",
            "& .auth-back": {
              cursor: "pointer",
              marginRight: "8px",
            },
            "& .modal-title ": {
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "24px",
              fontFamily: "Expletus Sans",
            },
          },
          "& .filter-link": {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "19px",
            color: "#4BA3B7",
            fontFamily: "Expletus Sans",
            textDecoration: "none",
          },
        },
      },
      "&.project-created-dialog": {
        "& .modal-heading": {
          marginBottom: "134px",
          [theme.breakpoints.down("md")]: {
            marginBottom: "100px",
          },
          [theme.breakpoints.down("xs")]: {
            marginBottom: "42px",
          },
          "& .close-icon": {
            marginLeft: "auto",
          },
        },
        "& .modal-footer": {
          marginTop: "auto",
          "& .MuiButton-root": {
            "&:last-child": {
              marginBottom: "0",
            },
          },
          "& .secondary-btn": {
            [theme.breakpoints.down("xs")]: {
              borderColor: "#4BA3B7",
              color: "#4BA3B7",
            },
          },
        },
      },
      "&.project-template-dialog": {
        "& .modal-description": {
          marginBottom: "68px",
        },
      },
      "&.create-tasklist-dialog": {
        "& .primary-btn": {
          fontSize: "16px",
          lineHeight: "21px",
        },
        "& .modal-heading": {
          "& .modal-title": {
            fontSize: "24px",
            lineHeight: "28px",
            letterSpacing: "unset",
            [theme.breakpoints.down("md")]: {
              fontSize: "22px",
              lineHeight: "26px",
            },
          },
        },
      },
      "&.create-timesheet-dialog": {
        "& .modal-heading": {
          "& .modal-title": {
            lineHeight: "28px",
            letterSpacing: "unset",
            fontSize: "24px",
            [theme.breakpoints.down("md")]: {
              lineHeight: "26px",
              fontSize: "22px",
            },
          },
        },
        "& .primary-btn": {
          fontSize: "16px",
          lineHeight: "21px",
        },
      },
      "&.project-dialogbox": {
        "& .modal-heading": {
          "& .modal-title": {
            fontSize: "24px",
            lineHeight: "28px",
            letterSpacing: "unset",
            [theme.breakpoints.down("md")]: {
              fontSize: "22px",
              lineHeight: "26px",
            },
          },
        },
      },
      "&.move-tasklist-dialog": {
        "& .modal-form": {
          marginTop: "56px",
          [theme.breakpoints.down("md")]: {
            marginTop: "32px",
          },
        },
        "& .form-info-wrapper": {
          margin: "0 0 24px",
        },
        "& .modal-footer": {
          "& .MuiButton-root": {
            marginBottom: "0",
          },
        },
      },
      "& .hide-sm": {
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },
      "& .show-sm": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          display: "block",
        },
      },
      "& .progress-wrap": {
        borderRadius: "6px",
        background: "#ECF1F4",
        height: "4px",
        width: "100%",
        "& .current-progress": {
          marginLeft: "auto",
          borderRadius: "16px",
          maxWidth: "100%",
          height: "inherit",
          borderLeft: "4px solid #fff",
          background: "#4BA3B7",
        },
        "& .progress-inner": {
          maxWidth: "100%",
          borderRadius: "inherit",
          height: "inherit",
          background: "#94A3B8",
          "&.red": {
            background: "#C7263E",
          },
          "&.completed": {
            background: "#00BA88",
          },
          "&.inprogress": {
            background: "#4BA3B7",
          },
        },
      },
      "& .team-multiselect": {
        "& .MuiAutocomplete-inputRoot": {
          paddingLeft: "16px",
        },
        "& .MuiAutocomplete-input": {
          "&:first-child": {
            padding: "0",
          },
        },
        "& .MuiFormLabel-root": {
          fontFamily: "Roboto",
          fontWeight: 400,
          letterSpacing: "0.105px",
        },
        "& .MuiAutocomplete-endAdornment": {
          "& .MuiIconButton-root": {
            fontSize: "20px",
            color: "#94A3B8",
            right: "15px",
            "& .MuiSvgIcon-root": {
              fontSize: "20px",
            },
          },
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#E8ECF2",
            },
          },
        },
        "& .MuiChip-root": {
          backgroundColor: "#ECF1F4",
          borderRadius: "6px",
          padding: "6px 8px",
          height: "auto",
          color: "#4F4F4F",
          fontFamily: "Roboto",
          fontSize: "13px",
          fontWeight: 400,
          lineHeight: "17px",
          margin: "0 5px 5px 0",
          "& .MuiChip-label": {
            paddingLeft: "0",
          },
          "& .MuiChip-deleteIcon": {
            width: "17px",
            height: "17px",
            margin: "0",
          },
        },
      },
      "& .modal-dialoginner": {
        padding: "15px 60px",
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        [theme.breakpoints.down("md")]: {
          padding: "15px 32px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "24px",
        },
        "& .MuiCheckbox-root": {
          color: "#94A3B8",
          "&:hover": {
            backgroundColor: "rgba(54, 145, 166, 0.04)",
          },
          "&.Mui-checked": {
            color: "#4ba3b7",
          },
          "& .MuiSvgIcon-root": {
            width: "24px",
            height: "24px",
          },
        },
        "& .MuiRadio-root": {
          color: "#94A3B8",
          padding: "0",
          "&:hover": {
            backgroundColor: "rgba(54, 145, 166, 0.04)",
          },
          "&.Mui-checked": {
            color: "#4ba3b7",
          },
          "& .MuiSvgIcon-root": {
            width: "24px",
            height: "24px",
          },
        },
      },
      "& .modal-heading": {
        marginBottom: "11px",
        display: "flex",
        justifyContent: "space-between",
        paddingTop:"30px",
        "& .modal-title ": {
          fontWeight: 400,
          fontSize: "22px",
          lineHeight: "26px",
          fontFamily: "Expletus Sans",
          color: "#2b2b2b",
        },
        "& .heading-left": {
          alignItems: "center",
          display: "flex",
          "& .auth-back": {
            opacity: "0.67",
            marginRight: "8px",
            color: "#2B2B2B",
            cursor: "pointer",
          },
          "& .modal-title ": {
            color: "#2b2b2b",
            fontSize: "14px",
            lineHeight: "19px",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
          },
        },
        "& .close-icon": {
          lineHeight: "0",
          color: "#94A3B8",
        },
      },
      "& .modal-description": {
        "& .select-outer": {
          width: "100%",
          marginBottom: "24px",
          "& .MuiInputLabel-formControl": {
            fontFamily: "Roboto",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "19px",
            letterSpacing: "0.1px",
            color: "#94A3B8",
            display: "flex",
            width: "calc(100% - 46px)",
            "&.MuiFormLabel-filled": {
              width: "auto",
              color: "rgba(43,43,43,0.87)",
              fontSize: "12px",
              fontFamily: "Roboto",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "0.0075em",
            },
          },
          "& .MuiSelect-select": {
            fontSize: "14px",
            letterSpacing: "0",
            color: "#000000",
            opacity: "1",
            fontWeight: "400",
            fontFamily: "Roboto",
            display: "flex",
            alignItems: "center",
            paddingRight: "45px",
            "&:focus": {
              backgroundColor: "transparent",
            },
            "& .status-name": {
              color: "#2B2B2B",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "26px",
              letterSpacing: "0.105px",
            },
            "& .status": {
              width: "12px",
              height: "12px",
              background: "#94A3B8",
              borderRadius: "100%",
              marginLeft: "10px",
              "&.open": {
                background: "#4BA3B7",
              },
              "&.cancelled": {
                background: "#F7E223",
              },
            },
          },
          "& .MuiSvgIcon-root": {
            fontSize: "20px",
            color: "#94A3B8",
            right: "24px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ecf2",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            },
          },
          "& .MuiFormLabel-root": {
            "&.Mui-focused": {
              width: "auto",
            },
            "& .MuiInputLabel-asterisk": {
              color: "#e86577",
              fontSize: "18px",
              lineHeight: "23px",
              marginLeft: "auto",
            },
          },
        },
        "& .datepicker-wrapper": {
          "& .rmdp-container": {
            "& .rmdp-input": {
              paddingLeft: "16px",
              letterSpacing: "0.105px",
              "&::-webkit-input-placeholder": {
                color: "#94A3B8",
              },
              "&:-ms-input-placeholder": {
                color: "#94A3B8",
              },
              "&::placeholder": {
                color: "#94A3B8",
              },
            },
          },
        },
        "& .task-subtitle": {
          color: "rgba(43, 43, 43, 0.67)",
          fontFamily: "Roboto",
          fontSize: "12px",
          lineHeight: "16px",
          fontWeight: 400,
          letterSpacing: "0.09px",
          marginBottom: "8px",
        },
        "& .task-progress-block": {
          marginBottom: "15px",
          [theme.breakpoints.down("xs")]: {
            marginBottom: "16px",
          },
          "& .progress-info": {
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginBottom: "14px",
            color: "#2B2B2B",
            fontFamily: "Roboto",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "14px",
            textTransform: "uppercase",
            "& .total-value": {
              color: "#94A3B8",
              marginTop:"15px",
            },
          },
        },
        "& .taskview-list": {
          marginBottom: "8px",
          padding: "0",
          "& .MuiListItem-root": {
            padding: "16px 0",
            borderBottom: "1px solid #E8ECF2",
            display: "flex",
            alignItems: "center",
            "&:last-child": {
              borderBottom: "0",
            },
            "&.date-item-root": {
              alignItems: "flex-start",
              [theme.breakpoints.down("xs")]: {
                justifyContent: "space-between",
              },
              "& .list-label": {
                [theme.breakpoints.down("xs")]: {
                  width: "auto",
                },
              },
              "& .list-description": {
                [theme.breakpoints.down("xs")]: {
                  width: "auto",
                  paddingRight: "7px",
                },
              },
            },
            "& .list-description": {
              letterSpacing: "0.105px",
              color: "#2B2B2B",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "26px",
              paddingLeft: "15px",
              fontFamily: "Roboto",
              width: "67%",
              [theme.breakpoints.down("xs")]: {
                width: "43.4%",
              },
              "& .brand-names":{ 
                display:"flex",
                "& .brand-list":{
                  marginRight:"25px"
                },
                "& .more-btn":{
                 position:"absolute",
                //  right:"70px",
                 top:"30px"
                },
              },
              "& .list-subtitle": {
                color: "#94A3B8",
                fontSize: "10px",
                lineHeight: "14px",
                letterSpacing: "normal",
               
              },
              "& .assignee-list": {
                display: "flex",
                alignItems: "center",
                "& .assignee-bubble": {
                  display: "flex",
                  position: "relative",
                  alignItems: "center",
                  letterSpacing: "-0.244px",
                  justifyContent: "center",
                  background: "#ECF1F4",
                  marginLeft: "-4px",
                  color: "rgba(43, 43, 43, 0.87)",
                  borderRadius: "100%",
                  border: "2px solid #FFF",
                  fontSize: "9.586px",
                  fontFamily: "Roboto",
                  width: "24px",
                  height: "24px",
                  fontWeight: 600,
                  lineHeight: "13.42px",
                  "&.more-bubble": {
                    cursor: "pointer",
                  },
                  "&:first-child": {
                    marginLeft: "0",
                  },
                },
              },
              "& .date-info": {
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
                "&:last-child": {
                  marginBottom: "0",
                },
                "& .label": {
                  color: "#94A3B8",
                  // minWidth: "67px",
                  textAlign: "right",
                },
                "& .value": {
                  marginLeft: "8px",
                  display: "flex",
                  alignItems: "center",
                  "& .icon": {
                    marginLeft: "8px",
                  },
                },
              },
              "& .untracked-hours":{
                color:"#4BA3B7",
                textDecoration: "none",
                "& .value-container":{
                  display: "flex",
                  alignItems: "center",
                },
                "& .icon":{
                  marginLeft: "8px",
                }
              }
            },
            "& .list-description1": {
              letterSpacing: "0.105px",
              color: "#2B2B2B",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "26px",             
              fontFamily: "Roboto",
              width: "67%",
              [theme.breakpoints.down("xs")]: {
                width: "43.4%",
              },
              "& .brand-names":{ 
                display:"flex",
                "& .brand-list":{
                  marginRight:"10px"
                },
                "& .more-btn":{
                 position:"absolute",
                 right:"70px",
                 top:"30px"
                },
              },
              "& .list-subtitle": {
                letterSpacing: "normal",
                color: "#94A3B8",
                lineHeight: "14px",
                fontSize: "10px",
              },
              "& .assignee-list": {
                alignItems: "center",
                display: "flex",
                "& .assignee-bubble": {
                  marginLeft: "-4px",
                  color: "rgba(43, 43, 43, 0.87)",
                  letterSpacing: "-0.244px",
                  justifyContent: "center",
                  background: "#ECF1F4",
                  width: "24px",
                  height: "24px",
                  borderRadius: "100%",
                  border: "2px solid #FFF",
                  fontSize: "9.586px",
                  fontFamily: "Roboto",
                  fontWeight: 600,
                  lineHeight: "13.42px",
                  display: "flex",
                  position: "relative",
                  alignItems: "center",
                  "&:first-child": {
                    marginLeft: "0",
                  },
                  "&.more-bubble": {
                    cursor: "pointer",
                  },
                },
              },
              "& .date-info": {
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
                // marginLeft:"20px",
                "&:last-child": {
                  marginBottom: "0",
                },
                "& .label": {
                  color: "#94A3B8",
                  minWidth: "60px",
                  textAlign: "right",
                },
                "& .value": {
                  marginLeft: "8px",
                  display: "flex",
                  alignItems: "center",
                  "& .icon": {
                    marginLeft: "8px",
                  },
                },
              },
              "& .date-info-1": {
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
                marginLeft: "163px",
                [theme.breakpoints.down("xs")]: {
                  marginLeft: "50px",
                },
                width: "100%",
                "&:last-child": {
                  marginBottom: "0",
                },
                "& .label": {
                  color: "#94A3B8",
                  minWidth: "60px",
                  textAlign: "right",                 
                },
                "& .value": {
                  marginLeft: "8px",
                  display: "flex",
                  alignItems: "center",
                  "& .icon": {
                    marginLeft: "8px",
                  },
                },
              },
              "& .untracked-hours":{
                color:"#4BA3B7",
                textDecoration: "none",
                "& .value-container":{
                  display: "flex",
                  alignItems: "center",
                },
                "& .icon":{
                  marginLeft: "8px",
                }
              }
            },
            "& .list-label": {
              letterSpacing: "0.105px",
              fontWeight: 400,
              lineHeight: "26px",
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "12px",
              width: "33%",
              [theme.breakpoints.down("xs")]: {
                width: "56.6%",
              },
            },
            "& .list-label-1": {
              letterSpacing: "0.105px",
              fontWeight: 400,
              lineHeight: "26px",
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "12px",            
            },
          },
          "& .accordion-block": {
            boxShadow: "none",
            borderRadius: "12px",           
            width:"100%", 
            "& .employee-txt":{
              display: "flex",
              justifyContent:"space-between",
              width: "65%",
              "& .employee-value":{
                color: "#2B2B2B",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
              },
              "& .list-actual": {
                display:"flex",
                alignItems:"center",
                letterSpacing: "0.105px",
                color: "#2B2B2B",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "26px",
                paddingLeft: "15px",
                fontFamily: "Roboto",
                          
                "& .list-subtitle": {
                  letterSpacing: "normal",
                  color: "#94A3B8",
                  lineHeight: "14px",
                  fontSize: "10px",
                },
                "& .list-value":{
                  color: "#2B2B2B",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontWeight: 400,
                  marginLeft:"10px",
                 }             
              },              
            },     
            "&:before": {
              display: "none",
            },
            "& .accordion-heading": {
              minHeight: "unset",
              padding: "0",
              alignItems: "flex-start",
              flexDirection: "row-reverse",
              marginLeft:"-5px",
              "& .heading": {
                color: "#2B2B2B",
                fontSize: "14px",
                width: "100%",
                fontFamily: "Expletus Sans",
                lineHeight: "19px",
                fontWeight: 500,
                marginBottom: "6px",
                [theme.breakpoints.down("xs")]: {
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 400,
                  marginBottom: "8px",
                },
              },
              "& .MuiAccordionSummary-content": {
                margin: "0",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "flex-start",
              },
              "& .sub-txt": {
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                marginBottom: "0",
                color: "#94A3B8",
                letterSpacing: "0.09px",
                lineHeight: "16px",
              },
              "& .MuiIconButton-root": {
                padding: "0",
                margin: "0",
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                  color: "#94A3B8",
                },
              },
            },
            "& .accordion-details": {
              padding: "0",
              marginTop: "24px", 
              "& .inventory-table": {
                width: "100%",
                margin: "20px 0",
                backgroundColor: "#fff",
                textAlign: "inherit",
                "& .inventeory-items":{
                    marginBottom:"6px",
                  "& .employee-value-head":{
                    color: "#2B2B2B",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 400,
                    display: "flex",
                    alignItems: "center",
                    marginTop:"12px",
                    "& .image":{
                      marginLeft:"6px"
                    }
                  },               
                  "& .employee-value":{
                    color: "#2B2B2B",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 400,
                  },
                }

            },             
              "& .labour-cost":{
               display: "flex",
               justifyContent:"space-between",
               width: "92%",
               "& .list-value":{
                color: "#2B2B2B",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 600,
               }
              },            
              "& table": {
                "& tr": {
                  "& td": {
                    "&.MuiTableCell-root": {
                      color: "#2B2B2B",
                      fontFamily: "Roboto",
                      fontSize: "13px",
                      fontWeight: 400,
                      lineHeight: "24px",
                      padding: "6px 8px 24px",
                      border: "0",
                      "&:first-child": {
                        paddingLeft: "0",
                        width: "43.5%",
                      },
                      "&:nth-child(2)": {
                        textAlign: "center",
                      },
                      "&:last-child": {
                        paddingRight: "0",
                        textAlign: "right",
                      },
                    },
                    "& .open-icon": {
                      display: "inline-block",
                      marginLeft: "3px",
                      position: "relative",
                      top: "2px",
                      "& .MuiSvgIcon-root": {
                        color: "#94A3B8",
                        fontSize: "14px",
                      },
                    },
                  },
                  "& th": {
                    border: "0",
                  },
                  "& .table-heading": {
                    color: "#94A3B8",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 600,
                    lineHeight: "16px",
                    letterSpacing: "0.09px",
                    padding: "0",
                    "&.last": {
                      padding: "15px 0 0",
                    },
                  },
                  "&.last-row": {
                    "& td": {
                      "&.MuiTableCell-root": {
                        padding: "24px 0 0",
                      },
                    },
                  },
                },
              },
            },
          },
        },
        "& .tasklist-tabwrapper": {
          marginBottom: "45px",
          "& .not-found":{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "19px",
            whiteSpace: "nowrap",
            marginLeft: "10px",
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            marginTop:"20px",
          },
          [theme.breakpoints.down("xs")]: {
            marginBottom: "22px",
          },
          "&:last-child": {
            marginBottom: "0",
          },
          "& .assignee-tabs": {
            "& .MuiTabs-root": {
              marginBottom: "0",
              [theme.breakpoints.down("xs")]: {
                margin: "0 -24px",
              },
            },
          },
          "& .MuiTabs-root": {
            borderBottom: "1px solid #E8ECF2",
            marginBottom: "17px",
            "& .MuiTab-root": {
              color: "#2B2B2B",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: 500,
              fontFamily: "Expletus Sans",
              textTransform: "capitalize",
              opacity: "0.67",
              minWidth: "unset",
              padding: "16px 24px",
              letterSpacing: "0",
              "& .MuiTouchRipple-root": {
                display: "none",
              },
              "&.Mui-selected": {
                opacity: "1",
              },
            },
          },
          "& .tab-heading": {
            marginBottom: "24px",
            alignItems: "center",
            justifyContent: "space-between",
            display: "flex",
            "& .sub-txt": {
              letterSpacing: "0",
              marginBottom: "0",
              fontWeight: 400,
              lineHeight: "17px",
              fontSize: "13px",
              fontFamily: "Roboto",
              color: "#94A3B8",
              [theme.breakpoints.down("xs")]: {
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.09px",
              },
            },
            "& .primary-txt": {
              fontFamily: "Expletus Sans",
              marginBottom: "5px",
              lineHeight: "21px",
              fontWeight: 500,
              fontSize: "16px",
              letterSpacing: "0",
              color: "#2B2B2B",
              [theme.breakpoints.down("xs")]: {
                fontSize: "14px",
                lineHeight: "19px",
              },
            },
            "& .heading-right": {
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
              "& .heading-link": {
                marginRight: "24px",
                "&:last-child": {
                  marginRight: "0",
                },
              },
            },
          },
          "& .attachments-list-default-view":{
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            "& .attacments-icon":{
              width:"68px",
              marginBottom:"40px"
            },
            "& .no-attachment-text":{
               fontFamily: "Expletus Sans",
               lineHeight: "16px",
               fontSize: "16px",
               fontWeight: 400,
               color: "#2B2B2B",
               marginBottom:"20px",
            },
            "& .no-attachment-sub-text":{
             fontFamily: "Roboto",
             lineHeight: "16px",
             fontSize: "14px",
             fontWeight: 400,
             color: "#94A3B8",
             marginBottom:"25px",
          },
          "& .secondary-btn": {
            minWidth: "327px",
            color: "#4BA3B7",
            borderColor: "#4BA3B7",
            minHeight: "48px",
            "&:hover": {
              color: "#4BA3B7",
              borderColor: "#4BA3B7",
            },       
         },
         },
          "& .attachments-list": {
            "& .list-secondary": {
              right: "0",
              "& .MuiIconButton-root": {
                margin: "0",
                padding: "0",
                color: "#828282",
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                },
              },
            },
            "& .list-item": {
              paddingLeft: "0",
              paddingBottom: "16px",
              paddingTop: "16px",
              borderBottom: "1px solid #E8ECF2",
              "& .list-img": {
                marginRight: "8px",
                width: "28px",
              },
              "& .list-name": {
                fontFamily: "Expletus Sans",
                lineHeight: "16px",
                fontSize: "12px",
                fontWeight: 500,
                color: "#2B2B2B",
              },
            },
            "& .subheader-title": {
              borderBottom: "1px solid #E8ECF2",
              paddingBottom: "16px",
              justifyContent: "space-between",
              padding: "0",
              position: "static",
              display: "flex",
              "& .title": {
                opacity: "0.67",
                lineHeight: "16px",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontWeight: 500,
                color: "#2B2B2B",
              },
              "& .tasklist-link": {
                fontFamily: "Expletus Sans",
                textDecoration: "none",
                fontSize: "14px",
                opacity: "0.67",
                fontWeight: 500,
                lineHeight: "19px",
                color: "#4BA3B7",
              },
            },
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "#4BA3B7",
          },
          "& .description-box": {
            fontFamily: "Roboto",
            fontWeight: 400,
            letterSpacing: "0.105px",
            padding: "16px",
            color: "#2B2B2B",
            fontSize: "14px",
            background: "#F2F8FC",
            lineHeight: "26px",
            borderRadius: "12px",
          },
        },
        "& .heading-block": {
          marginBottom: "46px",
          [theme.breakpoints.down("xs")]: {
            marginBottom: "26px",
          },
          "& .primary-txt": {
            marginBottom: "6px",
            fontSize: "24px",
            fontFamily: "Expletus Sans",
            fontWeight: 400,
            color: "rgba(43, 43, 43, 0.87)",
            lineHeight: "32px",
          },
          "& .sub-txt": {
            marginBottom: "0",
            lineHeight: "26px",
          },
        },
        "& .activitylist-ul": {
          padding: "0",
          "& .activity-li": {
            position: "relative",
            background: "transparent",
            padding: "0",
            marginBottom: "32px",
            justifyContent: "space-between",
            display: "flex",
            "& .activity-icon": {
              marginRight: "16px",
              width: "32px",
              height: "32px",
              minWidth: "unset",
            },
            "& .activity-details": {
              margin: "0",
              "& .activity-info": {
                letterSpacing: "0.09px",
                marginBottom: "2px",
                lineHeight: "16px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: "#2B2B2B",
                fontSize: "12px",
                "& .name": {
                  marginRight: "5px",
                  color: "#4BA3B7",
                },
              },
              "& .activity-date": {
                letterSpacing: "0.125px",
                fontWeight: 400,
                lineHeight: "17px",
                fontFamily: "Roboto",
                fontSize: "10px",
                color: "#94A3B8",
              },
            },
            "&:last-child": {
              marginBottom: "0",
              "&:before": {
                display: "none",
              },
            },
            "&:before": {
              left: "16px",
              height: "100%",
              borderRight: "1px solid #E8ECF2",
              position: "absolute",
              top: "100%",
              content: "''",
            },
          },
        },
        "& .tasklist-member": {
          padding: "0",
          "& .tasklist-li": {
            padding: "26px 24px 18px",
            position: "relative",
            justifyContent: "space-between",
            background: "transparent",
            display: "flex",
            borderBottom: "1px solid #ECF1F4",
            cursor: "pointer",
            [theme.breakpoints.down("xs")]: {
              padding: "26px 0 18px",
            },
            "& .tasklist-left": {
              display: "flex",
              alignItems: "center",
              "& .assignee-info": {
                marginLeft: "14px",
              },
              "& .assignee-profile": {
                letterSpacing: "-0.395px",
                lineHeight: "21.679px",
                borderRadius: "100%",
                height: "38px",
                alignItems: "center",
                fontFamily: "Roboto",
                justifyContent: "center",
                background: "#ECF1F4",
                display: "flex",
                color: "rgba(43, 43, 43, 0.87)",
                fontSize: "15.485px",
                fontWeight: 600,
                minWidth: "38px",
                textTransform: "capitalize",
              },
              "& .assignee-name": {
                marginBottom: "2px",
                lineHeight: "19px",
                fontWeight: 500,
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                color: "#2b2b2b",
                textTransform: "capitalize",
              },
              "& .assignee-email": {
                fontSize: "12px",
                lineHeight: "16px",
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
              },
            },
            "& .tasklist-icon": {
              minWidth: "unset",
              marginLeft: "10px",
            },
            "& .tasklist-hours": {
              textAlign: "right",
              color: "#2B2B2B",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 600,
              lineHeight: "16px",
              letterSpacing: "0.09px",
              "& .hours": {
                display: "block",
                color: "#94A3B8",
                fontWeight: 400,
                marginTop: "3px",
              },
            },
          },
          "& .tasklist-total": {
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            padding: "16px 24px",
            borderBottom: "1px solid #ECF1F4",
            position: "static",
            opacity: "0.67",
            [theme.breakpoints.down("xs")]: {
              width: "auto",
              margin: "0 -24px",
            },
            "& .total-member": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "19px",
            },
            "& .select-control": {
              "&.sortby-control": {
                "& .MuiSelect-select": {
                  paddingRight: "26px",
                },
                "& .MuiSelect-icon": {
                  fontSize: "20px",
                  width: "20px",
                  height: "20px",
                  color: "#2B2B2B",
                  top: "calc(50% - 11px)",
                },
              },
              "& .MuiSelect-select": {
                color: "#2b2b2b",
                opacity: "1",
                fontSize: "12px",
                letterSpacing: "0",
              },
            },
          },
        },
        "& .template-list": {
          padding: "0",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "0",
            paddingRight: "0",
            position: "static",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#225980",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-adornedStart": {
                paddingLeft: "16px",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "17px 16px 16px",
                "&::-webkit-input-placeholder": {
                  color: "#94A3B8",
                },
                "&:-ms-input-placeholder": {
                  color: "#94A3B8",
                },
                "&::placeholder": {
                  color: "#94A3B8",
                },
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "7px",
                },
              },
            },
          },
          "& .multiselect-membres": {
            display: "flex",
            alignItems: "center",
            width: "auto",
            maxWidth: "unset",
            justifyContent: "space-between",
            padding: "16px 0",
            margin: "0",
            borderBottom: "1px solid #ECF1F4",
            [theme.breakpoints.down("xs")]: {
              padding: "15px 0",
              margin: "0",
            },
            "& .total-member": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "19px",
              opacity: "0.67",
            },
            "& .select-control": {
              opacity: "0.67",
              "& .MuiSelect-select": {
                color: "#2b2b2b",
                opacity: "1",
                fontSize: "12px",
                letterSpacing: "0",
                paddingRight: "26px",
              },
              "& .MuiSelect-icon": {
                top: "calc(50% - 11px)",
                color: "#2b2b2b",
                fontSize: "20px",
              },
            },
          },
          "& .template-li": {
            display: "flex",
            justifyContent: "space-between",
            background: "transparent",
            position: "relative",
            padding: "21px 0",
            borderBottom: "1px solid #ECF1F4",
            cursor: "pointer",
            "&.Mui-selected": {
              backgroundColor: "#F2F8FC",
              "&:hover": {
                backgroundColor: "#F2F8FC",
              },
            },
            "& .template-left": {
              "& .name": {
                fontSize: "14px",
                lineHeight: "19px",
                color: "#2b2b2b",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
                marginBottom: "4px",
              },
              "& .sub-txt": {
                fontSize: "12px",
                lineHeight: "16px",
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
                letterSpacing: "0",
                marginBottom: "0",
              },
            },
            "& .template-icon": {
              minWidth: "unset",
              marginLeft: "10px",            
              "& .MuiCheckbox-root": {
                padding: "0",
              },
              "& .MuiIconButton-colorSecondary": {
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },
            },
          },
        },
        "& .move-tasklist-list": {
          padding: "0",
          "& .search-wrapper":{
            position:"relative",
            "& .filter-image":{
             cursor:"pointer",
            },
          "& .search-box":{
            width:"85%",
            "& .MuiOutlinedInput-root":{
              borderRadius: "12px",
              marginLeft: "-4px",
            },
            "& .MuiOutlinedInput-input":{
              marginLeft: "6px",
            },
          },
          "& .sort-by":{
            position: 'absolute',
            right: 0,
            top: '0px',
            borderRadius: '12px',
            border: '1px solid var(--Stroke, #E8ECF2)',
            padding: '16px',
          }
          },
          "& .line":{
            borderBottom: "1px solid #E8ECF2",
            marginTop: "20px",
            width:"100%"
          },
          "& .multiselect-membres": {
            width: "auto",
            justifyContent: "space-between",
            border: "0",
            alignItems: "center",
            maxWidth: "unset",
            display: "flex",
            padding: "0",
            margin: "0 0 4px",
            "& .total-member": {
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "0.09px",
            },
            "& .select-control": {
              "& .MuiSelect-select": {
                color: "#2b2b2b",
                opacity: "1",
                fontSize: "12px",
                letterSpacing: "0",
                paddingRight: "26px",
              },
              "& .MuiSelect-icon": {
                top: "calc(50% - 11px)",
                color: "#2b2b2b",
                fontSize: "20px",
              },
            },
          },
          "& .template-li": {
            display: "flex",
            justifyContent: "space-between",
            background: "transparent",
            position: "relative",
            padding: "20px 0",
            borderBottom: "1px solid #ECF1F4",
            cursor: "pointer",
            "& .template-left": {
              "& .sub-txt": {
                fontWeight: 500,
                marginBottom: "0",
                lineHeight: "16px",
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
                letterSpacing: "0",
                fontSize: "12px",
              },
              "& .name": {
                fontFamily: "Expletus Sans",
                marginBottom: "4px",
                lineHeight: "19px",
                color: "#2b2b2b",
                fontSize: "14px",
                fontWeight: 500,
              },             
              "& .line":{
                borderBottom: "1px solid #E8ECF2",
                marginTop: "20px",
              },
              "& .deal-name": {
                fontFamily: "Expletus Sans",
                marginBottom: "4px",
                marginTop: "6px",
                lineHeight: "19px",
                color: "#2b2b2b",
                fontSize: "14px",
                fontWeight: 600,
              },
              "& .deal-owner":{
                color: "#94A3B8",
                fontFamily:"Roboto",
                fontSize: "10px",
                textTransform: "uppercase",
                fontWeight: 500,
                textAlign: "start",
                paddingRight:"10px",
                paddingTop:"15px"
              },
              "& .deal-owner-name":{
                color: "#2B2B2BDE",
                fontFamily:"Roboto",
                fontSize: "12px",
                fontWeight: 400,
                textAlign: "start",
                paddingRight:"10px",
                paddingTop:"8px",
              },
              "& .deal-container":{
                display:"flex",
                alignItems:"center",
                "& .status-item": {
                  display: "flex",
                  alignItems:"center",
                  "& .status-name": {
                    marginRight: "10px",
                    textTransform: "capitalize",
                    fontFamily: "Roboto",
                  marginBottom: "4px",
                  lineHeight: "19px",
                  color: "#2b2b2b",
                  fontSize: "10px",
                  fontWeight: 400,
                  },
                  "& .status": {
                    width: "10px",
                    height: "10px",
                    background: "#94A3B8",
                    borderRadius: "100%",
                    marginLeft: "auto",
                    marginBottom:"4px",
                    "&.open": {
                      background: "#4BA3B7",
                    },
                    "&.cancelled": {
                      background: "#F7E223",
                    },
                    "&.sky-blue": {
                      background: "#12AEF1",
                    },
                    "&.orange": {
                      background: "#F6A318",
                    },
                    "&.yellow": {
                      background: "#F7E223",
                    },
                    "&.black": {
                      background: "#2B2B2B",
                    },
                    "&.green": {
                      background: "#00BA88",
                    },
                  },
                },
                "& .deal-close-date":{
                  fontFamily: "Roboto",
                  marginBottom: "4px",
                  lineHeight: "19px",
                  color: "#2b2b2b",
                  fontSize: "10px",
                  fontWeight: 400,
                  "&.content-dots": {
                    color: "#797878",
                    marginLeft:"12px",
                    "&:before": {
                      content: '""',
                      width: "8px",
                      height: "8px",
                      borderRadius: "100%",
                      background: "#ECF1F4",
                      display: "inline-block",
                      marginRight: "8px",
                    },
                  }, 
                },
              },
             
            },
            "& .template-icon-deal": {
              marginLeft: "10px",
              minWidth: "unset",
              display:"flex",
              alignItems:"center",
              position: "absolute",
              right: 0,
              "& .price-text":{
                fontFamily: "Expletus Sans",
                marginRight: "4px",
                lineHeight: "19px",
                color: "#2b2b2b",
                fontSize: "14px",
                fontWeight: 600,
               },
              "& .MuiCheckbox-root": {
                padding: "0",
              },
              "& .MuiIconButton-colorSecondary": {
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },
            },           
            "& .template-icon": {
              marginLeft: "10px",
              minWidth: "unset",              
              "& .MuiCheckbox-root": {
                padding: "0",
              },
              "& .MuiIconButton-colorSecondary": {
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },
            },
          },
          "& .MuiListSubheader-gutters": {
            position: "static",
            paddingRight: "0",
            paddingLeft: "0",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiOutlinedInput-input": {
                padding: "17px 16px 16px",
                letterSpacing: "0",
                lineHeight: "17px",
                fontSize: "13px",
                "&:-ms-input-placeholder": {
                  color: "#94A3B8",
                },
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "7px",
                },
                "&::-webkit-input-placeholder": {
                  color: "#94A3B8",
                },
                "&::placeholder": {
                  color: "#94A3B8",
                },
              },
              "& .MuiOutlinedInput-adornedStart": {
                paddingLeft: "16px",
              },
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                  fill: "#225980",
                },
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
              },
            },
          },
        },
        "& .assignee-multiSelect-list": {
          padding: "0",          
          "&.selected-list": {
            "& .MuiListItem-root": {
              background: "#F2F8FC",
            },
          },
          "& .MuiListSubheader-gutters": {
            position: "static",
            paddingRight: "0",
            paddingLeft: "0",
            "& .form-control": {
              marginBottom: "12px",
              "& .MuiOutlinedInput-input": {
                padding: "16px",
                lineHeight: "17px",
                letterSpacing: "0",
                fontSize: "13px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
              "& .MuiInputAdornment-positionStart": {
                marginRight: "16px",
              },
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                  fill: "#225980",
                },
              },
            },
            "&.multiselect-subheader": {
              color: "#2B2B2B",
              marginBottom: "0",
              borderBottom: "1px solid #ECF1F4",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              lineHeight: "19px",
              display: "flex",
              padding: "19px 24px",
              [theme.breakpoints.down("xs")]: {
                margin: "0 -24px",
                width: "auto",
              },
              "& .tasklist-link": {
                textDecoration: "none",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "19px",
                whiteSpace: "nowrap",
                marginLeft: "10px",
                color: "#C7263E",
                fontFamily: "Expletus Sans",
              },
            },
            "&.multiselect-subtxt": {
              borderBottom: "1px solid #ECF1F4",
              textTransform: "uppercase",
              fontSize: "10px",
              fontWeight: 400,
              lineHeight: "normal",
              fontFamily: "Roboto",
              padding: "10px 20px",
              color: "#94A3B8",
              [theme.breakpoints.down("xs")]: {
                margin: "0 -24px",
                width: "auto",
              },
            },
          },
          "& .multiselect-membres": {
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            padding: "16px 24px",
            borderBottom: "1px solid #ECF1F4",
            margin: "0",
            [theme.breakpoints.down("xs")]: {
              width: "auto",
              margin: "0 -24px",
            },
            "& .total-member": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "19px",
            },
            "& .select-control": {
              "&.sortby-control": {
                "& .MuiSelect-select": {
                  paddingRight: "26px",
                },
                "& .MuiSelect-icon": {
                  top: "calc(50% - 11px)",
                  color: "#2b2b2b",
                  fontSize: "20px",
                },
              },
              "& .MuiSelect-select": {
                color: "#2b2b2b",
                opacity: "1",
                fontSize: "12px",
                letterSpacing: "0",
              },
            },
          },
          "& .MuiListItem-root": {
            borderBottom: "1px solid #ECF1F4",
            cursor: "pointer",
            padding: "8px 24px 6px",
            [theme.breakpoints.down("xs")]: {
              margin: "0 -24px",
              width: "auto",
            },
          },
          "& .assignee-detail": {
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            display: "flex",
            "& .assignee-left": {
              display: "flex",
              flex: "1",
              "& .assignee-info": {
                marginLeft: "10px",
              },
              "& .assignee-email": {
                fontWeight: 500,
                lineHeight: "16px",
                fontFamily: "Expletus Sans",
                color: "#94A3B8",
                fontSize: "12px",
                marginBottom:"6px",
              },
              "& .assignee-name": {
                marginBottom: "2px",
                lineHeight: "19px",
                fontWeight: 500,
                color: "#2b2b2b",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                textTransform: "capitalize",
              },
              "& .assignee-profile": {
                letterSpacing: "-0.395px",
                fontWeight: 600,
                display: "flex",
                color: "rgba(43, 43, 43, 0.87)",
                justifyContent: "center",
                background: "#ECF1F4",
                alignItems: "center",
                borderRadius: "100%",
                fontFamily: "Roboto",
                height: "39px",
                fontSize: "15.485px",
                lineHeight: "21.679px",
                minWidth: "39px",
                textTransform: "uppercase",
              },
              "& .chip-wrapper": {
                marginTop: "3px",
                "& .MuiChip-root": {
                  height: "24px",
                  marginBottom: "2px",
                },
              },
            },
            "& .assignee-right": {
              marginLeft: "10px",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              "& .hours-details": {
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 600,
                lineHeight: "16px",
                letterSpacing: "0.09px",
                marginRight: "16px",
                textAlign: "right",
                [theme.breakpoints.down("md")]: {
                  marginRight: "8px",
                },
                "& .gray-txt": {
                  color: "#94A3B8",
                  fontWeight: 400,
                  marginTop: "4px",
                },
              },
              "& .assinee-selected": {
                width: "20px",
                height: "20px",
                "& .normal-img": {
                  display: "none",
                },
                "& .selected-img": {
                  display: "block",
                },
              },
              "& .assinee-unselected": {
                width: "20px",
                height: "20px",
                "& .normal-img": {
                  display: "block",
                },
                "& .selected-img": {
                  display: "none",
                },
              },
            },
          },
        },
        "& .multiselect-membres": {
          justifyContent: "space-between",
          margin: "0 -40px",
          alignItems: "center",
          width: "auto",
          maxWidth: "unset",
          display: "flex",
          padding: "15px 60px",
          borderBottom: "1px solid #ECF1F4",
          [theme.breakpoints.down("xs")]: {
            margin: "0",
            padding: "15px 0",
          },
          "& .select-control": {
            "& .down-arrow": {
              marginLeft: "6px",
              fontSize: "20px",
            },
            "& .MuiSelect-select": {
              paddingRight: "0",
              letterSpacing: "0",
              opacity: "1",
              fontSize: "12px",
              color: "#2b2b2b",
            },
          },
          "& .total-member": {
            fontWeight: 500,
            lineHeight: "19px",
            fontFamily: "Expletus Sans",
            fontSize: "12px",
            color: "#2B2B2B",
          },
        },
        "& .project-create-wrapper": {
          textAlign: "center",
          marginBottom: "75px",
          [theme.breakpoints.down("xs")]: {
            marginBottom: "35px",
          },
          "& .project-img": {
            marginBottom: "40px",
            [theme.breakpoints.down("xs")]: {
              maxWidth: "289px",
              margin: "0 auto 32px",
            },
          },
          "& .modal-title": {
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Expletus Sans",
            fontSize: "24px",
            lineHeight: "28px",
            fontWeight: 400,
            marginBottom: "7px",
            [theme.breakpoints.down("md")]: {
              fontSize: "22px",
              lineHeight: "26px",
            },
            [theme.breakpoints.down("xs")]: {
              fontSize: "18px",
              lineHeight: "24px",
              marginBottom: "12px",
            },
          },
          "& .sub-txt": {
            letterSpacing: "0.25px",
            [theme.breakpoints.down("xs")]: {
              color: "#7A7A7A",
              lineHeight: "19px",
              letterSpacing: "0.42px",
            },
          },
        },
        "& .no-task-block": {
          textAlign: "center",
          padding: "31px 0 95px",
          [theme.breakpoints.down("xs")]: {
            padding: "15px 0 18px",
          },
          "& .task-img": {
            marginBottom: "16px",
          },
          "& .heading": {
            color: "#2B2B2B",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            marginBottom: "8px",
          },
          "& .sub-txt": {
            color: "#94A3B8",
            letterSpacing: "0.105px",
            marginBottom: "0",
          },
          "& .button-wrapper": {
            marginTop: "32px",
            "& .MuiButton-root": {
              maxWidth: "327px",
              color: "#4BA3B7",
              borderColor: "#4BA3B7",
              minHeight: "48px",
              "&:hover": {
                color: "#4BA3B7",
                borderColor: "#4BA3B7",
              },
            },
          },
        },
        "& .tasklist-task-wrap": {
          marginBottom: "16px",
          "&:first-child": {
            "& .task-heading": {
              paddingTop: "0",
            },
          },
          "&:last-child": {
            marginBottom: "0",
          },
          "& .task-heading": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px 0",
            "& .heading": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
            },
            "& .total-task": {
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "0.09px",
            },
          },
          "& .tasklist-task-ul": {
            padding: "0",
            width: "100%",
            "& .tasklist-task-ul": {
              paddingLeft: "32px",
            },
            "& .tasklist-task-li": {
              alignItems: "flex-start",
              background: "transparent",
              flexDirection: "column",
              padding: "0",
            },
            "& .tasklist-inner": {
              display: "flex",
              alignItems: "flex-start",
              width: "100%",
              cursor: "pointer",
              padding: "16px 0",
              borderBottom: "1px solid #E8ECF2",
            },
            "& .tasklist-icon": {
              minWidth: "unset",
              marginRight: "16px",
              "& .MuiIconButton-root": {
                padding: "0",
              },
            },
            "& .tasklist-label": {
              margin: "0",
              flex: "1",
            },
          },
          "& .task-title": {
            display: "flex",
            justifyContent: "space-between",
            "& .title-dash": {
              color: "#2B2B2B",
              fontSize: "14px",
              lineHeight: "19px",
              fontFamily: "Expletus Sans",
              fontWeight: 400,  
              textDecoration: "line-through"           
            },
            "& .title": {
              color: "#2B2B2B",
              fontSize: "14px",
              lineHeight: "19px",
              fontFamily: "Expletus Sans",
              fontWeight: 400,
              "& .priority-status": {
                color: "#C7263E",
                marginRight: "3px",
              },
            },
            "& .title-right": {
              marginLeft: "10px",
              "& .controller-link": {
                width: "22px",
                cursor:"pointer",
              },
            },
          },
          "& .task-desc": {
            display: "flex",
            justifyContent: "space-between",
            color: "#94A3B8",
            fontSize: "12px",
            lineHeight: "16px",
            fontFamily: "Roboto",
            fontWeight: 400,
            letterSpacing: "0.09px",
            marginTop: "3px",
            "& .desc-left": {
              marginRight: "10px",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            },
            "& .task-about": {
              paddingLeft: "14px",
              marginLeft: "8px",
              position: "relative",
              "&:before": {
                content: '""',
                width: "6px",
                height: "6px",
                borderRadius: "100%",
                background: "#E8ECF2",
                display: "inline-block",
                position: "absolute",
                left: "0",
                top: "5px",
              },
            },
            "& .timing": {
              color: "#2B2B2B",
              fontFamily: "Roboto",
              fontSize: "10px",
              fontWeight: 400,
              lineHeight: "17px",
              letterSpacing: "0.125px",
            },
          },
        },
        "& .budget-wrapper": {
          "& .budget-main": {
            "& .MuiSlider-root": {
              height: 23,
              borderRadius: "24px",
              padding: "0",
              "& .MuiSlider-rail": {
                background: "#00BA88",
                opacity: "0.17",
                height: "inherit",
                borderRadius: "inherit",
              },
              "& .MuiSlider-track": {
                border: "none",
                background: "#00BA88",
                height: "inherit",
                borderRadius: "inherit",
              },
              "& .MuiSlider-thumb": {
                border: "0",
                height: "23px",
                marginTop: "0",
                borderRadius: "0",
                backgroundColor: "transparent",
                "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                  boxShadow: "inherit",
                },
                "&:before": {
                  display: "none",
                },
              },
              "& .MuiSlider-valueLabel": {
                // display: "none",
              },
            },
            "& .slider-wrap": {
              borderRadius: "12px",
              border: "1px solid #E8ECF2",
              padding: "15px 16px",
              marginBottom: "24px",
              "&.overflow-count": {
                "& .legends-dots": {
                  background: "#F5DADE",
                  "&.actual": {
                    background: "#C7263E",
                  },
                },
                "& .progress-wrap": {
                  background: "#C7263E",
                  "& .progress-inner": {
                    background: "#F5DADE",
                  },
                  "& .progress-label": {
                    color: "#2B2B2B",
                  },
                },
              },
              "& .slider-heading": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "13px",
              },
              "& .slider-label": {
                color: "#94A3B8",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "19px",
                fontFamily: "Expletus Sans",
              },
              "& .legends-wrapper": {
                display: "flex",
                alignItems: "center",
                color: "#2B2B2B",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "16px",
                letterSpacing: "0.09px",
              },
              "& .slider-legends": {
                marginRight: "8px",
                display: "flex",
                alignItems: "center",
                "&:last-child": {
                  marginRight: "0",
                },
              },
              "& .legends-dots": {
                width: "11px",
                height: "11px",
                background: "#D4F3EB",
                borderRadius: "100%",
                marginLeft: "8px",
                "&.actual": {
                  background: "#00BA88",
                },
              },
              "& .slider-inner": {
                display: "flex",
                alignItems: "center",
                "& .max-value": {
                  marginLeft: "12px",
                  color: "#2B2B2B",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  letterSpacing: "0.09px",
                },
                "& .MuiSlider-valueLabel": {
                  transform: "none",
                  transition: "none",
                  top: "0",
                  bottom: "0",
                  "& > span": {
                    width: "auto",
                    height: "23px",
                    transform: "none",
                    borderRadius: "0",
                    background: "transparent",
                    "& > span": {
                      color: "#2B2B2B",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      letterSpacing: "0.09px",
                      transform: "none",
                    },
                  },
                },
              },
            },
            "& .progress-wrap": {
              width: "100%",
              height: "22px",
              background: "rgba(0, 186, 136, 0.17)",
              borderRadius: "24px",
              position: "relative",
              "& .progress-inner": {
                background: "#00BA88",
                height: "inherit",
                borderRadius: "inherit",
                maxWidth: "100%",
                borderRight: "3px solid #fff",
              },
              "& .progress-label": {
                textAlign: "center",
                color: "#fff",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "23px",
                letterSpacing: "0.09px",
                height: "inherit",
                maxWidth: "100%",
                position: "absolute",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
              },
            },
            "& .accordion-block": {
              boxShadow: "none",
              borderRadius: "12px",
              border: "1px solid #E8ECF2",
              minHeight: "unset",
              padding: "16px 15px",
              "&:before": {
                display: "none",
              },
              "& .accordion-heading": {
                minHeight: "unset",
                padding: "0",
                alignItems: "flex-start",
                "& .heading": {
                  width: "100%",
                  color: "#2B2B2B",
                  fontFamily: "Expletus Sans",
                  fontSize: "14px",
                  lineHeight: "19px",
                  fontWeight: 500,
                  marginBottom: "6px",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 400,
                    marginBottom: "8px",
                  },
                },
                "& .sub-txt": {
                  marginBottom: "0",
                  color: "#94A3B8",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  letterSpacing: "0.09px",
                },
                "& .MuiAccordionSummary-content": {
                  margin: "0",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                },
                "& .MuiIconButton-root": {
                  padding: "0",
                  margin: "0",
                  "& .MuiSvgIcon-root": {
                    fontSize: "20px",
                    color: "#94A3B8",
                  },
                  "& .MuiTouchRipple-root": {
                    display: "none",
                  },
                },
              },
              "& .accordion-details": {
                padding: "0",
                marginTop: "24px",
                "& table": {
                  "& tr": {
                    "& td": {
                      "&.MuiTableCell-root": {
                        color: "#2B2B2B",
                        fontFamily: "Roboto",
                        fontSize: "13px",
                        fontWeight: 400,
                        lineHeight: "24px",
                        padding: "6px 8px 24px",
                        border: "0",
                        "&:first-child": {
                          paddingLeft: "0",
                          width: "43.5%",
                        },
                        "&:nth-child(2)": {
                          textAlign: "center",
                        },
                        "&:last-child": {
                          paddingRight: "0",
                          textAlign: "right",
                        },
                      },
                      "& .open-icon": {
                        display: "inline-block",
                        marginLeft: "3px",
                        position: "relative",
                        top: "2px",
                        "& .MuiSvgIcon-root": {
                          color: "#94A3B8",
                          fontSize: "14px",
                        },
                      },
                    },
                    "& th": {
                      border: "0",
                    },
                    "& .table-heading": {
                      color: "#94A3B8",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: "16px",
                      letterSpacing: "0.09px",
                      padding: "0",
                      "&.last": {
                        padding: "15px 0 0",
                      },
                    },
                    "&.last-row": {
                      "& td": {
                        "&.MuiTableCell-root": {
                          padding: "24px 0 0",
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          "& .budget-footer": {
            borderTop: "1px dashed rgba(148, 163, 184, 0.37)",
            paddingTop: "20px",
            marginTop: "25px",
            textAlign: "right",
            color: "#2B2B2B",
            fontFamily: "Roboto",
            fontSize: "16px",
            lineHeight: "22px",
            fontWeight: 400,
            "& .price": {
              marginLeft: "8px",
            },
          },
        },
        "& .button-wrapper": {
          marginTop: "48px",
          [theme.breakpoints.down("xs")]: {
            marginTop: "32px",
          },
          "&.full-btn": {
            "& .MuiButton-root": {
              width: "100%",
            },
          },
        },
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "24px",
        color: "#94A3B8",
        letterSpacing: "0.105px",
        fontWeight: 400,
        fontFamily: "Roboto",
        marginTop: "0",
        marginBottom: "24px",
        [theme.breakpoints.down("xs")]: {
          color: "rgba(43, 43, 43, 0.67)",
          fontSize: "12px",
          lineHeight: "17px",
        },
      },
      "& .close-icon": {
        color: "rgba(43, 43, 43, 0.87)",
        lineHeight: "0",
      },
      "& .form-info-wrapper": {
        margin: "0 0 28px",
        "&:last-child": {
          margin: "0",
        },
        "& .select-modal-field": {
          "& .MuiInputAdornment-positionEnd": {
            "& .MuiSvgIcon-root": {
              fontSize: "20px",
              color: "#94A3B8",
            },
          },
        },
        "& .form-row": {
          display: "flex",
          flexWrap: "wrap",
          margin: "0 -8px",
          "& .form-col": {
            padding: "0 8px",
            flex: "0 0 100%",
            maxWidth: "100%",
            "&.col6": {
              flex: "0 0 50%",
              maxWidth: "50%",
            },
            "& .form-control": {
              marginBottom: "24px",
            },
          },
        },
      },
      "& .profile-team": {
        "& .team-label": {
          fontSize: "10px",
          fontFamily: "Roboto",
          lineHeight: "12px",
          textTransform: "uppercase",
          color: "#94A3B8",
          marginBottom: "8px",
          fontWeight: 400,
        },
      },
      "& .bottom-link": {
        "& .modal-link": {
          marginLeft: "3px",
        },
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        textAlign: "center",
        color: "#2B2B2B",
      },
      "& .modal-form": {
        marginTop: "0",
      },
      "& .current-team": {
        color: "#94A3B8",
        padding: "17px 16px",
        fontSize: "14px",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        border: "1px solid #e8ecf2",
        borderRadius: "6px",
      },
      "& .form-heading": {
        letterSpacing: "0",
        lineHeight: "18px",
        color: "#2B2B2B",
        fontFamily: "Roboto",
        fontSize: "16px",
        margin: "0 0 16px",
        fontWeight: 500,
      },
      "& .select-control": {
        "&.multi-select": {
          "&.full-outlined": {
            width: "100%",
            marginBottom: "24px",
            "& .multiselect-label": {
              display: "block",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "18px",
              color: "#94A3B8",
              fontFamily: "Roboto",
              letterSpacing: "0.105px",
            },
            "& .MuiSelect-select": {
              fontFamily: "Roboto",
              fontWeight: 400,
              color: "#2B2B2B",
              padding: "0 40px 0 16px",
              lineHeight: "20px",
              letterSpacing: "0.105px",
              minHeight: "56px",
              "& .assignee-list": {
                marginLeft: "0",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e8ecf2",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px",
                },
              },
            },
            "& .MuiInputLabel-outlined.Mui-focused, & .MuiFormLabel-filled": {
              fontSize: "12px",
              lineHeight: "16px",
              color: "rgba(43,43,43,0.87)",
              width: "auto",
              "& ~.MuiOutlinedInput-root": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#e8ecf2",
                },
              },
              "&.Mui-error": {
                color: "rgba(43,43,43,0.87)",
              },
            },
            "& .multiple-clear": {
              position: "absolute",
              fontSize: "15px",
              zIndex: 1,
              color: "#94A3B8",
              top: "50%",
              right: "27px",
              transform: "translateY(-50%)",
            },
          },
          "& .multiple-clear": {
            position: "absolute",
            fontSize: "15px",
            right: "18px",
            top: "3px",
            zIndex: 1,
            color: "#94A3B8",
          },
          "& .MuiInput-formControl": {
            marginTop: "0",
          },
          "& .MuiSelect-select": {
            alignItems: "center",
            display: "flex",
            paddingRight: "40px",
            "& .select-icon": {
              marginRight: "6px",
            },
            "& .assignee-list": {
              marginLeft: "6px",
              alignItems: "center",
              display: "flex",
              "& .assignee-bubble": {
                letterSpacing: "-0.244px",
                fontSize: "9.586px",
                fontWeight: 600,
                border: "2px solid #FFF",
                height: "24px",
                display: "flex",
                color: "rgba(43, 43, 43, 0.87)",
                background: "#ECF1F4",
                alignItems: "center",
                fontFamily: "Roboto",
                position: "relative",
                lineHeight: "13.42px",
                marginLeft: "-4px",
                borderRadius: "100%",
                width: "24px",
                justifyContent: "center",
                "&:first-child": {
                  marginLeft: 0,
                },
              },
            },
          },
          "& .multiselect-label": {
            display: "none",
          },
        },
        "&.status-control": {         
          "&.completed": {
            "& .MuiSelect-select": {
              background: "rgba(148, 163, 184, 0.07)",
              color: "#94A3B8",
            },
            "& .MuiSelect-icon": {
              color: "#94A3B8",
            },
          },
          "&.cancelled": {
            "& .MuiSelect-select": {
              background: "rgba(247, 226, 35, 0.07)",
              color: "#F7E223",
            },
            "& .MuiSelect-icon": {
              color: "#F7E223",
            },
          },
          "& .on-track": {
            "& .MuiSelect-select": {
              background: "rgba(0, 186, 136, 0.07)",
              color: "#00BA88",
            },
            "& .MuiSelect-icon": {
              color: "#00BA88",
            },
          },
          "& .MuiSelect-icon": {
            width: "16px",
            height: "16px",
            right: "12px",
            color: "#12AEF1",
            top: "calc(50% - 8px)",
          },
          "& .MuiSelect-select": {
            // color: "#12AEF1",
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: "400",
            lineHeight: "16px",
            letterSpacing: "0.09px",
            opacity: "1",
            borderRadius: "12px",
            // background: "rgba(18, 174, 241, 0.07)",
            padding: "6px 32px 6px 12px",
            marginRight:"-34px",
          },
         "& .in-progress-status" :{
          "& .MuiSelect-select": {
            color: "#F6A318",
            backgroundColor: "#F6A31812"
          },
          "& .MuiSelect-icon": {
            color: "#F6A318",
          },            
        },
        "& .open-status" :{
          "& .MuiSelect-select": {
            color: "#12AEF1",
            background: "rgba(18, 174, 241, 0.07)",
          },
          "& .MuiSelect-icon": {
            color: "#12AEF1",
          },            
        },
         "& .completed-status" :{
          "& .MuiSelect-select": {
            color: "rgb(43 43 43 / 87%)",
            backgroundColor: "#3b3a3812"
          },
          "& .MuiSelect-icon": {
            color: "rgb(43 43 43 / 87%)",
          },            
        },
        "& .off-track-status" :{
          "& .MuiSelect-select": {
            color: "#C7263E",
            backgroundColor: "#F4EBEC"
          },
          "& .MuiSelect-icon": {
            color: "#C7263E",
          },            
        }, 
        "& .completed-project-status":{
          "& .MuiSelect-select": {
            color: "rgba(43, 43, 43, 0.87)",
            backgroundColor: "#3b3a3812"
          },
          "& .MuiSelect-icon": {
            color: "rgba(43, 43, 43, 0.87)",
          },  
        },   
        "& .cancelled-status" :{
          "& .MuiSelect-select": {
            color: "#F7E223",
            backgroundColor: "#FDFADB"
          },
          "& .MuiSelect-icon": {
            color: "#F7E223",
          },            
        },        
        },
        "& .MuiInput-underline:after": {
          display: "none",
        },
        "& .MuiSelect-select": {
          fontFamily: "Expletus Sans",
          paddingTop: "0",
          paddingBottom: "0",
          color: "rgba(43, 43, 43, 0.87)",
          opacity: "0.671",
          fontWeight: "500",
          letterSpacing: "0.1px",
          fontSize: "14px",
          "& .clear-icon": {
            width: "50px",
            height: "50px",
            background: "red",
          },
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
        "& .MuiInput-underline:before": {
          display: "none",
        },
        "& .select-icon": {
          marginRight: "6px",
          position: "relative",
        },
      },
      "& .primary-btn": {
        width: "100%",
        marginBottom: "17px",
        lineHeight: "19px",
        fontSize: "14px",
        minHeight: "56px",
        [theme.breakpoints.down("xs")]: {
          minHeight: "48px",
        },
      },
      "& .modal-footer": {
        marginTop: "auto",
        "& .half-btn": {
          justifyContent: "space-between",
          flexWrap: "wrap",
          display: "flex",
          width: "100%",
          "& .MuiButton-root": {
            marginRight: "10px",
            marginBottom: "0",
            width: "auto",
            "&.secondary-btn": {
              minWidth: "140px",
            },
            "&.primary-btn": {
              padding: "12px 38px",
            },
            "&:last-child": {
              marginRight: "0",
            },
          },
        },
      },
      "& .secondary-btn": {
        marginBottom: "17px",
        lineHeight: "19px",
        fontSize: "14px",
        minHeight: "56px",
        width: "100%",
        [theme.breakpoints.down("xs")]: {
          minHeight: "48px",
        },
      },
    },
    timestandDropbox: {
      zIndex: 1300,
      borderRadius: "12px",
      padding: "24px",
      minWidth: "230px",
      background: "#fff",
      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
      position: "absolute",
      bottom: "100%",
      "& .timestand-inner": {
        "& .timestand-heading": {
          fontSize: "16px",
          lineHeight: "24px",
          color: "#2B2B2B",
          fontWeight: 400,
          fontFamily: "Expletus Sans",
          marginBottom: "16px",
        },
        "& .time": {
          display: "flex",
          alignItems: "center",
          "& .MuiFormControl-root": {
            maxWidth: "33px",
          },
          "& .MuiInputBase-input": {
            fontSize: "14px",
            lineHeight: "18px",
            color: "#2b2b2b",
            fontWeight: 500,
            fontFamily: "Expletus Sans",
            padding: "0",
            "&::-webkit-input-placeholder": {
              opacity: "1",
              color: "rgba(43, 43, 43, 0.87)",
            },
            "&:-ms-input-placeholder": {
              opacity: "1",
              color: "rgba(43, 43, 43, 0.87)",
            },
            "&::placeholder": {
              opacity: "1",
              color: "rgba(43, 43, 43, 0.87)",
            },
          },
          "& .colon-txt": {
            fontSize: "14px",
            lineHeight: "18px",
            color: "#2b2b2b",
            fontWeight: 500,
            fontFamily: "Expletus Sans",
            margin: "0 10px 0 5px",
          },
        },
      },
      "& .timestand-actions": {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingTop: "16px",
        marginTop: "16px",
        borderTop: "1px solid #E8ECF2",
        "& .secondary-link": {
          fontSize: "14px",
          lineHeight: "18px",
          color: "#94A3B8",
          fontWeight: 500,
          fontFamily: "Expletus Sans",
          textDecoration: "none",
          marginRight: "24px",
          cursor: "pointer",
          "&:last-child": {
            marginRight: "0",
          },
        },
        "& .primary-link": {
          color: "#4BA3B7",
          cursor: "pointer",
        },
      },
    },
    modalDialog: {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502)",
      },
    },
    modalFilterDialogBox: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: 680,
      backgroundColor: "#ffffff",
      borderRadius: "0",
      padding: "0",
      height: "100%",
      maxWidth: "100%",
      overflowY: "auto",
      [theme.breakpoints.down("sm")]: {
        width: 570 ,
      },
      [theme.breakpoints.down("xs")]: {
        width: "90% ",
      },
      "& .primary-btn2":{
        marginTop:"45px !important",
        width:"100% !important",
       },
       "& .MuiSwitch-switchBase": {
        color: "#fafafa ",
        [theme.breakpoints.down("xs")]: {
          color: "#ffffff ",
        },
        "&.Mui-checked": {
          color: "#3691a6",
          [theme.breakpoints.down("xs")]: {
            transform: "translateX(12px) ",
          },
          "&+ .MuiSwitch-track": {
            backgroundColor: "#3691a6 ",
          },
        },
      },
      "& .MuiSwitch-thumb": {
        boxShadow: "none",
        filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.2))",
        [theme.breakpoints.down("xs")]: {
          height: "8px",
          width: "8px",
        },
      },
      "& .MuiSwitch-colorSecondary.Mui-checked":{
        color:'#FFF' 
      },
      "&.view-modal-dialog": {       
        "& .view-more-dropdown":{       
          "& .dropdown-menu-item":{
            display: 'flex',
            padding: '12px 0px',
            justifyContent: 'space-between',
            alignSelf: 'stretch',
            alignItems: 'center',
          },
          "& .MuiFormControl-root":{
            marginLeft:"20px"
            // width:"280px",
          },
        
          "& .dropdown-menu-item-column":{
            display: 'flex',
            padding: '8px 16px',
            justifyContent: 'space-between',
            alignSelf: 'stretch',
            alignItems: 'center',
          },
          "& .MuiSvgIcon-root":{
            color:"#E8ECF2 "
          },         
          "& .MuiTouchRipple-root": {
            display: "none ",
          },      
          "& .dropdown-menu-item-text":{
            color: '#2B2B2B',
            fontFamily: 'Expletus Sans',
            fontSize: '14px',
            lineHeight: 'normal',
            fontStyle: 'normal',
            fontWeight: 500,
          }
        },              
        "& .modal-content":{  
          padding:"60px", 
          "& .form-row": {
            display: "flex",
            flexWrap: "wrap",
            margin: "0 -8px",
            "& .form-col": {
              padding: "0 8px",
              flex: "0 0 100%",
              maxWidth: "100%",
              "&.col6": {
                flex: "0 0 50%",
                maxWidth: "50%",
                [theme.breakpoints.down("xs")]: {
                  flex: "0 0 100%",
                  maxWidth: "100%",
                },
              },
              "& .form-control": {
                marginBottom: "16px",
              },
              "& .autocomplete-wrapper": {
                marginBottom: "16px",
              },
            },
          },       
          "& .select-outer": {
            width: "100%",
            marginBottom: "16px",
            "& .error-select":{
              color:'#C7263E ',  
            },
            "& svg":{
             fill:'#94A3B8 ',  
           },
            "& .Mui-error":{
              "& .MuiOutlinedInput-notchedOutline":{
                borderColor:'#C7263E',  
              }
            },       
            "& .MuiInputLabel-formControl": {
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              letterSpacing: "0.1px",
              fontWeight: 500,
              lineHeight: "19px",
              width: "calc(100% - 46px)",
              color: "#94A3B8",
              display: "flex",
              "&.MuiFormLabel-filled": {
                width: "auto",
                color : "rgba(43,43,43,0.87)",
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.0075em",
                fontFamily: "Roboto",
                fontWeight: "400",
              }
            },
            "& .MuiSelect-select": {
              letterSpacing: "0",
              color: "#000000",
              fontSize: "14px",
              opacity: "0.671",
              fontWeight: "500",
              justifyContent:'space-between',
              fontFamily: "Expletus Sans",
              display:'flex',
              "&:focus": {
                backgroundColor: "transparent",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e8ecf2 ",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px ",
                },
              },
            },
            "& .MuiFormLabel-root": {
              "&.Mui-focused": {
                color : "rgba(43,43,43,0.87)",
                width: "auto",
                fontSize: "12px",
                letterSpacing: "0.0075em",
                fontWeight: "400",
                lineHeight: "16px",
                fontFamily: "Roboto",
              },
              "& .MuiInputLabel-asterisk": {
                marginLeft: "auto",
                lineHeight: "23px",
                fontSize: "18px",
                color: "#e86577",
              }
            }
          },         
          "& .desc":{
            marginBottom:"60px"
          },
          "& .filter-headding":{
            color: '#2B2B2B',
            fontSize: '16px',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            lineHeight: '24px',
            fontWeight: 500,
            letterSpacing: '0.25px',
            marginBottom:"10px",
            marginTop:"8px",
            textTransform:"capitalize"
          },
          "& .modal-wrapper":{
            justifyContent:"space-between",
            display:"flex",
              alignItems:"center", 
              "& .reset-cross":{
                display:"flex",
                "& .reset-txt":{
                  color: 'var(--brand-color-primary-100, #4BA3B7)',
                  fontSize: '14px',
                  fontFamily: 'Expletus Sans',
                  fontStyle: 'normal',
                  textAlign: 'right',                
                  fontWeight: 500,
                  marginRight:"16px",
                  lineHeight: 'normal',
                  cursor:"pointer",
                },
                "& .close-icon":{
                  cursor:"pointer",
                  "& .MuiSvgIcon-root": {
                    fontSize: "16px",
                    height: "24px",
                    width: "24px",
                    color: "#94A3B8",
                  },
                },
               },            
            "& .modal-headding":{
              fontFamily: 'Expletus Sans',
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: '34px',
              fontWeight: 400,
              fontStyle: 'normal',
              letterSpacing: '0.25px',
              lineHeight: 'normal',
            },
          },
        }         
      },    
    },
    modalFilterDialog: {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502) ",
      },
    },
    secondaryButton: {
      background: "transparent",
      border: "1px solid #e8ecf2",
      borderRadius: "7px",
      filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2b2b2b",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      textTransform: "unset",
      maxWidth: "100%",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      width: "auto",
      minWidth: "94px",
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b",
      },
    },
    dropMenu: {
      "& .dropmenu-btn": {
        letterSpacing: "0",
        background: "#4ba3b7",
        border: "1px solid #4ba3b7",
        borderRadius: "7px",
        filter: "none",
        boxShadow: "none",
        fontSize: "14px",
        lineHeight: "18px",
        color: "#ffffff",
        fontWeight: 500,
        fontFamily: "Expletus Sans",
        textAlign: "center",
        padding: "12px 10px",
        minHeight: "48px",
        marginBottom: "0",
        transition: "all 0.5s ease-in-out",
        textTransform: "capitalize",
        width: "auto",
        minWidth: "156px",
        [theme.breakpoints.down("xs")]: {
          padding: "8px 6px",
          minHeight: "46px",
          minWidth: "unset",
        },
      },
    },
    dropDropdown: {
      "& .MuiMenu-paper": {
        minWidth: "220px",
        backgroundColor: "#ffffff",
        borderRadius: "12px",
        marginTop: "31px",
        boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.22)",
        "& .MuiMenuItem-root": {
          fontSize: "14px",
          lineHeight: "20px",
          letterSpacing: "0",
          color: "#2B2B2B",
          padding: "16px 24px",
          fontWeight: 500,
          fontFamily: "Expletus Sans",
          display: "flex",
        },
        "& .MuiList-padding": {
          paddingTop: "16px",
          paddingBottom: "8px",
        },
      },
    },
    createdropDropdown: {
      "& .MuiMenu-paper": {
        minWidth: "220px",
        backgroundColor: "#ffffff",
        borderRadius: "12px",
        marginTop: "31px",
        "& .MuiList-padding": {
          paddingTop: "16px",
          paddingBottom: "8px",
        },
        "& .MuiMenuItem-root": {
          fontSize: "14px",
          lineHeight: "20px",
          letterSpacing: "0",
          color: "#2B2B2B",
          padding: "16px 24px",
          fontWeight: 500,
          fontFamily: "Expletus Sans",
          display: "flex",
        },
      },
    },
    notificationBox: {
      "&.display-center": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
      "& .MuiButton-label": {
        textTransform: "initial",
        color: "#4BA3B7",
        fontFamily: "Expletus Sans",
      },
      "& .MuiTypography-body1": {
        fontSize: "14px",
        color: "#2B2B2B",
        fontFamily: "Roboto",
        fontWeight: 400,
        lineHeight: "26px",
        letterSpacing: "0.105px",
      },
      "&.btn-mark-as-read": {
        textTransform: "initial",
        color: "#4BA3B7",
        fontSize: "14px",
      },
      "&.title": {
        color: "#000000",
        fontSize: "18px",
        lineHeight: "24px",
        fontWeight: "400",
        fontFamily: "Expletus Sans",
      },
      "&.sub-title": {
        fontSize: "12px",
        marginTop: "6px",
        color: "#2B2B2B",
        fontFamily: "Roboto",
      },
      "&.list": {
        paddingTop: "0px",
        paddingBottom: "0px",
      },
      "&.list-item": {
        padding: "10px 24px 10px 0",
        minHeight: "80px",
      },
      "&. MuiTypography-displayBlock": {
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "26px",
        letterSpacing: "0.0075em",
      },
      "& .MuiPaper-rounded": {
        borderRadius: "0px",
      },
      "&.notify-item-title": {
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "26px",
        letterSpacing: "0.0075em",
        marginRight: "24px",
      },
      "&.notify-item-content": {
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.0075em",
        color: "#94A3B8",
        marginTop: "6px",
      },
      "&.image-box": {
        height: "48px",
        width: "48px",
        minWidth: "48px",
        backgroundColor: "#F2F8FC",
        borderRadius: "24px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "24px",
        marginLeft: "24px",
      },
      "&.image-tasks-list": {
        width: "6px",
        height: "6px",
        marginLeft: "8px",
        marginRight: "8px",
      },
      "&.bg-white": {
        backgroundColor: "#FFFFFF",
      },
      "&.bg-gray": {
        backgroundColor: "#ECF1F4",
        cursor: "pointer",
      },
      "&.divider": {
        backgroundColor: "#000000",
        borderWidth: "1px",
        with: "100%",
      },
      "&.fiber-icon": {
        fontSize: "10px",
        color: "#94A3B8",
        marginLeft: "8px",
        marginRight: "8px",
        marginTop: "6px",
      },
      "&::-webkit-scrollbar": {
        width: 5,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "orange",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "red",
        borderRadius: 2,
      },
    },
    modalBoxCenter: {
      display: "flex",
      // alignItems: "flex-end",
      justifyContent: "flex-end",
    },
    mergeInfoGroupsModal: {
      // height: "100vh",
      width: "550px",
      position: "relative",
      display: "inline-block",
      overflowY: "auto",
      backgroundColor: "#ffffff",
      // padding: "0px 30px",
      "& .default-cart": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& .default-cart-img": {
          marginBottom: "20px",
          width: "320px",
        },
      },
      "& .primary-btn-2": {
        fontSize: "14px",
        width: "75%",
        marginTop: "48px",
        borderRadius: "10px",
        marginBottom: "48px",
      },
      "& .cart-empty-text": {
        fontFamily: "Expletus Sans",
        color: " #2B2B2B",
        fontSize: "26px",
        fontStyle: "normal",
        marginBottom: "8px",
        fontWeight: "500",
        textAlign: "center",
        lineHeight: "26px",
      },
      "& .header-container": {
        display: "flex",
        justifyContent: "space-between",
        padding: "0px 30px",
        "& .modal-title-box": {
          margin: "45px 0px",
          "& .title-txt": {
            color: "var(--brand-color-brand-color-dark, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "22px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "26px",
            marginBottom: "8px",
          },
          "& .sub-title-txt": {
            color: "#505050",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "16px",
            letterSpacing: "0.09px",
          },
        },
        "& .heading-bar": {
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          // margin: "20px 20px 0px 0px",
          justifyContent: "end",
          cursor: "pointer",
          "& .close-icon": {
            height: "24px",
            width: "24px",
            color: "#959595",
            cursor: "pointer",
            marginTop: "-30px",
          },
        },
      },
      "& .headder": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 30px",
        "& .checkboxes": {
          "& .MuiFormControlLabel-root": {
            marginRight: "0",
            marginLeft: "-7px",
            "& .MuiFormControlLabel-label": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              letterSpacing: "0.1px",
            },
          },
          "& .MuiCheckbox-root": {
            color: "#94A3B8",
            padding: "7px",
            "&:hover": {
              backgroundColor: "rgba(54, 145, 166, 0.04)",
            },
            "&.Mui-checked": {
              color: "#4ba3b7",
            },
            "& .MuiSvgIcon-root": {
              width: "20px",
              height: "20px",
            },
          },
        },
        "& .remove-txt": {
          color: "#4BA3B7",
          fontFamily: "Expletus Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          marginRight: "24px",
          cursor: "pointer",
          whiteSpace: "nowrap",
          textTransform: "capitalize",
        },
      },
      "& .card-container-flex": {
        // display:"flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 40px",
        "& .card-container": {
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          "& .cart-img": {
            width: "78px",
            height: "78px",
          },
          "& .checkboxes": {
            marginRight: "10px",
            "& .MuiFormControlLabel-root": {
              marginRight: "0",
              marginLeft: "-7px",
              "& .MuiFormControlLabel-label": {
                color: "#2B2B2B",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
                letterSpacing: "0.1px",
              },
            },
            "& .MuiCheckbox-root": {
              color: "#94A3B8",
              padding: "7px",
              "&:hover": {
                backgroundColor: "rgba(54, 145, 166, 0.04)",
              },
              "&.Mui-checked": {
                color: "#4ba3b7",
              },
              "& .MuiSvgIcon-root": {
                width: "20px",
                height: "20px",
              },
            },
          },
          "& .card-content": {
            marginLeft: "16px",
            "& .cart-heading": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
            },
            "& .cart-sub-heading": {
              width: "180px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow:"ellipsis",
              color: "#2B2B2B",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              marginTop: "8px",
            },
            "& .price-container": {
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
              "& .cart-price-dash-text": {
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
                marginTop: "10px",
                marginLeft: "10px",
                textDecoration: "line-through",
              },
              "& .cart-price-text": {
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
                marginTop: "10px",
                marginLeft: "10px",
                textTransform: "capitalize",
              },
            },
            "& .cart-price": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              marginTop: "10px",
            },
          },
        },
        "& .card-quantity-container": {
          display: "flex",
          position: "absolute",
          right: "50px",
          alignItems: "center",
          marginTop: "0",
          "& .form-control": {
            marginBottom: "0",
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
            },
            "& .MuiInputAdornment-root": {
              "& .MuiSvgIcon-root": {
                fontSize: "20px",
                fill: "#94A3B8",
              },
            },
            "& .MuiOutlinedInput-input": {
              width: "32px",
              height: "8px",
              fontSize: "12px",
              borderRadius: "20px",
              fontFamily: "Roboto",
              color: "#333333",
              fontWeight: "600",
            },
          },
          "& .secondary-btn": {
            height: "32px",
            width: "32px",
            fontSize: "24px",
            lineHeight: "19px",
            borderRadius: "20px",
            marginLeft: "10px",
          },
          "& .cart-quantity": {
            margin: "12px",
          },
        },
      },
      "& .line": {
        borderBottom: "1px solid #E8ECF2",
        marginTop: "20px",
      },
      "& .cart-subtotal-text": {
        color: "#000000DE",
        fontFamily: "Roboto",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        marginTop: "16px",
        textAlign: "end",
        paddingBottom: "200px",
        padding: "0px 30px",
      },
      "& .cart-end-section": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 30px",
        marginBottom: "16px",
        "& .cart-end-text": {
          color: "#000000DE",
          fontFamily: "Expletus Sans",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
        },
        "& .cart-end-price-text": {
          color: "#000000DE",
          fontFamily: "Expletus Sans",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "600",
        },
      },
      "& .cart-end-section-hide": {
        display: "none",
      },
      "& .note-section": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#F6F8DE",
        borderRadius: "12px",
        padding: "12px",
        margin: "30px",
        "& .note-section-txt": {
          color: "#000000DE",
          fontFamily: "Roboto",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "600",
        },
        "& .note-section-des-txt": {
          color: "#000000DE",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
        },
      },
      "& .button-section": {
        background: "#F2F8FC",
        // height: "80px",
        padding: "12px 30px",
        width:"100%",
        display:" inline-block", 
        bottom: 0,
        zIndex: 1000,
        position: "sticky",   
        "& .button-section-total-txt": {
          color: "#000000DE",
          fontFamily: "Roboto",
          fontSize: "10px",
          fontStyle: "normal",
          fontWeight: "400",
          textTransform: "uppercase",
          marginBottom: "16px",
        },
        "& .button-section-price-txt": {
          color: "#000000DE",
          fontFamily: "Expletus Sans",
          fontSize: "22px",
          fontStyle: "normal",
          fontWeight: "400",
          marginBottom: "16px",
          "& .span": {
            color: "#000000DE",
            fontFamily: "Roboto",
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: "400",
            marginBottom: "16px",
          },
        },
        "& .primary-btn": {
          position: "absolute",
          top: "0px",
          right: "0px",
          height: "100%",
          borderRadius: 0,
        },
      },
    },
    secondaryButtonn: {
      border: "1px solid #E8ECF2",
      background: "transparent",
      fontSize: "14px",
      borderRadius: "7px",
      color: "#4BA3B7",
      lineHeight: "18px",
      fontWeight: 500,
      textAlign: "center",
      fontFamily: "Expletus Sans",
      maxWidth: "100%",
      textTransform: "unset",
      // minWidth: "198px",
      padding: "12px 10px",
      marginBottom: "0",
      minHeight: "48px",
      textDecoration: "none",
      cursor: "pointer",
      // transition: "all 0.5s ease-in-out",
      "&:hover": {
        color: "#4BA3B7",
        background: "transparent",
      },
    },
    createTaskBox: {
      borderRadius: "12px",
      background: "#FFF",
      boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.22)",
      padding: "0",
      overflow: "hidden",
      maxWidth: "591px",
      width: "100%",
      marginLeft: "auto",
      maxHeight: "unset",
      display: "inline-block",
      top: "auto !important",
      bottom: "95px",
      right: "32px",
      position: "fixed",
      [theme.breakpoints.down("xs")]: {
        top: "0 !important",
        bottom: "0",
        right: "0",
        left: "0 !important",
        maxWidth: "100%",
        overflow: "auto",
      },
      "& .auth-back": {
        padding: "16px 24px",
        color: "rgba(43,43,43,0.67)",
      },
      "& .assignee-list": {
        display: "flex",
        alignItems: "center",
        // marginLeft: "6px",
        "& .assignee-bubble": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#ECF1F4",
          width: "28.328px",
          height: "28.328px",
          borderRadius: "100%",
          border: "2.364px solid #FFF",
          color: "rgba(43, 43, 43, 0.87)",
          fontSize: "9.586px",
          fontFamily: "Roboto",
          fontWeight: 600,
          lineHeight: "13.42px",
          letterSpacing: "-0.244px",
          position: "relative",
          marginLeft: "-4.7268px",
          "&:first-child": {
            marginLeft: "0",
          },
        },
      },
      "& .task-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "40px 40px 14px",
        marginBottom: "0",
        [theme.breakpoints.down("xs")]: {
          padding: "24px 24px 12px",
        },
        "& .sub-txt": {
          letterSpacing: "0.09px",
          fontWeight: 400,
          color: "#94A3B8",
          fontFamily: "Roboto",
          lineHeight: "16px",
          fontSize: "12px",
          [theme.breakpoints.down("xs")]: {
            color: "#2B2B2B",
          },
        },
        "& .modal-title": {
          fontFamily: "Expletus Sans",
          fontWeight: 400,
          lineHeight: "24px",
          fontSize: "18px",
          marginBottom: "4px",
          letterSpacing: "0",
          color: "#2B2B2B",
          [theme.breakpoints.down("xs")]: {
            lineHeight: "26px",
            fontSize: "22px",
            marginBottom: "8px",
          },
        },

        "& .heading-right": {
          display: "flex",
          marginLeft: "10px",
          "& .heading-icon": {
            marginRight: "24px",
            "&:last-child": {
              marginRight: "0",
            },
          },
        },
      },
      "& .task-description": { 
        padding: "10px 40px",
        overflowY: "auto",
        maxHeight: "calc(100vh - 300px)",
        [theme.breakpoints.down("xs")]: {
          overflowY: "unset",
          maxHeight: "unset",
          padding: "12px 24px 8px",
        },
        "& .rmdp-container": {
          "& .rmdp-input": {
            paddingLeft: "16px",
            // backgroundImage: `url(${calendar2}) !important`,
            backgroundRepeat: "no-repeat",
            "&::-webkit-input-placeholder": {
              color: "#94A3B8",
            },
            "&:-ms-input-placeholder": {
              color: "#94A3B8",
            },
            "&::placeholder": {
              color: "#94A3B8",
            },
          },
        },
      },
      "& .form-heading": {
        color: "#2B2B2B",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "19px",
        fontFamily: "Expletus Sans",
        letterSpacing: "0",
        marginBottom: "16px",
        display: "flex",
        justifyContent: "space-between",
        "& .close-icon": {
          marginLeft: "10px",
          "& .MuiSvgIcon-root": {
            fontSize: "16px",
            width: "16px",
            height: "16px",
            color: "#94A3B8",
          },
        },
      },
      "& .form-info-wrapper": {
        marginBottom: "16px",
        "& .expenditure-title": {
          color: "#000",
          fontFamily: "Expletus Sans",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "24px",
          marginBottom: "16px",
        },
        "& .wrap-option": {
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          marginBottom: "24px",
  
          "& .btn-add-option": {
            textTransform: "none",
            display: "flex",
            flexDirection: "row",
            padding: " 6px 12px",
            alignItems: "flex-start",
            gap: "8px",
            borderRadius: "15px",
            border: "1px solid var(--stroke, #E8ECF2)",
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
  
            fontFamily: "Roboto",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "16px",
            letterSpacing: "0.09px",
  
            marginRight: "12px",
            marginBottom: "12px",
          },
        },
        "& .favorite-box": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderRadius: "8px",
          padding: "24px",
          border: "1px solid #E8ECF2",
          marginTop:"20px",
          "& .category-left-box": {
            "& .left-title": {
              color: "var(--task-completed-color, #2B2B2B)",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
              marginBottom: "10px",
            },
            "& .left-sub-txt": {
              color: "var(--text-secondary, #94A3B8)",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "16px",
              letterSpacing: "0.09px",
            },
            "& .total-amount-txt": {
              color: "var(--text-secondary, #94A3B8)",
              texAlign: "right",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
            },
          },
          "& .category-right-box": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // marginTop: "24px",
            
            "& .amount-txt": {
              color: "var(--text-secondary, #2B2B2B)",
              texAlign: "right",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
              marginLeft: "6px",
            },
            "& .arrow-btn": {
              // minWidth: "24px",
              "& .btn-icon": {
                height: "24px",
                width: "24px",
                color:"#94A3B8"
              },
            },
          },
        },
        "&:last-child": {
          marginBottom: "0",
        },
        "& .form-row": {
          "&:last-child": {
            "& .form-control": {
              [theme.breakpoints.down("xs")]: {
                marginBottom: "0",
              },
            },
          },
        },
      },
      "& .form-row": {
        display: "flex",
        flexWrap: "wrap",
        margin: "0 -8px",
        "& .form-col": {
          padding: "0 8px",
          flex: "0 0 100%",
          maxWidth: "100%",
          "&.col6": {
            flex: "0 0 50%",
            maxWidth: "50%",
            [theme.breakpoints.down("xs")]: {
              flex: "0 0 100%",
              maxWidth: "100%",
            },
          },
          "& .form-control": {
            marginBottom: "16px",
          },
          "& .autocomplete-wrapper": {
            marginBottom: "16px",
          },
        },
      },
      "& .attachlist-ul": {
        padding: "0",
        "& .attachlist-li": {
          paddingLeft: "0",
          paddingTop: "15px",
          paddingBottom: "15px",
          borderBottom: "1px solid #E8ECF2",
          background: "transparent",
          "&:last-child": {
            borderBottom: "0",
          },
          "& .attachlist-label": {
            color: "#2B2B2B",
            fontSize: "14px",
            lineHeight: "19px",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
            margin: "0",
            "& .MuiTypography-root": {
              font: "inherit",
              letterSpacing: "inherit",
            },
          },
          "& .attachlist-icon": {
            minWidth: "unset",
            marginRight: "15px",
          },
        },
      },
      "& .upload-list-row": {
        display: "flex",
        flexWrap: "wrap",
        margin: "16px -8px 0",
        "& .upload-col": {
          padding: "0 8px",
          marginBottom: "16px",
          "&.col3": {
            flex: "0 0 25%",
            [theme.breakpoints.down("xs")]: {
              flex: "0 0 33.33%",
            },
          },
        },
        "& .upload-inner": {
          borderRadius: "6px",
          border: "2px dashed #e5e5e5",
          padding: "15px 16px",
          position: "relative",
          width:"108px",
          "& .upload-img": {
            textAlign: "center",
            width: "76px",
            height: "76px",
            maxWidth: "100%",
            margin: "0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        },
        "& .upload-details": {
          "& .img-name": {
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "8px",
            fontWeight: 400,
            lineHeight: "12px",
            marginTop: "6px",
            textAlign: "center",
            width: "108px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
        "& .close-icon": {
          color: "#94A3B8",
          position: "absolute",
          right: "8px",
          top: "8px",
          width: "20px",
          height: "20px",
          fontSize: "20px",
          "& .MuiSvgIcon-root": {
            width: "inherit",
            height: "inherit",
            color: "inherit",
            fontSize: "inherit",
          },
        },
      },
      "& .inventory-wrapper": {
        marginBottom: "12px",
        "& .links-wrapper": {
          display: "flex",
          flexWrap: "wrap",
        },
        "& .inventory-link": {
          padding: "5px 12px",
          borderRadius: "15px",
          border: "1px solid #E8ECF2",
          color: "rgba(43, 43, 43, 0.87)",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0.09px",
          textDecoration: "none",
          marginRight: "12px",
          marginBottom: "12px",
        },
      },
      "& .createtask-bottom": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "14px 40px 40px",
        [theme.breakpoints.down("xs")]: {
          padding: "8px 24px 24px",
          flexWrap: "wrap",
        },
        "& .button-wrapper": {
          "&.full-btn": {
            width: "100%",
            "& .MuiButton-root": {
              width: "100%",
            },
          },
        },
        "& .createtask-left": {
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginBottom: "24px",
          },
        },
        "& .createtask-right": {
          [theme.breakpoints.down("xs")]: {
            width: "100%",
          },
          "& .MuiButton-root": {
            [theme.breakpoints.down("xs")]: {
              width: "100%",
            },
          },
        },
        "& .outerbutton-wrapper": {
          marginRight: "8px",
          position: "relative",
          "& .outer-link": {
            minWidth: "48px",
            height: "48px",
            borderRadius: "48px",
            border: "1px dashed #E8ECF2",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "none",
            padding: "10px",
            position: "relative",
            cursor: "pointer",
            "& .priority": {
              color: "#94A3B8",
              "&.active-priority": {
                color: "#2B2B2B",
              },
            },
            "& .time": {
              color: "#2B2B2B",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontWeight: 500,
              lineHeight: "19px",
              // marginLeft: "5px",
            },
            "& .assignee-bubble": {
              color: "rgba(43, 43, 43, 0.87)",
              fontFamily: "Expletus Sans",
              fontSize: "11.331px",
              fontWeight: 500,
              lineHeight: "15.863px",
              letterSpacing: "-0.289px",
            },
            "& .cancel-link": {
              position: "absolute",
              right: "-2px",
              top: "-2px",
              "& .MuiSvgIcon-root": {
                width: "20px",
                height: "20px",
                fontSize: "20px",
                color: "#2B2B2B",
              },
            },
          },
        },
        "& .timestand-dropbox": {
          zIndex: "1300",
        },
        "& .primary-btn": {
          minWidth: "165px",
        },
      },
      "& .select-outer": {
        width: "100%",
        marginBottom: "24px",
        "& .MuiInputLabel-formControl": {
          fontFamily: "Expletus Sans",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "19px",
          letterSpacing: "0.1px",
          color: "#94A3B8",
          display: "flex",
          width: "calc(100% - 46px)",
          "&.MuiFormLabel-filled": {
            width: "auto",
            color: "rgba(43,43,43,0.87)",
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: 400,
            lineHeight: "16px",
            letterSpacing: "0.0075em",
          },
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0",
          color: "#000000",
          opacity: "1",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          display: "block",
          alignItems: "center",
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e8ecf2",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px",
            },
          },
        },
        "& .MuiFormLabel-root": {
          "&.Mui-focused": {
            width: "auto",
          },
          "& .MuiInputLabel-asterisk": {
            color: "#e86577",
            fontSize: "18px",
            lineHeight: "23px",
            marginLeft: "auto",
          },
        },
      },
      "& .hide-sm": {
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },
      "& .show-sm": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          display: "block",
        },
      },
    },
    fileUploadWrapper: {
      border: "2px dashed #e5e5e5",
      padding: "20px 24px",
      borderRadius: "12px",
      textAlign: "center",
      transition: "all 0.5s ease-in-out",
      cursor: "pointer",
      "&:hover": {
        // filter: "drop-shadow(0px 16px 10px rgba(0,0,0,0.05))",
        // backgroundColor: "#fafcfe",
        // borderColor: "transparent",
      },
      "& .upload-icon": {
        marginBottom: "16px",
      },
      "& .secondary-btn2": {
        color: "#4BA3B7",
        borderRadius: "6px",
        borderColor: "#4BA3B7",
        minWidth: "130px",
        minHeight: "36px",
        padding: "8px 4px",
        "&:hover": {
          color: "#4BA3B7",
        },
      },
      "& .file-heading": {
        fontSize: "16px",
        lineHeight: "21px",
        margin: "0 0 7px",
        color: "rgba(0,0,0,0.87)",
        letterSpacing: "0.15px",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
      },
      "& .file-subtxt": {
        fontSize: "12px",
        lineHeight: "16px",
        color: "#2B2B2B",
        letterSpacing: "0.09px",
        fontFamily: "Roboto",
        fontWeight: 400,
        marginBottom: "16px",
        "& .browse-link": {
          color: "#4ba3b7",
          cursor: "pointer",
          margin: "0 5px",
          fontWeight: 700,
        },
      },
    },
    fileDropDrag: {
      filter: "drop-shadow(0px 16px 10px rgba(0,0,0,0.05))",
      backgroundColor: "#fafcfe",
      borderColor: "transparent",
    },
   
  });

  export function assigneeA11yProps(index: any) {
    return {
      id: `assignee-tab-${index}`,
      "aria-controls": `assignee-tabpanel-${index}`,
    };
  }


export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

export function viwA11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export class GlobalHeaderWeb extends GlobalHeaderWebController {
  constructor(props: Props) {
    super(props);
  }

  popupDeal = (classes:any) => {
    return (
      <>
        {this.state.selectedDeals &&  <Box className="info-group favorite-product">           
          <Box className="group-form favorite-box" component="form">
            <Box className="category-left-box">
              <Typography className="left-title">
              {this.state.singleDealData.attributes?.name}
              </Typography>               
              <Typography className="total-amount-txt">
              To [{this.state.singleDealData.attributes?.contacts.length > 0 && (
                              <>
                                {this.state.singleDealData.attributes?.contacts
                                  .map(
                                    (contact: {
                                      id: string;
                                      name: string;
                                    }) => contact.name
                                  )
                                  .join(", ")}
                              </>
                            )}]; Close by {this.renderCloseDate(this.state.singleDealData.attributes?.close_date)}
              </Typography>
            </Box>
            <Box className="category-right-box">
              <Typography className="amount-txt">
              ${this.state.singleDealData.attributes?.total_amount}                       
              </Typography>
              <Button
                className="arrow-btn"
                data-test-id="close-deal"
                onClick={this.handleCloseShowAddDeals}
              >
                <CloseIcon className="btn-icon" />
              </Button>
            </Box>
          </Box>
        </Box>
        }
      </>
    )
  }

  handleChipInsideTaskBlock = (classes:any) => {
    return (
      <>
         {this.state.showAddDeal && this.addDealsModal(classes)}
                  {this.state.isDealsFilter && this.renderFiltersModal(classes)}
                  {this.state.showInventoryAssets &&   
                  <AddInventoryAndAssests
                    openProductsModal={this.state.showInventoryAssets}
                    closeProductsModal={this.handleShowInventoryAssetsClose}
                    classes={this.props.classes}
                    selectedDealProducts={this.selectedProducts}
                    buttonTxt={this.t("Continue")}
                    data-test-id="is-save-product"
                  />}                 
                   {this.state.viewSelectedProducts && (
                    <ProductInventoryViewModal
                      modalOpen={this.state.viewSelectedProducts}
                      modalClose={this.handleViewInventoryAssetsClose}
                      selectedProductsData={this.state.selectedProductsData}
                      openEdit={this.handleShowInventoryAssets}
                      classes={classes}
                      data-test-id="productInventory"
                      t={this.t}
                    />
                  )}
      </>
    )
  }

  createTaskBlock=(classes:any)=>{
    const popOverOpen = Boolean(this.state.popoveAnchorEl);
    const popoverId = popOverOpen ? "simple-popover" : undefined;
    const timeStandOpen = Boolean(this.state.timeStandAnchorEl);
    const timeStandId = timeStandOpen ? "simple-popper" : undefined;
    return(
      <Box className={classes.createTaskWrapper}>
          <Box className="task-actions">
            {this.state.draftTasks.map((draftTask: any) => {
              return (
                  <Button
                    className={`secondary-btn ${classes.secondaryButton}`}                   
                  >
                    <Box
                      className="draft-name"
                      component="span"
                      onClick={(e: any) => this.openDraftTask(e, draftTask.id)}
                      data-test-id="openDraftTask"
                    >
                      <img
                        className="draft-img"
                        src={fileTree}
                        alt="fileTree"
                      />
                      {draftTask.title == "" ? "Draft" : draftTask.title}
                    </Box>
                     <Box
                      data-test-id="deleteDraftId"
                      className="close"
                      onClick={(e: any) => this.deleteDraftTask(draftTask.id)}
                    >
                      <img src={closeGray} alt="closeGray" />
                     </Box>
                  </Button>
              );
            })}
          </Box>
          <Button style={{display:"none"}} data-test-id="selectedProducts" onClick={()=>this.getAllProductsFromInventory(["12"])}></Button>
          <Popover
            onClose={this.popoverHandleClose}
            open={popOverOpen}
            anchorEl={this.state.popoveAnchorEl}
            id={popoverId}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}           
            classes={{ paper: `${classes.createTaskBox}` }}
            data-test-id="create-task-popover"
            onKeyDown={(event) => {this.handleTaskEscEvent(event)}}
          >
            <Box
              onClick={this.popoverHandleClose}
              className="auth-back show-sm"
            >
              <i>
                <svg
                  className="MuiSvgIcon-root"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                </svg>
              </i>
            </Box>    
            <Box className="task-heading">
              <Box className="heading-left">
                <Typography className="modal-title" component="h2">
                  {this.state.isEditing ? this.t(configJSON.txtEditTask) : this.t(configJSON.txtCreateTask)}
                </Typography>
                <Box component="p" className="sub-txt">
                  {this.state.isEditing
                    ? this.t(configJSON.letsEditTaskSubTxt)
                    : this.t(configJSON.txtLetCreate)}
                </Box>
              </Box>
              <Box className="heading-right">
                 <Box
                  data-test-id="saveAsDraftTask"
                  onClick={this.saveAsDraftTask}
                  className="heading-icon hide-sm"
                   
                >
                  <img alt="minimizeGray" src={minimizeGray}  />
                 </Box>
                 <Box
                  onClick={this.popoverHandleClose}
                  className="heading-icon hide-sm"
                   
                  data-test-id="closeTaskPopover"
                >
                  <img alt="closeGray" src={closeGray}  />
                 </Box>
              </Box>
            </Box>
            <Box className="task-description">
              <Box className="task-form" component="form">
                <Box className="form-info-wrapper">
                  <Box className="form-row">
                    <Box className="form-col">
                      <CustomInputWeb
                        data-test-id="crateTaskTitle"
                        label={this.t(`${configJSON.txtTitle}`)}
                        value={this.state.title}
                        errors={this.state.taskerror.title}
                        type="text"
                        name="title"
                        onChange={this.handleOnChange}
                        isRequired={true}
                      />
                    </Box>
                  </Box>
                  <Box className="form-row">
                  <Box className="form-col col6">
                    <FormControl className="select-outer" variant="outlined">
                      <InputLabel id="project">{this.t(`${configJSON.projectTxt}`)}</InputLabel>
                      <Select
                        labelId="project"
                        id="project"
                        value={this.state.projectID > 0 ?this.state.projectID:undefined}
                        onChange={this.projectChangeHandler}
                        name="project"
                        label={this.t(`${configJSON.projectTxt}`)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: `${classes.dropdownStyle} modal-dropdown`,
                          },
                        }}
                        data-test-id="projectSelect"
                        IconComponent={ExpandMoreIcon}
                      >
                        {this.state.projectList.map((proj) => {
                          return (
                            <MenuItem value={Number(proj.id)} key={proj.id}>
                              {proj.attributes.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className="form-col col6">
                    <FreeSoloCreateOption
                      options={this.state.allTaskList.map((val) => {
                        return {
                          id: val.id,
                          title: val.attributes.name,
                        };
                      })}
                      addEditCallback={this.taskListAdd}
                      addLinkTitle={this.t(`${configJSON.addNewTaskListTxt}`)}
                      noOptionTitle={this.t(`${configJSON.noTaskListFoundTxt}`)}
                      label={this.t(`${configJSON.labelTitleText}`)}
                      id="job-id"
                      onChange={this.taskListHandler}
                      value={this.state.taskListObject.name}
                      errors={this.state.taskerror.tasklistName}
                      errorMsgNoRecord={this.t(`${configJSON.notCreatedTasklistTxt}`)}
                      hideOtherOptions={true}
                      data-test-id="taskTasklist"
                      notRequired={true}
                    />
                  </Box>
                </Box>
                  <Box className="form-row">
                    <Box className="form-col col6">
                      <Box className="form-control">
                        <CustomDatePicker
                          t={this.t}
                          error={this.state.taskerror.due_date}
                          currentDate={this.state.due_date}
                          onOpenPickNewDate={false}
                          data-test-id="crateTaskDueDate"
                          required={false}
                          onChange={this.changeDueDate}
                          placeholder={this.t(`${configJSON.txtDueDate}`)}
                          showPastWarning={true}
                          inPortal={true}                         
                        />
                      </Box>
                    </Box>
                    <Box className="form-col col6">
                      <Box className="form-control">
                        <CustomDatePicker
                          t={this.t}
                          placeholder={this.t(`${configJSON.txtDeadline}`)}
                          currentDate={this.state.deadline_date}
                          onChange={this.changeDeadlineDate}
                          onOpenPickNewDate={false}
                          data-test-id="crateTaskDeadline"
                          showPastWarning={true}
                          inPortal={true}
                          required={false}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box className="form-row">
                    <Box className="form-col">
                      <CustomInputWeb
                        type="text"
                        label={this.t(`${configJSON.txtDescription}`)}
                        isMultiline
                        rows={4}
                        value={this.state.description}
                        name="description"
                        onChange={this.handleOnChange}
                        data-test-id="crateTaskDesc"
                      />                      
                    </Box>
                  </Box>                              
                  <Box className="form-row">
                  <Box className="wrap-option">                      
                     {!this.state.showAddProductsAssets && this.state.selectedProductsId.length === 0 && (
                       <Button
                         className="btn-add-option"
                         data-test-id="handleShowAssets"
                         onClick={this.handleShowInventoryAssets}
                       >
                         + {this.t(`${configJSON.addProductAssestsTxt}`)}
                       </Button>
                     )}
                  </Box>
                  <Box className="wrap-option">                      
                     {!this.state.showAddExpenditures && (
                       <Button
                         className="btn-add-option"
                         data-test-id="handleShowExpenditures"
                         onClick={this.handleShowExpenditures}
                       >
                         + {this.t(`${configJSON.addExpenditures}`)}
                       </Button>
                     )}
                  </Box>
                  <Box className="wrap-option">                      
                     {!this.state.showAddDeal && this.state.selectedDeals===""  && (
                       <Button
                         className="btn-add-option"
                         data-test-id="handleShowDeal"
                         onClick={this.handleShowAddDeals}
                       >
                         + {this.t(`${configJSON.addDealTxt}`)}
                       </Button>
                     )}
                  </Box>
                  </Box>
                  {this.state.showAddExpenditures && 
                  <Box className="form-row">
                    <Box className="form-col">
                    <Typography className="expenditure-title">
                    {this.t(`${configJSON.addExpenditures}`)}
                    </Typography>
                      <CustomInputWeb
                        type="number"                       
                        placeholder={this.t(`${configJSON.expendituresText}`)}  
                        autoFocus={true}                     
                        value={this.state.expenditures}
                        name="expenditures"
                        onChange={this.handleOnChange}
                        data-test-id="expenditures"
                        onKeyDown={(e: any) => {
                          if (["e", "E", "-"].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        startAdornment={
                          <InputAdornment position="start">
                            <AttachMoneyIcon />
                          </InputAdornment>
                        }
                      />                      
                    </Box>
                  </Box>}  
                  {this.handleChipInsideTaskBlock(classes)}
                  {this.state.selectedProductsId.length > 0 &&  <Box className="info-group favorite-product">           
                  <Box className="group-form favorite-box" component="form">
                    <Box className="category-left-box">
                      <Typography className="left-title">
                      {this.state.selectedProductsId.length} {this.t(`${configJSON.inventoryItemAddedTxt}`)}
                      </Typography>               
                      <Typography className="total-amount-txt">
                      {generateSummary(this.state.productCountAndName, this.t)}
                      </Typography>
                    </Box>
                    <Box className="category-right-box">                      
                      <Button
                        className="arrow-btn"
                        data-test-id="view-products"
                        onClick={this.handleViewInventoryAssets}
                      >
                         <ArrowForwardIosIcon className="btn-icon" />
                      </Button>
                    </Box>
              </Box>
                  </Box>}
                 {this.popupDeal(classes)}
                </Box>              
                <Box className="form-info-wrapper">
                  <Typography className="form-heading" variant="h2">
                  {this.t(`${configJSON.attachmentsTxt}`)}
                  </Typography>
                  <FileDrop
                    onDragOver={this.dragEnterEvent}
                    onDrop={() => this.handleOnDrop}
                    onDragLeave={this.dragLeaveEvent}
                    data-test-id="handleOnDropCreateTask"
                  >
                    <Box
                      onClick={this.onBrowse}
                      className={`${classes.fileUploadWrapper} ${this.state.fileDrop && classes.fileDropDrag} `}
                      data-test-id="onBrowseTask"
                    >
                      <form ref={this.formRef}>
                        <input
                          name="upload-photo"
                          type="file"
                          accept="*"
                          onChange={this.handleOnDrop}
                          multiple
                          ref={this.fileRef}
                          hidden
                          data-test-id="add-task-attachment"
                        />
                      </form>
                      <Box className="file-upload-block">
                        <Box component="p" className="file-subtxt">
                          {this.t(`${configJSON.clickHereTxt}`)}
                          <Box component="span" className="browse-link">
                            {this.t(`${configJSON.browseTxt}`)}
                          </Box>
                          {this.t(`${configJSON.yourPictureTxt}`)}
                        </Box>
                        <Box className="upload-icon">
                          <img src={uploadImg} alt="uploadImg" />
                        </Box>
                        <Button
                          className={`secondary-btn2 ${classes.secondaryButton}`}
                        >
                          {this.t(`${configJSON.addAttachmentsTxt}`)}
                        </Button>
                      </Box>
                    </Box>
                  </FileDrop>
                  {this.state.filesUploaded.length > 0 && (
                 <List className="attachlist-ul">
                    {this.state.filesUploaded.map((file: any, index: any) => {
                      return(
                        <ListItem
                        disableRipple
                        className="attachlist-li"                        
                        role={undefined}
                        dense
                        button
                        key={file.name}
                      >
                        <ListItemIcon className="attachlist-icon">
                          <img src={checkGreen} alt="checkGreen" />
                        </ListItemIcon>
                        <ListItemText
                          className="attachlist-label"                         
                          primary={file.name || file.filename}
                        />
                        <ListItemSecondaryAction>
                           <Box   className="tasklist-link">
                            <img src={closeGray} alt="closeGray" data-test-id="removeTaskFileUPload" onClick={()=>this.removeFileUPload(index)}/>
                           </Box>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )})}
                  </List>
                )}                  
                </Box>
              </Box>
            </Box>
            <Box className="createtask-bottom">
              <Box className="createtask-left">
                <Box className="outerbutton-wrapper">
                   <Box
                    data-test-id="crateTaskPriority"
                    className="outer-link dropmenu-btn"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                     
                    onClick={this.dropMenuHandler}
                  >
                    <Box
                      className={`priority ${
                        this.state.priority == 1 || this.state.priority == 2
                          ? "active-priority"
                          : ""
                      }`}
                    >
                      {this.state.priority == 1 ? "!!" : "!!!"}
                    </Box>
                   </Box>
                  <Menu
                    open={Boolean(this.state.createTaskanchorEl)}
                    className={classes.dropDropdown}
                    transformOrigin={{
                      horizontal: "left",
                      vertical: "bottom",
                    }}
                    anchorEl={this.state.createTaskanchorEl}
                    keepMounted
                    onClose={() => this.dropMenuClose(this.state.priority)}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    id="simple-menu"  
                    data-test-id="createTaskanchorElId"    
                  >
                    <MenuItem
                      data-test-id="CreateTaskPriorityEmergency"
                      onClick={() => this.dropMenuClose(2)}
                    >
                      <Box className="priority-name">{this.t(`${configJSON.txtEmergency}`)}</Box>
                      <Box className="priority-icon">!!!</Box>
                    </MenuItem>
                    <MenuItem
                      data-test-id="CreateTaskPriorityCritical"
                      onClick={() => this.dropMenuClose(1)}
                    >
                      <Box className="priority-name">{this.t(`${configJSON.txtCritical}`)}</Box>
                      <Box className="priority-icon">!!</Box>
                    </MenuItem>
                    <MenuItem
                      data-test-id="CreateTaskPriorityNone"
                      onClick={() => this.dropMenuClose(0)}
                    >
                      <Box className="priority-name">{this.t(`${configJSON.txtNone}`)}</Box>
                    </MenuItem>
                  </Menu>
                </Box>
                <Box className="outerbutton-wrapper">
                   <Box
                    data-test-id="CreateTaskHours"
                    className="outer-link"
                    aria-describedby={timeStandId}
                    onClick={this.toggleHoursModal}
                     
                  >
                    <img alt="timerSand" src={timerSand}/>
                    <Box className="time">{this.state.taskHours}</Box>
                   </Box>
                  {this.state.showHoursModal && (
                    <Box className={classes.timestandDropbox} id={timeStandId}>
                      <Box className="timestand-inner">
                        <Box className="timestand-heading">{this.t(`${configJSON.txtTaskHours}`)}</Box>
                        <Box component="form">
                          <Box className="time">
                            <TextField
                              InputProps={{
                                disableUnderline: true,
                              }}
                              inputProps={{
                                step: 1,
                                min: 0,
                                max: 999,
                              }}
                              placeholder="00"
                              id="standard-basic"
                              name="hours"
                              label=""
                              value={this.state.hours}
                              onChange={this.hoursChange}
                              type="number"
                              data-test-id="CreateTaskHoursInput"
                            />
                            <Box component="span" className="colon-txt">
                              :
                            </Box>
                            <TextField
                              InputProps={{
                                disableUnderline: true,
                              }}
                              placeholder="00"
                              id="standard-basic2"
                              label=""
                              name="minutes"
                              type="number"
                              value={this.state.minutes}
                              onChange={this.minutesChange}
                              data-test-id="CreateTaskMinutesInput"
                            />                           
                          </Box>
                        </Box>
                      </Box>
                      <Box className="timestand-actions">
                         <Box                           
                          className="secondary-link"
                          onClick={this.toggleHoursModal}
                          data-test-id="CreateTaskHoursCancel"
                        >
                          {this.t(`${configJSON.txtCancel}`)}
                         </Box>
                         <Box                           
                          className="primary-link"
                          onClick={this.setHours}
                          data-test-id="CreateTaskHoursSave"
                        >
                          {this.t(`${configJSON.txtSave}`)}
                         </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box className="outerbutton-wrapper">
                   <Box
                    data-test-id="createTaskassignee"
                    onClick={this.assigneeHandler}
                    className="outer-link"
                     
                  >
                    <img alt="accountPlus"  src={accountPlus} />
                    <Box className="assignee-list">
                      {this.displayAssigneeOptions()
                        .filter((y: any) => this.state.assignees.includes(y.id))
                        .slice(0, 3)
                        .map((user: any) => {
                          return (
                            <Box className="assignee-bubble" key={user.id}>
                              {user.initials}
                            </Box>
                          );
                        })}
                      {this.state.assignees.length > 3 && (
                        <Box className="assignee-bubble">
                          +{this.state.assignees.length - 3}
                        </Box>
                      )}
                    </Box>
                   </Box>
                </Box>
              </Box>
              <Box className="createtask-right">
                <Button
                  className={`primary-btn ${classes.primaryButton}`}
                  onClick={this.createTask}
                  data-test-id="saveTaskBtn"
                >
                  {this.state.isEditing ? this.t(configJSON.updateTaskTxt) : `+ ${this.t(configJSON.txtCreateTask)}`}
                </Button>
              </Box>
            </Box>
          </Popover>
      </Box>
    )
  }

  changeAssigneeModal = (classes:any) => {
    const teamsOptions = this.displayTeamsOptions().filter(
      (team) => !this.state.selectedTaskAssignees.includes(team.id));
      
    const assigneeOptions = this.displayAssigneeOptions().filter(
      (assignee) => !this.state.selectedTaskAssignees.includes(assignee.id)
    );
    return (
    <Modal
      open={this.state.isActiveChangesAssignee}
      onClose={this.assigneeChangeModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalDialog}
      data-test-id="isActiveChangesAssigneeId"
    >
      <Box className={classes.modalDialogBox}>
              <Box className="modal-dialoginner">
                <Box className="modal-heading">
                  <Typography className="modal-title" component="h2">
                    {this.state.selectedTaskAssignees.length} {this.t(`${configJSON.txtMemberSelected}`)}
                  </Typography>
                   <Box
                     
                    className="close-icon"
                    onClick={this.assigneeChangeModalClose}
                    data-test-id="assigneeModalClose"
                  >
                    <CloseIcon />
                   </Box>
                </Box>
                <Box className="modal-description">
                  <Box component="p" className="sub-txt">
                    {this.t(`${configJSON.txtAssigneeDescription}`)}
                  </Box>
                  <Box className="modal-form" component="form">
                    <List className="assignee-multiSelect-list selected-list">
                      <ListSubheader>
                        <CustomInputWeb
                          placeholder={this.t(`${configJSON.txtSearchAssignees}`)}
                          fullWidth={true}
                          autoFocus={true}
                          name="assigneeSearchText"
                          onChange={this.handleOnChange}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                          data-test-id="searchId"
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader>
                      <ListSubheader className="multiselect-subheader">
                      {this.t(`${configJSON.txtAssignees}`)}
                         <Box
                           
                          className="tasklist-link"
                          onClick={this.clearSelectedAssignees}
                          data-test-id="clearAssignees"
                        >
                         {this.t(`${configJSON.txtRemoveAllAssignees}`)}
                         </Box>
                      </ListSubheader>                     
                      {this.displayAssigneeOptions()
                        .filter((x: any) =>
                          this.state.selectedTaskAssignees.includes(x.id)
                        )
                        .map((member: any) => (
                          <ListItem
                              onClick={() => this.changeAssignee(member.id)}
                              value={member.id}
                              key={member.id}
                              data-test-id="changeAssigneeId"
                          >
                            <Box className="assignee-detail">
                              <Box className="assignee-left">
                                <Box className="assignee-profile">
                                  {member.initials}
                                </Box>
                                <Box className="assignee-info">
                                  <Box className="assignee-name">
                                    {member.title}
                                  </Box>
                                  <Box className="assignee-email">
                                    {member.email}
                                  </Box>
                                  {member.job_role && <Chip
                                    className={classes.chipButton}
                                    label={member.job_role}
                                  />} 
                                </Box>
                              </Box>
                              <Box className="assignee-right">
                                <Box className="assinee-selected">
                                  <img
                                    alt="checkBoxEmpty"
                                    src={checkBoxEmpty}
                                    className="normal-img"
                                  />
                                  <img
                                    alt="checkBoxFilled"
                                    src={checkBoxFilled}
                                    className="selected-img"
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </ListItem>
                        ))}
                        {this.displayTeamsOptions()
                        .filter((x: any) =>
                          this.state.selectedTaskAssignees.includes(x.id)
                        )
                        .map((team: any) => (
                          <ListItem onClick={() => this.changeAssignee(team.id)} value={team.id} key={team.id} data-test-id="teamId">
                            <Box className="assignee-detail">
                              <Box className="assignee-left 1">
                                <Box className="assignee-profile 1">
                                  {team.initials}
                                </Box>
                                <Box className="assignee-info">
                                      <Box className="assignee-name">
                                        {team.title}
                                      </Box>
                                      <Box className="assignee-email">
                                        {team.users} {team.users >1 ? this.t(`${configJSON.membersTxt}`) : this.t(`${configJSON.memberTxt}`)}
                                      </Box>
                                    </Box>
                              </Box>
                              <Box className="assignee-right">
                                <Box className="assinee-selected">
                                  <img
                                    alt="checkBoxEmpty"
                                    src={checkBoxEmpty}
                                    className="normal-img"
                                  />
                                  <img
                                    alt="checkBoxFilled"
                                    src={checkBoxFilled}
                                    className="selected-img"
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </ListItem>
                        ))}
                      <ListSubheader className="multiselect-subheader">
                      {this.t(`${configJSON.txtUnassigned}`)}
                      </ListSubheader>
                    </List>
                    <Box className="tasklist-tabwrapper">
                      <Box className="assignee-tabs">
                        <Tabs
                          data-test-id="updateAssigneeTabs"
                          onChange={this.setAssigneeTabsValue}
                          value={this.state.assigneeTabsValue}
                          aria-label="simple tabs example"
                        >
                          <Tab label={this.t(`${configJSON.teamsTxt}`)} {...assigneeA11yProps(0)} />
                          <Tab label={this.t(`${configJSON.membersTxt}`)} {...assigneeA11yProps(1)} />
                        </Tabs>
                      </Box>

                      <TabPanel value={this.state.assigneeTabsValue} index={0}>
                        <List className="assignee-multiSelect-list">
                          <ListSubheader className="multiselect-membres">
                            <Box className="total-member">
                              {teamsOptions.length
                              }&nbsp;
                             {teamsOptions.length > 1 ? this.t(`${configJSON.teamsTxt}`): this.t(`${configJSON.teamTxt}`)}
                            </Box>
                            <FormControl className="select-control sortby-control">
                              <Select
                              IconComponent={ExpandMoreIcon}
                                MenuProps={{
                                  anchorOrigin: {
                                    horizontal: "left",
                                    vertical: "bottom",
                                  },
                                  transformOrigin: {
                                    horizontal: "left",
                                    vertical: "top",
                                  },
                                  classes: { paper: classes.dropdownStyle },
                                  getContentAnchorEl: null,
                                }}
                                displayEmpty
                                defaultValue={this.t(`${configJSON.txtSortBy}`)}
                                onChange={this.changeSortByAssignee}
                                data-testid="sortTeams"
                                renderValue={(value: any) => {
                                  return <Box>{value}</Box>;
                                }}
                                
                              >
                                <MenuItem value="A-Z">{this.t(`${configJSON.aTozTxt}`)}</MenuItem>
                                <MenuItem value="Z-A">{this.t(`${configJSON.zToaTxt}`)}</MenuItem>
                              </Select>
                            </FormControl>
                          </ListSubheader>
                          {this.sortRecords(this.displayTeamsOptions())
                            .filter(
                              (x: any) =>
                                !this.state.selectedTaskAssignees.includes(x.id)
                            )
                            .map((option: any) => (
                              <ListItem
                                key={option.id}
                                value={option.id}
                                onClick={() => this.changeAssignee(option.id)}
                              >
                                <Box className="assignee-detail">
                                  <Box className="assignee-left">
                                    <Box className="assignee-profile">
                                      {option.initials}
                                    </Box>
                                    <Box className="assignee-info">
                                      <Box className="assignee-name">
                                        {option.title}
                                      </Box>
                                      <Box className="assignee-email">
                                        {option.users} {option.users >1 ? this.t(`${configJSON.membersTxt}`) : this.t(`${configJSON.memberTxt}`)}
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box className="assignee-right">
                                    <Box className="assinee-unselected">
                                      <img
                                        className="normal-img"
                                        src={checkBoxEmpty}
                                        alt="checkBoxEmpty"
                                      />
                                      <img
                                        className="selected-img"
                                        src={checkBoxFilled}
                                        alt="checkBoxFilled"
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              </ListItem>
                            ))}
                        </List>
                      </TabPanel>
                      <TabPanel value={this.state.assigneeTabsValue} index={1}>
                        {assigneeOptions.length !==0 ? <List className="assignee-multiSelect-list">
                          <ListSubheader className="multiselect-membres">
                            <Box className="total-member">
                              {assigneeOptions.length}&nbsp;
                              {assigneeOptions.length > 1 ? this.t(`${configJSON.membersTxt}`): this.t(`${configJSON.memberTxt}`)}
                            </Box>
                            <FormControl className="select-control sortby-control">
                              <Select
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    horizontal: "left",
                                    vertical: "top",
                                  },
                                  getContentAnchorEl: null,
                                  classes: { paper: classes.dropdownStyle },
                                }}
                                displayEmpty
                                onChange={this.changeSortByAssignee}
                                data-testid="sortTeams"
                                defaultValue={this.t(`${configJSON.txtSortBy}`)}
                                IconComponent={ExpandMoreIcon}
                                renderValue={(value: any) => {
                                  return <Box>{value}</Box>;
                                }}                               
                              >
                                <MenuItem value="A-Z">{this.t(`${configJSON.aTozTxt}`)}</MenuItem>
                                <MenuItem value="Z-A">{this.t(`${configJSON.zToaTxt}`)}</MenuItem>
                              </Select>
                            </FormControl>
                          </ListSubheader>
                          {this.sortRecords(this.displayAssigneeOptions())
                            .filter(
                              (ass: any) =>
                                !this.state.selectedTaskAssignees.includes(ass.id)
                            )
                            .map((user: any) => (
                              <ListItem
                                  data-test-id={`changeAssignee-${user.id}`}
                                  value={user.id}
                                  onClick={() => this.changeAssignee(user.id)}
                                  key={user.id}
                              >
                                <Box className="assignee-detail">
                                  <Box className="assignee-left">
                                    <Box className="assignee-profile">
                                      {user.initials}
                                    </Box>
                                    <Box className="assignee-info">
                                      <Box className="assignee-name">
                                        {user.title}
                                      </Box>
                                      <Box className="assignee-email">
                                        {user.email}                                      
                                      </Box>  
                                      {user.job_role && <Chip
                                         className={classes.chipButton}
                                         label={user.job_role}
                                      />}                                   
                                    </Box>
                                  </Box>
                                  <Box className="assignee-right">
                                    <Box className="assinee-unselected">
                                      <img
                                        alt="checkBoxEmpty"
                                        src={checkBoxEmpty}
                                        className="normal-img"
                                      />
                                      <img
                                        className="selected-img"
                                        alt="checkBoxFilled"
                                        src={checkBoxFilled}
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              </ListItem>
                            ))}
                        </List> : <Typography className="not-found">{this.t(configJSON.memberNotFountError)}</Typography>
                        }
                        
                      </TabPanel>
                    </Box>
                  </Box>
                </Box>
              </Box>
      </Box>
    </Modal>
    )
  }

  renderCloseDate = (date: any) => {
    if (date === null) {
      return "-";
    }
    return moment(date).format("MMM DD, YYYY");
  };

  stageStatusClassMaping = (dealStageStatus: string) => {
    if (dealStageStatus === "Initiated") {
      return <Box className="status sky-blue"></Box>;
    } else if (dealStageStatus === "sent proposal") {
      return <Box className="status orange"></Box>;
    } else if (dealStageStatus === "follow up") {
      return <Box className="status yellow"></Box>;
    } else if (dealStageStatus === "won") {
      return <Box className="status green"></Box>;
    } else if (dealStageStatus === "lost") {
      return <Box className="status black"></Box>;
    }
  };
  
  addDealsModal=(classes:any)=>{  
    let dealsListData;
    const { allDealsData, searchResults } =
      this.state;
    if (this.state.isSearching) {
      dealsListData = searchResults;
    } else {
      dealsListData = allDealsData;
    } 
    return(
      <Modal
        open={this.state.showAddDeal}
        onClose={this.handleCloseShowAddDeals}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalDialog}
        >
          <Box className={`${classes.modalDialogBox} move-tasklist-dialog`}>
            <Box className="modal-dialoginner">
              <Box className="modal-heading">
                <Typography component="h2" className="modal-title" >
                  {this.t(`${configJSON.chooseDealsTxt}`)}
                </Typography>
                 <Box
                   
                  className="close-icon"
                  onClick={this.handleCloseShowAddDeals}
                  data-test-id="handleCloseShowAddDeals"
                >
                  <CloseIcon />
                 </Box>
              </Box>
              <Box className="modal-description">
                <Box component="p" className="sub-txt">
                {this.t(`${configJSON.chosseDealsSubTxt}`)}
                </Box>
                <Box className="modal-form" component="form">
                  <Box className="form-info-wrapper">
                    <RadioGroup
                      aria-label="templatelist"
                      name="templatelist1"
                    >
                      <List className="move-tasklist-list">
                      <Box className="search-wrapper">
                      <Box className="search-box">
                        <CustomInputWeb
                          type="search"
                          label=""
                          value={this.state.searchValue}
                          onChange={this.handleSearch}
                          placeholder={this.t(`${configJSON.searchPlaceholder}`)}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon style={{ color: "#225980" }} />
                            </InputAdornment>
                          }
                          data-test-id="searchfield"
                        />
                        <Box className="sort-by" data-test-id="handleFiltersOPen" onClick={this.handleDealsFilter}>
                          <img src={sortby} alt="bg-image" className="filter-image" />
                        </Box>
                      </Box>
                    </Box>
                        <ListSubheader className="multiselect-membres">
                          <Box className="total-member">
                          `${dealsListData.length} ${this.t(dealsListData.length > 1 ? configJSON.dealsTxt : configJSON.dealTxt)}`
                          </Box>
                          <FormControl className="select-control sortby-control">
                                <Select
                                IconComponent={ExpandMoreIcon}
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      horizontal: "left",
                                      vertical: "top",
                                    },
                                    getContentAnchorEl: null,
                                    classes: { paper: classes.dropdownStyle },
                                  }}
                                  displayEmpty                                 
                                  onChange={this.handleSortByDeals}
                                  defaultValue={this.t(`${configJSON.txtSortBy}`)}
                                  data-test-id="sortDeals"
                                  renderValue={(value: any) => {
                                    return <Box>{value}</Box>;
                                  }}                                  
                                >
                                  <MenuItem value="A-Z">{this.t(`${configJSON.aTozTxt}`)}</MenuItem>
                                  <MenuItem value="Z-A">{this.t(`${configJSON.zToaTxt}`)}</MenuItem>
                                </Select>
                          </FormControl>                      
                        </ListSubheader> 
                          <Box className="line"></Box>
                      </List>
                    </RadioGroup>
                  </Box>
                  <Box className="form-info-wrapper">
                    <RadioGroup
                      aria-label="templatelist"
                      name="templatelist1"
                    >
                      <List className="move-tasklist-list">                       
                        {dealsListData.map((value:any) => {
                          return (
                            <ListItem
                              className="template-li"
                              key={value.id}
                              onClick={this.dealsValue(value.id)}
                              data-test-id="dealsDatatest"
                            >
                              <Box
                                className="template-left"
                              >
                                <Box className="deal-container">
                                  <Typography className="deal-close-date">
                                    Close by:&nbsp;
                                    {this.renderCloseDate(value.attributes.close_date)}
                                    </Typography>
                                    <Typography className="deal-close-date content-dots"></Typography>                                   
                                            <Box className="status-item">
                                            <Box className="status-name">
                                              {this.t(`${value.attributes.stage}`)}
                                            </Box>
                                            {this.stageStatusClassMaping(
                                              value.attributes.stage
                                            )}
                                          </Box>
                                </Box>
                                <Box className="deal-name">
                                {value.attributes.name}
                              </Box>
                              <Box>                              
                              <table>
                                <tr>
                                  <th className="deal-owner">{this.t(`${configJSON.contactTxt}`)}</th>
                                  <th className="deal-owner">{this.t(`${configJSON.dealOwnerNameTxt}`)}</th>
                                </tr>
                                <tr>
                                  <td className="deal-owner-name">{value.attributes.contacts.length > 0 && (
                                      <>
                                        {value.attributes.contacts
                                          .map(
                                            (contact: {
                                              id: string;
                                              name: string;
                                            }) => contact.name
                                          )
                                          .join(", ")}
                                      </>
                                    )}</td>
                                  <td className="deal-owner-name">{value.attributes.deal_owner.name}</td>                                
                                </tr>                                
                              </table>
                          </Box>                              
                              </Box>
                              <ListItemIcon className="template-icon-deal">
                              <Box className="price-text">
                                ${value.attributes.total_amount}
                              </Box>
                                <Radio
                                  checked={this.state.selectedDeals === value.id}
                                  tabIndex={-1}                                
                                />
                              </ListItemIcon>
                            </ListItem>
                          );
                        })}
                      </List>
                    </RadioGroup>
                  </Box>
                </Box>
              </Box>
              <Box className="modal-footer">                 
                <Button
                  className={`primary-btn ${classes.primaryButton}`}
                  data-test-id="handleContinouDeal"
                  disabled={dealsListData.length == 0}
                  onClick={() => this.handleContinouDeal(this.state.selectedDeals)}
                >
                 {this.t(`${configJSON.continoueBtnTxt}`)}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
    )
  }
  renderFiltersModal = (classes:any) =>{ 
    const { allDealsData } =
      this.state;  
      const ownerFilters = allDealsData.map((dealOwner: any) => ({
        value: dealOwner.attributes.deal_owner.id,
        label: dealOwner.attributes.deal_owner.name,
      }));
      const uniqueOwnerFiltersSet = new Set(
        ownerFilters.map((item: any) => item.value)
      );
  
     const uniqueOwnerFilters = Array.from(uniqueOwnerFiltersSet).map((value) =>
        ownerFilters.find((item: any) => item.value === value)
      );
    const uniqueContactNamesSet = new Set(); 
    const uniqueContacts: any = [];

    allDealsData.forEach((deal: any) => {
      const contacts = deal.attributes.contacts || [];
      contacts.forEach((contact: { id: string; name: string }) => {
        const contactId = contact.id;
        const contactName = contact.name;

        if (!uniqueContactNamesSet.has(contactName)) {
          uniqueContactNamesSet.add(contactName);
          uniqueContacts.push({ id: contactId, name: contactName });
        }
      });
    });

    return(
    <Modal
      className={classes.modalFilterDialog}
      aria-labelledby="modal-modal-title"
      onClose={this.handleCloseDealsFilter}
      open={this.state.isDealsFilter}
      aria-describedby="modal-modal-description"
    >
      <Box className={`view-modal-dialog ${classes.modalFilterDialogBox}`}>
        <Box className="modal-content">
          <Box className="modal-wrapper">
            <Box>
              <Typography className="modal-headding">{this.t(`${configJSON.txtFilters}`)}</Typography>
            </Box>
            <Box className="reset-cross">
              <Box
                className="reset-txt"
                onClick={this.resetFilters}
                data-test-id="resetFilters"
              >
                {this.t(`${configJSON.txtReset}`)}
              </Box>
              <Box
                className="close-icon"
                onClick={this.handleCloseDealsFilter}
                data-test-id="handleFiltersClose"
              >
                <CloseIcon className="close-icon" />
              </Box>
            </Box>
          </Box>
          <Typography className="desc"></Typography>
              <Typography className="filter-headding">{this.t(`${configJSON.stageTxt}`)}</Typography>
              <FormControl
                className="select-outer"
                variant="outlined"
              >
                <InputLabel>
                  {this.t(`${configJSON.stageTxt}`)}
                </InputLabel>
                <Select
                  value={this.state.filterStageVal}
                  name="filterStageVal"
                  onChange={this.handleFiltersInputChange}
                  label={this.t(`${configJSON.chooseCategory}`)}
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  data-test-id="stage-filter"
                >                  
                  {configJSON.stageState.map((unit: any) => {
                    return (
                      <MenuItem value={unit.value} key={unit.value} className="status-item">
                      <Box className="status-name">{this.t(`${unit.name}`)}</Box>
                      <Box className={`status ${unit.className}`}></Box>
                    </MenuItem>
                    )
                  })}

                </Select>
              </FormControl>      
            <Typography className="filter-headding">{this.t(`${configJSON.dealAmountTxt}`)}</Typography>
            <Box className="form-row">
            <Box className="form-col col6">
            <Box className="form-control">
                   <CustomInputWeb
                      type="number"
                      label={this.t(`${configJSON.fromTxt}`)}
                      value={this.state.fromAmount}
                      name="fromAmount"
                      onChange={this.handleFiltersInputChange}                
                      data-test-id="fromAmount"
                    />
              </Box>
              </Box>
              <Box className="form-col col6">
                <Box className="form-control">
                    <CustomInputWeb
                      type="number"
                      label={this.t(`${configJSON.toTxt}`)}
                      value={this.state.toAmount}
                      name="toAmount"
                      onChange={this.handleFiltersInputChange}
                      data-test-id="toAmount"
                    />
               </Box>
              </Box>
              </Box>  
              <Typography className="filter-headding">{this.t(`${configJSON.closeByDateTxt}`)}</Typography>
              <Box className="form-row">
            <Box className="form-col col6">
            <Box className="form-control">          
             <FormControl
                className="select-outer"
                variant="outlined"
              >
                <InputLabel>
                {this.t(`${configJSON.closeByDateTxt}`)}
                </InputLabel>
                <Select
                  value={this.state.closeDate}
                  name="closeDate"
                  onChange={this.handleFiltersInputChange}
                  label= {this.t(`${configJSON.closeByDateTxt}`)}
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  data-test-id="closeDate"
                > 
                 <MenuItem value="on_or_before">{this.t(`${configJSON.onOrBefore}`)}</MenuItem>
                 <MenuItem value="on_or_after">{this.t(`${configJSON.onOrAfter}`)}</MenuItem>
                </Select>
              </FormControl> 
              </Box>
              </Box>
              <Box className="form-col col6">
                <Box className="form-control">
                <CustomDatePicker
                  t={this.t}
                  currentDate=""
                  onOpenPickNewDate={false}
                  placeholder={this.t(`${configJSON.selectDateFilter}`)}
                  onChange={this.changeDateFilter}               
                  data-test-id="select-date"
                />                 
               </Box>
              </Box>
              </Box>                    
              <Typography className="filter-headding">{this.t(`${configJSON.contactTxt}`)}</Typography>
              <FormControl
                className="select-outer"
                variant="outlined"
              >
                <InputLabel>
                  {this.t(`${configJSON.selectContactTxt}`)}
                </InputLabel>
                <Select
                  value={this.state.contact}
                  name="contact"
                  onChange={this.handleFiltersInputChange}
                  label={this.t(`${configJSON.selectContactTxt}`)}
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  data-test-id="contact"
                >
                  {uniqueContacts.map((contact: any) => {
                    return (
                      <MenuItem key={contact.id} value={contact.id}>
                         {contact.name}
                      </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>           
          
              <Typography className="filter-headding">{this.t(`${configJSON.dealOwnerNameTxt}`)}</Typography>
              <FormControl
                className="select-outer"
                variant="outlined"
              >
                <InputLabel>
                  {this.t(`${configJSON.dealOwnerNameTxt}`)}
                </InputLabel>
                <Select
                  value={this.state.dealOwner}
                  name="dealOwner"
                  onChange={this.handleFiltersInputChange}
                  label={this.t(`${configJSON.dealOwnerNameTxt}`)}
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  data-test-id="dealOwner"
                >
                  {uniqueOwnerFilters.map((dealOwner: any) => {
                    return (
                      <MenuItem
                        key={dealOwner.value}
                        value={dealOwner.value}
                        >
                        {dealOwner.label}
                        </MenuItem>
                    );
                   })}
                </Select>
              </FormControl>            
            <Box className="view-more-dropdown">
                                <Box className="dropdown-menu-item">
                                  <Typography className="dropdown-menu-item-text">
                                    {this.t(`${configJSON.hasTasks}`)}
                                  </Typography>
                                  <Switch
                                    className="dropdown-menu-item-switch"
                                    checked={this.state.hasTasksDeals}
                                    onChange={this.handleChecked}
                                    name="hasTasksDeals"
                                    data-test-id="hasTaskDeals"
                                  />
                                </Box>
                                <Box className="dropdown-menu-item">
                                  <Typography className="dropdown-menu-item-text">
                                    {this.t(`${configJSON.hasAttachment}`)}
                                  </Typography>
                                  <Switch
                                    className="dropdown-menu-item-switch"
                                    checked={this.state.hasAttachment}
                                    onChange={this.handleChecked}
                                    name="hasAttachment"
                                  />
                                </Box>
                                <Box className="dropdown-menu-item">
                                  <Typography className="dropdown-menu-item-text">
                                    {this.t(
                                      `${configJSON.hasAssociatedProduct}`
                                    )}
                                  </Typography>
                                  <Switch
                                    className="dropdown-menu-item-switch"
                                    checked={this.state.hasAssociatedProduct}
                                    onChange={this.handleChecked}
                                    name="hasAssociatedProduct"
                                  />
                                </Box>
                 </Box>
            
              <Button className={`primary-btn2 ${classes.primaryButton}`} 
               data-test-id="applyFilters" onClick={this.applyFilters}>
                {this.t(`${configJSON.txtApplyFilters}`)}</Button>
        </Box>
      </Box>
    </Modal>
  )};
  projectPopover = (classes:any) => {
    const { popoverAnchorEl,isEditing } = this.state;
  
    const popOverOpen = Boolean(popoverAnchorEl);
    const popoverId = popOverOpen ? "simple-popover" : undefined; 

    return (
    <Popover
      id={popoverId}
      open={popOverOpen}
      anchorEl={popoverAnchorEl}
      onClose={this.popoverHandleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      classes={{ paper: `${classes.createTaskBox}` }}
      data-test-id="create-project-popover"
      onKeyDown={(event) => {this.handleProjectEscEvent(event)}}
    >
      <Box
        onClick={this.popoverHandleClose}
        className="auth-back show-sm"
      >
        <i>
          <svg
            className="MuiSvgIcon-root"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
          </svg>
        </i>
      </Box>
      <Box className="task-heading">
        <Box className="heading-left">
          <Typography className="modal-title" component="h2">
            {isEditing ? this.t(configJSON.editProjectTxt) : this.t(configJSON.createProjectTxt)}
          </Typography>
          <Box component="p" className="sub-txt">
            {isEditing
              ? this.t(configJSON.editProjectSubTxt)
              : this.t(configJSON.letsCreateProjectTxt)}
          </Box>
        </Box>
        <Box className="heading-right">
            <Box
              className="heading-icon hide-sm"
              onClick={this.saveAsDraftProject}
              data-test-id="saveAsDraftProject"
            >
              <img src={minimizeGray} alt="minimizeGray" />
            </Box>
          <Box
            className="heading-icon hide-sm"
            data-test-id="popoverProjectClose"
            onClick={this.popoverHandleClose}
          >
            <img src={closeGray} alt="closeGray" />
          </Box>
        </Box>
      </Box>
      <Box className="task-description">
        <Box className="task-form" component="form">
          <Box className="form-info-wrapper">
            <Box className="form-row">
              <Box className="form-col">
                <CustomInputWeb
                  type="text"
                  label={this.t(`${configJSON.txtTitle}`)}
                  value={this.state.title}
                  isRequired={true}
                  name="title"
                  onChange={this.handleOnChange}
                  errors={this.state.popuperror.title}
                  data-test-id="crateProjectTitle"
                />
              </Box>
            </Box>
            <Box className="form-row">
              <Box className="form-col">
                <FreeSoloCreateOption
                  options={this.state.categoryList.map((val) => {
                    return {
                      id: val.id,
                      title: val.attributes.category,
                    };
                  })}
                  addEditCallback={this.addCategory}
                  addLinkTitle={this.t(`${configJSON.addNewCategoryTxt}`)}
                  noOptionTitle={this.t(`${configJSON.noCategoryFoundTxt}`)}
                  label={this.t(`${configJSON.categoryTxt}`)}
                  id="job-id"
                  onChange={this.categoryChangeHandler}
                  value={this.state.category}
                  errors={this.state.popuperror.category}
                  deleteCallback={this.deleteCategory}
                  errorMsgNoRecord={this.t(`${configJSON.noCategoryCreatedTxt}`)}
                  data-test-id="projectCategory"
                  notRequired={true}
                />
                  <ConfirmActionModal
                    isOpen={this.state.isDeletingCategory}
                    handleClose={this.deleteCategoryModalClose}
                    modalConfirmAction={this.deleteCategoryApi}
                    deleteTeamId={this.state.deleteCategoryId}
                    modalMessage={this.t(`${configJSON.confirmationTxt}`)}
                    confirmBtnTxt={this.t(`${configJSON.txtDelete}`)}
                    modalHeading={this.t(`${configJSON.deleteCategoryTxt}`)}
                    cancelBtnTxt={this.t(`${configJSON.txtCancel}`)}
                    data-testid="confirmDeleteCategory"
                  />
              </Box>
            </Box>
            <Box className="form-row">
              <Box className="form-col col6">
                <Box className="form-control">
                  <CustomDatePicker
                    placeholder={this.t(`${configJSON.startDateTxt}`)}
                    currentDate={this.state.startDate}
                    onChange={this.changeStartDate}
                    onOpenPickNewDate={false}
                    data-test-id="createProjectstartDate"
                    required={true}
                    error={this.state.popuperror.startDate}
                    inPortal={true}
                    maxDate={this.state.endDate == ""?undefined:this.state.endDate}
                    t={this.t}
                  />
                </Box>
              </Box>
              <Box className="form-col col6">
                <Box className="form-control">
                  <CustomDatePicker
                    t={this.t}
                    placeholder={this.t(`${configJSON.endDateTxt}`)}
                    currentDate={this.state.endDate}
                    onChange={this.changeEndDate}
                    onOpenPickNewDate={false}
                    data-test-id="createProjectEnddate"
                    showPastWarning={true}
                    inPortal={true}
                    minDate={this.state.startDate == ""?undefined:this.state.startDate}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="form-row">
              <Box className="form-col">
                <CustomInputWeb
                  type="text"
                  label={this.t(`${configJSON.txtDescription}`)}
                  isMultiline
                  rows={4}
                  value={this.state.description}
                  name="description"
                  onChange={this.handleOnChange}
                  data-test-id="crateProjectDesc"
                />
              </Box>
            </Box>
          </Box>
          <Box className="attachments-container">
          <FileDrop
                  onDrop={()=>this.handleOnDropEvent}
                  onDragOver={this.dragEnterEvent}
                  onDragLeave={this.dragLeaveEvent}
                  data-test-id="handleOnDrop"
                >
                  <Box
                    className={`${classes.fileUploadWrapper} ${this.state
                      .fileDropEvent && classes.fileDropDrag} `}
                    onClick={this.onBrowseEvent}
                  data-test-id="onBrowse"
                  >
                    <form ref={this.formRef}>
                      <input
                        name="upload-photo"
                        accept="*"
                        type="file"
                        onChange={this.handleOnDropEvent}
                        multiple
                        hidden
                        ref={this.fileRef}
                        data-test-id="add-project-attachment"
                      />
                    </form>
                    <Box className="file-upload-block 1">
                      <Box className="file-subtxt" component="p" >
                        {this.t(`${configJSON.clickHereTxt}`)}
                        <Box className="browse-link" component="span">
                          {this.t(`${configJSON.browseTxt}`)}
                        </Box>
                        {this.t(`${configJSON.yourPictureTxt}`)}
                      </Box>
                      <Box className="upload-icon 1">
                        <img src={uploadImg} alt="uploadImg1" />
                      </Box>
                      <Button className={`secondary-btn2 ${classes.secondaryButton}`}>{this.t(`${configJSON.addAttachmentsTxt}`)}
                      </Button>
                    </Box>
                  </Box>
          </FileDrop>
          </Box>
              {this.state.filesUploaded.length > 0 && (
               <List style={{display:"flex"}}>
                  {this.state.filesUploaded.map((file: any, index: any) => {
                    return(
                    <ListItem key={file.name}>
                  <Box className="form-info-wrapper">         
                  <Box className="upload-list-row">
                      <Box className="upload-col col3">
                <Box className="upload-inner">
                  <Box className="close-icon" data-test-id="removeFileUPload" 
                  onClick={()=>this.removeFileUPloadEvent(index)}>
                    <CloseIcon />
                  </Box>
                  <Box className="upload-img">
                    {getPlaceholderImage(file.type)}
                  </Box>                
                </Box>
                <Box className="upload-details">
                  <Box className="img-name">{file.name || file.filename}</Box>
                </Box>
                     </Box>
                     </Box>
                   </Box>
                    </ListItem>
                  )})}
                </List>
              )}             
        </Box>
      </Box>
      <Box className="createtask-bottom">
        <Box className="createtask-left">
          <Box className="outerbutton-wrapper">
            <Box                
              className="outer-link"
              onClick={this.projectLeadHandler}
              data-test-id="projectLeadAssignee"
            >
              {this.state.assignees.length > 0 ? (
                <>
                  <Box className="cancel-link">
                    <CancelIcon
                      onClick={(et: any) => {
                        et.stopPropagation();
                        et.preventDefault();
                        this.setState({ assignees: [] });
                      }}
                      data-test-id="cancelLinkId"
                    />
                  </Box>
                  <Box className="assignee-list">
                  {this.displayAssigneeOptions()
                    .filter((x: any) =>
                      this.state.assignees.includes(x.id)
                    )
                    .slice(0, 4)
                    .map((option: any) => {
                      return (
                        <Box className="assignee-bubble" key={option.id}>
                          {option.initials}
                        </Box>
                      );
                    })}
                  {this.state.assignees.length > 4 && (
                    <Box className="assignee-bubble">
                      +{this.state.assignees.length - 4}
                    </Box>
                  )}
                  </Box>
                </>
              ) : (
                <img src={accountPlus} alt="accountPlus" />
              )}
            </Box>
          </Box>
        </Box>
        <Box className="createtask-right">
          <Button
            className={`primary-btn ${classes.primaryButton}`}
            onClick={this.createProject}
            data-test-id="saveProjectBtn"
          >
            {isEditing
              ? this.t(`${configJSON.updateProjectTxt}`)
              : this.t(`${configJSON.createProjectTxt}`)}
          </Button>
        </Box>
      </Box>
    </Popover>
    )
  }

  projectCreatedModal=(classes:any)=>{
    return(
      <Modal
        open={this.state.isActiveProjectCreated}
        onClose={this.projectCreatedModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalDialog}        
      >
      <Box className={`${classes.modalDialogBox} project-created-dialog`}>
        <Box className="modal-dialoginner">
          <Box className="modal-heading">
            <Box                
              className="close-icon"
              onClick={this.projectCreatedModalClose}
              data-test-id="projectCreatedModalClose"
            >
              <CloseIcon />
            </Box>
          </Box>
          <Box className="modal-description">
            <Box className="project-create-wrapper">
              <Box className="project-img">
                <img src={projectCreate} alt="projectCreate" />
              </Box>
              <Typography className="modal-title" component="h2">                 
                {this.t(`${configJSON.projectCreatedMsgTxt}`)}
              </Typography>
              <Box component="p" className="sub-txt">
                {this.t(`${configJSON.projectCreateSuccessSubTxt}`)}
              </Box>
            </Box>
          </Box>
          <Box className="modal-footer">
            <Button
              className={`secondary-btn ${classes.secondaryButton}`}
              title="Create Another Project"
              onClick={this.createAnotherProject}
              data-test-id="createAnotherProjectId"
            >                
              {this.t(`${configJSON.createAnotherProject}`)}
            </Button>
            <Button
              className={`primary-btn ${classes.primaryButton}`}
              title="Create Tasks"
              onClick={this.taskOpenPopover}
            >
              {this.t(`${configJSON.createTaskTxt}`)}
            </Button>
          </Box>
        </Box>
      </Box>
      </Modal>
    )
  }

  createProjectBlock=(classes:any)=>{
    const popOverOpen = Boolean(this.state.popoverAnchorEl);
    return(
        <Box className={classes.createTaskWrapper}>
          <Box className="task-actions">
            {this.state.draftProjects.map((draftProject: any) => {
              return (
                  <Button
                    className={`secondary-btn ${classes.secondaryButton}`}
                    key={`draftProject-${draftProject.id}`}
                  >
                    <Box
                      className="draft-name"
                      component="span"
                      onClick={(e: any) => this.openDraftProject(e, draftProject.id)}
                      data-test-id="draftProjectId"
                    >
                      <img
                        className="draft-img"
                        src={fileTree}
                        alt="fileTree"
                      />
                      {draftProject.title == "" ? this.t(configJSON.draftTxt) : draftProject.title}
                    </Box>
                    <Box                      
                      className="close"
                      onClick={(e: any) => this.deleteDraftProject(draftProject.id)}
                      data-test-id="draftProjectDeleteid"
                    >
                      <img src={closeGray} alt="closeGray" />
                    </Box>
                  </Button>
              );
            })}
          </Box>
        </Box>
    )
  }

  projectListItem=(classes:any, option:any,selected:boolean=true)=>{
    return(
      <ListItem
      key={option.id}
      value={option.id}
      onClick={() => this.changeProjectLead(option.id)}
      data-test-id="projectLead"
    >
      <Box className="assignee-detail">
        <Box className="assignee-left">
          <Box className="assignee-profile">
            {" "}
            {option.initials}
          </Box>
          <Box className="assignee-info">
            <Box className="assignee-name">
              {option.title}
            </Box>
            <Box className="assignee-email">
              {option.email}
            </Box>
            <Box className="chip-wrapper">
              {option.team
                .slice(0, 2)
                .map((teamName: string) => {
                  return (
                    <Chip
                      key={teamName}
                      className={classes.chipButton}
                      // label="Sales Department"
                      label={teamName}
                    />
                  );
                })}

              {option.team.length > 2 && (
                <Chip
                  className={`more-btn ${classes.chipButton}`}
                  label={`+${
                    option.team.length - 2
                  } ${this.t(configJSON.moreTxt)}`}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box className="assignee-right">
          <Box className={selected?"assinee-selected":"assinee-unselected"}>
            <img
              className="normal-img"
              src={checkBoxEmpty}
              alt="checkBoxEmpty"
            />
            <img
              className="selected-img"
              src={checkBoxFilled}
              alt="checkBoxFilled"
            />
          </Box>
        </Box>
      </Box>
    </ListItem>
    )

    }

    assigneeListHeader=(classes:any,selAssignee:any[])=>{
      const assignLength = this.displayAssigneeOptions().filter(
        (x: any) =>
          !selAssignee.includes(x.id)
      ).length
      return(
        <ListSubheader className="multiselect-membres">
            <Box className="total-member">
              {assignLength}&nbsp;
              {assignLength >1 ? this.t(`${configJSON.membersTxt}`) :this.t(`${configJSON.memberTxt}`)}
            </Box>
           {this.sortByDesign(classes,this.sortByAssigneeHandler)}
      </ListSubheader>
      )
    }

    sortByDesign = (classes:any,onChange:any)=>{

      return (
        <FormControl className="select-control sortby-control">
          <Select
            data-testid="sortTeams"
            displayEmpty
            defaultValue={this.t(`${configJSON.txtSortBy}`)}
            renderValue={(value: any) => {
              return <Box>{value}</Box>;
            }}
            IconComponent={ExpandMoreIcon}
            onChange={onChange}

            MenuProps={{
              anchorOrigin: {
                horizontal: "left",
                vertical: "bottom",
              },
              transformOrigin: {
                horizontal: "left",
                vertical: "top",
              },
              getContentAnchorEl: null,
              classes: { paper: classes.dropdownStyle },
            }}
          >
            <MenuItem value="A-Z">{this.t(`${configJSON.aTozTxt}`)}</MenuItem>
            <MenuItem value="Z-A">{this.t(`${configJSON.zToaTxt}`)}</MenuItem>
          </Select>
        </FormControl>
      )
    }

  projectLeadModal = (classes:any) => {
    return (
      <Modal
            open={this.state.isActiveChangeProjectLead}
            onClose={this.projectLeadChangeModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={classes.modalDialog}
            data-testid="project-lead"
          >
            <Box className={`${classes.modalDialogBox} project-dialogbox`}>
              <Box className="modal-dialoginner">
                <Box className="modal-heading">
                  <Typography className="modal-title" component="h2">                      
                   {this.t(`${configJSON.changeProjectLeadTxt}`)}
                  </Typography>
                  <Box                     
                    className="close-icon"
                    onClick={this.projectLeadChangeModalClose}
                    data-test-id="projectLeadModalClose"
                  >
                    <CloseIcon />
                  </Box>
                </Box>
                <Box className="modal-description">
                  <Box component="p" className="sub-txt">
                    {this.t(`${configJSON.selectSubTxt}`)}
                  </Box>
                  <Box className="modal-form" component="form">
                    <List className="assignee-multiSelect-list selected-list">
                      <ListSubheader>
                        <CustomInputWeb
                          placeholder={this.t(`${configJSON.searchProjectLeadTxt}`)}
                          fullWidth={true}
                          autoFocus={true}
                          name="assigneeSearchText"
                          onChange={this.handleOnChange}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              e.stopPropagation();
                            }
                          }}
                          data-test-id="projectLeadSearch"
                        />
                      </ListSubheader>
                      <ListSubheader className="multiselect-subheader">
                        {this.t(`${configJSON.projectLeadTxt}`)}
                        <Box                           
                          className="tasklist-link"
                          onClick={this.removeProjectLead}
                          data-test-id="clearProjectLead"
                        >
                          {this.t(`${configJSON.projectRemoveTxt}`)}
                        </Box>
                      </ListSubheader>
                      
                      {this.displayAssigneeOptions()
                        .filter((x: any) => this.state.assignees.includes(x.id))
                        .map((option: any) => (
                          this.projectListItem(classes,option)
                        ))}
                      <ListSubheader className="multiselect-subheader">
                        {this.t(`${configJSON.txtUnassigned}`)}
                      </ListSubheader>
                    </List>
                    <Box className="tasklist-tabwrapper">
                     
                        <List className="assignee-multiSelect-list">
                          {this.assigneeListHeader(classes,this.state.assignees)}
                          {this.sortAssignees(this.displayAssigneeOptions())
                            .filter(
                              (x: any) => !this.state.assignees.includes(x.id)
                            )
                            .map((option: any) => (
                              this.projectListItem(classes,option,false)
                            ))}
                        </List>
                      
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
      </Modal>
    )
  }

  render() {
    const { classes } = this.props;
    const { notifications } = this.state;
    const fetchMoreData = this.fetchMoreData;

    return (
      <>
        <AppBar
          position="static"
          className={`profile-header ${classes.HeaderWrapper}`}
        >
          <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
          <Box className="header-left">
            <Box className="auth-logo">
              <img src={imgLogo} alt="logo" />
            </Box>
            <Box component="nav" className="header-nav">
              <List className="nav-list">
                <ListItem>
                  <RoleSelectionDropdown navigation={this.props.navigation}/>
                </ListItem>
                <ListItem>
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    // to="/dashboard"
                    to={JSON.parse(localStorage.getItem("selectedRole") || "{}")?.link || "/dashboard"}
                  >
                    {this.t(`${configJSON.dashboard}`)}
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                    className="nav-link"
                    to="/EScore"
                  >
                    {this.t(`${configJSON.EScore}`)}
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink className="nav-link" to="/inventorymanagement">
                    {this.t(`Inventory`)}
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    to="/direct-emails"
                    isActive={(match, location) => {
                      return [
                        "/email-campaigns",
                        "/direct-emails",
                        "/contacts",
                        "/groups",
                      ].includes(location.pathname);
                    }}
                  >
                    {this.t(`${configJSON.marketing}`)}
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink className="nav-link" to="/etoh-shop">
                    {this.t(`${configJSON.etohShop}`)}
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    to="/project"
                  >
                    {this.t(`${configJSON.taskList}`)}
                  </NavLink>
                </ListItem>
              </List>
            </Box>
          </Box>
          <Box className="header-right">
            <Link to="#" className={`link-btn`} data-test-id="createnewid" title="+ Create New" onClick={(e) => this.createTaskOpen(e)}>
              + {this.t(`${configJSON.createNew}`)}
            </Link>
            <Menu
              id="simple-menu-create"
              anchorEl={this.state.handleCreateTaskEl}
              open={Boolean(this.state.handleCreateTaskEl)}
              onClick={() => this.closeCreateTask()}
              data-test-id="closeMenuCreateTask"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              getContentAnchorEl={null}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              className={`${classes.createdropDropdown} drop-dropdown`}
            >
              <MenuItem 
                data-test-id="task-menu"
               ref={this.popoveRef}
               onClick={() => this.setState({
                popoveAnchorEl: this.popoveRef.current,
               })}
              >
                {this.t(`${configJSON.task}`)}
              </MenuItem>
              <MenuItem
                data-test-id="project-menu"
                ref={this.popoverRef}
                onClick={() => this.setState({
                    popoverAnchorEl: this.popoverRef.current,
                 })}
              >
                {this.t(`${configJSON.project}`)}
              </MenuItem>
              <MenuItem
              >
                {this.t(`${configJSON.inventory}`)}
              </MenuItem>
              <MenuItem
              >
                {this.t(`${configJSON.emailCamp}`)}
              </MenuItem>
              <MenuItem
                >
                  {this.t(`${configJSON.directemail}`)}
                </MenuItem>
                <MenuItem
                >
                  {this.t(`${configJSON.contact}`)}
                </MenuItem>
                <MenuItem
                >
                  {this.t(`${configJSON.group}`)}
                </MenuItem>
                <MenuItem
                >
                  {this.t(`${configJSON.deal}`)}
                </MenuItem>
            </Menu>
            <Box className="right-icon" onClick={this.handleOpenCartEvent}>
              <IconButton
                className="icon-btn"
                aria-controls="simple-menu"
                aria-haspopup="true"
                disableRipple
              >
                <img src={cartIcon} alt="cartIcon" />
              </IconButton>
            </Box>
            <Box className="right-icon">
              <IconButton
                className="icon-btn"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={(e) => this.handleQuictChatPopoverOpen(e)}                
                disableRipple
              >
                <img src={chatBubble} alt="chatBubble" />
              </IconButton>
              <QuickChat navigation={undefined} liveChatId={""} 
              handleQuickChatPopoverClose={this.handleQuickChatPopoverClose}
              classes={this.props.classes} anchorQuickChatEl={this.state.anchorQuickChatEl} />
            </Box>
            <Box className="right-icon">
              <IconButton
                className="icon-btn"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={(e) => this.handlePopoverOpen(e)}
                disableRipple
                data-test-id="notification-icon"
              >
                <img src={bellBadge} alt="bellBadge" />
              </IconButton>
              <Popover
                open={Boolean(this.state.anchorEl)}
                anchorEl={this.state.anchorEl}
                onClose={this.handlePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                style={{ top: "9px" }}
              >
                <Card>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: "24px",
                      paddingTop: "24px",
                      paddingRight: "24px",
                      paddingBottom: "15px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        className={`${classes.notificationBox} title`}
                      >
                        {this.t(`${configJSON.notificationsTxt}`)}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={`${classes.notificationBox} sub-title`}
                      >
                        {this.t(`${configJSON.notificationsSubTxt}`)}
                      </Typography>
                    </Box>
                    <Box>
                      <Button
                        variant="text"
                        className={`${classes.notificationBox} btn-mark-as-read`}
                        onClick={this.markReadAllNotifications}
                        data-test-id="mark-read-all-notifications"
                      >
                        {this.t(`${configJSON.markAllAsRead}`)}
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    id="parentBox"
                    style={{ maxHeight: 384, width: 432, overflow: "auto" }}
                  >
                    {notifications && notifications.length > 0 && (
                      <InfiniteScroll
                        dataLength={notifications.length}
                        //next={this.fetchMoreData ? this.fetchMoreData : null}
                        next={fetchMoreData}
                        hasMore={
                          this.state.hasNextPage
                            ? this.state.hasNextPage
                            : false
                        }
                        loader={
                          <Box className="loader" key="loader">
                            <Typography>
                              {this.t(`${configJSON.loading}`)}
                            </Typography>
                          </Box>
                        }
                        className={`${classes.notificationBox} list`}
                        scrollableTarget="parentBox"
                      >
                        {notifications.map((notification: any) => {
                          const isRead: boolean =
                            notification && notification.attributes.is_read;
                            const notificationType = notification.attributes.payload.notification_type;
                            const icon = getNotificationIcon(notificationType);
                          return (
                            <Card
                              className={
                                isRead
                                  ? `${classes.notificationBox} list-item bg-white display-center`
                                  : `${classes.notificationBox} list-item bg-gray display-center`
                              }
                              onClick={() => {
                                  this.readNotification(notification.id);
                                  const { link , isModal, id } = notification.attributes.payload;
                                  window.location.href = `${link}?isModal=${isModal}&id=${id}`;                                 
                                }
                              }
                              key={`${notification.id}`}
                              data-test-id="imageIcons"
                            >
                              <Box
                                className={`${classes.notificationBox} image-box`}
                              > 
                               <img src={icon.src} alt={icon.alt} /> 
                              </Box>
                              <Box>
                                <Typography>
                                  {this.t(`${notification.attributes.content}`)}
                                </Typography>
                                <Box
                                  className={`${classes.notificationBox} display-center`}
                                >
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    className={`${classes.notificationBox} notify-item-content`}
                                  >
                                    {moment(
                                      notification.attributes.created_at
                                    ).format("D MMMM YYYY h:mm a")}
                                  </Typography>
                                  <FiberManualRecordIcon
                                    className={`${classes.notificationBox} fiber-icon`}
                                  />
                                  <Typography
                                    variant="body2"
                                    className={`${classes.notificationBox} notify-item-content`}
                                  >
                                    {notification.attributes.payload.upper_level_type}
                                  </Typography>
                                </Box>
                              </Box>
                            </Card>
                          );
                        })}
                      </InfiniteScroll>
                    )}
                  </Box>
                </Card>
              </Popover>
            </Box>
            <Box
              className="right-icon profile-menu"
              aria-controls="simple-menu"
              aria-haspopup="true"
              data-test-id="header-click"
              onClick={(e) => this.headerMenuOpen(e)}
            >
              <IconButton
                className="icon-btn"
                name="el1"
                aria-controls="simple-menu"
                aria-haspopup="true"
                data-test-id="header-click"
                disableRipple
              >
                <img src={menuIcon} alt="menuIcon" />
                <Box className="profile-icon">{this.initials}</Box>
              </IconButton>
            </Box>
            <Menu
              id="simple-menu"
              anchorEl={this.state.headerMenuEl2}
              open={Boolean(this.state.headerMenuEl2)}
              onClose={() => this.headerMenuClose()}
              data-test-id="closeMenu"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              getContentAnchorEl={null}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              className={`${classes.dropDropdown} drop-dropdown`}
            >
              <MenuItem
                data-test-id="navigateToProfile"
                onClick={this.navigateToProfile}
              >
                {this.t(`${configJSON.profile}`)}
              </MenuItem>
              <MenuItem
                data-test-id="navigateToSettings"
                onClick={this.navigateToSettings}
              >
                {this.t(`${configJSON.settings}`)}
              </MenuItem>
              <MenuItem
                data-test-id="navigateToHelpCenter"
                onClick={this.navigateToHelpCenter}
              >
                {this.t(`${configJSON.helpCenter}`)}
              </MenuItem>
              <MenuItem
                data-test-id="handleLogoutUser"
                onClick={this.handleLogoutUser}
              >
                {this.t(`${configJSON.logout}`)}
              </MenuItem>
            </Menu>
          </Box>
          </div>
          {this.projectPopover(classes)}
          {this.projectLeadModal(classes)}
          {this.projectCreatedModal(classes)}
          {this.createTaskBlock(classes)}
          {this.createProjectBlock(classes)}
          {this.changeAssigneeModal(classes)}
        </AppBar>
        <ShoppingCartOrders
          navigation={undefined}
          id={""}
          classes={this.props.classes}
          isCartOpen={this.state.isCartOpen}
          handleCloseCartEvent={this.handleCloseCartEvent}
        />
      </>
    );
  }
}
export default withStyles(customStyles, { withTheme: true })(
  withMediaQuery("(max-width:991px)")(withHistory(GlobalHeaderWeb))
);
// Customizable Area End
