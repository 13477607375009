// Customizable Area Start
import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import {
  useStyles,
  createTeams,
  formatDate,
  TeamMemberHead
} from "./CustomTable.web";
import TableItems from "./CustomTableItems.web";
import { deleteOutline } from "../assets";
import i18n from "../../../../web/src/utilities/i18n";

export const configJSON = require("../config");

export default function TeamMember(props: any) {
  const {
    renderTeams,
    unAssignedTeams,
    renameTeam,
    deleteTeam,
    hideActionColumn,
    multipleDelete,
    isFromBulk,
    userRole,
    tableType="teams",
  } = props;

  const rows = renderTeams.map((team: any) => {
    return createTeams(
      team.id,
      team.attributes.title,
      team.attributes.users,
      5.4
    );
  });

  const classes = useStyles(props);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(configJSON.RowsPerPage);

  const teamActions ={
    renameAction: renameTeam,
    deleteAction: deleteTeam,
  }
  const t = (key:any, variables?: Record<string, any>) => {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  useEffect(() => {
    let newSelected: any[] = [];
    if (renderTeams.length > 0) {
      newSelected = selected.filter((x: any) =>
      renderTeams.find((y: any) => y.id === x)
      );
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  }, [renderTeams]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: { name: string }) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: any) => {
    if (isFromBulk) {
      return;
    }
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const unSelect = (event:React.MouseEvent<unknown>, name: string) => {
    const updatedArray = selected.filter(item => item === name);

    setSelected(updatedArray)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: any) => selected.indexOf(name) !== -1;
  let tableClass;
  if (userRole === "manager") {
    tableClass = "team-list-manager";
  } else if (userRole === "employee") {
    tableClass = "team-list-employee";
  }

  const tabName: string = tableType==="groups" ? "Group" : "Team";

  let selectionMessage: string;

  if (selected.length > 0) {
    selectionMessage = selected.length === 1
      ? `1 ${tabName} ${t("Selected")}`
      : `${selected.length} ${tabName}s ${t("Selected")}`;
  } else {
    const countTab: string = rows.length === 1 ? tabName : `${tabName}s`;
    selectionMessage = `${rows.length} ${countTab}`;
  }

  return (
    <div className={classes.root}>
      <Box>
        <Box className={classes.paginationToolbar}>
          <Box className="total-member">
            <Box className="member-count">
              {!hideActionColumn && (
                <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length < rows.length
                  }
                  checked={rows.length > 0 && selected.length === rows.length}
                  onChange={handleSelectAllClick}
                  data-test-id="handle-select-all"
                />
              )}
              {selectionMessage}
              {selected.length > 0 && (
                <Box
                  className="delete-icon"
                  onClick={() => multipleDelete(selected)}
                  data-testid="delete-icon"
                >
                  <img src={deleteOutline} alt="deleteOutline" />
                </Box>
              )}
            </Box>
          </Box>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.paginationTable}
          />
        </Box>
        <TableContainer>
          <Table
            className={`${
              hideActionColumn
                ? "bulk-upload-confirm"
                : `viewTeamList ${tableClass}`
            } ${classes.table}`}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TeamMemberHead
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
              tableType="teams"
              membersType={false}
              hideActionColumn={hideActionColumn}
              userRole={userRole}
              moduleType={tableType}
            />
            <TableBody>
              {
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: number) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const teamFields = {
                    id: row.id,
                    teamName: row.name,
                    users: row.users,
                    email: row.email,
                    team: row.team,
                    joinDate:formatDate(row.createdDate),
                  }
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      data-test-id="unAssignedListcheckbox"
                    >
                      <TableItems
                      teamDetails={teamFields}
                      teamActions={teamActions}
                      checkBoxHandler={(event: any) =>
                        handleClick(event,row.name)
                      }
                      unCheckHandler={(event: any) => unSelect(event, row.name)}
                        id={row.id}
                        teamName={row.name}
                        users={row.users}
                        joinDate={formatDate(row.createdDate)}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        tableType="teams"
                        renameAction={renameTeam}
                        deleteAction={deleteTeam}
                        hideActionColumn={hideActionColumn}
                        userRole={userRole}
                        moduleType={tableType}
                      />
                    </TableRow>
                  );
                })}

              {unAssignedTeams?.length > 0 && (
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox />
                  </TableCell>
                  <TableCell
                    className="name-cell"
                    component="th"
                    id="myid"
                    scope="row"
                    padding="none"
                  >
                    <Link
                      to={`/view-team-members/unassigned-members`}
                      className="table-link"
                    >
                      <Box className="name-col">Unassigned Members</Box>
                    </Link>
                  </TableCell>
                  <TableCell className="email-cell">
                    {unAssignedTeams.length}
                  </TableCell>

                  <TableCell className="action-link">-</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
// Customizable Area End
