// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import React from "react";
import { Message } from "../../../../framework/src/Message";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "../../../../components/src/TaskListStyles.web";
import moment from "moment";
import { normalizeDate } from "../../../../components/src/helpers/Utilities";
import { toast } from "react-toastify";
import { DateObject } from "react-multi-date-picker";
import {deleteIcon,moveIcon,copyIcon,accountPlusBlack, copyDuplicate,backCheckIcon} from "../assets";
import i18n from "../../../../web/src/utilities/i18n";
import {convertToFiles, getCurrentTime ,createCommonToastNotification} from "../../../../components/src/ReusableFunctions"
import { CostData } from "../../../../components/src/ReusableEnums";

export const configJSON = require("../config.js");
export interface Props extends WithStyles<typeof customStyles> {
  tab?: number;
  updateData?: () => void;
  redirectTo?: (tab:string) => void;
  projectId?: number;
  taskListId?: number;
}

export interface S {
  title: string;
  projectId:number;
  due_date: string;
  deadline_date: string;
  description: string;
  labour_cost: number;
  hours: string;
  minutes: string;
  seconds: string;
  unTrackedHours:string;
  unTrackedMinutes:string;
  unTrackedSeconds:string;
  priority: number;
  status: number;
  assignees: any[];
  tasks: any[];
  filterTasks: any[];
  dueDateFilter: string;
  tabsValue: number;
  todayTask: any[];
  tomorrowTask: any[];
  thisWeekTask: any[];
  thisMonthTask: any[];
  editTask: any;
  subTaskChecked: any[];
  setShow: boolean;
  isFiltering: boolean;
  selectedAssigneeVal: any;
  selectedPriorityVal: any;
  selectedProjectVal: any;
  selectedTitleVal: any;
  selectedTaskListVal: any;
  searchValue: string;
  filteredData: any;
  isAscending: boolean | null;
  searchTask: string;
  modalOpen: boolean;
  tabValue: any;
  isTeamsTab: boolean;
  viewTabsValue: number;
  assigneeTabsValue: number;
  assigneeList: any[];
  logDates: DateObject[];
  selectedDueDateVal: DateObject[];
  selectedDeadlineDateVal: DateObject[];
  logAssignees: any[];
  activityLogList: any[];
  anchorEl: any;
  projectAnchor:any;
  isActiveFilter: boolean;
  isDeletingTask: boolean;
  checkedTopTaskSelected: boolean;
  popoveAnchorEl: any;
  createTaskanchorEl: any;
  isFileValidType: boolean;
  isPause: boolean;
  isCancel: boolean;
  isLoading: boolean;
  filesUploaded: File[];
  membersList: any;
  timeStandAnchorEl: any;
  isActiveChangesAssignee: boolean;
  isActiveChangeStatus: boolean;
  selectedTasks: number[];
  newStatus: number | null;
  assigneeSearchText: string;
  selectedTaskAssignees: any[];
  sortAssignee: string;
  taskHours: string;
  unTrackedTaskours: string;
  draftTasks: any[];
  currentDraftId: number;
  showHoursModal: boolean;
  error: {
    title: string;
    due_date: string;
    tasklistName: string;
  };
  addNote: string;
  changePriorityViewEl: any;
  changePriorityTaskEl: any;
  changePriorityTopEl: any;
  selectedSortByVal: string;
  teams: any[];
  deleteTaskId: number;
  priorityId: number;
  taskHoverIsActive: boolean;
  changeDueDateTaskId: number;
  isEditing: boolean;
  updateTaskId: number;
  taskTopDetailsAnchorEl: any;
  isRenameTasklist: boolean;
  isDeletingTasklist: boolean;
  isActiveMoveTasklist: boolean;
  moveTasklistChecked: any;
  tasklistName: string;
  taskListObject: {
    id: number;
    project: number;
    name: string;
  };
  tasklistPopoveAnchorEl: any;
  projectList: any[];
  allTaskList: any[];
  currentProject: any;
  showDeleteProject: boolean;
  projectTaskList: any[];
  datePickerOpen:boolean;
  taskListDetails:any;
  projectViewOpen:boolean;
  showEditProject:boolean;
  projectStatusChange:number;
  showTimesheet:boolean;
  isMoveTasklistproject:boolean;
  taskListData:any[];
  taskID:any;
  showAddExpenditures:boolean;
  showAddProductsAssets:boolean;
  showAddDeal:boolean;
  expenditures:any;
  allDealsData:any;
  selectedDeals:string;
  singleDealData: any;
  isSearching:boolean;
  searchResults:any;
  sortBySelectedVal:string;
  isDealsFilter:boolean;
  filterStageVal:string;
  contact:string;
  dealOwner:string;
  hasTasksDeals: boolean;
  hasAttachment: boolean;
  hasAssociatedProduct: boolean;
  fromAmount:string;
  toAmount:string;
  selectedDate:string;
  closeDate:string;
  showInventoryAssets:boolean;
  selectedProductsId:any;
  viewSelectedProducts:boolean;
  selectedProductsData:any;
  fileDrop:boolean;
  attachmentTaskRemove:boolean;
  attachmentID:string;
  isUpdating:boolean;
  movedTasklist:string;
  isInventoryAssests:boolean;
  openConsumableAnchorElMore:any;
  openEditConsumable:boolean;
  staticID:number;
  itemID:string;
  totalCostDetails:CostData;
  productCountAndName:any;
  selectedTeamList:string[];
}

export interface SS {
  id: any;
}

export default class TaskListController extends BlockComponent<Props, S, SS> {
  datePickerRef: React.RefObject<any>;
  popoverRef: React.RefObject<any>;

  taskFilterApiID: string = "";
  getTaskDetailApiID: string = "";
  createTaskApiID: string = "";
  changePriorityApiID: string = "";
  changeStatusApiID: string = "";
  viewChangeStatusApiID: string = "";
  editTaskApiID: string = "";
  memberListApiID: string = "";
  activityLogFilterApiID: string = "";
  createDuplicateTaskApiID: string = "";
  removeAssigneesApiID: string = "";
  taskDeleteApiID: string = "";
  activityLogApiID: string = "";
  addAssigneeApiID: string = "";
  updateAssigneeApiID: string = "";
  fetchTeamApiCallId: string = "";
  updateDueDateApiId: string = "";
  undoActionAPICalld: string = "";
  deleteTasklistApiID: string = "";
  editTasklistApiID: string = "";
  moveTasklistApiID: string = "";
  projectListApiID: string = "";
  taskListCreateApiId: string = "";
  tasklistAddApiId: string = "";
  deleteProjectApiID:string="";
  projectDetailApiID:string="";
  taskListApiID: string = "";
  taskDetailApiID:string="";
  projectTasklistViewApiID: string = "";
  cloneProjectApiID:string="";
  statusUpdateApiID:string="";
  updateUnTrackedHoursApiID:string ="";
  userSessionData: any;
  userToken: any;
  userRole:any;
  draftTaskId: number = 1;

  fileRef: React.RefObject<HTMLInputElement>;
  formRef: React.RefObject<HTMLFormElement>;
  apiCallsCount: number = 1;
  DATE_FORMAT: string = "YYYY-MM-DD";
  setErrorTaskList: any;
  showDialogTaskList: any;
  currenTaskList: {
    id: number;
  } = { id: 0 };
  getTaskListRequestApiID: string="";
  moveTasklistProjectApiID:string="";
  getAllDealsRequestId: string = "";
  getSingleDealRequestId: string = "";
  searchDealAPICallId: string = "";
  attachmentsReqestApiID:string ="";
  getTotalCostDetailApiID:string="";
  getAllProductsFromInventoryApiID:string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.datePickerRef = React.createRef();
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
 

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.popoverRef = React.createRef();
    this.fileRef = React.createRef();
    this.formRef = React.createRef();

    this.state = {
      title: "",
      projectId:0,
      due_date: "",
      deadline_date: "",
      description: "",
      labour_cost: 30.5,
      hours: "",
      minutes: "",
      seconds: "00",
      unTrackedHours:'',
      unTrackedMinutes:'',
      unTrackedSeconds:'00',
      priority: 0,
      status: 0,
      assignees: [],
      tasks: [],
      filterTasks: [],
      dueDateFilter: "",
      tabsValue: props.tab ?? 0,
      todayTask: [],
      tomorrowTask: [],
      thisWeekTask: [],
      thisMonthTask: [],
      editTask: null,
      subTaskChecked: [0],
      setShow: false,
      isFiltering: false,
      selectedAssigneeVal: [],
      selectedPriorityVal: [],
      selectedProjectVal: [],
      selectedTitleVal: [],
      selectedTaskListVal: [],
      searchValue: "",
      filteredData: [],
      isAscending: null,
      searchTask: "",
      modalOpen: false,
      tabValue: "1",
      isTeamsTab: true,
      viewTabsValue: 0,
      assigneeTabsValue: 0,
      assigneeList: [],
      logDates: [],
      selectedDueDateVal: [],
      selectedDeadlineDateVal: [],
      logAssignees: [],
      activityLogList: [],
      anchorEl: null,
      projectAnchor:null,
      isActiveFilter: false,
      isDeletingTask: false,
      checkedTopTaskSelected: false,
      popoveAnchorEl: null,
      createTaskanchorEl: null,
      isFileValidType: true,
      isPause: false,
      isCancel: false,
      isLoading: false,
      filesUploaded: [],
      membersList: [],
      timeStandAnchorEl: null,
      isActiveChangesAssignee: false,
      isActiveChangeStatus: false,

      selectedTasks: [],
      newStatus: null,
      assigneeSearchText: "",
      selectedTaskAssignees: [],
      sortAssignee: "",
      taskHours: "",
      unTrackedTaskours: "",
      draftTasks: [],
      currentDraftId: 0,
      showHoursModal: false,
      error: {
        title: "",
        due_date: "",
        tasklistName: "",
      },
      addNote: "",
      selectedSortByVal: "",
      changePriorityViewEl: null,
      changePriorityTaskEl: null,
      changePriorityTopEl: null,
      teams: [],
      deleteTaskId: 0,
      priorityId: 0,
      taskHoverIsActive: false,
      changeDueDateTaskId: 0,
      isEditing: false,
      updateTaskId: 0,
      taskTopDetailsAnchorEl: null,
      isRenameTasklist: false,
      isDeletingTasklist: false,
      isActiveMoveTasklist: false,
      moveTasklistChecked: 0,
      tasklistName: "",
      taskListObject: {
        id: 0,
        name: "",
        project: 0,
      },
      tasklistPopoveAnchorEl: null,
      projectList: [],
      allTaskList: [],
      currentProject:null,
      showDeleteProject: false,
      projectTaskList: [],
      datePickerOpen:false,
      taskListDetails:null,
      projectViewOpen:false,
      showEditProject:false,
      projectStatusChange:0,
      showTimesheet:false,
      isMoveTasklistproject:false,
      taskListData:[],
      taskID:[],
      showAddExpenditures:false,
      showAddProductsAssets:false,
      showAddDeal:false,
      expenditures:"",
      allDealsData:[],
      selectedDeals:"",
      singleDealData:[],
      isSearching:false,
      searchResults:[],
      sortBySelectedVal:"",
      isDealsFilter:false,
      filterStageVal:"",
      contact:"",
      dealOwner:"",
      hasAttachment: false,
      hasAssociatedProduct: false,
      hasTasksDeals: false,
      fromAmount:"",
      toAmount:"",
      closeDate:"",
      selectedDate:"",
      showInventoryAssets:false,
      selectedProductsId:[],
      viewSelectedProducts:false,
      selectedProductsData:[],
      fileDrop:false,
      attachmentTaskRemove:false,
      attachmentID:"",
      isUpdating:false,
      movedTasklist:"",
      isInventoryAssests:false,
      openConsumableAnchorElMore:null,
      openEditConsumable:false,
      staticID: 1,
      itemID:"",
      totalCostDetails:{  
      total_cost: "",
      inventory_costs: {},
      labour_cost: "",
      expenditures: "0.0",
      hours: "",
      actual_hours: 0,
      employee_count:0,
    },
    productCountAndName:[],
    selectedTeamList:[]
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    this.userRole = this.userToken.data.attributes.job_type;
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    this.fetchTasksOnUpdate(prevState);
    this.clearError(prevState)
    if (prevState.filterTasks !== this.state.filterTasks) {
      this.setTaskByTabs();
    } 
    if (
      prevState.searchTask !== this.state.searchTask ||
      this.state.tasks !== prevState.tasks
    ) {
      this.filterTasks();
    }   
    if (
      prevState.editTask !== this.state.editTask &&
      this.state.editTask !== null
    ) {
      //this.getActivityLog(this.state.editTask.id);
    if (this.state.editTask !== null) {
        const result: string[] = this.state.editTask.attributes.assignees.map(
          (item: any) => String(item.id)
        );
        this.setState({ selectedTaskAssignees: result });
        if (this.state.isActiveChangeStatus) {
          const status = this.state.editTask?.attributes.status;
          let statusValue = 1;

          if (status === "open") {
            statusValue = 0;
          } else if (status === "completed") {
            statusValue = 2;
          }
          this.setState({
            newStatus: statusValue,
          });
        }
      }
    }
    if(prevState.projectId !== this.state.projectId){
      this.setState({
        error :{
          ...this.state.error,
          tasklistName: "",
        }
      })
    }     
  }

  async componentDidMount(): Promise<void> {
    const lang = localStorage.getItem("lang") ?? "en";
    await (i18n as any).changeLanguage(lang);
    this.getTasks(true);
    this.getMembersList();
    this.getProjects();
    if (this.props.taskListId) {
      this.getTaskListDetails(this.props.taskListId);
    }
    if (this.props.projectId) {
      this.getProjectTasklist(this.props.projectId);
      this.projectDetails(this.props.projectId)
    } else {
      //this.getTaskLists();
    }
    this.getTeams();
  }

  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  dragEnter = () => {
    this.setState({
      fileDrop: true,
    });
  };
  dragLeave = () => {
    this.setState({
      fileDrop: false,
    });
  };
  onBrowse = () => {
    if (this.fileRef.current !== null) {
      this.fileRef.current.click();
    }
  };
  
  handleUploadEvent =()=>{
    this.setState({isUpdating:true})
  }

  handleOnDrop = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({attachmentTaskRemove:true,attachmentID:""})
    const files = event.target.files;
    if (files) {
      const newFilesArray = Array.from(files);
  
      this.setState((prevState) => {
        const allFiles = [...prevState.filesUploaded, ...newFilesArray];
        const uniqueFiles = Array.from(
          new Map(
            allFiles.map((file) => [`${file.name}-${file.lastModified}`, file])
          ).values()
        );  
        return { filesUploaded: uniqueFiles };
      });
      if(this.state.isUpdating){
        this.addAttachmentsForProjectEvent(newFilesArray);       
      }
    }   
  };

  removeFileUPload = (index: any) => {
    const updatedArray = [
      ...this.state.filesUploaded.slice(0, index),
      ...this.state.filesUploaded.slice(index + 1),
    ];
    this.setState({ filesUploaded: updatedArray });
  };

  handleShowInventoryAssets = ()=>{
    this.setState({showInventoryAssets:true})
  }
  handleShowInventoryAssetsClose = ()=>{
    this.setState({showInventoryAssets:false})
  }
  handleViewInventoryAssets = ()=>{
    this.setState({viewSelectedProducts:true})
  }
  handleViewInventoryAssetsClose = ()=>{
    this.setState({viewSelectedProducts:false})
  }
  allIneventoryAssestsModalOpen = () => {
    this.setState({ isInventoryAssests: true });
    this.getTaskDetails(this.state.taskID);
  };
  allIneventoryAssestsModalClose = () => {
    this.setState({ isInventoryAssests: false });
  };
  openProver =(event:any, staticID:number, itemID:string) =>{
    this.setState({ openConsumableAnchorElMore: event.target , staticID:staticID , itemID:itemID});
  }
  closeProver =() =>{
    this.setState({openConsumableAnchorElMore:null})
  }
  openEditConsumableModal = ()=>{
    this.setState({ openEditConsumable: true });
  }
  closeEditConsumableModal = ()=>{
    this.setState({ openEditConsumable: false });
  }
  backToInventory = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "InventoryManagement");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  selectedProducts=(productsSelected:any,selectedProductsData:any)=>{
    this.getAllProductsFromInventory(productsSelected);
    this.setState({selectedProductsData:selectedProductsData})
    const formattedProducts = productsSelected.map((productId:any) => {
  
      return {
          id: productId , 
          dosage: ""
      };
  });

  this.setState({selectedProductsId:formattedProducts});
  }
  handleShowExpenditures = ()=>{
    this.setState({showAddExpenditures:true})
  }
  handleDealsFilter = ()=>{
    this.setState({isDealsFilter:true})
  }
  handleCloseDealsFilter = ()=>{
    this.setState({isDealsFilter:false})
  }
  handleShowAddDeals = ()=>{
    this.setState({showAddDeal:true})
    this.getAllDealsData();
  }
  handleCloseShowAddDeals = ()=>{
    this.setState({selectedDeals:"",showAddDeal:false})
  }
  handleContinouDeal = (selectedId:any)=>{
    this.setState({selectedDeals:selectedId, showAddDeal:false})
    this.getSingleDealData(selectedId)
  }
  dealsValue = (value: any) => () => {
    this.setState({ selectedDeals: value });
  };
  handleSearch = (event: { target: { value: string } })=>{
    this.setState({searchValue: event.target.value, isSearching: true },() => {this.searchDealsAPI()})
  }
  handleSortByDeals = (event : {target : {value : any}}) => {    
    if (event.target.value != undefined) {
      this.setState({sortBySelectedVal : event.target.value, isSearching: true}, () => {this.searchDealsAPI()})
    } 
  } 
  handleFiltersInputChange = (event:any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  handleChecked = (event:any) => {
    const { name, checked } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: checked,      
    }));
  };
  changeDateFilter = (date: any) => {
    const strDate = date.format("YYYY-MM-DD");
    this.setState({selectedDate:strDate})
  };
  resetFilters = ()=>{
    this.setState({
    filterStageVal:"",
    contact:"",
    dealOwner:"",
    hasAttachment: false,
    hasAssociatedProduct: false,
    hasTasksDeals: false,
    fromAmount:"",
    toAmount:"",
    closeDate:"",
    selectedDate:""
  })
  }
  applyFilters = ()=> {
    this.setState({isSearching:true})
    this.searchDealsAPI();
    this.handleCloseDealsFilter();
  }
  searchDealsAPI = () => {
    const {searchValue,
    sortBySelectedVal,
    filterStageVal,
    contact,
    dealOwner,
    hasAttachment,
    hasAssociatedProduct,
    hasTasksDeals,
    fromAmount,
    toAmount,
    closeDate,
    selectedDate
  } = this.state;

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchDealAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchDealEndPoint}?search=${searchValue}&sort_by=${sortBySelectedVal}&contact_id=${contact}&deal_owner_id=${dealOwner}&stage=${filterStageVal}&attachments=${hasAttachment}&products=${hasAssociatedProduct}&tasks=${hasTasksDeals}&from_amount=${fromAmount}&to_amount=${toAmount}&close_date_type=${closeDate}&close_date=${selectedDate}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAllDealsData = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllDealsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDealsEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getSingleDealData = (dealId:number) => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleDealRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewSingleDeal + `id=${dealId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  refreshData=()=>{
    if (this.props.projectId) {
      this.getProjectTasklist(this.props.projectId);
    } else {
      this.getTaskLists();
    }
    if (this.props.updateData) {
      this.props.updateData();
    }
  }

  fetchTasksOnUpdate = (prevState: S) => {
    if (
      prevState.selectedAssigneeVal !== this.state.selectedAssigneeVal ||
      prevState.selectedPriorityVal !== this.state.selectedPriorityVal ||
      prevState.selectedSortByVal !== this.state.selectedSortByVal ||
      prevState.selectedProjectVal !== this.state.selectedProjectVal ||
      prevState.selectedTaskListVal !== this.state.selectedTaskListVal 
      ) {
      this.getTasks();
    } 
  }
  
  clearError = (prevState:S) => {
    if (prevState.title !== this.state.title) {
      this.setState({
        error: {
          ...this.state.error,
          title: "",
        },
      });
    } 
    if (prevState.due_date !== this.state.due_date) {
      this.setState({
        error: {
          ...this.state.error,
          due_date: "",
        },
      });
    } 
    if (prevState.tasklistName !== this.state.tasklistName) {
      this.setState({
        error: {
          ...this.state.error,
          tasklistName: "",
        },
      });
    }
  }
  clearSelectedTask = () => {
    this.setState({ selectedTasks: [] });
  };

  containsText = (text: string, searchText: string) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  displayAssigneeOptions = () => {
    const assigneeNames = this.state.assigneeList.map(
      (item: {
        id: number;
        attributes: {
          first_name: string;
          last_name: string;
          email: string;
          job_role:string;
        };
      }) => ({
        id: item.id,
        title: `${item.attributes.first_name} ${item.attributes.last_name}`,
        email: item.attributes.email,
        initials: `${item?.attributes?.first_name?.charAt(
          0
        )}${item?.attributes?.last_name?.charAt(0)}`,
        job_role: item.attributes.job_role,
      })
    );
    return assigneeNames.filter((obj: { title: string }) =>
      this.containsText(obj.title, this.state.assigneeSearchText)
    );
  };
  displayTeamsOptions = () => {
    type obj = {
      id: string;
    }
    const teamsName = this.state.teams.map(
      (item: {
        id: string;
        attributes: {
          id: number;
          title: string;
          users: number;
          member: obj[]
      }}) => ({
        id: item.id,
        title: `${item.attributes.title}`,
        users: item.attributes.users,
        initials: `${item?.attributes?.title?.charAt(
          0
        )}${item.attributes.title.charAt(0)}`,
        memberId:item.attributes.member.map((item:{ id:string })=>{
            return item.id.toString()
        })
      })
    );
    return teamsName.filter((obj: { title: string }) =>
      this.containsText(obj.title, this.state.assigneeSearchText)
    );
  };
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (!responseJson.errors) {
          switch (apiRequestCallId) {
            case this.taskFilterApiID:
              this.setState({
                tasks: responseJson.data,
                isLoading: false
              });
              break;
            case this.getTaskDetailApiID:
              this.setState({
                editTask: responseJson.data,
              });
              break;
            case this.attachmentsReqestApiID:
                 this.getTaskDetails(this.state.taskID);
                 this.setState({filesUploaded:[]})
             break;
            case this.memberListApiID:
              this.setState({ assigneeList: responseJson.members.data });
              break;            
            case this.fetchTeamApiCallId:
              this.setState({
                teams: responseJson.data,
              });
              break;
            case this.undoActionAPICalld:
              this.getTasks();
              this.getMembersList();
              break;

            case this.moveTasklistApiID:
              this.createToastNotification("Tasklist Moved.", moveIcon);
              this.moveTasklistModalClose()
              break;
            case this.getTaskListRequestApiID:
               this.setState({taskListData:responseJson.data})
                break;
            case this.moveTasklistProjectApiID:
                this.moveTaskProjectResponse(responseJson);
                break;    
                case this.getAllDealsRequestId:
                  this.setState({ isLoading: false });
                  this.setState({ allDealsData: responseJson.data});                  
                  break; 
                case this.getSingleDealRequestId:
                  this.setState({ isLoading: false });
                  this.setState({ singleDealData: responseJson.data});                  
                  break; 
                case this.searchDealAPICallId:      
                  this.setState({isLoading: false})
                  this.setState({searchResults : responseJson.data}) 
                  break;
                case this.updateUnTrackedHoursApiID:
                  this.setState({isLoading: false})
                  this.createToastNotification("Task hours updated");
                  break;
                case this.getTotalCostDetailApiID: 
                   this.setState({totalCostDetails:responseJson})                 
                  break;
                case this.getAllProductsFromInventoryApiID:                   
                    this.setState({productCountAndName:responseJson.Product.data})              
                  break;  
            default:
              this.taskApiresponse(apiRequestCallId,responseJson);
              this.taskListProjectApiResponse(apiRequestCallId,responseJson);
              this.updateAssigneeApiResponse(apiRequestCallId,responseJson);
          }
        }
      }
    }
  }
  getAllProductsFromInventory = (productsSelected:any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };
    const body = {"catalogue_ids": productsSelected}     
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllProductsFromInventoryApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.allInventoryProductApiEndpint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getTotalCostDetails = (taskID:number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTotalCostDetailApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTotalCostEndpoint + `?id=${taskID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  moveTaskProjectResponse =(responseJson:{message:string})=>{
    if(responseJson.message){
      const taskCount = this.state.selectedTasks.length;
      const taskText =
      taskCount > 1
          ? this.t(`${configJSON.tasksMovedTxt}`)
          : this.t(`${configJSON.taskMovedTxt}`);
      const message = `${taskCount} ${taskText}.`;
      this.createToastNotification( taskCount===0 ? this.t(`${configJSON.taskMovedTxt}`):message , moveIcon);               
      this.moveTasklistProjectModalClose() 
      this.changePriorityViewClose()
      this.refreshData()               
    }
  }
  updateAssigneeApiResponse = (apiRequestCallId:any,responseJson:any) => {
    switch(apiRequestCallId)
    {
      case this.addAssigneeApiID:
      case this.removeAssigneesApiID:
        if(responseJson.message){
          this.createToastNotification(this.t(configJSON.taskAssign), accountPlusBlack);
        }
        if (this.apiCallsCount == 1) {
          if (this.state.modalOpen) {
            this.getTaskDetails(this.state.editTask.id);
          } else {
            this.setState({ editTask: null });
          }
        }          
        this.apiCallsCount = this.apiCallsCount - 1;
        break;
       case this.cloneProjectApiID:
        this.createToastNotification(this.t("Project Duplicated."),copyIcon);
        this.refreshData()
        break;
       case this.statusUpdateApiID:
        this.refreshData()
        break;
    }
  }
  taskApiresponse = (apiRequestCallId:any,responseJson:any) => {
    switch(apiRequestCallId)
    {
      case this.changePriorityApiID:
        this.getTasks();
        if (this.state.editTask !== null) {
          this.getTaskDetails(this.state.editTask.id);
        }
        break;
      case this.changeStatusApiID:
        createCommonToastNotification(this.t(configJSON.taskCompeletd),backCheckIcon);
       setTimeout(() => {
        this.getTasks();
        if (this.state.isActiveChangeStatus) {
          this.changeStatusModalClose();
        }
        if (this.state.editTask !== null) {
          this.getTaskDetails(this.state.editTask.id);
        }
       },5000)
        break;
      case this.updateDueDateApiId:
        if (responseJson.data.length > 0) {
          this.createToastNotification("Due date updated succesfully");
        }
        break;
      case this.createTaskApiID:
        if (this.state.currentDraftId !== 0) {
          this.setState({
            draftTasks: this.state.draftTasks.filter(
              (x) => x.id !== this.state.currentDraftId
            ),
            currentDraftId: 0,
          });
        }
        this.setState({filesUploaded:[]})
        this.getTasks();
        this.popoverHandleClose();
        this.createToastNotification("Task created successfully");
        this.refreshData();
        this.setState({filesUploaded:[]});
        break;
      case this.editTaskApiID:
        this.createToastNotification(responseJson.message);
        this.getTasks();
        this.popoverHandleClose();
        this.refreshData();
        this.setState({filesUploaded:[]})
        break;
      case this.createDuplicateTaskApiID:
        this.createToastNotification(this.t(configJSON.duplicateSuccesssMsg),copyDuplicate);
        this.setState({ modalOpen: false });
        this.getTasks();
        this.refreshData()
        break;
      case this.taskDeleteApiID:
        this.createToastNotification(responseJson.message);
        this.setState({
          selectedTasks: [],
          isDeletingTask: false,
          editTask: null,
          deleteTaskId: 0,
        });
        this.handle_modalClose();
        this.getTasks();
        this.refreshData()
        break;
      case this.activityLogApiID:
      case this.activityLogFilterApiID:
        this.setState({ activityLogList: responseJson.data });
        break; 
    }
  }

  taskListProjectApiResponse = (apiRequestCallId:any,responseJson:any) => {
    switch(apiRequestCallId){
      case this.editTasklistApiID:
        this.createToastNotification(responseJson.message);
        if (this.props.taskListId) {
        this.getTaskListDetails(this.props.taskListId);
        }
        this.renameTaskListModalClose(null);
        this.refreshData()
        break;
      case this.projectListApiID:
        this.setState({
          projectList: responseJson.data,
        });
        break;
      case this.taskDetailApiID:
        this.setState({taskListDetails:responseJson.data})
        break;
      case this.taskListApiID:             
      case this.projectTasklistViewApiID:
        this.setState({
          allTaskList: responseJson.data,
        });
        break;
      case this.taskListCreateApiId:
        this.createToastNotification(responseJson.message);
        this.tasklistPopoverHandleClose();
        this.refreshData()
        break;
      case this.tasklistAddApiId:
        this.getProjectTasklist(this.state.projectId);
        this.showDialogTaskList(false);
        this.refreshData()
        break;
      case this.deleteTasklistApiID:
        this.createToastNotification("Tasklist Deleted",deleteIcon);
        this.deleteTasklistModalClose();
        this.refreshData()
        if (this.props.redirectTo) {
          this.props.redirectTo("Project");
        }
        break;
      case this.projectDetailApiID:
        this.setState({
          currentProject: responseJson.data,
        });
        this.projectStatusSet(responseJson.data.attributes.status)
        break;
      case this.deleteProjectApiID:
        this.createToastNotification("Project Deleted", deleteIcon);
       this.deleteCallback()
        break;
    }
  }
 
  selectAssignee = (assigneeId: number) => {
    let selectedAssignee = this.state.logAssignees;
    if (selectedAssignee.includes(assigneeId)) {
      selectedAssignee = selectedAssignee.filter((x) => x !== assigneeId);
    } else {
      selectedAssignee.push(assigneeId);
    }
    this.setState({
      logAssignees: selectedAssignee,
    });
  };
  minutesChange = (event: any) => {
    const name = event.target.name as keyof S;
    let newValue = event.target.value;
    newValue = newValue.replace(/\D/g, "");
    if (Number(newValue) > 59) {
      newValue = this.state.minutes;
    }
    newValue = String(Number(newValue));
    if (Number(newValue < 10)) {
      newValue = "0" + newValue;
    }
    this.setState({ [name]: newValue } as unknown as Pick<S, keyof S>);
  };

  secondsChange = (event:any) => {
    const name = event.target.name as keyof S;
    let newValue = event.target.value;
    newValue = newValue.replace(/\D/g, "");
    if (Number(newValue) > 59) {
      newValue = this.state.seconds;
    }
    newValue = String(Number(newValue));
    if (Number(newValue < 10)) {
      newValue = "0" + newValue;
    }
    this.setState({ [name]: newValue } as unknown as Pick<S, keyof S>);
  }

  hoursChange = (event: any) => {
    const name = event.target.name as keyof S;
    let newValue = event.target.value;
    newValue = newValue.replace(/\D/g, "");
    if (Number(newValue) > 999) {
      newValue = this.state.hours;
    }
    newValue = String(Number(newValue));
    if (Number(newValue < 10)) {
      newValue = "0" + newValue;
    }
    this.setState({ [name]: newValue } as unknown as Pick<S, keyof S>);
  };

  toggleHoursModal = () => {
    this.setState({ showHoursModal: !this.state.showHoursModal });
  };


  assigneeChangeModalClose = (e: any) => {
    e.preventDefault();
    if (this.state.editTask !== null) {
      this.updateAssignee();
    } else if (this.state.popoveAnchorEl !== null) {
      this.setState({ assignees: this.state.selectedTaskAssignees });
    }

    this.setState({ isActiveChangesAssignee: false });
  };
  changeStatusModalClose = () => {
    this.setState({ isActiveChangeStatus: false });
  };

  openDraftTask = (event: any, id: number) => {
    const draftTask = this.state.draftTasks.find((x) => x.id == id);
    this.setState({
      title: draftTask.title,
      due_date: draftTask.due_date,
      deadline_date: draftTask.deadline_date,
      description: draftTask.description,
      taskHours: draftTask.taskHours,
      hours: draftTask.hours,
      minutes: draftTask.minutes,
      priority: draftTask.priority,
      assignees: draftTask.assignees,
      popoveAnchorEl: event.currentTarget,
      currentDraftId: id,
    });
  };

  deleteDraftTask = (id: number) => {
    const draftTask = this.state.draftTasks?.filter((x) => x.id !== id);
    this.setState({ draftTasks: draftTask });
  };
  saveAsDraftTask = () => {
    let draftTasks = this.state.draftTasks;
    if (this.state.currentDraftId !== 0) {
      let draftTask = draftTasks.find(
        (x: any) => x.id == this.state.currentDraftId
      );
      draftTask.title = this.state.title;
      draftTask.due_date = this.state.due_date;
      draftTask.deadline_date = this.state.deadline_date;
      draftTask.description = this.state.description;
      draftTask.taskHours = this.state.taskHours;
      draftTask.hours = this.state.hours;
      draftTask.minutes = this.state.minutes;
      draftTask.priority = this.state.priority;
      draftTask.assignees = this.state.assignees;
    } else {
      draftTasks = [
        ...draftTasks,
        {
          id: this.draftTaskId,
          title: this.state.title,
          due_date: this.state.due_date,
          deadline_date: this.state.deadline_date,
          description: this.state.description,
          taskHours: this.state.taskHours,
          hours: this.state.hours,
          minutes: this.state.minutes,
          priority: this.state.priority,
          assignees: this.state.assignees,
        },
      ];
    }
    this.setState({
      draftTasks: draftTasks,
    });
    this.draftTaskId = this.draftTaskId + 1;
    this.popoverHandleClose();
  };

  clearTaskModal = () => {
    this.setState({
      title: "",
      due_date: "",
      deadline_date: "",
      description: "",
      taskHours: "",
      priority: 0,
      status: 0,
      assignees: [],
      currentDraftId: 0,
      hours: "",
      minutes: "",
      showAddExpenditures:false,
      expenditures:"",
      selectedProductsData:[],
      selectedDeals:"",
      allDealsData:[],
      showAddDeal:false,
      showInventoryAssets:false,
      selectedProductsId:[],
      filesUploaded:[],
    });
  };
  closeTimeStand = () => {
    this.setState({ timeStandAnchorEl: null });
  };
  setHours = () => {
    let totalHours = "";
    if (this.state.hours !== "" || this.state.minutes !== "" || this.state.seconds !== "") {
      totalHours = this.state.hours == "" ? "00" : this.state.hours;
      totalHours += ":";
      totalHours += this.state.minutes == "" ? "00" : this.state.minutes;
      totalHours += ":";
      totalHours += this.state.seconds == "" ? "00" : this.state.seconds;
    }
    this.setState({
      taskHours: totalHours,
      showHoursModal: false,
    });
  };
  setUnTrackedHours = () => {
    let totalHours = "";
    if (this.state.unTrackedHours !== "" || this.state.unTrackedMinutes !== "" || this.state.unTrackedSeconds !== "") {
      totalHours = this.state.unTrackedHours == "" ? "00" : this.state.unTrackedHours;
      totalHours += ":";
      totalHours += this.state.unTrackedMinutes == "" ? "00" : this.state.unTrackedMinutes;
      totalHours += ":";
      totalHours += this.state.unTrackedSeconds;
    }
    this.setState({
      unTrackedTaskours: totalHours,
      showHoursModal: false,
    }, () => this.addUntrackedHours());
  };
  clearSelectedAssignees = () => {
    this.setState({ selectedTaskAssignees: [], selectedTeamList:[] });
  };

  sortRecords = (filterData: any) => {
    const { sortAssignee } = this.state;
    if (sortAssignee != "") {
      let leftIndex: number;
      let rightIndex: number;
      leftIndex = sortAssignee == "Z-A" ? 1 : -1;
      rightIndex = sortAssignee == "Z-A" ? -1 : 1;
      filterData = filterData.sort((member1: any, member2: any) => {
        if (member1.title.toLowerCase() < member2.title.toLowerCase())
          return leftIndex;
        if (member1.title.toLowerCase() > member2.title.toLowerCase())
          return rightIndex;
        return 0;
      });
    }

    return filterData;
  };
  changeSortByAssignee = (e: any) => {
    this.setState({ sortAssignee: e.target.value });
  };

  changeAssignee = (assigneeId: number) => {
    let selectedAssignee = this.state.selectedTaskAssignees;
    if (selectedAssignee.includes(assigneeId)) {
      selectedAssignee = selectedAssignee.filter((x) => x !== assigneeId);
    } else {
      selectedAssignee.push(assigneeId);
    }
    this.setState({
      selectedTaskAssignees: selectedAssignee,
    });
  };

  changeAssigneeWithMemberId = (assigneeId: string[], teamId:string) => {
    let selectedAssignee = this.state.selectedTaskAssignees;
    assigneeId.forEach((item:string)=>{
    if (!selectedAssignee.includes(item)) {
      selectedAssignee.push(item);
    }
  });
    this.setState({
      selectedTaskAssignees: selectedAssignee,
      selectedTeamList:[...this.state.selectedTeamList, teamId]
    });
  };

  createToastNotification = (toastMesssage: string,toastIcon?: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{toastMesssage}</div>
        <div        
          className="toast-link"
          data-test-id="undo-toast-link"
          onClick={this.undoLastAction}
        >
          undo
        </div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        icon: toastIcon ? () => <img src={toastIcon} alt="icon" /> : undefined
      }
    );
  };

  filterTasks = () => {
    let tasks = this.state.tasks;
    if (this.state.searchTask !== "") {
      tasks = tasks.filter((task) => {
        if (
          task.attributes.title
            .toLowerCase()
            .search(this.state.searchTask.toLowerCase()) !== -1
        )
          return task;
      });
    }
    this.setState({ filterTasks: tasks });
  };

  getOpenTask = () => {
    
    let taskToShow = this.state.filterTasks;
    switch (this.state.tabsValue) {
      case 1:
        taskToShow = this.state.todayTask;
        break;
      case 2:
        taskToShow = this.state.tomorrowTask;
        break;
      case 3:
        taskToShow = this.state.thisWeekTask;
        break;
      case 4:
        taskToShow = this.state.thisMonthTask;
        break;
    }
    const openTask = taskToShow.filter((task) => {
      if (task.attributes.status === "open") return task;
    });
    return openTask;
  };
  getInprogressTask = () => {
    let taskInprogress = this.state.filterTasks;
    switch (this.state.tabsValue) {
      case 1:
        taskInprogress = this.state.todayTask;
        break;
      case 2:
        taskInprogress = this.state.tomorrowTask;
        break;
      case 3:
        taskInprogress = this.state.thisWeekTask;
        break;
      case 4:
        taskInprogress = this.state.thisMonthTask;
        break;
    }
    const inProgressTasks = taskInprogress.filter((task) => {
      if (task.attributes.status === "in_progress") return task;
    });
    return inProgressTasks;
  };
  getCanceledTask = () => {
    let taskToShow = this.state.filterTasks;
    switch (this.state.tabsValue) {
      case 1:
        taskToShow = this.state.todayTask;
        break;
      case 2:
        taskToShow = this.state.tomorrowTask;
        break;
      case 3:
        taskToShow = this.state.thisWeekTask;
        break;
      case 4:
        taskToShow = this.state.thisMonthTask;
        break;
    }
    const cancelTask = taskToShow.filter((task) => {
      if (task.attributes.status === "cancelled") return task;
    });
    return cancelTask;
  };
  getCompletedTask = () => {
    let taskToShow = this.state.filterTasks;
    switch (this.state.tabsValue) {
      case 1:
        taskToShow = this.state.todayTask;
        break;
      case 2:
        taskToShow = this.state.tomorrowTask;
        break;
      case 3:
        taskToShow = this.state.thisWeekTask;
        break;
      case 4:
        taskToShow = this.state.thisMonthTask;
        break;
    }
    const completedTask = taskToShow.filter((task) => {
      if (task.attributes.status === "completed") return task;
    });
    return completedTask;
  };
  setTaskByTabs = () => {
    const today = this.state.filterTasks.filter((task) => {
      if (task.attributes.due_date === this.getTodayFullDate()) return task;
    });
    const tomorrow = this.state.filterTasks.filter((task) => {
      if (task.attributes.due_date === this.getTomorrowFullDate()) return task;
    });
    const thisWeek = this.state.filterTasks.filter((task) => {
      if (
        moment(task.attributes.due_date).isBetween(
          this.getThisWeeksDates().startDate,
          this.getThisWeeksDates().endDate
        ) ||
        moment(task.attributes.due_date).isSame(
          moment(this.getThisWeeksDates().startDate)
        ) ||
        moment(task.attributes.due_date).isSame(
          moment(this.getThisWeeksDates().endDate)
        )
      )
        return task;
    });
    const thisMonth = this.state.filterTasks.filter((task) => {
      if (
        moment(task.attributes.due_date).isBetween(
          this.getThisMonthsDates().startDate,
          this.getThisMonthsDates().endDate
        ) ||
        moment(task.attributes.due_date).isSame(
          moment(this.getThisMonthsDates().startDate)
        ) ||
        moment(task.attributes.due_date).isSame(
          moment(this.getThisMonthsDates().endDate)
        )
      )
        return task;
    });
    this.setState({
      todayTask: today,
      tomorrowTask: tomorrow,
      thisWeekTask: thisWeek,
      thisMonthTask: thisMonth,
    });
  };
  getTodayFullDate = () => normalizeDate(moment());

  getTomorrowFullDate = () => normalizeDate(moment().add(1, "day"));

  getThisWeeksDates = () => {
    const startOfWeek = moment().startOf("week");
    const endOfWeek = moment().endOf("week");

    return {
      startDate: startOfWeek.format("YYYY-MM-DD"),
      endDate: endOfWeek.format("YYYY-MM-DD"),
    };
  };

  getThisMonthsDates = () => {
    const startOfMonth = moment().startOf("month");
    const endOfMonth = moment().endOf("month");

    return {
      startDate: startOfMonth.format("YYYY-MM-DD"),
      endDate: endOfMonth.format("YYYY-MM-DD"),
    };
  };
  setTabsValue = (obj: any, val: any) => {
    this.setState({
      tabsValue: val,
    });
  };


  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as keyof S;
    const value = event.target.value;
    this.setState({ [name]: value } as unknown as Pick<S, keyof S>);
  };

  getTasks = (isDefault: boolean = false) => {
    if (!isDefault && this.props.updateData) {
      this.props.updateData();
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

   

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.taskFilterApiID = requestMessage.messageId;
    let params: string[] = [];
   const addDateParams = (paramType:string, selectedDateVal: DateObject[]) => {
    if (selectedDateVal && selectedDateVal.length > 0) {
      const startDate = new Date(selectedDateVal[0].format("YYYY-MM-DD")).toISOString().slice(0, 10);
      
      if (selectedDateVal.length === 1) {
        params.push(`${paramType}[start_date]=` + startDate);
        params.push(`${paramType}[end_date]=` + startDate);
      } else if (selectedDateVal.length > 1) {
        const endDate = new Date(selectedDateVal[1].format("YYYY-MM-DD")).toISOString().slice(0, 10);
        params.push(`${paramType}[start_date]=` + startDate);
        params.push(`${paramType}[end_date]=` + endDate);
      }
    }
  };
  
  addDateParams("deadline_date", this.state.selectedDeadlineDateVal );
  addDateParams("due_date", this.state.selectedDueDateVal);

    if (this.state.selectedAssigneeVal.length > 0) {
      params.push(`assignees=[${this.state.selectedAssigneeVal}]`);
    }
    if (this.state.selectedPriorityVal.length > 0) {
      params.push(`priority=[${this.state.selectedPriorityVal}]`);
    }
    if (this.state.selectedProjectVal.length > 0) {
      params.push(`account_block_project_id=[${this.state.selectedProjectVal}]`);
    }
    if (this.state.selectedTaskListVal.length > 0) {
      params.push(`account_block_task_list_id=[${this.state.selectedTaskListVal}]`);
    }
    if (this.state.selectedSortByVal == "Due Date") {
      params.push(`sort_by=due_date`);
    } else if (this.state.selectedSortByVal == "Time Tracked") {
      params.push(`sort_by=time_tracked`);
    }else {
      params.push(`sort_by=priority`);
    }

    if (this.props.projectId) {
      params.push(`account_block_project_id=[${this.props.projectId}]`);
    }
    if (this.props.taskListId) {
      params.push(`account_block_task_list_id=[${this.props.taskListId}]`);
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskFilterEndPoint +
        `${params.length > 0 ? "?" + params.join("&") : ""}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    this.setState({
      isLoading:true
    })
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTaskListsMoveData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTaskListRequestApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskListEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTaskDetails = (taskId: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTaskDetailApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.singleTaskEndPoint + `?id=${taskId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  attachmentRemoveEvent = (attachmentTaskID:string) =>{
    this.setState({attachmentTaskRemove:true,attachmentID:attachmentTaskID,filesUploaded:[]},
     () => this.addAttachmentsForProjectEvent([])
    )
   }
 
   addAttachmentsForProjectEvent = (tasksAttachments:any) => {
     const formdata = new FormData();
       formdata.append("attachments[0][remove]", this.state.attachmentTaskRemove.toString());
       formdata.append("attachments[0][id]", this.state.attachmentID )
       formdata.append("id", this.state.taskID?.toString());
       if(tasksAttachments){
        tasksAttachments.forEach((file:any, index:number) => {
            const key1 = `attachments[${index}][data]`;
            formdata.append(key1, file);
          });
       } 
 
     const header = {
       token: this.userToken.meta.token,
     };
 
     const requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
     );
     this.attachmentsReqestApiID = requestMessage.messageId;
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       configJSON.taskAttachments
     );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
     );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestBodyMessage),
       formdata
     );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.methodPUT
     );
 
     runEngine.sendMessage(requestMessage.id, requestMessage);
   };

  createTask = async () => {
    let error = this.state.error;
    let hasError = false;
    if (this.state.title.trim() === "") {
      error.title = this.t("Please enter title");
      this.setState({ error: error });
      hasError = true;
    }
    
    if (hasError) {
      return;
    }
    let labour_cost: number = 0;
    let assignees = [...this.state.assignees];

    if (assignees.length == 0) {
      assignees = [this.userToken.data.id];
    }
    let taskHours = this.state.taskHours;
    let hours = this.state.hours;
    let minutes = this.state.minutes;
    if (taskHours == "") {
      taskHours = "00:00";
      hours = "0";
      minutes = "0";
    }
    for (const element of assignees) {
      const assignee = this.state.assigneeList.find((x) => x.id == element);
      labour_cost +=
        (assignee.attributes.hourly_pay *
          (Number(hours) + Number(minutes) / 60)) /
        assignees.length;
    }
    const priorityMapping:{ [key: string]: string } = {
      "0": "normal",
      "1": "critical",
      "2": "emergency",
    };
    const formdata = new FormData();
    formdata.append("data[title]", this.state.title);
    formdata.append("data[due_date]", this.state.due_date);
    formdata.append("data[deadline_date]",this.state.deadline_date == ""
    ? moment(this.state.due_date).add(14, "day").format("YYYY-MM-DD")
    : this.state.deadline_date);
    formdata.append("data[description]", this.state.description);
    formdata.append("data[labour_cost]", labour_cost.toFixed(2),);
    formdata.append("data[hours]", taskHours);
    formdata.append("data[priority]", priorityMapping[this.state.priority.toString()]);
    formdata.append("data[status]", "open");
    formdata.append("data[expenditures]", this.state.expenditures);
    formdata.append("data[account_block_project_id]", this.state.projectId?.toString());
    formdata.append("data[account_block_task_list_id]", this.state.taskListObject.id.toString());
    formdata.append("data[deal_ids][]",this.state.selectedDeals.toString());
   
    if(this.state.selectedProductsId.length > 0){
      this.state.selectedProductsId.forEach((item:any) => {
        formdata.append("data[inventory_or_assets][]", JSON.stringify(item));
      });    
    }

    if(assignees.length > 0){
      assignees.forEach((productId:any) => {
        formdata.append("data[assignees][]", productId.toString());
      });    
    }
    let filesTaskArray;
   
    if (this.state.isEditing) {
      filesTaskArray = await convertToFiles(this.state.filesUploaded);
    } else {
      filesTaskArray = this.state.filesUploaded;
    }

    if(filesTaskArray){
      for (const file of filesTaskArray) {
        formdata.append("data[attachments][]", file);
      }
    }

    if(this.state.filesUploaded){
      for (const file of this.state.filesUploaded) {
        formdata.append("data[attachments][]", file);
       }
    }

    if(this.state.isEditing){
      formdata.append("data[id]", this.state.updateTaskId.toString())
    }

    if (this.state.isEditing) {
      this.editTask(formdata);
      return;
    }

    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createTaskApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskCreateEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  changeDueDate = (date: any) => {
    this.setState({
      due_date: date?.isValid ? date.format("YYYY-MM-DD") : "",
      error: { ...this.state.error, due_date: "" },
    });

    if (date?.isValid) {
      this.setState({
        deadline_date: moment(date.format("YYYY-MM-DD"))
          .add(14, "day")
          .format("YYYY-MM-DD"),
      });
    }
  };
  changeDeadlineDate = (date: any) => {
    this.setState({
      deadline_date: date?.isValid ? date.format("YYYY-MM-DD") : "",
    });
  };

  changePriority = (priority: number, id: number = 0) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changePriorityApiID = requestMessage.messageId;

    let ids = this.state.selectedTasks.map((id) => Number(id));

    if (this.state.editTask != null) {
      ids = [Number(this.state.editTask.id)];
    }
    if (id > 0) {
      ids = [Number(id)];
    }
    let body = {
      data: {
        id: JSON.stringify(ids),
        priority: priority,
      },
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskChangePriorityEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPATCH
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  dateRangeFilter = (date: any) => {
    this.setState({ logDates: date });
  };
  dueDateRangeFilter = (date: any) => {
    this.setState({ selectedDueDateVal: date });
  };
  deadlineDateRangeFilter = (date: any) => {
    this.setState({ selectedDeadlineDateVal: date });
  };

  changeStatus = () => {
    let ids;
    if (this.state.selectedTasks.length > 0) {
      ids = this.state.selectedTasks.map((id) => Number(id));
    } else {
      ids = [Number(this.state.editTask.id)];
    }

    this.updateStatus(ids, this.state.newStatus ?? 0);
  };
  viewChangeStatus = (e: any) => {
    let ids = [Number(this.state.editTask.id)];
    this.updateStatus(ids, e.target.value ?? 0);
  };
  moveTask = (taskId: number) => {
    this.updateStatus([Number(taskId)], 2);
  };
  updateStatus = (ids: number[], statusId: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changeStatusApiID = requestMessage.messageId;

    let body = {
      data: {
        id: JSON.stringify(ids),
        status: statusId,
      },
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskChangeStatusEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPATCH
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editTask = (body: any) => {
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editTaskApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskEditEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateAssignee = () => {
    let selAssignee = this.state.selectedTaskAssignees;

    if (selAssignee.length == 0) {
      selAssignee = [this.state.editTask.attributes.created_by];
    }
    const removeAssignee = this.state.editTask.attributes.assignees.filter(
      (x: any) => !selAssignee.includes(String(x.id))
    );

    const addAssignee = selAssignee.filter(
      (x: any) =>
        this.state.editTask.attributes.assignees.find(
          (y: any) => y.id == Number(x)
        ) == undefined
    );
    this.apiCallsCount = 1;
    if (removeAssignee.length > 0 && addAssignee.length > 0) {
      this.apiCallsCount = 2;
    }

    if (removeAssignee.length > 0) {
      this.removeAssignees(removeAssignee.map((x: any) => String(x.id)));
    }

    if (addAssignee.length > 0) {
      this.addAssignee(addAssignee);
    }
    if (!this.state.modalOpen) {
      this.setState({ editTask: null });
    }
  };

  getMembersList = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.memberListApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.memberListEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleClearSelection = (key: any) => {
    const newVal: any[] = [];
    const newState = { [key]: newVal } as Pick<S, keyof S>;
    this.setState({ ...newState, isFiltering: true });
    this.setState({ selectedDeadlineDateVal: [], isFiltering: true }, () => {
      this.getTasks();
    });
  };

  saveSelectedOption = (event: { target: { value: any } }, key: any) => {
    
    if (event.target.value != undefined) {
      if (key == "selectedSortByVal") {
        this.setState({ selectedSortByVal: event.target.value });
      } else {
        if(event.target.value.length >= 1 && event.target.value.includes(undefined)){
          return;
        }
        const setNewValue = event.target.value.filter(
          (x: any) => x != undefined
        );
        const newState = { [key]: setNewValue } as Pick<S, keyof S>;
        this.setState({ ...newState, isFiltering: true });
      }
    }
  };
  handleStatusChange = (event: { target: { value: any } }) => {
    if (event.target.value != undefined) {
      this.setState({ newStatus: event.target.value });
    }
  };
  handle_modalOpen = (taskId: number) => {
    this.setState({ modalOpen: true });

    this.setState({taskID:taskId})
    this.getTaskDetails(taskId);
    this.getTotalCostDetails(taskId)
  };

 

  assigneeHandler = (e: any, id: number = 0) => {
    e.preventDefault();
    this.setState({ isActiveChangesAssignee: true });
    if (this.state.editTask !== null) {
      const result: string[] = this.state.editTask.attributes.assignees.map(
        (item: any) => String(item.id)
      );
      this.setState({ selectedTaskAssignees: result });
    } else if (this.state.popoveAnchorEl !== null) {
      this.setState({ selectedTaskAssignees: this.state.assignees });
    } else {
      this.setState({
        selectedTaskAssignees: [],
      });
      if (id > 0) {
        this.getTaskDetails(id);
      }
    }
  };

  changeStatusHandler = (e: any, id: number = 0) => {
    if (id > 0) {
      this.getTaskDetails(id);
    }
    this.setState({ isActiveChangeStatus: true, newStatus: null, addNote: "" });
  };

  handle_modalClose = () => {
    this.setState({
      modalOpen: false,
      editTask: null,
      viewTabsValue: 0,
      logAssignees: [],
    });
    this.handleMenuClose();
  };
  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
      taskHoverIsActive: false,
      priorityId: 0,
    });
  };
  handleMenuOpen = (event: any, id: number = 0) => {
    if (id > 0) {
      this.setState({ priorityId: id });
    }
    this.setState({
      anchorEl: event.currentTarget,
      taskHoverIsActive: true,
    });
  };
  projectMenuOpen=(event: any)=>{
    this.setState({
      projectAnchor: event.currentTarget,
    });
  }
  projectMenuClose=()=>{
    this.setState({
      projectAnchor: null,
    });
  }
  changePriorityTaskClose = (priority: number = -1) => {
    if (priority > -1) {
      this.changePriority(priority, this.state.priorityId);
    }
    this.setState({
      changePriorityTaskEl: null,
      anchorEl: null,
      priorityId: 0,
    });
  };
  changePriorityTaskOpen = (event: any) => {
    this.setState({ changePriorityTaskEl: event.currentTarget });
  };
  changePriorityViewClose = (priority: number = -1) => {
    if (priority > -1) {
      this.changePriority(priority);
    }
    this.setState({ changePriorityViewEl: null, anchorEl: null });
  };
  changePriorityViewOpen = (event: any) => {
    this.setState({ changePriorityViewEl: event.currentTarget });
  };
  changePriorityTopClose = (priority: number = -1) => {
    if (priority > -1) {
      this.changePriority(priority);
    }
    this.setState({ changePriorityTopEl: null });
  };
  changePriorityTopOpen = (event: any) => {
    this.setState({ changePriorityTopEl: event.currentTarget });
  };
  tabsChangeHandler = (e: any, tabValue: any) => {
    this.setState({ tabValue: tabValue });
  };

  setViewTabsValue = (obj: any, val: any) => {
    this.setState({
      viewTabsValue: val,
    });
  };

  setAssigneeTabsValue = (obj: any, val: any) => {
    this.setState({
      assigneeTabsValue: val,
    });
  };

  dropMenuHandler = (event: any) => {
    this.setState({ createTaskanchorEl: event.currentTarget });
  };

  dropMenuClose = (priority: number) => {
    this.setState({
      createTaskanchorEl: null,
      priority: priority,
    });
  };
  createDuplicateTask = (taskId: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createDuplicateTaskApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createDuplicateTask + `?id=${taskId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleClose = () => {
    this.setState({
      isDeletingTask: false,
      deleteTaskId: 0,
    });
  };
  deleteTask = (id: number = 0) => {
    let deleteTaskId = this.state.deleteTaskId;
    if (id > 0) {
      deleteTaskId = id;
    }
    this.setState({ isDeletingTask: true, deleteTaskId: deleteTaskId });
  };
  deleteTaskFromList = () => {
    if (this.state.editTask !== null) {
      this.confirmDelete(JSON.stringify([this.state.editTask.id].map(Number)));
    } else if (this.state.deleteTaskId > 0) {
      this.confirmDelete(JSON.stringify([this.state.deleteTaskId].map(Number)));
    } else {
      this.confirmDelete(JSON.stringify(this.state.selectedTasks.map(Number)));
    }
  };

  handleTaskSelectedChange = (taskId: number) => {
    let selectedTask = this.state.selectedTasks;
    if (selectedTask.includes(taskId)) {
      selectedTask = selectedTask.filter((x) => x !== taskId);
    } else {
      selectedTask.push(taskId);
    }
    this.setState({
      selectedTasks: selectedTask,
    });
  };
  removeAssignees = (assignee: any[]) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeAssigneesApiID = requestMessage.messageId;

    const body = {
      id: this.state.editTask.id,
      account_ids: assignee,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeAssigneesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  confirmDelete = (result: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const body = {
      data: {
        ids: result,
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.taskDeleteApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskDeleteEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addAssignee = (assignee: any[]) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const body = {
      data: {
        id: this.state.editTask.id,
        assignees: assignee.map(String),
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addAssigneeApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addAssigneesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTeams = () => {
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webTeamsGetEndPoint
    );

      
    this.fetchTeamApiCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  popoverHandleClick = (event: any) => {
    const taskL = this.state.allTaskList.find((tl:any)=>tl.id == this.props.taskListId ?? 0);
    this.setState({ popoveAnchorEl: event.currentTarget, currentDraftId: 0, projectAnchor:null,projectId:this.props.projectId ?? 0,taskListObject:{id:taskL == null ?0:taskL.id,name:taskL == null?"":taskL.attributes.name,project:this.props.projectId??0} });
  };
  editTaskOpen = () => {
    const status = this.state.editTask?.attributes.status;
    let statusValue = 1;

    if (status === "open") {
      statusValue = 0;
    } else if (status === "completed") {
      statusValue = 2;
    }

    const priority = this.state.editTask?.attributes.priority;
    let priorityValue = 0;

    if (priority === "emergency") {
      priorityValue = 2;
    } else if (priority === "critical") {
      priorityValue = 1;
    }
    const assigneeValue: string[] =
      this.state.editTask.attributes.assignees.map((item: any) =>
        String(item.id)
      );

    const hoursValue = this.state.editTask.attributes.hours.split(":");
    this.setState({
      popoveAnchorEl: this.popoverRef.current,
      currentDraftId: 0,
      modalOpen: false,
      viewTabsValue: 0,
      logAssignees: [],
      isEditing: true,
      title: this.state.editTask.attributes.title,
      due_date: this.state.editTask.attributes.due_date,
      deadline_date: this.state.editTask.attributes.deadline_date,
      description: this.state.editTask.attributes.description,
      labour_cost: this.state.editTask.attributes.labour_cost,
      hours: hoursValue[0],
      minutes: hoursValue[1],
      taskHours: this.state.editTask.attributes.hours,
      priority: priorityValue,
      status: statusValue,
      assignees: assigneeValue,
      updateTaskId: Number(this.state.editTask.id),
      editTask: null,
      filesUploaded: this.state.editTask.attributes.attachments_url,
      expenditures: this.state.editTask?.attributes.expenditures,
      projectId:this.state.editTask?.attributes.account_block_project_id,
      selectedProductsId:this.state.editTask?.attributes.inventory_or_assets,
      taskListObject:{id:this.state.editTask?.attributes.account_block_task_list_id,name:this.state.editTask?.attributes.task_list_name,project:this.props.projectId??0}
    });
    this.handleMenuClose();
  };

  popoverHandleClose = () => {
    this.clearTaskModal();
    this.setState({
      popoveAnchorEl: null,
      showHoursModal: false,
      isEditing: false,
    });
  };

  timeStandClick = (event: any) => {
    this.setState({ timeStandAnchorEl: event.currentTarget ?? null });
  };

  changeDueDates = (date: any) => {
    if (!date?.isValid) {
      return;
    }
    const strDate = date.format("YYYY-MM-DD");
    let ids = this.state.selectedTasks.map((id) => Number(id));
    this.updateDueDateFilter(ids, strDate);
  };

  singleDueDateChange = (date: any) => {
    if (!date?.isValid) {
      return;
    }
    const strDate = date.format("YYYY-MM-DD");
    this.updateDueDateFilter([Number(this.state.changeDueDateTaskId)], strDate);
  };
  openDueDate = (taskId: number) => {
    this.setState({ changeDueDateTaskId: taskId,datePickerOpen:true} );
  };

  updateDueDateFilter = (ids: number[], strDate: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const body = {
      data: {
        id: JSON.stringify(ids),
        due_date: strDate,
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateDueDateApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webUpdateDueDate
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPATCH
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  undoLastAction = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.undoActionAPIEndPint
    );
      
    this.undoActionAPICalld = requestMessage.messageId;
     
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteTasklist = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTasklistApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tasklistDeleteEndPoint + `?id=${this.props.taskListId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editTasklist = () => {
    let error = this.state.error;
    let hasError = false;
    if (this.state.tasklistName.trim() === "") {
      error.tasklistName = "Please enter tasklistName";
      this.setState({ error: error });
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };
    const body = {
      name: this.state.tasklistName,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editTasklistApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tasklistUpdateEndPoint + `?id=${this.props.taskListId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: body })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  moveTasklist = () => {
    if(this.state.moveTasklistChecked == 0){
      return;
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.moveTasklistApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tasklistMoveEndPoint +
      `?id=${this.props.taskListId}&project_id=${this.state.moveTasklistChecked}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPATCH
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  moveTasklistProject = () => {
    const body = {
      "task_ids": this.state.taskID,
      "project_id": this.state.moveTasklistChecked ? this.state.moveTasklistChecked : "" ,
      "task_list_id": this.state.movedTasklist ? this.state.movedTasklist: "" ,
    };
    const header = {
      token: this.userToken.meta.token,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.moveTasklistProjectApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.moveTasklistAndProjectApiPath 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: body })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  taskTopDetailsMenuOpen = (event: any, id: number = 0) => {
    this.setState({
      taskTopDetailsAnchorEl: event.currentTarget,
    });
  };
  taskTopDetailsMenuClose = () => {
    this.setState({
      taskTopDetailsAnchorEl: null,
    });
  };

  renameTaskListModalOpen = () => {
    this.setState({
      isRenameTasklist: true,
      tasklistName: this.state.taskListDetails?.attributes.name,
    });
  };
  renameTaskListModalClose = (e: any) => {
    e?.preventDefault();
    this.setState({ isRenameTasklist: false });
  };

  deleteTasklistHandler = () => {
    this.setState({ isDeletingTasklist: true,taskTopDetailsAnchorEl:null });
  };
  deleteTasklistModalClose = () => {
    this.setState({
      isDeletingTasklist: false,
    });
  };

  moveTasklistHandler = (e: any) => {
    e.preventDefault();
    this.setState({ isActiveMoveTasklist: true,moveTasklistChecked:0 ,taskTopDetailsAnchorEl:null});
  };
  moveTasklistModalClose = () => {
    this.setState({ isActiveMoveTasklist: false });
  };

  moveTasklistProjectHandler = (e: any, taskIds:number[]) => {  
    e.preventDefault();    
    this.setState({ 
      isMoveTasklistproject: true,
      moveTasklistChecked:0 ,
      taskTopDetailsAnchorEl:null,      
      taskID:taskIds.length===0 ? this.state.taskID:taskIds
    });
    this.getTaskListsMoveData();
    this.projectDetails(this.state.editTask?.attributes.account_block_project_id);
  };
  moveTasklistProjectModalClose = () => {
    this.setState({ isMoveTasklistproject: false , modalOpen:false});
    this.handleMenuClose();
  };

  moveTasklistGroupList = (value: any) => () => {
    this.setState({ moveTasklistChecked: value });
  };

  movedTasklistEvent = (value: any) => () => {
    this.setState({  movedTasklist: value });
  };
 
  handleProjectChange = (event: { target: { value: any } }) => {
    if (event.target.value != undefined) {
      this.setState({
        taskListObject: {
          ...this.state.taskListObject,
          project: event.target.value,
        },
      });
    }
  };
  handleTaskListNameChange = (event: { target: { value: any } }) => {
    if (event.target.value != undefined) {
      this.setState({
        taskListObject: {
          ...this.state.taskListObject,
          name: event.target.value,
        },
        error: { ...this.state.error, tasklistName: "" },
      });
    }
  };
  tasklistPopoverHandleClose = () => {
    this.setState({
      tasklistPopoveAnchorEl: null,
    });
  };

  tasklistPopoverOpen = (e: any) => {
    e.preventDefault();
    this.setState({
      tasklistPopoveAnchorEl: this.popoverRef.current,
      taskListObject: {
        ...this.state.taskListObject,
        name: "",
        project: this.props.projectId ?? 0,
      },
      taskTopDetailsAnchorEl: null,
      projectAnchor:null
    });
  };

  getProjects = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectListApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectListEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTaskLists = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.taskListApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskListViewEndPoint 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getProjectTasklist = (projectId: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectTasklistViewApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectTaskListViewEndPoint + `?project_id=${projectId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTaskListDetails = (tasklistId:number) => {
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };
    this.taskDetailApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskListViewEndPoint + `?id=${tasklistId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  projectDetails = (proId: number) => {
 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectDetailApiID = requestMessage.messageId;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectShowEndPoint + `?id=${proId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteProjectHandler = () => {
    this.setState({
      showDeleteProject: true,
      anchorEl: null,
      projectAnchor:null
    });
  };
  projectDeleteModalClose = () => {
    this.setState({
      showDeleteProject: false,
    });
  };
  deleteProject = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteProjectApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectDeleteEndPoint + `?id=${this.props.projectId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  projectChangeHandler = (event: { target: { value: any } }) => {
    this.getProjectTasklist(event.target.value);
    if (event.target.value != undefined) {
      this.setState({
          projectId: event.target.value,
      });
    }
  };
  taskListHandler = (e: any, newValue: any) => {
    this.setState({      
      taskListObject:{ ...this.state.taskListObject,id: newValue.id, name: newValue.title },
    });
  };

  
  createTaskList = () => {
    let error = this.state.error;
    let hasError = false;
    if (this.state.taskListObject.project == 0) {
      hasError = true;
    }
    if (this.state.taskListObject.name.trim() === "") {
      error.tasklistName = "Please enter TaskList Name";
      hasError = true;
    }
    if (hasError) {
      this.setState({ error: error });
      return;
    }

    this.taskListCreateApi(
      this.state.taskListObject.name,
      this.state.taskListObject.project
    );
  };


  taskListAdd = (data: any, setError?: any, setShowDialog?: any) => {
    this.setErrorTaskList = setError;
    this.showDialogTaskList = setShowDialog;
    this.taskListCreateApi(data.title, this.state.projectId, 1);
  };

  taskListCreateApi = (tlName: string, projectId: number, typeIndex: number = 0) => {
    let error = this.state.error;
    let hasError = false;
    
    if (projectId == 0) {
      error.tasklistName = "Please choose the project";
      hasError = true;
    }
    if (hasError) {
      this.setState({ error: error });
      return;
    }
    const body = {
      name: tlName,
    };
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (typeIndex == 0) {
      this.taskListCreateApiId = requestMessage.messageId;
    } else {
      this.tasklistAddApiId = requestMessage.messageId;
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createTaskListEndPoint +
        `?id=${projectId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: body })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  datePickerCloseHandler = () => {
    this.setState({datePickerOpen:false})
  }
  openProject=()=>{
    this.setState({projectViewOpen:true})
  }
  closeProject=()=>{
    this.setState({projectViewOpen:false})
  }
  createtaskPopoverOpen = (e: any, id: number,popoverRef:React.RefObject<any>) => {
    e.preventDefault();
    this.setState({
      popoveAnchorEl: this.popoverRef.current,
      projectId:id
    });
  };
  createtasklistPopoverOpen=(e: any,popoverRef:React.RefObject<any>)=>{
    e.preventDefault();
    this.setState({
      tasklistPopoveAnchorEl: this.popoverRef.current,
      taskListObject: {
        ...this.state.taskListObject,
        name: "",
        project: this.props.projectId ?? 0,
      },
      taskTopDetailsAnchorEl: null,
      projectAnchor:null
    });
  }

  editProjectModalShow=()=>{
    this.setState({
      showEditProject:true
    })    
  }

  editProjectModalClose=()=>{
    this.setState({
      showEditProject:false
    })
    if (this.props.projectId) {
      this.projectDetails(this.props.projectId);  
    }
     
  }

  timeSheetModalShow=()=>{
    this.setState({
      showTimesheet:true,
      taskTopDetailsAnchorEl:null
    }) 
  }

  timeSheetModalClose=()=>{
    this.setState({
      showTimesheet:false
    }) 
  }
  cloneProject = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      token: this.userToken.meta.token,
      "Content-Type": configJSON.validationApiContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.duplicateProject + `?id=${this.state.currentProject.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.cloneProjectApiID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteCallback=()=>{
    this.projectDeleteModalClose();
    this.refreshData()
    if (this.props.redirectTo) {
      this.props.redirectTo("AllProject");
    }
  }
  projectStatusSet=(projectStatus:any)=>{
    projectStatus=projectStatus?projectStatus.toLowerCase():""
    let projStatusId=0
    switch(projectStatus){
      case "completed":
        projStatusId=3
        break;
      case "on track":
        projStatusId=1
        break;
      case "off track":
        projStatusId=2
        break; 
    }
    this.setState({projectStatusChange:projStatusId})
  }

  changeProjectStatus = (projectStatusId:number) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      token: this.userToken.meta.token,
      "Content-Type": configJSON.validationApiContentType,
    };   
    this.statusUpdateApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectStatusEndPoint + `?id=${this.props.projectId}&status=${projectStatusId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPATCH
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateProjectStatus = (event: any) => {
    this.changeProjectStatus(event.target.value ?? 0);
    this.setState({projectStatusChange:event.target.value ?? 0})
  };

  addUntrackedHours = () => {
  const timestamp = getCurrentTime();
  let taskId;
  if(this.state.editTask?.id !== null){
    taskId =  this.state.editTask?.id;
  }
  
  const header = {
    "Content-Type": "application/json",
    token: this.userToken.meta.token,
  };
    const body = {
      "data":{
         "task_id": this.state.editTask?.id,
         "status_updated_at": timestamp,
         "action_type": 'manual',
         "manual_hours": this.state.unTrackedTaskours 
    
     }
  } 
  
  
  const requestMessage: Message = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.updateUnTrackedHoursApiID = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.updateTaskProgress
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodPOST
  );
  this.setState({ isLoading: true });

  runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleTaskEscEvent = (event: any) => {
    event.stopPropagation()
  if (event.key === 'Escape') {
    this.saveAsDraftTask()
  }
  }

  LabourCostCalculation = () => {
    let projectedLaborCost = 0;
    let minPerPerson = (Number(this.state.hours) * 60 + Number(this.state.minutes)) / this.state.selectedTaskAssignees.length as number;
    const members = this.state.assigneeList.filter((item) => {
      return (this.state.selectedTaskAssignees.includes(item.id));
    });
    members.forEach((item) => {
      projectedLaborCost += Number(item.attributes.hourly_pay) * (minPerPerson / 60);
    });
    return Math.ceil(projectedLaborCost).toString();
  }

}
// Customizable Area End
