// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import React from "react";
import { Message } from "../../../../framework/src/Message";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "../../../../components/src/TaskListStyles.web";
import moment from "moment";
import { toast } from "react-toastify";
import { DateObject } from "react-multi-date-picker";
import CloseIcon from '@material-ui/icons/Close';
import i18n from "../../../../web/src/utilities/i18n";
import { checkQueryParams, processDates } from "../../../../components/src/ReusableFunctions";
const baseConfig = require("../../../../framework/src/config");

export const configJSON = require("../config.js");
export interface Props extends WithStyles<typeof customStyles> {
  redirectTo?: () => void
  updateData?: () => void;
}

export interface S {
  labour_cost: number;
  priority: number;
  status: number;
  assignees: any[];
  dueDateFilter: string;
  sublistActive: boolean;
  subTaskChecked: any[];
  tasklistTaskChecked: any[];
  setShow: boolean;
  isFiltering: boolean;
  selectedAssigneeVal: any;
  selectedPriorityVal: any;
  selectedTitleVal: any;
  searchValue: string;
  filteredData: any;
  isAscending: boolean | null;
  searchProject: string;
  modalOpen: boolean;
  tabValue: any;
  isTeamsTab: boolean;
  viewTabsValue: number;
  ProjectTaskTabsValue: number;
  assigneeTabsValue: number;
  selectedDueDateVal: DateObject[];
  selectedDeadlineDateVal: DateObject[];
  selectedStatusVal: any;
  selectedCategoryVal: any;
  selectedProjectLeadVal: any;
  logAssignees: any[];
  anchorEl: any;
  checkedTopTaskSelected: boolean;
  popoveAnchorEl: any;
  membersList: any;
  isActiveChangesAssignee: boolean;
  isActiveProjectTemplate: boolean;
  assigneeSearchText: string;
  selectedAssignees: any[];
  sortByAssignee: string;
  taskHours: string;

  addNote: string;
  selectedSortByVal: string;
  taskHoverIsActive: boolean;
  updateTaskId: number;
  chooseGroupChecked: any[];
  //added

  assigneeList: any[];
  projectList: any[];
  error: {
    title: string;
    taskListName: string;
    taskListProject: string;
    due_date: string;
  };
  title: string;
  categoryList: any[];
  description: string;
  chooseTemplateChecked: any;
  taskListObject: {
    id: number;
    project: number;
    name: string;
  };
  projectTaskList: any[];
  projectTask: any[];
  taskPopoveAnchorEl: any;
  tasklistPopoveAnchorEl: any;
  due_date: string;
  deadline_date: string;
  createTaskanchorEl: any;
  hours: string;
  minutes: string;
  showHoursModal: boolean;
  taskObject: {
    tasklist: { id: number; title: string };
    project: number;
  };
  filterProject:any
  projectId:any;
  projectAnchorEl:any;
  isLoading:boolean;
}

export interface SS {
  id: any;
}

export default class ProjectsController extends BlockComponent<Props, S, SS> {
  datePickerRef: React.RefObject<any>;
  priorityOption: any[] = [
    {
      id: 2,
      // title: "Normal",
      title: "Emergency",
      status: "!!!",
    },
    {
      id: 1,
      title: "Critical",
      status: "!!",
    },
    {
      id: 0,
      title: "None",
      status: "",
    },
  ];
  projectListApiID: string = "";
  taskListCreateApiId: string = "";
  tasklistAddApiId: string = "";
  getCategoryListApiID: string = "";
  memberListApiID: string = "";
  projectTasklistViewApiID: string = "";
  createTaskApiID: string = "";
  userSessionData: any;
  userToken: any;
  fileRef: React.RefObject<HTMLInputElement>;
  formRef: React.RefObject<HTMLFormElement>;
  setErrorTaskList: any;
  showDialogTaskList: any;
  currenTaskList: {
    id: number;
  } = { id: 0 };
  projectFilterApiID: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.formRef = React.createRef();
    this.datePickerRef = React.createRef();
    this.fileRef = React.createRef();


    this.state = {
      title: "",
      description: "",
      labour_cost: 30.5,
      priority: 0,
      status: 0,
      assignees: [],
      dueDateFilter: "",
      sublistActive: false,
      subTaskChecked: [0],
      tasklistTaskChecked: [0],
      setShow: false,
      isFiltering: false,
      selectedAssigneeVal: [],
      selectedPriorityVal: [],
      selectedTitleVal: [],
      searchValue: "",
      filteredData: [],
      isAscending: null,
      searchProject: "",
      modalOpen: false,
      tabValue: "1",
      isTeamsTab: true,
      viewTabsValue: 0,
      ProjectTaskTabsValue: 0,
      assigneeTabsValue: 0,
      assigneeList: [],
      selectedDueDateVal: [],
      selectedDeadlineDateVal: [],
      selectedStatusVal: [],
      selectedCategoryVal: [],
      selectedProjectLeadVal: [],
      logAssignees: [],
      anchorEl: null,
      checkedTopTaskSelected: false,
      popoveAnchorEl: null,
      membersList: [],
      isActiveChangesAssignee: false,
      isActiveProjectTemplate: false,
      assigneeSearchText: "",
      selectedAssignees: [],
      sortByAssignee: "",
      taskHours: "",
      error: {
        title: "",
        taskListName: "",
        taskListProject: "",
        due_date: "",
      },
      addNote: "",
      selectedSortByVal: "",
      taskHoverIsActive: false,
      updateTaskId: 0,
      chooseGroupChecked: [0],
      projectList: [],
      categoryList: [],
      chooseTemplateChecked: 0,
      taskListObject: {
        id: 0,
        name: "",
        project: 0,
      },
      projectTaskList: [],
      projectTask: [],
      taskPopoveAnchorEl: null,
      tasklistPopoveAnchorEl: null,
      due_date: "",
      deadline_date: "",
      createTaskanchorEl: null,
      hours: "",
      minutes: "",
      showHoursModal: false,
      taskObject: {
        tasklist: { id: 0, title: "" },
        project: 0,
      },
      filterProject:[],
      projectId:0,
      projectAnchorEl:null,
      isLoading:false,
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);    
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    this.fetchTasksOnUpdate(prevState);
    if (this.state.title != prevState.title) {
      this.setState({ error: { ...this.state.error, title: "" } });
    }
    if (
      prevState.searchProject !== this.state.searchProject ||
      this.state.projectList !== prevState.projectList
    ) {
      this.filterProject();
    } 
  }

  async componentDidMount(): Promise<void> {
    this.fetchAllProjects();
    this.getCategory();
    this.fetchAssigneeList();
    const lang = localStorage.getItem("lang") ?? "en";
    await (i18n as any).changeLanguage(lang);
    const id = checkQueryParams('isModal', 'true', 'id');
    if (id){ this.setState({ modalOpen: true, projectId: id })}
  }

  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }

  containsText = (text: string, searchText: string) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  displayAssigneeOptions = () => {
    const assigneeNames = this.state.assigneeList.map(
      (item: {
        id: number;
        attributes: {
          first_name: string;
          last_name: string;
          email: string;
          team: any[];
        };
      }) => ({
        id: item.id,
        title: `${item.attributes.first_name} ${item.attributes.last_name}`,
        email: item.attributes.email,
        initials: `${item.attributes.first_name
          ?.charAt(0)
          ?.toUpperCase()}${item.attributes?.last_name?.charAt(0)?.toUpperCase()}`,
        team: item.attributes.team
          ? item.attributes.team.map((team) => team.title)
          : [],
      })
    );
    return assigneeNames.filter((obj: { title: string }) =>
      this.containsText(obj.title, this.state.assigneeSearchText)
    );
  };
  
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (!responseJson.errors) {
          switch (apiRequestCallId) {
            case this.getCategoryListApiID:
              this.setState({
                categoryList: responseJson.data,
              });
              break;
              case this.projectFilterApiID:
                this.setState({isLoading:false,filterProject:responseJson.data})
                break;
            case this.memberListApiID:
              this.setState({ assigneeList: responseJson.members.data });
              break;

              default:
                this.projectApiResponse(apiRequestCallId,responseJson);
                this.tasklistTaskApiResponse(apiRequestCallId,responseJson)
            
          }
        } 
      }
    }
  }

  projectApiResponse = (apiRequestCallId:any,responseJson:any) => {
    switch(apiRequestCallId)
    {
        case this.projectListApiID:
          this.setState({
            projectList: responseJson.data,
            isLoading: false
          });
          break;
        case this.projectTasklistViewApiID:
          this.setState({
            projectTaskList: responseJson.data,
          });
          break;
    }
  }

  tasklistTaskApiResponse = (apiRequestCallId:any,responseJson:any) => {
    switch(apiRequestCallId)
    {
      case this.taskListCreateApiId:
        this.createToastNotification(responseJson.message);
        this.tasklistPopoverHandleClose();
        break;
      case this.tasklistAddApiId:
        this.createTitleSuccess(responseJson);
        break;
      case this.createTaskApiID:
        this.setState({
          projectTask: responseJson.data,
        });

        this.taskPopoverHandleClose();
        this.createToastNotification("Task created successfully");
        if (this.props.updateData) {
          this.props.updateData();
        }
        break;

    }
  }

  fetchTasksOnUpdate = (prevState: S) => {
    if (
      prevState.selectedStatusVal !== this.state.selectedStatusVal ||
      prevState.selectedCategoryVal !== this.state.selectedCategoryVal ||
      prevState.selectedProjectLeadVal !== this.state.selectedProjectLeadVal ||
      prevState.selectedAssigneeVal !== this.state.selectedAssigneeVal ||
      prevState.selectedSortByVal !== this.state.selectedSortByVal  
      ) {
      this.getProjectFilters();
    } 
  }

  getProjectFilters = () => {
    let processedValue = '';
    switch (this.state.selectedSortByVal) {
      case 'A to Z':
        processedValue = 'A-Z';
        break;
      case 'Z to A':
        processedValue = 'Z-A';
        break;  
      case 'End Date':
        processedValue = 'end_date';
        break; 
      case 'Category':
        processedValue = 'category';
        break;   
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };
    
    let [startDate1, startDate2] = processDates(this.state.selectedDueDateVal);
    let [endDate1, endDate2] = processDates(this.state.selectedDeadlineDateVal);
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectFilterApiID = requestMessage.messageId;
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.projectFilterEndPoint}?category_id=${this.state.selectedCategoryVal}&start_date_1=${startDate1}&start_date_2=${startDate2}&end_date_1=${endDate1}&end_date_2=${endDate2}&project_lead=${this.state.selectedProjectLeadVal}&assignee=${this.state.selectedAssigneeVal}&status=${this.state.selectedStatusVal}&sort_by=${processedValue}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    this.setState({
      isLoading:true
    })
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };



  createTitleSuccess = (responseJson: any) => {
    this.projectTasklistGet(this.state.taskObject.project);
    this.showDialogTaskList(false);
  };


  assigneeChangeModalClose = (e: any) => {
    e.preventDefault();
    this.setState({
      assignees: this.state.selectedAssignees,
      isActiveChangesAssignee: false,
    });
  };


  clearProjectModal = () => {
    this.setState({
      title: "",
      due_date: "",
      deadline_date: "",
      description: "",
      assignees: [],
      error: {
        title: "",
        taskListName: "",
        taskListProject: "",
        due_date: "",
      },
      taskListObject: {
        id: 0,
        name: "",
        project: 0,
      },
    });
  };
  

  clearSelectedAssignees = () => {
    this.setState({ selectedAssignees: [] });
  };


  sortAssignees = (dataToSort: any) => {
    const { sortByAssignee } = this.state;
    if (sortByAssignee != "") {
      let leftSort: number;
      let rightSort: number;
      leftSort = sortByAssignee == "Z-A" ? 1 : -1;
      rightSort = sortByAssignee == "Z-A" ? -1 : 1;
      dataToSort = dataToSort.sort((assignee1: any, assignee2: any) => {        
        if (assignee1.title.toLowerCase() > assignee2.title.toLowerCase())
          return rightSort;
        if (assignee1.title.toLowerCase() < assignee2.title.toLowerCase())
          return leftSort;
        return 0;
      });
    }

    return dataToSort;
  };
  sortByAssigneeHandler = (e: any) => {
    this.setState({ sortByAssignee: e.target.value });
  };

  changeAssigneeHandler = (changeAssigneeId: number) => {
    let changeAssignees = this.state.selectedAssignees;
    if (changeAssignees.includes(changeAssigneeId)) {
      changeAssignees = changeAssignees.filter((x) => x !== changeAssigneeId);
    } else {
      changeAssignees.push(changeAssigneeId);
    }
    this.setState({
      selectedAssignees: changeAssignees,
    });
  };
  


  createToastNotification = (toastMesssage: string, toastIcon?: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{toastMesssage}</div>
        <a href="#" className="toast-link" data-test-id="undo-toast-link">
          {/* undo */} <CloseIcon />
        </a>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        icon: toastIcon ? () => <img src={toastIcon} alt="icon" /> : undefined,
      }
    );
  };

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as keyof S;
    const value = event.target.value;
    this.setState({ [name]: value } as unknown as Pick<S, keyof S>);
  };

  addTask = () => {
    let errorFlag = false;
    let error = this.state.error;
    if (this.state.due_date.trim() === "") {
      error.due_date = this.t("Please enter due date");
      this.setState({ error: error });
      errorFlag = true;
    }
    if (this.state.title.trim() === "") {
      error.title = this.t("Please enter title");
      this.setState({ error: error });
      errorFlag = true;
    }
    if (errorFlag) {
      return;
    }
   
    let assignees = [...this.state.assignees];

    if (assignees.length == 0) {
      assignees = [this.userToken.data.id];
    }
    let labourCost: number = 0;    
    let minutes = this.state.minutes;
    let hours = this.state.hours;
    let taskHours = this.state.taskHours;
    if (taskHours == "") {
      taskHours = "02:00";
      hours = "2";
      minutes = "0";
    }
    for (const element of assignees) {
      const assignee = this.state.assigneeList.find((x) => x.id == element);
      labourCost +=
        (assignee.attributes.hourly_pay *
          (Number(hours) + Number(minutes) / 60)) /
        assignees.length;
    }
    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const body = {      
      description: this.state.description,
      labour_cost: labourCost.toFixed(2),      
      due_date: this.state.due_date,
      hours: taskHours,
      status: this.state.status,
      priority: this.state.priority,
      title: this.state.title,
      assignees: assignees,      
      deadline_date:
        this.state.deadline_date == ""
          ? moment(this.state.due_date).add(14, "day").format("YYYY-MM-DD")
          : this.state.deadline_date,
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskCreateEndPoint+`?project_id=${this.state.projectId}&tasklist_id=${this.state.taskObject.tasklist.id}`
    );
    
    this.createTaskApiID = requestMessage.messageId;    

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: body })
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
   
  dueDateRangeFilter = (date: any) => {
      this.setState({ selectedDueDateVal: date });
  };
  deadlineDateRangeFilter = (date: any) => {
      this.setState({ selectedDeadlineDateVal: date });
  };

  filterChangeHandler = (e: { target: { value: any } }, p: any) => {
    if (e.target.value != undefined) {
      if (p == "selectedSortByVal") {
        this.setState({ selectedSortByVal: e.target.value });
      } else {
        if(e.target.value.length >= 1 && e.target.value.includes(undefined)){
          return;
        }
        const newValue = e.target.value.filter(
          (x: any) => x != undefined
        );
        const updState = { [p]: newValue } as Pick<S, keyof S>;
        this.setState({ ...updState, isFiltering: true });
      }
    }
  };

  handle_modalOpen = (projectId: number) => {
    this.setState({ modalOpen: true,projectId: projectId});
  };


  clearSelectionHandler = (key: any) => {
    const empVal: any[] = [];
    const updatedState = { [key]: empVal } as Pick<S, keyof S>;
    this.setState({ ...updatedState, isFiltering: true });
    switch(key){
      case "selectedDeadlineDateVal" :
      this.setState({ selectedDeadlineDateVal: [], isFiltering: true }, () => {
        this.getProjectFilters();
      });
      break;
      case "selectedDueDateVal" :
        this.setState({ selectedDueDateVal: [], isFiltering: true }, () => {
          this.getProjectFilters();
        });
      break;
      default:this.setState({ isFiltering: false })
    }    
  };

  assigneeHandler = (e: any, id: number = 0) => {
    e.preventDefault();
    this.setState({
      isActiveChangesAssignee: true,
      selectedAssignees: this.state.assignees,
    });
  };


  projectTemplateHandler = () => {
    this.setState({ isActiveProjectTemplate: true
       });
  };
  projectTemplateModalClose = () => {
    this.setState({ isActiveProjectTemplate: false });
  };

  projectViewClose = () => {
    this.setState({
      modalOpen: false,
      viewTabsValue: 0,
      ProjectTaskTabsValue: 0,
      logAssignees: [],
    });
    this.handleMenuClose();
    const projectUrl = window.location.href.split('?')[0];
    window.history.replaceState({}, document.title, projectUrl);
  };
  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
      taskHoverIsActive: false,
    });
  };
  handleMenuOpen = (event: any, id: number = 0) => {
    this.setState({
      anchorEl: event.currentTarget,
      taskHoverIsActive: true,
    });
  };

  
  tabsChangeHandler = (e: any, tabValue: any) => {
    this.setState({ tabValue: tabValue });
  };

  setViewTabsValue = (obj: any, val: any) => {
    this.setState({
      viewTabsValue: val,
    });
  };

  setProjectTaskTabsValue = (obj: any, val: any) => {
    this.setState({
      ProjectTaskTabsValue: val,
    });
  };

  setAssigneeTabsValue = (obj: any, val: any) => {
    this.setState({
      assigneeTabsValue: val,
    });
  };

  popoverHandleClose = () => {
    this.setState({
      popoveAnchorEl: null
    });
  };

  taskPopoverHandleClose = () => {
    this.clearProjectModal();
    this.setState({
      taskPopoveAnchorEl: null,
    });
  };

  taskPopoverOpen = (e: any, id: number,popoverRef:React.RefObject<any>) => {
    e.preventDefault();
    this.projectTasklistGet(id);
    this.setState({
      taskPopoveAnchorEl: popoverRef.current,
      taskObject: {
        project: id,
        tasklist: { id: 0, title: "" },
      },
      anchorEl: null,
    });
  };
  tasklistPopoverHandleClose = () => {
    this.clearProjectModal();
    this.setState({
      tasklistPopoveAnchorEl: null,
    });
  };

  tasklistPopoverOpen = (e: any,popoverRef:React.RefObject<any>) => {
    e.preventDefault();
    this.setState({
      tasklistPopoveAnchorEl: popoverRef.current,
      taskListObject: {
        ...this.state.taskListObject,
        project: this.state.projectId,
      },
      anchorEl: null,
    });
  };

  onBrowse = () => {
    if (this.fileRef.current !== null) {
      this.fileRef.current.click();
    }
  }; 

  handleTemplateGroupList = (value: any) => () => {
    this.setState({ chooseTemplateChecked: value });
  };

  //added code
  fetchAllProjects = () => {    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectListEndPoint
    );
    this.projectListApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.setState({
      isLoading: true
    })
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCategory = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoryListApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryListEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchAssigneeList = () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.memberListEndPoint
    );
    this.memberListApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  filterProject = () => {
    let projectlist = this.state.projectList;
    if (this.state.searchProject !== "") {
      projectlist = projectlist.filter((project) => {
        if (
          project.attributes.title
            .toLowerCase()
            .search(this.state.searchProject.toLowerCase()) !== -1
        )
          return project;
      });
    }
    this.setState({ filterProject: projectlist });
  };

  getOpenProjects = () => {
    let projectToShow = this.state.filterProject;
  
    const openProject = projectToShow.filter((project:any) => {
      if (project.attributes.status === null || project.attributes.status.toLowerCase() === "open") return project;
    });

    return openProject;
  };
  getonTrackProjects = () => {
    let projectToShow = this.state.filterProject;
    const onTrackProject = projectToShow.filter((project:any) => {
      if (project.attributes.status?.toLowerCase()  === "on track") return project;
    });
    return onTrackProject;
  };
  getoffTrackProjects = () => {
    let projectToShow = this.state.filterProject;
    const offTrackProject = projectToShow.filter((project:any) => {
      if (project.attributes.status?.toLowerCase() === "off track") return project;
    });
    return offTrackProject;
  };
  getCompletedProjects = () => {
    let projectToShow = this.state.filterProject;
    const completedProject = projectToShow.filter((project:any) => {
      if (project.attributes.status?.toLowerCase() === "completed") return project;
    });
    return completedProject;
  };


  handleProjectChange = (event: { target: { value: any } }) => {
    if (event.target.value != undefined) {
      this.setState({
        taskListObject: {
          ...this.state.taskListObject,
          project: event.target.value,
        },
      });
    }
  };

  createTaskProjectChange = (event: { target: { value: any } }) => {
    if (event.target.value != undefined) {
      this.setState({
        taskObject: {
          ...this.state.taskObject,
          project: event.target.value,
        },
      });
    }
  };

  handleTaskListNameChange = (event: { target: { value: any } }) => {
    if (event.target.value != undefined) {
      this.setState({
        taskListObject: {
          ...this.state.taskListObject,
          name: event.target.value,
        },
        error: { ...this.state.error, taskListName: "" },
      });
    }
  };
  createTaskList = () => {
    let error = this.state.error;
    let hasError = false;
    if (this.state.taskListObject.project == 0) {
      error.taskListProject = "Please select Project";
      this.setState({ error: error });
      hasError = true;
    }
    if (this.state.taskListObject.name.trim() === "") {
      error.taskListName = "Please enter TaskList Name";
      this.setState({ error: error });
      hasError = true;
    }
    if (hasError) {
      return;
    }
    this.createTaskListApiCall(
      this.state.taskListObject.name,
      this.state.taskListObject.project
    );
  };

  projectTasklistGet = (projId: number) => {
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectTaskListViewEndPoint + `?project_id=${projId}`
    );
    this.projectTasklistViewApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
      );
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deadlineChange = (deadline: any) => {
    this.setState({
      deadline_date: deadline?.isValid ? deadline.format("YYYY-MM-DD") : "",
    });
  };

  dropMenuClick = (event: any) => {
    this.setState({ createTaskanchorEl: event.currentTarget });
  };

  dueDateChange = (dueDate: any) => {
    if (dueDate?.isValid) {
      this.setState({
        deadline_date: moment(dueDate.format("YYYY-MM-DD"))
          .add(14, "day")
          .format("YYYY-MM-DD"),
      });
    }
    this.setState({
      due_date: dueDate?.isValid ? dueDate.format("YYYY-MM-DD") : "",
      error: { ...this.state.error, due_date: "" },
    });

  };
  dropMenuClose = (priority: number) => {
    this.setState({
      createTaskanchorEl: null,
      priority: priority,
    });
  };

  hoursChange = (event: any) => {
    let newValue = event.target.value;
    // Validate input: Allow only numbers and limit the value to 0-999
    newValue = newValue.replace(/\D/g, "");
    if (Number(newValue) > 999) {
      newValue = this.state.hours;
    }
    newValue = String(Number(newValue));
    if (Number(newValue < 10)) {
      newValue = "0" + newValue;
    }
    this.setState({ hours: newValue });
  };

  minutesChange = (event: any) => {
    let newValue = event.target.value;
    // Validate input: Allow only numbers and limit the value to 0-999
    newValue = newValue.replace(/\D/g, "");
    if (Number(newValue) > 59) {
      newValue = this.state.minutes;
    }
    newValue = String(Number(newValue));
    if (Number(newValue < 10)) {
      newValue = "0" + newValue;
    }
    this.setState({ minutes: newValue });
  };

  toggleHoursModal = () => {
    this.setState({ showHoursModal: !this.state.showHoursModal });
  };

  setHours = () => {
    let totalHours = "";
    if (this.state.hours !== "" || this.state.minutes !== "") {
      totalHours = this.state.hours == "" ? "00" : this.state.hours;
      totalHours += ":";
      totalHours += this.state.minutes == "" ? "00" : this.state.minutes;
    }
    this.setState({
      taskHours: totalHours,
      showHoursModal: false,
    });
  };
  taskListChangeHandler = (e: any, newValue: any) => {
    this.setState({
      taskObject: {
        ...this.state.taskObject,
        tasklist: { id: newValue.id, title: newValue.title },
      },
    });
  };

  addTaskList = (data: any, setError?: any, setShowDialog?: any) => {
    this.setErrorTaskList = setError;
    this.showDialogTaskList = setShowDialog;
    this.createTaskListApiCall(data.title, this.state.taskObject.project, 1);
  };

  createTaskListApiCall = (name: string, project: number, type: number = 0) => {
    const body = {
      name: name,
    };
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (type == 0) {
      this.taskListCreateApiId = requestMessage.messageId;
    } else {
      this.tasklistAddApiId = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createTaskListEndPoint + `?id=${project}` 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: body })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

}
// Customizable Area End
