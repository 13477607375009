// Customizable Area Start
import React from "react";
import clsx from "clsx";
import { lighten, makeStyles, createTheme } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import i18n from "../../../../web/src/utilities/i18n";
const BREAKPOINTSMEMBERS = {
  xs: 0,
  sm: 600,
  md: 1025,
  lg: 1281,
  xl: 1920,
};

const breakpointsFullMembers = {
  breakpoints: createBreakpoints({
    values: BREAKPOINTSMEMBERS,
  }),
};

const customThemeMembers = createTheme(
  {
    palette: {
      primary: {
        main: "#FF66CC",
      },
    },
  },
  breakpointsFullMembers
);
export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiCheckbox-root": {
      color: "#94A3B8",
      "&:hover": {
        backgroundColor: "rgba(54, 145, 166, 0.04)",
      },
      "&.Mui-checked": {
        color: "#4ba3b7",
      },
      "& .MuiSvgIcon-root": {
        height: "20px",
        width: "20px",
      },
    },
  },
  table: {
    minWidth: 1250,
    [theme.breakpoints.down("sm")]: {
      minWidth: 950,
    },

    [theme.breakpoints.down("md")]: {
      minWidth: 1050,
    },
    
    "&.view-teammember": {
      "& .MuiTableCell-body": {
        "&.action-link": {
          [theme.breakpoints.down("md")]: {
            width: "22.4%",
          },
          width: "14.5%",
          
          [theme.breakpoints.down("sm")]: {
            width: "21.43%",
          },
        },

        "&.name-cell": {
          [theme.breakpoints.down("md")]: {
            width: "22.3%",
          },
          width: "19%",
          
          [theme.breakpoints.down("sm")]: {
            width: "24.7%",
          },
        },
        "&.joindate-cell": {
          width: "45%",
          [theme.breakpoints.down("md")]: {
            width: "18%",
          },
          [theme.breakpoints.down("sm")]: {
            width: "21%",
          },
        },
        "&.email-cell": {
          "& .table-link": {
            marginLeft: "0",
          },
          [theme.breakpoints.down("md")]: {
            width: "30%",
          },
          width: "20.4%",
         
          [theme.breakpoints.down("sm")]: {
            width: "24.5%",
          },
          
        },
        
        
      },
    },
    "&.viewTeamList": {
      "& .MuiTableCell-body": {
        "&.action-link": {
          width: "15.5%",
          [theme.breakpoints.down("md")]: {
            width: "20.5%",
            paddingRight: "0",
          },
          [theme.breakpoints.down("sm")]: {
            width: "23.5%",
          },
        },
        
        "&.email-cell": {
          [theme.breakpoints.down("md")]: {
            width: "54%",
          },
          width: "59%",
          "& .table-link": {
            marginLeft: "0",
          },
         
          [theme.breakpoints.down("sm")]: {
            width: "42.5%",
          },
         
        },
        "&.name-cell": {
          [theme.breakpoints.down("sm")]: {
            width: "28.5%",
          },
          width: "21%",
          [theme.breakpoints.down("md")]: {
            width: "24%",
          },
         
        },
        "&.team-cell": {
          [theme.breakpoints.down("sm")]: {
            width: "29%",
          },
          width: "44%",
          [theme.breakpoints.down("md")]: {
            width: "27%",
          },
         
        },
       
      },
    },
    "&.view-memberList": {
      [theme.breakpoints.down("sm")]: {
        minWidth: "1232px",
      },
      "& .MuiTableCell-body": {
        
        "&.email-cell": {
          "& .table-link": {
            marginLeft: "0",
          },
          width: "22%",
          [theme.breakpoints.down("md")]: {
            width: "17.8%",
          },
          
        },
        "&.action-link": {
          [theme.breakpoints.down("md")]: {
            width: "23.2%",
          },
          width: "14.5%",
         
        },
        "&.team-cell": {
          width: "25.9%",
          [theme.breakpoints.down("md")]: {
            width: "27.8%",
          },
        },
        "&.name-cell": {
          width: "18.5%",
          [theme.breakpoints.down(1366)]: {
            width:"19%"
          },
          [theme.breakpoints.down("md")]: {
            width: "15.4%",
          },
          
        },
        "&.joindate-cell": {
          width: "14.5%",
          [theme.breakpoints.down("md")]: {
            width: "10.5%",
          },
          [theme.breakpoints.down("md")]: {
            width: "13%",
          }
        },
       
      },
    },
    "&.bulk-upload-confirm": {
      "& .MuiTableCell-head": {
        "&.MuiTableCell-paddingNone": {
          padding: "14px",
        },
      },
      "& .MuiTableCell-body": {
        "&.MuiTableCell-paddingNone": {
          padding: "14px",
        },
        "&.email-cell": {
          width: "74.8%",
        },
        "&.name-cell": {
          width: "25.3%",
        },
        
       
      },
    },

    "&.mem-list-employee": {
      "& .MuiTableCell-body": {
        "&.MuiTableCell-root": {
          "&:last-child": {
            width: "auto",
          }          
        }
      }
    },
    "&.mem-list-manager": {
      "& .MuiTableCell-body": {
        "&.MuiTableCell-root": {
          "&:last-child": {
            width: "auto",
          }          
        }
      }
    },
    "&.team-list-employee": {
      "& .MuiTableCell-body": {
        "&.MuiTableCell-root": {
          "&:last-child": {
            width: "auto",
          }          
        }
      }
    },
    
   
   
    "& .MuiTableRow-root": {
      "&.Mui-selected": {
        background: "#fafcfe",
        "&:hover": {
          background: "#fafcfe",
        },
        "& .MuiTableCell-root": {
          "& .profile-name": {
            backgroundColor: "#eaeff2",
          },
        },
      },
      "&.disable-member-row": {
        "&:hover": {
          background: "#ffffff",
        },
      },
    },
    "& .MuiTableCell-head": {
      fontSize: "14px",
      letterSpacing: "0.1px",
      lineHeight: "20px",
      color: "rgba(43, 43, 43, 0.369)",
      padding: "19px 23px",
      fontWeight: "500",
      fontFamily: "Expletus Sans",
      [theme.breakpoints.down("xs")]: {
        padding: "17px 10px",
      },
      [theme.breakpoints.down("md")]: {
        padding: "19px 15px",
      },
     
      "&.MuiTableCell-paddingNone": {
        padding: "0",
      },
      "&.MuiTableCell-paddingCheckbox": {
        padding: "0 40px 0 18px",
      },
      "& .MuiTableSortLabel-iconDirectionAsc":{
        display: "none",
      },
    },
    "& .disable-member-row": {
      "& .MuiTableCell-body": {
        "&:not(.action-link)": {
          userSelect: "none",
          pointerEvents: "none",
          opacity: "0.569",
        },

        "&.action-link": {
          "& .table-link": {
            userSelect: "none",
            pointerEvents: "none",
            opacity: "0.569",
            "&.reactivate": {
              userSelect: "unset",
              pointerEvents: "unset",
              opacity: "1",
            },
          },
        },
      },
    },
    "& .MuiTableCell-body": {
      fontSize: "14px",
      letterSpacing: "0.1px",
      lineHeight: "20px",
      padding: "26px 16px",
      color: "#2b2b2b",
      fontWeight: "500",
      fontFamily: "Expletus Sans",
      
      [theme.breakpoints.down("xs")]: {
        padding: "25px 18px",
      },
      [theme.breakpoints.down("md")]: {
        padding: "26px 21px",
      },
      
      "&.MuiTableCell-paddingNone": (props: { userRoles: string }) => ({
        padding: props.userRoles !== "employee" ? "0 12px 0 0px" : "0 12 0 8px",
      }),
      "&.MuiTableCell-paddingCheckbox": {
        padding: "0 40px 0 18px",
      },
      "&.action-link": {
        whiteSpace: "noWrap",
        "& .table-link": {
          marginLeft: "0",
          whiteSpace: "noWrap",
        },
      },
      "& .MuiLink-root": {
        color: "inherit",
      },

      "& .show-me-link": {
        marginLeft: "5px",
        background: "#4ba3b7",
        borderRadius: "6px",
        color: "#FFFFFF",
        textAlign: "center",
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: "12px", 
        lineHeight: "16px",
        letterSpacing: "0.0075em",  
        padding: "2px 8px",
        textTransform: "capitalize",
      },
      
      "& .member-team-link": {
        cursor: "pointer",
        color: "#4ba3b7",
        marginLeft: "10px",
        "&:hover": {
          textDecoration: "underline",
        },
      },

      "& .table-link": {
        color: "#2b2b2b",
        textDecoration: "none",
        marginLeft: "10px",
        "&:hover": {
          textDecoration: "underline",
        },
      },
      
      "& .table-menu": {
        display: "inline-block",
        "& .table-link": {
          padding: "0",
          textTransform: "none",
          font: "inherit",
          "& .MuiButton-endIcon": {
            marginLeft: "2px",
          },
        },
      },
     
      "&.name-cell": {
        width: "18.6%",
        [customThemeMembers.breakpoints.down("md")]: {
          width: "16.6%",
        },
      },
      "&.email-cell": {
        width: "22%",
        [customThemeMembers.breakpoints.down("md")]: {
          width: "19%",
        },
      },
      "&.team-cell": {
        width: "26%",
        [customThemeMembers.breakpoints.down("md")]: {
          width: "24%",
        },
      },
      "&.joindate-cell": {
        width: "14.4%",
        [customThemeMembers.breakpoints.down("md")]: {
          width: "13.4%",
        },
      },
      "& .profile-name": {
        width: "48px",
        height: "48px",
        backgroundColor: "#fafcfe",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textTransform: "uppercase",
        borderRadius: "24px",
        
        
        fontWeight: "700",
        marginRight: "12px",
      },

      "& .select-control": {
        "& .MuiInput-underline:before": {
          display: "none",
        },
        "& .MuiInput-underline:after": {
          display: "none",
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0.1px",
          color: "#2b2b2b",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          paddingBottom: "0",
          paddingTop: "0",
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
       
      },

      "& .name-col": {
        display: "flex",
        alignItems: "center",
        "& .table-link": {
          marginLeft: "0",
        },
      },
      
      
    },

    "& .action-link": {
      "&> a": {
        marginRight: "25px",
        "&:last-child": {
          marginRight: "0",
        },
      },
      width: "14.6%",
      [customThemeMembers.breakpoints.down("md")]: {
        width: "21.6%",
      },
      
    },
  },
  dropDropdown: {
    "& .MuiMenu-paper": {
      minWidth: "156px",
      filter: "drop-shadow(0px 1px 2.5px rgba(0,0,0,0.2))",
      backgroundColor: "#fafafa",
      borderRadius: "8px",
      marginTop: "7px",
      boxShadow: "none",
      "& .MuiList-padding": {
        paddingTop: "8px",
        paddingBottom: "8px",
      },
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#000000",
        padding: "14px 17px",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        display: "flex",
        justifyContent: "flex-start",
      },
      
    },
  },
  dropdownStyle: {
    marginTop: "21px",
    maxWidth: "190px",
    width: "100%",
    "& .MuiList-padding": {
      paddingTop: "4px",
      paddingBottom: "8px",
    },
    "& .MuiMenuItem-root": {
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0",
      color: "#000000",
      padding: "14px 17px",
      fontWeight: "500",
      fontFamily: "Expletus Sans",
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
      whiteSpace: "normal",
    },
    
  },
 
  paginationToolbar: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #e8ecf2",
    padding: "4px 0px 5px 16px",
    "& .member-count": {
      fontSize: "14px",
      letterSpacing: "0.1px",
      lineHeight: "20px",
      color: "rgba(43,43,43,0.87)",
      fontWeight: "500",
      fontFamily: "Expletus Sans",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
        letterSpacing: "0",
        lineHeight: "16px",
        color: "#2B2B2B",
      },
      "& .delete-icon": {
        marginLeft: "23px",
        cursor: "pointer",
      },
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 23px 7px 14px",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  paginationTable: {
    "& .MuiTablePagination-toolbar": {
      minHeight: "unset",
    },
    "& .MuiTablePagination-caption": {
      opacity: "0.671",
      fontSize: "14px",
      letterSpacing: "0.1px",
      lineHeight: "20px",
      color: "#2b2b2b",
      fontWeight: "500",
      fontFamily: "Expletus Sans",
    },
    "& .MuiTablePagination-actions": {
      "& .MuiIconButton-root": {
        color: "#2b2b2b",
        padding: "6px",
        "&.Mui-disabled": {
          opacity: "0.369",
        },
      },
    },
    "& .MuiSelect-select": {
      fontFamily: "Expletus Sans",
    },
  },
}));

export interface MembersData {
  memId: number;
  isOriginalOwner: boolean;
  name: string;
  lastname: string;
  email: string;
  team: any;
  job_role: any;
  job_type: any;
  joinDate: any;
  hourly_pay: any;
  deactivated: boolean;
  invite_accepted: boolean;
  country: string;
  state:string;
  postal_code:string;
  city:string;
  company_place_address:string,
  company_name: string,
}

export function createData(
  memId: number,
  isOriginalOwner: boolean,
  name: string,
  lastname: string,
  email: string,
  team: any,
  job_role: any,
  job_type: any,
  joinDate: any,
  hourly_pay: any,
  deactivated: boolean,
  invite_accepted: boolean,
  country: string,
  state:string,
  postal_code:string,
  city:string,
  company_place_address:string,
  company_name: string,
): MembersData {
  return {
    memId,
    isOriginalOwner,
    name,
    lastname,
    email,
    team,
    job_role,
    job_type,
    joinDate,
    hourly_pay,
    deactivated,
    invite_accepted,
    country,
    state,
    postal_code,
    city,
    company_place_address,
    company_name,
  };
}

export interface TeamsData {
  id: any;
  name: any;
  users: any;
  actions: any;
}

export function createTeams(
  id: any,
  name: any,
  users: any,
  actions: any
): TeamsData {
  return { id, name, users, actions };
}

export function createIndividualData(
  memId: number,
  isOriginalOwner: boolean,
  name: string,
  lastname: string,
  email: string,
  job_role: any,
  job_type: any,
  hourly_pay: any,
  joinDate: any,
  team: any,
  country: string,
  state:string,
  postal_code:string,
  city:string,
  company_place_address:string,
  company_name: string,
  deactivated: boolean,
) {
  return {
    memId,
    isOriginalOwner,
    name,
    lastname,
    email,
    job_role,
    job_type,
    hourly_pay,
    joinDate,
    team,
    country,
    state,
    postal_code,
    city,
    company_place_address,
    company_name,
    deactivated,
  };
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


export function formatDate(dateString: any) {
  const currentdate = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const formattedDate = currentdate.toLocaleDateString("en-US", options);
  return formattedDate;
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilized = array.map((el, index) => [el, index] as [T, number]);
  stabilized.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilized.map((el) => el[0]);
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
type Order = "asc" | "desc";




interface TeamMemberHeadProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSortTeams?: (
    event: React.MouseEvent<unknown>,
    property: keyof TeamsData
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order?: string;
  orderBy?: string;
  rowCount: number;
  tableType: string;
  membersType: boolean;
  hideActionColumn?: boolean;
  userRole: string;
  moduleType: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof MembersData;
  label: string;
  numeric: boolean;
}

export function TeamMemberHead(props: TeamMemberHeadProps) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSortTeams,
    tableType,
    membersType,
    hideActionColumn,
    userRole,
    moduleType="teams",
  } = props;

  let headCells;
  const tabName: string = moduleType==="groups" ? "Group" : "Team";
  const t = (key:any, variables?: Record<string, any>) => {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  if (tableType === "teams") {
    headCells = [
      {
        id: "id",
        numeric: false,
        disablePadding: true,
        label: `${tabName} Name`,
      },
      { id: "users", numeric: false, disablePadding: false, label: "Users" },
    ];
    if (!hideActionColumn) {
      if(userRole !== "employee")
      headCells.push({
        id: "actions",
        numeric: false,
        disablePadding: false,
        label: "Actions",
      });
    }
  } else {
    if (membersType) {
      headCells = [
        { id: "name", numeric: false, disablePadding: true, label: "Name" },
        { id: "email", numeric: false, disablePadding: false, label: "Email" },
        {
          id: "joinDate",
          numeric: false,
          disablePadding: false,
          label: t("Joined Date"),
        },
      ];
    } else {
      headCells = [
        { id: "name", numeric: false, disablePadding: true, label: "Name" },
        { id: "email", numeric: false, disablePadding: false, label: "Email" },
        { id: "team", numeric: false, disablePadding: false, label: "Teams" },
        {
          id: "joinDate",
          numeric: false,
          disablePadding: false,
          label: t("Joined Date"),
        },
      ];
    }
    if (!hideActionColumn) {
      if(userRole !== "employee"){
      headCells.push({
        id: "actions",
        numeric: false,
        disablePadding: false,
        label: "Actions",
      });
    }
    }
  }
  

  const getPadding = (disablePadding: boolean, userRole: string) => {
    if (disablePadding) {
      return userRole !== "employee" ? "none" : "0px 0px 0px 8px";
    }
    return "normal";
  };


  return (
    <TableHead>
      <TableRow>
      {!hideActionColumn && userRole !== "employee" && (
          <TableCell padding="checkbox">
            {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          /> */}
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            style={{padding: getPadding(headCell.disablePadding, userRole)}}

          >
            <TableSortLabel
            >
              {t(`${headCell.label}`)}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  highlight:
  theme.palette.type === "light"
    ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
    : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
title: {
  flex: "1 1 100%",
},
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
 
}));
// Customizable Area End